/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
/* load core modules end */

/**
 * load child components
 */
import ImageButton from "../ImageButton";
/* load child components end */

/**
 * load images
 */
const moreIcon = process.env.PUBLIC_URL + "/images/plus-icon.svg";
/* load images end */

/* Styled Components */
export const MoreButtonWrapper = styled.div(
  ({ theme }) => `
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  background-color: ${theme.colours.greyLight};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: end;

  img {
    height: 18px;
    width: 18px;
  }
`
);
/* Styled Components end */

/**
 * CTA to show more information modal when clicked.
 * @component
 */
const MoreButton = ({ onClickHandler }) => {
  const { t: translate } = useTranslation();
  const theme = useTheme();
  return (
    <MoreButtonWrapper theme={theme}>
      <ImageButton
        src={moreIcon}
        onClickHandler={onClickHandler}
        testid="moreIcon"
        buttonContainerStyles="button-container"
        alt={translate("more")}
        tooltipDirection="top"
      />
    </MoreButtonWrapper>
  );
};

MoreButton.propTypes = {
  onClickHandler: PropTypes.func,
};

export default MoreButton;
