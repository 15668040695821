import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
/**
 * load styled components
 */
import {
  ModalContentContainer,
  PopUpContainer,
  ModalPopUp,
  Title,
  Content,
  Subtitle,
  Button,
  ButtonContent,
} from "./Purchase4KWarningModal.styles";

const Purchase4KWarningModal = ({ title, subtitle, onConfirm, onCancel, isClosable }) => {
  const { t: translate } = useTranslation();

  return (
    <React.Fragment>
      <ModalContentContainer>
        <PopUpContainer>
          <ModalPopUp>
            <Title>{title}</Title>
            <Content>
              <Subtitle>
                <p>{subtitle}</p>
              </Subtitle>
              {isClosable ? (
                <Button type="confirm" onClick={onCancel}>
                  <ButtonContent>{translate("ok")}</ButtonContent>
                </Button>
              ) : (
                <>
                  <Button
                    type="confirm"
                    onClick={(event) => {
                      event.stopPropagation();
                      onConfirm();
                    }}
                  >
                    <ButtonContent>{translate("confirm")}</ButtonContent>
                  </Button>
                  <Button type="cancel" onClick={onCancel}>
                    <ButtonContent>{translate("cancel")}</ButtonContent>
                  </Button>
                </>
              )}
            </Content>
          </ModalPopUp>
        </PopUpContainer>
      </ModalContentContainer>
    </React.Fragment>
  );
};

Purchase4KWarningModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

export default Purchase4KWarningModal;
