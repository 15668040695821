import styled from "styled-components";

// Wrapper for the input container
export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// Input field styling
export const TextInputStyled = styled.input`
  background-color: ${({ theme }) => theme.colours.translucentWhite};
  padding: 12px 16px;
  font-family: ${({ theme }) => theme.typography.regularFont};
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  color: ${({ theme }) => theme.colours.white};
  line-height: 27px;
  border: 0;
  border-radius: 8px;

  &.invalid,
  &.invalid:focus {
    outline: 1px solid ${({ theme }) => theme.colours.red};
  }
`;

export const TextInputError = styled.span`
  padding: 4px 16px 0;
  color: ${({ theme }) => theme.colours.red};
  font-family: ${({ theme }) => theme.typography.regularFont};
  font-size: ${({ theme }) => theme.typography.smallFontSize};
  line-height: 16px;
`;
// Container to hold the input and icon
export const InputWithIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
`;

export const InputIcon = styled.img`
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const StyledTextInput = styled.input`
  width: 100%;
  padding-right: 40px;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colours.translucentCloudyGray};
  color: ${({ theme }) => theme.colours.dimGray};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colours.dimGray};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colours.dimGray};
  }

  &.invalid {
    border-color: ${({ theme }) => theme.colours.red};
  }
`;
