/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
/* load core modules end */

/**
 * load child components
 */
import MoreButton, { MoreButtonWrapper } from "./MoreButton";
/* load child components end */

/* Styled Components */
const ClusterWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isWallContentUI",
})`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: fit-content;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  height: fit-content;
  gap: 40px;
  margin: ${({ isWallContentUI }) => (isWallContentUI ? `0 0 0 ${45}` : `3px ${0}`)}px;

  &:hover {
    background-color: ${({ theme }) => theme.colours.focusedRecordingButtonBackground};
    ${MoreButtonWrapper} {
      background-color: ${({ theme }) => theme.colours.greyExtraLight};

      .btn-tooltip {
        visibility: visible;
        opacity: 1;
        background-color: ${({ theme }) => theme.colours.greyExtraLight};
        top: -10px;
      }
    }
  }

  .content {
    padding-left: 10px;
    margin-top: 10px;
  }

  p {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }

  .wall-button-wrapper {
    display: none;
  }
`;
/* Styled Components end */

/**
 * An interactive wrapper for the information on details page wall,
 * to show more information modal when clicked.
 * @component
 */
const InformationCluster = ({ children, isWallContentUI, onInformationClusterClick }) => (
  <ClusterWrapper onClick={onInformationClusterClick} isWallContentUI={isWallContentUI}>
    {children}
    <MoreButton onClickHandler={onInformationClusterClick} />
  </ClusterWrapper>
);

InformationCluster.propTypes = {
  children: PropTypes.node.isRequired,
  isWallContentUI: PropTypes.bool,
  onInformationClusterClick: PropTypes.func,
};

export default InformationCluster;
