import styled from "styled-components";

export const InternetPageWrapper = styled.div`
  width: 900px;
  max-width: 100%;
  margin: 64px auto;

  @media only screen and (max-width: 1366px) {
    margin-top: 32px;
  }

  @media only screen and (max-width: 768px) {
    width: 550px;
    margin-top: 53px;
  }
`;

export const InternetHeading = styled.h1`
  font: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.primaryFontColor};
  font-size: ${({ theme }) => theme.largeFontSize};
`;

export const InternetDescription = styled.p`
  margin-top: 50px;
  margin-bottom: 24px;
  font: ${({ theme }) => theme.regularFont};
  color: ${({ theme }) => theme.gray};
  font-size: ${({ theme }) => theme.mediumFontSize};
  white-space: pre-wrap;
`;
