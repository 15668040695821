import styled from "styled-components";

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colours.black};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  top: 0;
  overflow: auto;
`;

export const Modal = styled.div`
  width: 800px;
  max-width: 85%;
  text-align: left;
  background-color: ${({ theme }) => theme.colours.scheduleBg};
  border-radius: 10px;

  .heading-row {
    padding: 40px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 28px;
      line-height: 34px;
    }

    p {
      max-width: 600px;
      margin: 0;
      font-family: ${({ theme }) => theme.typography.primaryFont};
      color: ${({ theme }) => theme.colours.white};
      font-size: ${({ theme }) => theme.typography.largeFontSizeHd};
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .kids-ratings-description {
    margin: 0;
    padding: 0 32px 24px;
    regularFont: normal 24px/29px Open Sans, sans-serif,
    font-family: ${({ theme }) => theme.typography.regularFont};
    font-size: ${({ theme }) => theme.typography.mediumFontSize};
    line-height: 27px;
  }

  .scroll-container {
    max-height: 65vh;
    overflow: auto;
    padding: 0 32px;

    .rating-group {
      border-bottom: 1px solid ${({ theme }) => theme.colours.scrollGrey};
      padding: 24px 0 16px;
      font-family: ${({ theme }) => theme.typography.regularFont};
      font-size: ${({ theme }) => theme.typography.mediumFontSize};

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 40px;
      }

      .rating-heading {
        font-weight: bold;
        line-height: 27px;
      }

      .rating-definition {
        display: flex;
        margin-top: 16px;
        font-size: ${({ theme }) => theme.typography.mediumSwimlaneFontSize};
        line-height: 24px;
        color: ${({ theme }) => theme.colours.playerBufferIndicator};
      }

      .rating-value {
        width: 72px;
      }

      .rating-description {
        flex: 1;
      }
    }
  }
`;
