import styled, { keyframes } from "styled-components";

const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
  `;

const Animation = styled.div`
  animation: ${placeHolderShimmer} 10s infinite linear;
`;

const FeedPageTopAnimation = styled.div`
  animation: ${placeHolderShimmer} 5s infinite linear;
`;

const FeedPageSkeletonContainer = styled.div(
  () => `
  height: 100vh;
  display: flex;
`
);

const FeedPageSkeletonBox = styled.div(
  ({ $isMovieDetails }) => `
  position: fixed;
  padding-left: 48px;
  margin-top: ${$isMovieDetails ? "356px" : "180px"};
`
);

const FeedPageDescLine = styled(FeedPageTopAnimation)(
  ({ $isLastLine }) => `
  width: ${$isLastLine ? "300px" : "672px"};
  height: ${$isLastLine ? "40px" : "24px"};
  margin-bottom: ${$isLastLine ? "0" : "16px"};
  margin-top: ${$isLastLine && "25px"};
  border-radius: 4px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
`
);

const FeedPageSwimlaneSkeletonWrapper = styled.div(
  () => `
  position: fixed;
  top: 638px;
  `
);

const SwimlaneSkeletonBox = styled.div(({ $isFeedPage, $isDetailsPage }) =>
  $isFeedPage || $isDetailsPage
    ? `
  display: flex;
  flex-wrap: ${$isDetailsPage && "wrap"};
  `
    : `
  @supports (display: grid) {
    padding: 24px 42px 0 38px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(161px, 1fr));
    grid-column-gap: 20px;
}`
);

const SwimlaneSkeletonBody = styled(Animation)(
  ({ $isFeedPage, $height, $width, $isDetailsPage }) => `
  min-width: ${$isDetailsPage ? "438px" : $isFeedPage ? `${$width}px` : "170px"};
  height: ${$isFeedPage || $isDetailsPage ? `${$height}px` : "253px"};
  margin-bottom: ${$isFeedPage || $isDetailsPage ? "100px" : "70px"};
  margin-right: ${($isFeedPage || $isDetailsPage) && "20px"};
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  `
);

const SkeletonDetailMovie = styled.div(
  () => `
  position: relative;
  min-height: 797px;
  `
);

const SkeletonDetailContentWrapper = styled.div(
  () => `
    position: absolute;
    bottom: 0;
    margin-bottom: 60px;
    padding-left: 45px;
  `
);

const DetailSkeletonScheduleTable = styled.div(
  ({ theme }) => `
    background-color: ${theme.colours.focusedRecordingButtonBackground};
    overflow: hidden;
    padding: 35px 45px 0;
  `
);

const DetailsPageSkeletonButton = styled(Animation)(
  () => `
  width: 438px;
    height: 50px;
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 30px;
  `
);

export {
  FeedPageSkeletonContainer,
  FeedPageSkeletonBox,
  FeedPageDescLine,
  FeedPageSwimlaneSkeletonWrapper,
  SwimlaneSkeletonBody,
  SwimlaneSkeletonBox,
  SkeletonDetailMovie,
  SkeletonDetailContentWrapper,
  DetailSkeletonScheduleTable,
  DetailsPageSkeletonButton,
};
