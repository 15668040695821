import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../shared/hooks/useWindowSize";
import ScheduleItem from "../ScheduleItem";
import OnDemandItem from "../OnDemandItem";
import constants from "../../shared/constants";
import { isGhostChannel } from "../../shared/utils/epg";
import { sortShowtimes, shouldFilterSchedule, findContainerByLayout } from "../../shared/utils";
import "./style.scss";
import { useReducers } from "../../shared/hooks/useReducer";

const dropdownIcon = `${process.env.PUBLIC_URL}/images/white-chevron.svg`;
const { ON_DEMAND_OPTIONS, REDUCER_TYPE, CONTAINER_LAYOUTS } = constants;
const MORE_ITEMS_ROW_COUNT = 4;
let displayItems = 0;

const Schedules = React.memo(
  ({
    schedules,
    onItemClick,
    allOnDemandItems,
    toastClickHandler,
    openRecordingModal,
    vodPlaybackType,
    assetDuration,
    isGeoBlocked,
    assetContainers,
    is4KPlayRestricted,
  }) => {
    const {
      provider: appProvider,
      subscribedChannels,
      isInHome,
      channelMapInfo,
      featureToggles,
    } = useReducers(REDUCER_TYPE.APP);
    const { isLookbackEnabled } = featureToggles;
    const [windowWidth] = useWindowSize();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [showMoreItems, setShowMoreItems] = useState(false);
    const [showAllItems, setShowAllItems] = useState(false);

    const { t: translate } = useTranslation();

    useEffect(() => {
      return () => {
        setCurrentTabIndex(0);
      };
    }, []);

    useEffect(() => {
      if (windowWidth <= 1366 && windowWidth > 1024) {
        displayItems = 3;
      } else {
        displayItems = 4;
      }
    }, [windowWidth]);

    const programSchedules = useMemo(() => {
      const programSchedules = [];
      if (schedules && schedules.length) {
        const setScheduleEntitlements = (schedule) => {
          const channelID = schedule?.channel?.channelId;
          const currentChannel = channelMapInfo?.containers?.find((channel) => parseInt(channel.id) === channelID);
          const isChannelRecordable = channelMapInfo?.containers?.some(
            (channel) => parseInt(channel.id) === channelID && channel.metadata?.isRecordable
          );
          const currentSubscribedChannel = subscribedChannels?.containers?.find(
            (channel) => channel.metadata.channelId === channelID
          );

          const modifiedSchedule = { ...schedule };
          modifiedSchedule.channelImg = currentChannel ? currentChannel.assets[0].logoBig : "";
          modifiedSchedule.isChannelRecordable = isChannelRecordable ? true : false;
          modifiedSchedule.isPlayable = currentSubscribedChannel ? true : false;
          modifiedSchedule.isChannelSubscribed = modifiedSchedule.isPlayable;
          modifiedSchedule.isBlockedOutOfHome = currentSubscribedChannel?.metadata?.isNotAvailableOutOfHome;
          modifiedSchedule.is4KChannel = isGhostChannel(currentChannel);
          modifiedSchedule.channel = currentChannel;
          return modifiedSchedule;
        };

        const filteredSchedules = schedules
          .map((schedule) => {
            if (schedule?.metadata) {
              const modifiedSchedule = setScheduleEntitlements(schedule);
              return modifiedSchedule;
            }
            return schedule;
          })
          .filter((schedule) => !shouldFilterSchedule(schedule, isLookbackEnabled, isInHome));

        sortShowtimes(filteredSchedules, appProvider?.channelMapId);

        filteredSchedules.forEach((schedule) => {
          programSchedules.push(
            <ScheduleItem
              key={schedule?.id}
              schedule={schedule}
              toastClickHandler={toastClickHandler}
              openRecordingModal={openRecordingModal}
            />
          );
        });
      }

      return programSchedules;
    }, [
      channelMapInfo,
      isInHome,
      openRecordingModal,
      schedules,
      subscribedChannels,
      toastClickHandler,
      isLookbackEnabled,
      appProvider,
    ]);

    const isSchedulesTabActive = currentTabIndex === 0 && programSchedules?.length > 0;
    const isOnDemandTabActive = !isSchedulesTabActive && allOnDemandItems?.length > 0;

    const onAccordionClick = () => {
      if (isSchedulesTabActive) {
        programSchedules.length > displayItems * MORE_ITEMS_ROW_COUNT && showMoreItems === false
          ? setShowMoreItems(!showMoreItems)
          : showAllClickHandler();
      } else if (isOnDemandTabActive) {
        showAllClickHandler();
      }
    };
    const showAllClickHandler = () => {
      setShowAllItems(!showAllItems);
      if (isSchedulesTabActive) {
        setShowMoreItems(!showAllItems);
      }
    };
    const onTabClick = (evt, index) => {
      const tabLinks = document.getElementsByClassName("schedules-table-header");
      for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].className = tabLinks[i].className.replace(" active", "");
      }
      evt.currentTarget.className += " active";
      setCurrentTabIndex(index);
    };
    const getOnDemandElements = () => {
      if (allOnDemandItems?.length > 0) {
        let rentOption = null;
        let buyOption = null;

        return allOnDemandItems.reduce(
          (result, item, index) => {
            const element = (
              <OnDemandItem
                key={index}
                content={item}
                onItemClick={onItemClick}
                toastClickHandler={toastClickHandler}
                vodPlaybackType={vodPlaybackType}
                progressPercentage={getProgressPercentage(item.bookmarks, assetDuration)}
                isComplete={item.bookmarks?.[0]?.isComplete}
                isGeoBlocked={isGeoBlocked}
                is4KPlayRestricted={is4KPlayRestricted}
              />
            );
            const isAlreadyInWatchOptions = result.watchOptions.some(
              (existingItem) => existingItem.props.content.id === item.id
            );
            if (item.btnType === ON_DEMAND_OPTIONS.RENT && !rentOption) {
              rentOption = element;
              result.orderOptions.push(element);
            } else if (item.btnType === ON_DEMAND_OPTIONS.BUY && !buyOption) {
              buyOption = element;
              result.orderOptions.push(element);
            } else if (
              item.btnType !== ON_DEMAND_OPTIONS.RENT &&
              item.btnType !== ON_DEMAND_OPTIONS.BUY &&
              !isAlreadyInWatchOptions
            ) {
              result.watchOptions.push(element);
            }
            return result;
          },
          {
            orderOptions: [],
            watchOptions: [],
          }
        );
      }
      return null;
    };

    const onDemandElements = getOnDemandElements();
    const hasWatchOptions = onDemandElements?.watchOptions?.length > 0;
    const hasOrderOptions = onDemandElements?.orderOptions?.length > 0;
    const onDemandContainer = findContainerByLayout(assetContainers, CONTAINER_LAYOUTS.ON_DEMAND);
    const showTimesContainer = findContainerByLayout(assetContainers, CONTAINER_LAYOUTS.SHOW_TIMES);

    const shouldShowAccordion = () => {
      if (isSchedulesTabActive) {
        return programSchedules.length > displayItems;
      } else if (isOnDemandTabActive) {
        return onDemandElements?.watchOptions?.length > displayItems || (hasWatchOptions && hasOrderOptions);
      }
    };
    const getAccordionLabel = () => {
      if (isSchedulesTabActive) {
        return programSchedules.length > displayItems * MORE_ITEMS_ROW_COUNT && showMoreItems === false
          ? `${translate("more")} ${translate("airings")}`
          : translate("all_airings");
      } else if (isOnDemandTabActive) {
        return translate("all_on_demand");
      }
    };
    return programSchedules?.length || allOnDemandItems?.length ? (
      <div className="schedules-table">
        <div className="schedule-header">
          {programSchedules?.length ? (
            <div className="schedules-table-header active" onClick={(e) => onTabClick(e, 0)}>
              {showTimesContainer?.title}
            </div>
          ) : null}
          {allOnDemandItems?.length ? (
            <div
              className={`schedules-table-header ${!programSchedules || !programSchedules.length ? "active" : ""}`}
              onClick={(e) => onTabClick(e, 1)}
            >
              {onDemandContainer?.title}
            </div>
          ) : null}
        </div>
        {isSchedulesTabActive ? (
          <div className="schedules-list">
            <ul className="schedules">
              {showAllItems
                ? programSchedules
                : showMoreItems
                ? programSchedules.slice(0, displayItems * MORE_ITEMS_ROW_COUNT)
                : programSchedules.slice(0, displayItems)}
            </ul>
          </div>
        ) : null}
        {isOnDemandTabActive ? (
          <div className="onDemand-list">
            {hasWatchOptions && (
              <ul className="onDemand">
                {showAllItems ? onDemandElements?.watchOptions : onDemandElements?.watchOptions.slice(0, displayItems)}
              </ul>
            )}
            {hasOrderOptions && (!hasWatchOptions || showAllItems) && (
              <>
                <p>{translate("order_options")}</p>
                <ul className="onDemand">{onDemandElements?.orderOptions}</ul>
              </>
            )}
          </div>
        ) : null}

        {shouldShowAccordion() ? (
          <div className="accordion-btn-wrapper">
            <div className="accordion" onClick={onAccordionClick}>
              <div>{getAccordionLabel()}</div>
              <div className={showAllItems ? "icon-up" : "icon-down"}>
                <img src={dropdownIcon} alt="dropdown" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  }
);

/**
 * Get watched progress as a percentage using the most recent bookmark
 * @param {Array} bookmarks Array of bookmarks for this asset
 * @param {Number} duration From asset metadata
 * @returns {Number}
 */
const getProgressPercentage = (bookmarks, duration) => {
  if (bookmarks?.[0]?.startDeltaTime && !bookmarks?.[0]?.isComplete && duration) {
    return (bookmarks?.[0]?.startDeltaTime / duration) * 100;
  }
  return null;
};

Schedules.defaultProps = {
  onItemClick: () => {},
  openRecordingModal: () => {},
  assetDuration: undefined,
};

export default Schedules;
