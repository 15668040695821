/**
 * Wrapper for tracking custom page actions
 * @param {String} pageActionName
 * @param {Object} attributes Custom attributes that are specific to the action
 */
export const logNREvent = (pageActionName, attributes = {}) => {
  if (!window.newrelic || !pageActionName) {
    return;
  }
  window.newrelic.addPageAction(pageActionName, attributes);
};

/**
 * Set a custom attribute that will be added to subsequent events/page actions
 * @param {String} attributeName
 * @param {String|Integer|Boolean} value
 */
export const setNRAttribute = (attributeName, value) => {
  if (!window.newrelic || !attributeName || !value) {
    return;
  }
  window.newrelic.setCustomAttribute(attributeName, value?.toString() ?? value);
};

/**
 * Logs errors to new relic
 * @param {Object} errorObj - Error object to log
 * @param {Object} attributes Custom attributes that are specific to the error
 */
export const logNRError = (errorObj, attributes = {}) => {
  if (!window.newrelic || !errorObj) {
    return;
  }
  window.newrelic.noticeError(errorObj, attributes);
};
