import React from "react";
import { useTranslation } from "react-i18next";
import SeoPageTags from "../../components/SeoPageTags";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { getLocalStorage } from "../../shared/utils/localStorage";
import constants from "../../shared/constants";
import routes from "../../shared/constants/routes";
import { Redirect } from "react-router-dom";
import { useReducers } from "../../shared/hooks/useReducer";
import { InternetPageWrapper, InternetHeading, InternetDescription } from "./InternetPage.styles";

function InternetPage(props) {
  const { REDUCER_TYPE } = constants;
  const { provider: appProvider, userProfile } = useReducers(REDUCER_TYPE.APP);
  const { SETTINGS } = routes;
  const { t } = useTranslation();

  const deviceId = getLocalStorage("deviceId");
  useTrackPageView({ page: "InternetPage", deviceId });

  const isSPLUS = appProvider.userPropertyName === constants.USER_PROPERTY_NAMES.SPLUS;

  if (userProfile?.user?.profile?.profileData?.isMasterAccount === "N") {
    return <Redirect to={SETTINGS.route} />;
  }

  return (
    <React.Fragment>
      <SeoPageTags title={t("internet_data_usage")} keywords={["optik", "telus"]} />
      <InternetPageWrapper data-id="internet-page">
        <InternetHeading>{t("internet_data_usage")}</InternetHeading>
        {isSPLUS ? (
          <InternetDescription data-id="internet-description-splus">
            {t("message_internet_usage_byod_splus")}
          </InternetDescription>
        ) : (
          <InternetDescription data-id="internet-description">{t("message_internet_usage")}</InternetDescription>
        )}
      </InternetPageWrapper>
    </React.Fragment>
  );
}

export default InternetPage;
