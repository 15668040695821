import React from "react";
import { useTranslation } from "react-i18next";

import BackButton from "../../components/ImageButton";
import InformationCluster from "../../components/InformationCluster";

import { convertToPercentageString } from "../../shared/utils";
import { getAVSKeyArtImage, handleImageError } from "../../shared/utils/image";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { getEpisodeInfoLine1 } from "../../shared/utils/feedHelper";

import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { useReducers } from "../../shared/hooks/useReducer";

import constants from "../../shared/constants";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";

const { IMAGES, REDUCER_TYPE } = constants;
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";
const icon = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";
const liveBadgeIcon = process.env.PUBLIC_URL + "/images/Live_Badge.svg";

const EpisodeWallContent = ({
  playbackId,
  progressTime,
  contentMetadata,
  isRecordingNow,
  hasCC,
  hasDV,
  hasUHD,
  hasHDR,
  seriesInfo,
  children,
  isStandAloneEpisode,
  episodeTitle,
  onInformationClusterClick,
  firstLiveProgram,
  isSportsEventPage,
}) => {
  const { t: translate } = useTranslation();
  const { featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { is4KVodEnabled } = featureToggles;

  const { isAppLanguageFrench } = useAppLanguage();

  const getProgressPercentage = () => {
    if (progressTime > 0 && contentMetadata?.metadata?.duration) {
      return (progressTime / contentMetadata.metadata.duration) * 100;
    }
  };

  // Re-direct to series page
  const linkClickHandler = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SERIES_LINK};${LINK_INFO.SUB_NAV}`);
    window.location.href = seriesInfo?.url;
  };

  return (
    <>
      <div className="episode-content">
        <div className="episode-poster">
          <img
            src={`${getAVSKeyArtImage(contentMetadata?.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16)}?w=485`}
            alt=""
            onError={(e) => handleImageError(e, icon)}
          ></img>
          {progressTime && playbackId ? (
            <div className="ep-progress-bar-container">
              <div
                className="ep-progress-bar"
                style={{
                  width: convertToPercentageString(getProgressPercentage()),
                }}
              ></div>
            </div>
          ) : null}
        </div>
        <div className="episode-desc">
          {isStandAloneEpisode ? null : (
            <div
              className={seriesInfo?.url ? "episode-back-btn btn-cursor" : "episode-back-btn"}
              onClick={seriesInfo?.url ? linkClickHandler : () => {}}
            >
              <span>{seriesInfo?.name}</span>
              {seriesInfo?.url ? <BackButton src={VIEW_ALL_ICON} alt="viewAll" /> : null}
            </div>
          )}
          <InformationCluster onInformationClusterClick={onInformationClusterClick}>
            <div className="episode-details">
              <div className="episode-header">
                <h1 className={isStandAloneEpisode ? "no-series-header" : null}>{episodeTitle}</h1>
              </div>
              <h2>{getEpisodeInfoLine1(contentMetadata?.metadata, isAppLanguageFrench, isSportsEventPage)} </h2>
              <h2 className="eps-badge-wrap">
                {isRecordingNow ? (
                  <div className="recording-badge">
                    <img
                      src={
                        isAppLanguageFrench
                          ? process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg"
                          : process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg"
                      }
                      alt=""
                    />
                  </div>
                ) : null}
                {firstLiveProgram?.metadata?.extendedMetadata?.epg?.isLive ? (
                  <span className="live">
                    <img width={52} height={27} src={liveBadgeIcon} alt="liveBadgeIcon" />
                  </span>
                ) : firstLiveProgram?.metadata?.extendedMetadata?.epg?.isNew ? (
                  <span className="new">
                    <img src={process.env.PUBLIC_URL + "/images/New.svg"} alt="new-indicator" />
                  </span>
                ) : null}
                {hasUHD && hasHDR && is4KVodEnabled ? (
                  <span className="_4kHDR">
                    <img src={process.env.PUBLIC_URL + "/images/4K_HDR.svg"} alt="4k-indicator" />
                  </span>
                ) : null}
                {hasUHD && !hasHDR && is4KVodEnabled ? (
                  <span className="_4k">
                    <img src={process.env.PUBLIC_URL + "/images/4K_icon_grey.svg"} alt="4k-indicator" />
                  </span>
                ) : null}
                {hasCC ? (
                  <span className="cc">
                    <img src={process.env.PUBLIC_URL + "/images/CC.svg"} alt="cc-indicator" />
                  </span>
                ) : null}
                {hasDV ? (
                  <span className="dv">
                    <img src={process.env.PUBLIC_URL + "/images/DV.svg"} alt="dv-indicator" />
                  </span>
                ) : null}
              </h2>
              <p id="desc">{contentMetadata?.metadata?.longDescription || translate("no_information_available")}</p>
            </div>
          </InformationCluster>
        </div>
      </div>
      {children != null && children}
    </>
  );
};

export default EpisodeWallContent;
