import request from "../../service/request";
import constants from "../constants";
import recordingConstants from "../constants/recordingConstants";
import { addQueryParamsToString } from "../utils";
const { APP_ENVIRONMENTS, API_BASE_URL } = constants;
const { RECORDING_PARAMS } = recordingConstants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Schedules or updates recordings for MediaRoom LPVR and CPVR. Also used to stop in progress CPVR EVENT recordings.
 *
 * @param {Object} appProvider App provider information
 * @param {Boolean} isMR Flag indicating if context is MediaRoom or CPVR
 * @param {Object} recordingParams Object holding dynamic recording input params
 *
 * Supported non-backend-specific properties are:
 * @property {Boolean} recordingParams.isProgramOfSeries Flag indicating if selected record is a part of series or not
 * @property {String} recordingParams.recordingType The type of recording.
 *     Values can be:
 *       - "event" for single program recordings (episode or movie)
 *       - "series" for multi-program recordings (series)
 * @property {String} recordingParams.channelId ID of the channel to use for recording. If using MediaRoom, the value should be the MediaRoom-specific Station ID (gets mapped to AVS channelId on backend)
 * @property {String} recordingParams.programId ID of the program to record. If using MediaRoom, the value should be the MediaRoom-specific Program ID (gets mapped to AVS programId on backend)
 * @property {String} recordingParams.seriesId ID of the series to record. If using MediaRoom, the value should be the MediaRoom-specific Series ID (gets mapped to AVS seriesId on backend)
 * @property {String} recordingParams.recordId ID of the record you wish to update. If using MediaRoom, the value should be the MediaRoom-specific recording definition GUID
 * @property {Long} recordingParams.programStartTime UNIX timestamp when the specified program is airing. For series recordings, use the airing time of the episode from which recordings should start.
 * @property {String} recordingParams.episodeScope Indicating whether all episodes of a series should be recorded or only first-run episodes.
 *     If using MediaRoom, values can be:
 *       - "All" for all runs of the episode (includes re-runs)
 *       - "Firstrun" for only the first run of the episode
 *     If using CPVR, values can be:
 *       - "ALL" for all runs of the episode (includes re-runs)
 *       - "NEW" for only the first run of the episode
 *
 * Supported MediaRoom-specific properties are:
 * @property {Boolean} recordingParams.isAutoDeletionEnabled Indicates whether recordings will be automatically deleted when storage limit is reached
 * @property {String} recordingParams.title Program title
 * @property {Integer} recordingParams.channelNumber The regional channel number of the channel used to make the recording
 * @property {Integer} recordingParams.schedulingTime Used by the backend to determine when to schedule the recording.
 *     Values can be:
 *       - 0 for recording at a specific time specified via the programStartTime field in the body
 *       - 1 for recording at any time (programStartTime is ignored)
 *       - 2 for recording at any time (programStartTime is ignored) once per day, so reruns of the same episode are not recorded
 *
 * Supported CPVR-specific properties are:
 * @property {Long} recordingParams.recordingStartTime The recording start time of the asset in the DB (non user-specific recording information)
 * @property {Long} recordingParams.startDeltaTime The post-padding with respect to recordingStartTime for when you want to record the program
 *
 * @returns {Promise} request promise
 */
export const setRecordings = (appProvider, isMR = false, recordingParams = {}) => {
  const withCredentials = appProvider.userCluster === "R";
  let url,
    method = recordingParams.episodeScopeSeriesId || recordingParams.recordId ? "PUT" : "POST",
    data;

  if (isMR) {
    url = appProvider.config.avs_api_urls.recordings_url_MR;
    data = {
      recordingType: recordingParams.recordingType,
      channelId: recordingParams.channelId,
      programId: recordingParams.programId,
      programStartTime: recordingParams.programStartTime,
      isAutoDeletionEnabled: recordingParams.isAutoDeletionEnabled,
      postPadding: recordingParams.postPadding,
      title: recordingParams.title,
      duration: recordingParams.duration,
      channelNumber: recordingParams.channelNumber,
      seriesId: recordingParams.seriesId,
      episodeScope: recordingParams.episodeScope,
      schedulingTime: recordingParams.schedulingTime,
    };
    if (recordingParams.isProgramOfSeries) {
      data.recordGUID = recordingParams.recordId;
    } else {
      data.recordingDefinitionGUID = recordingParams.recordId;
    }
  } else {
    url = appProvider.config.avs_api_urls.CPVR.modify_recordings.replace(
      "$RECORDING_TYPE",
      recordingParams.recordingType?.toUpperCase()
    );
    data = {
      isAutoDeletionEnabled: true,
    };

    if (recordingParams.recordingType === RECORDING_PARAMS.EVENT) {
      // Currently the only thing we wish to edit for CPVR EVENT recordings is Channel & Time, and this only applies for scheduled recordings.
      // The AVS backend currently requires us to delete and create a new recording when "editing" the channel & time, so no PUT params are defined here
      // for editing EVENT recording settings.
      if (method === "POST") {
        data["channelId"] = recordingParams.channelId;
        data["programId"] = recordingParams.programId;
        data["programStartTime"] = recordingParams.programStartTime;
      } else {
        // To stop in progress recordings, we need to make a PUT call and include the recordingStartTime
        if (recordingParams.recordingStartTime) {
          data["recordId"] = recordingParams.recordId;
          data["startDeltaTime"] = recordingParams.startDeltaTime;
          data["recordingStartTime"] = recordingParams.recordingStartTime;
        }
      }
    } else {
      data["episodeScope"] = recordingParams.episodeScope;
      data["isChannelBoundEnabled"] = true;

      if (method === "POST") {
        // The POST call requires a 'seriesId' param that represents the content series ID
        data["seriesId"] = recordingParams.seriesId;
        data["channelId"] = recordingParams.channelId;
      } else {
        // The PUT call requires a `seriesId` param that represents the series recording ID
        data["seriesId"] = recordingParams.episodeScopeSeriesId?.toString() || recordingParams.seriesId?.toString();
      }
    }
  }

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method,
      headers: {
        "Content-Type": "application/json",
      },
      data,
      withCredentials,
    },
    null,
    appProvider
  );
};

/**
 * Fetches recordings for MediaRoom LPVR and CPVR.
 *
 * @param {Object} appProvider App provider information
 * @param {Boolean} isMR Flag indicating if context is MediaRoom or CPVR
 * @param {Boolean} includeMetadata CPVR-specific flag indicating which API endpoint to hit
 * @param {String} recordingType CPVR-specific string indicating which recording type endpoint to hit. Only required if includeMetadata = true
 * @param {Object} queryParams Dynamic object containing query param key-value pairs
 * @param {String} filterParams - (Recorded, Scheduled, Recording, Conflicted) MR-specific string to fetch the recordings.
 * @returns {Promise} request promise
 */
export const getRecordings = (
  appProvider,
  isMR = false,
  includeMetadata = true,
  recordingType,
  queryParams,
  filterParams
) => {
  const withCredentials = appProvider.userCluster === "R";
  const avsURLs = appProvider.config.avs_api_urls;
  let url;
  if (isMR) {
    url = avsURLs.recordings_url_MR;
    if (filterParams) {
      url += `?filter_status=${filterParams}`;
    }
  } else {
    if (includeMetadata) {
      url = avsURLs.CPVR.get_recordings_with_metadata.replace("$RECORDING_TYPE", recordingType?.toUpperCase());
    } else {
      url = avsURLs.CPVR.get_recordings;
    }
  }

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  if (queryParams) {
    url = addQueryParamsToString(url, queryParams, isMR && filterParams);
  }

  return request(
    {
      url,
      method: "GET",
      withCredentials,
    },
    null,
    appProvider
  );
};

/**
 * Deletes recordings for MediaRoom LPVR and CPVR
 *
 * @param {Object} appProvider App provider information
 * @param {Boolean} isMR Flag indicating if context is MediaRoom or CPVR
 * @param {Object} recordingParams Object holding dynamic recording input params
 *
 * Supported non-backend-specific properties are:
 * @property {String} recordingParams.recordingType The type of recording.
 *     Values can be:
 *       - "event" for single program recordings (episode or movie)
 *       - "series" for multi-program recordings (series)
 *
 * Supported MediaRoom-specific properties are:
 * @property {String} recordingParams.recordingDefinitionId The MediaRoom GUID of the recordingDefinition
 * @property {String} recordingParams.recordGUID The MediaRoom GUID of the recording
 * @property {Long} recordingParams.programStartTime UNIX timestamp - The MediaRoom recording's programStartTime
 * @property {Long} recordingParams.duration The MediaRoom recording's duration
 *
 * Supported CPVR-specific properties are:
 * @property {Boolean} recordingParams.deleteAllRecordings Flag indicating if all recordings should be deleted. If true, `recordingsToDelete` is not required
 * @property {Object[]} recordingParams.recordingsToDelete List of recording events to delete
 * @property {Long} recordingParams.recordingsToDelete[].recordId ID of the recording to delete
 * @property {Long} recordingParams.recordingsToDelete[].startDeltaTime Post-padding for the scheduled recording time
 * @property {Integer[]} recordingParams.seriesIds List of IDs for series recordings to delete
 * @property {Boolean} recordingParams.keepRecordedEpisodes Flag indicating if recorded and in-progress recordings should be kept or if all recordings should be deleted
 *
 * @returns request promise
 */
export const deleteRecordings = (appProvider, isMR = false, recordingParams = {}) => {
  const withCredentials = appProvider.userCluster === "R";
  let url, data;

  if (isMR) {
    url = appProvider.config.avs_api_urls.recordings_url_MR;
    data = {
      recordingdefinitionGuid: recordingParams.recordingdefinitionGuid,
      action: recordingParams.action,
      recordings: recordingParams.recordings,
    };
  } else {
    url = appProvider.config.avs_api_urls.CPVR.modify_recordings.replace(
      "$RECORDING_TYPE",
      recordingParams.recordingDeletePayload
        ? RECORDING_PARAMS.EVENT.toUpperCase()
        : recordingParams.recordingType.toUpperCase()
    );
    if (recordingParams.recordingType === RECORDING_PARAMS.EVENT && !recordingParams.recordingDeletePayload) {
      data = recordingParams.recordingsToDelete;
    } else {
      if (recordingParams.recordingDeletePayload) {
        data = recordingParams.recordingDeletePayload;
      } else {
        data = {
          seriesIds: recordingParams.seriesIds,
          isKeepRecordingsEnabled: recordingParams.keepRecordedEpisodes,
        };
      }
    }
  }

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      data,
      withCredentials,
    },
    null,
    appProvider
  );
};

/**
 * This API is triggered on app launch specifically for CPVR to get the recording profile object.
 * @param {Object} appProvider App provider information
 * @returns Object
 */
export const getUserProfileRecording = (appProvider) => {
  const AVS_API_LIST = appProvider.config.avs_api_urls;
  const withCredentials = appProvider.userCluster === "R";

  let url = AVS_API_LIST.user_profile_recording;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }

  return request(
    {
      url,
      method: "GET",
      withCredentials,
    },
    null,
    appProvider
  );
};

/**
 * Get bookmarks
 * @param {Object} appProvider
 * @returns {Promise}
 */
export function getRecordingsBookmarks(appProvider) {
  let url = appProvider.config.avs_api_urls.bookmarks;
  const withCredentials = appProvider.userCluster === "R";

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$USER_CLUSTER", "R");
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
  }
  return request(
    {
      url,
      method: "GET",
      withCredentials,
    },
    null,
    appProvider
  );
}
