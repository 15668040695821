import styled, { keyframes } from "styled-components";

// Keyframes for fadein/fadeout animations
const fadeIn = keyframes`
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 36px;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    right: 36px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
`;

// Styled container for the toast notification
export const ToastContainer = styled.div`
  position: fixed;
  max-width: 450px;
  height: auto;
  top: 118px;
  right: 36px;
  z-index: 11;
  padding: 24px 26px;
  border-radius: 11px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  visibility: hidden;
  font-size: 20px;
  line-height: inherit;
  color: ${({ dark }) => (dark ? "#ffffff" : "#000000")};
  background-color: ${({ dark }) => (dark ? "#1e1e1e" : "#ffffff")};

  &.show {
    visibility: visible;
    animation: ${fadeIn} 0.5s;
  }

  .icon-wrapper {
    padding-right: 25px;

    img {
      width: 37px;
      height: auto;
    }
  }
`;
