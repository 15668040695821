import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { showModalPopup } from "../../App/state/actions";
import PopUpOutline from "../PopUpOutline";
import AvatarButton from "../AvatarButton";
import constants from "../../shared/constants";
import errorConstants from "../../shared/constants/error";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import middleware from "../../shared/middleware";
import { trackWebAction } from "../../shared/analytics/dataLayer";
import { setLocalStorage } from "../../shared/utils/localStorage";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { sortAndFilterAvatars } from "../../shared/utils/userProfile";
import {
  ANALYTICS_ERROR_INFO,
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
} from "../../shared/constants/analytics";
import "./style.scss";

const { MODAL_TYPES, MAX_ACCOUNT_PROFILES, DEFAULT_AVATAR_CATEGORY } = constants;
const { ACTIVE_PROFILE_USER_ID } = storageConstants;
const { ERROR_TYPES } = errorConstants;

const { PROFILE_ADD, PROFILE_EDIT, SETTINGS, BASICS, ACCOUNT, SETTING_DETAIL, PARENTAL_PIN, PURCHASE_PIN } =
  routeConstants;

const settingsRoutes = [SETTINGS, BASICS, ACCOUNT, SETTING_DETAIL, PARENTAL_PIN, PURCHASE_PIN].map(
  (routeObject) => routeObject.route
);

const { logout, loginProfile, logoutProfile, getAvatarList } = middleware;

function ProfileMenu({ appProvider, userProfile, profiles, showModalPopup }) {
  const [isOnSettingsPage, setIsOnSettingsPage] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const menuHoverTimeout = useRef(null);
  const { t: translate } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const activeProfile = userProfile?.user?.profile?.profileData;
  const isMasterAccountLoggedIn = activeProfile?.isMasterAccount === "Y";
  // Show a default avatar if the master account profile has not been set up yet
  const showDefaultAvatar = isMasterAccountLoggedIn && !activeProfile.profileName;

  useEffect(() => {
    return () => {
      setShowDropdown(false);
    };
  }, []);

  useEffect(() => {
    if (showDefaultAvatar && !defaultAvatar) {
      getAvatarList(appProvider).then((result) => {
        if (result?.length > 0) {
          const sortedActiveAvatars = sortAndFilterAvatars(result);
          if (sortedActiveAvatars?.length > 0) {
            const defaultCategory = DEFAULT_AVATAR_CATEGORY.toLowerCase();
            const firstAvailableAvatar =
              sortedActiveAvatars.find((avatarInfo) => {
                return avatarInfo?.category?.toLowerCase()?.includes(defaultCategory);
              }) ?? sortedActiveAvatars[0];

            if (firstAvailableAvatar) {
              setDefaultAvatar(firstAvailableAvatar);
            }
          }
        }
      });
    }
  }, [appProvider, defaultAvatar, showDefaultAvatar]);

  useEffect(() => {
    setIsOnSettingsPage(settingsRoutes.includes(location.pathname));
  }, [location]);

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const logoutClickHandler = () => {
    trackWebAction(ANALYTICS_EVENT_TYPES.LOGOUT);
    logout(appProvider).catch((err) => {
      trackWebAction(ANALYTICS_EVENT_TYPES.ERROR, {
        name: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
        details: ANALYTICS_ERROR_INFO.LOGOUT_FAILURE,
        message: err.message,
        code: err.code,
      });
      console.error(err);
      showModalPopup(MODAL_TYPES.ERROR, {
        title: ERROR_TYPES.ERROR,
        message: "error_logout_message",
        isCloseable: true,
      });
    });
  };

  const menuMouseEnter = () => {
    setShowDropdown(true);
    if (menuHoverTimeout.current) {
      clearTimeout(menuHoverTimeout.current);
    }
  };

  const menuMouseLeave = () => {
    if (menuHoverTimeout.current) {
      clearTimeout(menuHoverTimeout.current);
    }
    menuHoverTimeout.current = setTimeout(() => {
      closeDropdown();
    }, 500);
  };

  return (
    <>
      <div className="profile-menu-container">
        <AvatarButton
          avatarInfo={activeProfile?.avatar ?? defaultAvatar}
          onMouseEnter={menuMouseEnter}
          onMouseLeave={menuMouseLeave}
          className={`profile-menu-avatar ${isOnSettingsPage ? "active" : ""}`}
          alt={translate("profiles_me")}
          isMenuMode={true}
          inPanic={appProvider.panicMode}
        />
      </div>
      {showDropdown && (
        <PopUpOutline
          className={`profile-menu-dropdown ${activeProfile?.kidsProfile ? "kids" : ""}`}
          handleOutsideComponentClick={closeDropdown}
        >
          <div className="profile-menu-item-container" onMouseEnter={menuMouseEnter} onMouseLeave={menuMouseLeave}>
            {profiles?.map(({ avatar, profileName, isMaster, kidsProfile, userId }) => {
              return userId !== activeProfile?.userId ? (
                <button
                  key={userId}
                  className="switch-profile-button"
                  onClick={async () => {
                    try {
                      if (!isMasterAccountLoggedIn) {
                        await logoutProfile(appProvider);
                      }
                      if (!isMaster) {
                        await loginProfile(appProvider, userId);
                      }
                      setLocalStorage(ACTIVE_PROFILE_USER_ID, userId);

                      history.push("/");
                      window.location.reload();
                    } catch (err) {
                      // TODO: How to handle switch profile errors?
                      console.error(err);
                    }
                  }}
                >
                  {avatar?.displayImages?.[0]?.imageUrl ? (
                    <img className="avatar" src={avatar.displayImages[0].imageUrl} alt="" />
                  ) : (
                    <div className="avatar-placeholder" />
                  )}
                  <div className="profile-text">
                    <span className={`profile-name ${profileName?.length > 16 ? "overflow" : ""}`}>{profileName}</span>
                    {isMaster && <span className="profile-type">{translate("admin")}</span>}
                    {kidsProfile && <span className="profile-type">{translate("pin_profile_kids")}</span>}
                  </div>
                </button>
              ) : null;
            })}
            {isMasterAccountLoggedIn && profiles?.length < MAX_ACCOUNT_PROFILES && (
              <Link
                to={PROFILE_ADD.route}
                className="profile-menu-link"
                onClick={() => {
                  closeDropdown();
                  setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.ADD_PROFILE};${LINK_INFO.PROFILE_MENU}`);
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/images/plus-icon.svg`} alt="" />
                <span>{translate("add_profile")}</span>
              </Link>
            )}
            {isMasterAccountLoggedIn ? (
              <Link
                to={PROFILE_EDIT.route}
                className="profile-menu-link"
                onClick={() => {
                  closeDropdown();
                  setSessionStorage(
                    ANALYTICS_STORAGE_KEYS.LINK,
                    `${LINK_INFO.EDIT_PROFILES};${LINK_INFO.PROFILE_MENU}`
                  );
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/images/Pen_Icon.svg`} alt="" />
                <span>{translate("profiles_edit")}</span>
              </Link>
            ) : (
              <Link
                to={`${PROFILE_EDIT.route}/${activeProfile?.userId}`}
                className="profile-menu-link"
                onClick={() => {
                  closeDropdown();
                  setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.EDIT_PROFILE};${LINK_INFO.PROFILE_MENU}`);
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/images/Pen_Icon.svg`} alt="" />
                <span>{translate("edit_profile")}</span>
              </Link>
            )}
            <Link
              to={SETTINGS.route}
              className="profile-menu-link"
              onClick={() => {
                closeDropdown();
                setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SETTINGS};${LINK_INFO.PROFILE_MENU}`);
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/images/gear-icon.svg`} alt="" />
              <span>{translate("settings")}</span>
            </Link>
            {!activeProfile?.kidsProfile && (
              <>
                <hr className="divider" />
                <button className="logout-button" onClick={logoutClickHandler}>
                  <img src={`${process.env.PUBLIC_URL}/images/exit-icon.svg`} alt="" />
                  <span>{translate("logout")}</span>
                </button>
              </>
            )}
          </div>
        </PopUpOutline>
      )}
    </>
  );
}

ProfileMenu.propTypes = {
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  profiles: PropTypes.array,
  showModalPopup: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  appProvider: app.provider,
  userProfile: app.userProfile,
  profiles: app.profileList,
});

const mapDispatchToProps = {
  showModalPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
