/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useReducers } from "../../shared/hooks/useReducer";
/* load core modules end */

/**
 * load styled components
 */
import {
  SeriesTitle,
  Title,
  Content,
  Description,
  Ratings,
  DetailsSection,
  Overlay,
  MetadataSection,
  Genres,
  Badges,
  FormatsContainer,
  ModalContentContainer,
  CloseButtonContainer,
  FormatBadge,
} from "./MoreInformationModal.styles";
/* load styled components end */

/**
 * load child components
 */
import ImageButton from "../ImageButton";
import InfoBlock from "./InfoBlock";
import RatingDetail from "./RatingDetail";
/* load child components end */

/**
 * load rating icons
 */
import GreenSplatIcon from "../RatingIcon/assets/RottenTomato.svg";
import SpilledPopIcon from "../RatingIcon/assets/SpilledPopcorn.svg";
import FreshPopIcon from "../RatingIcon/assets/FreshPopcorn.svg";
import FreshTomatoIcon from "../RatingIcon/assets/FreshTomato.svg";
import CertifiedFreshIcon from "../RatingIcon/assets/CertifiedFreshTomato.svg";
/* load rating icons end */

/**
 * load constants
 */
import ratingConstants from "../../shared/constants/rating";
import constants from "../../shared/constants";
/* load constants end */

// destructure constants
const { critics, fan, image_type } = ratingConstants;
const { REDUCER_TYPE } = constants;

/**
 * load images
 */
const newBadgeIcon = process.env.PUBLIC_URL + "/images/New.svg";
const newBadgeIconFr = process.env.PUBLIC_URL + "/images/New_fr.svg";
const _4K_ICON = process.env.PUBLIC_URL + "/images/4K_icon_grey.svg";
const _4KHDR_ICON = process.env.PUBLIC_URL + "/images/4K_HDR.svg";
const CC_ICON = process.env.PUBLIC_URL + "/images/CC.svg";
const DV_ICON = process.env.PUBLIC_URL + "/images/DV.svg";
const COLOURED_STAR = process.env.PUBLIC_URL + "/images/Star_On.svg";
const liveBadgeIcon = process.env.PUBLIC_URL + "/images/Live_Badge.svg";
/* load images end */

/**
 * More information modal to show users extra information
 * on detail pages
 * @component
 */
function MoreInformationModal({
  seriesTitle,
  title,
  description,
  rating,
  availableEpisodes,
  runtime,
  year,
  originalAirDate,
  season,
  genres,
  criticImage,
  fanImage,
  criticScore,
  fanScore,
  criticRating,
  memberRating,
  onClose,
  isNew,
  isCCAvailable,
  isDVAvailable,
  isUHD,
  is4K,
  isHDR,
  isLive,
  isAppLanguageFrench,
}) {
  const { t: translate } = useTranslation();
  const { featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { is4KVodEnabled } = featureToggles;
  const getRatingIcon = (type, imageType, numericalScore) => {
    let imgSrc;
    if (type === critics) {
      if (imageType?.toLowerCase() === image_type) imgSrc = CertifiedFreshIcon;
      else if (numericalScore >= 60) {
        imgSrc = FreshTomatoIcon;
      } else if (0 <= numericalScore && numericalScore < 60) imgSrc = GreenSplatIcon;
    } else if (type === fan) {
      if (numericalScore >= 60) imgSrc = FreshPopIcon;
      else if (0 <= numericalScore && numericalScore < 60) imgSrc = SpilledPopIcon;
    }
    return imgSrc;
  };
  return (
    <Overlay>
      <ModalContentContainer>
        <CloseButtonContainer>
          <ImageButton
            src={process.env.PUBLIC_URL + "/images/playback-close.svg"}
            alt={translate("close")}
            onClickHandler={onClose}
          />
        </CloseButtonContainer>
        <Content>
          <MetadataSection>
            {seriesTitle && <SeriesTitle>{seriesTitle}</SeriesTitle>}
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
            <DetailsSection>
              {genres ? <Genres>{genres.join(", ")}</Genres> : null}
              <Badges>
                {isLive && <img width={36} height={20} src={liveBadgeIcon} alt="liveBadgeIcon" />}
                {isNew && (
                  <img
                    width={isAppLanguageFrench ? 69 : 38}
                    height={20}
                    src={isAppLanguageFrench ? newBadgeIconFr : newBadgeIcon}
                    alt="newBadgeIcon"
                  />
                )}
              </Badges>
              <Ratings>
                {(criticScore || fanScore) && (
                  <div>
                    <InfoBlock
                      label={translate("rotten_tomatoes")}
                      detail={
                        <div>
                          {criticScore && (
                            <RatingDetail
                              icon={getRatingIcon(critics, criticImage, criticScore)}
                              score={`${criticScore}%`}
                              label="Critics score"
                              isCinocheRating={false}
                            />
                          )}
                          {fanScore && (
                            <RatingDetail
                              icon={getRatingIcon(fan, fanImage, fanScore)}
                              score={`${fanScore}%`}
                              label="Audience score"
                              isCinocheRating={false}
                            />
                          )}
                        </div>
                      }
                    />
                  </div>
                )}
                {(criticRating || memberRating) && (
                  <div>
                    <InfoBlock
                      label={translate("cinoche_com")}
                      detail={
                        <div>
                          {criticRating && (
                            <RatingDetail
                              icon={COLOURED_STAR}
                              score={criticRating}
                              label="Critic"
                              isCinocheRating={true}
                            />
                          )}
                          {memberRating && (
                            <RatingDetail
                              icon={COLOURED_STAR}
                              score={memberRating}
                              label="Members"
                              isCinocheRating={true}
                            />
                          )}
                        </div>
                      }
                    />
                  </div>
                )}
              </Ratings>
            </DetailsSection>
          </MetadataSection>
          <div>
            <div>
              {availableEpisodes ? (
                <InfoBlock label={translate("available_episodes")} detail={<span>{availableEpisodes}</span>} />
              ) : null}
              {rating ? <InfoBlock label={translate("rating")} detail={<span>{rating}</span>} /> : null}
              {season ? (
                <InfoBlock
                  label={translate("season")}
                  detail={
                    <span>
                      {translate("season")} {season}
                    </span>
                  }
                />
              ) : null}
              {year ? <InfoBlock label={translate("year_stb")} detail={<span>{year}</span>} /> : null}
              {originalAirDate ? (
                <InfoBlock label={translate("original_air_date")} detail={<span>{originalAirDate}</span>} />
              ) : null}
              {runtime ? <InfoBlock label={translate("runtime")} detail={<span>{runtime}</span>} /> : null}
              {(isCCAvailable || isDVAvailable || (is4KVodEnabled && isUHD)) && (
                <InfoBlock
                  label={translate("formats_available")}
                  detail={
                    <FormatsContainer>
                      {isUHD && !isHDR && is4KVodEnabled && (
                        <FormatBadge className="_4k">
                          <img src={_4K_ICON} alt="4K" />
                        </FormatBadge>
                      )}
                      {isUHD && isHDR && is4KVodEnabled && (
                        <FormatBadge className="_4kHDR">
                          <img src={_4KHDR_ICON} alt="4KHDR" />
                        </FormatBadge>
                      )}
                      {isCCAvailable && <img src={CC_ICON} alt="CC" />}
                      {isDVAvailable && <img src={DV_ICON} alt="DV" />}
                    </FormatsContainer>
                  }
                />
              )}
            </div>
          </div>
        </Content>
      </ModalContentContainer>
    </Overlay>
  );
}

MoreInformationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  rating: PropTypes.string,
  availableEpisodes: PropTypes.string,
  runtime: PropTypes.string,
  year: PropTypes.string,
  originalAirDate: PropTypes.string,
  season: PropTypes.string,
  genres: PropTypes.array,
  criticScore: PropTypes.string,
  fanScore: PropTypes.string,
  criticRating: PropTypes.string,
  memberRating: PropTypes.string,
  onClose: PropTypes.func,
  isNew: PropTypes.bool,
  isCCAvailable: PropTypes.bool,
  isDVAvailable: PropTypes.bool,
  is4K: PropTypes.bool,
  isLive: PropTypes.bool,
  isAppLanguageFrench: PropTypes.bool,
};

export default MoreInformationModal;
