import styled from "styled-components";

const VodSidebarItem = styled.div(
  ({ theme, width, $status }) => `
  display: flex;
  padding: ${width <= 1366 ? "22px 30px" : "22px 33px"};
  height: ${width <= 1366 ? "100px" : "142px"};
  padding-left: ${width <= 768 ? "25px" : ""};
  border-bottom: solid 1px ${theme.colours.recordingButtonBackground};
  align-items: flex-start;
  box-sizing: border-box;
  cursor: ${$status === "nonPlayable" ? "default" : "pointer"};
  background-color: ${$status === "playing" ? theme.colours.sharkGrayBackground : ""};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${theme.colours.sharkGrayBackground};
  }
  `
);

const VodSidebarItemThumbnail = styled.div(
  ({ theme, width }) => `
  height: ${width <= 768 ? "40px" : width <= 1366 ? "70px" : "99px"};
  width: ${width <= 768 ? "75px" : width <= 1366 ? "100px" : "170px"};
  background: ${theme.colours.sharkGrayBackground};
  position: relative;
  `
);

const VodSidebarThumbnail = styled.img(
  ({ $screen }) => `
  height: 100%;
  width: 100%;
  min-width: ${$screen <= 768 ? "75px" : $screen <= 1366 ? "100px" : "170px"};
  display: block;
  `
);

const VodSidebarOnNowPlay = styled.img(
  ({ $screen }) => `
  position: absolute;
  width: ${$screen <= 1366 ? "20px" : "30px"};
  height: ${$screen <= 1366 ? "20px" : "30px"};
  right: 6px;
  bottom: ${$screen <= 1366 ? "5px" : "10px"};
  `
);

const VodSidebarProgressBarContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colours.translucentCloudyGray};
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  `
);

const VodSidebarProgressBar = styled.div(
  ({ theme }) => `
  height: 100%;
  background-color: ${theme.colours.subscribeLink};
  max-width: 100%;
  `
);

const VodSidebarItemDescription = styled.div(
  ({ theme, width }) => `
  font: ${theme.typography.primaryFont};
  padding-left: 22px;
  font-size: ${width <= 1366 ? theme.typography.smallFontSwimlane : theme.typography.mediumSwimlaneFontSize};
  line-height: 1.2;
  flex-grow: 1;
  height: ${width <= 1366 ? "70px" : ""};
  `
);

const VodSidebarItemDescriptionTitle = styled.div(
  () => `
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `
);

const VodSidebarItemDateTime = styled.p(
  ({ theme, width }) => `
  font: ${theme.typography.primaryFont};
  font-size: ${
    width <= 768
      ? theme.typography.smallFontSizeHd
      : width <= 1366
      ? theme.typography.smallFontSwimlane
      : theme.typography.mediumSwimlaneFontSize
  };
  padding: 0px;
  margin: 0px;
  color: ${theme.colours.secondaryFontColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: ${width <= 1366 ? "21px" : ""};
  `
);

const VodSidebarItemDuration = styled.div(
  () => `
  display: flex;
  align-items: center;
  `
);

export {
  VodSidebarItem,
  VodSidebarItemThumbnail,
  VodSidebarThumbnail,
  VodSidebarOnNowPlay,
  VodSidebarProgressBarContainer,
  VodSidebarProgressBar,
  VodSidebarItemDescription,
  VodSidebarItemDescriptionTitle,
  VodSidebarItemDateTime,
  VodSidebarItemDuration,
};
