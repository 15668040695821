import moment from "moment";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO, MEDIA_VIDEO_PLAY_TYPES } from "../constants/analytics";
import routeConstants from "../constants/routes";
import {
  formatTrackingValue,
  getMappedContentType,
  getMappedMediaContentType,
  getMappedShowType,
  getStreamFormat,
} from "../utils/analytics";
import { isItemTypeEpisode, isItemTypeSeries } from "../utils/content";
import { getSessionStorage } from "../utils/sessionStorage";
import swimlaneConstants from "../constants/swimlane";
import { trackGenericAction } from "./dataLayer";
import { PLAY_BACK_MODE } from "../constants/conviva";

const {
  FEED,
  PAGE,
  SEARCH,
  SETTINGS,
  MOVIE_DETAIL_PAGE,
  SERIES_DETAIL_PAGE,
  GUIDE,
  CAST_DETAIL_PAGE,
  VIEW_ALL_PAGE,
  SVOD_ALL_PAGE,
  SIMILAR_ITEMS,
  SUB_CATEGORIES_PAGE,
  EPISODE_DETAIL_PAGE,
  SEARCH_VIEW_ALL,
  BASICS,
  ACCOUNT,
  SETTING_DETAIL,
  INTERNET_PAGE,
  LIVE_PLAYER,
  ON_DEMAND_PLAYER,
  RECORDINGS,
  PARENTAL_PIN,
  PURCHASE_PIN,
  LIVE_PLAYER_EPISODE,
  ON_DEMAND_PLAYER_EPISODE,
  RECORDING_PLAYER,
  PROFILE_ADD,
  PROFILE_EDIT,
  TRAILER,
  PROFILE_SELECT,
  MOVIE,
  EPISODE,
} = routeConstants;

const { SWIMLANE_TITLES } = swimlaneConstants;

/**
 * Gets arbitrary page type given current route
 * @param {String} currentRoute
 * @returns {String}
 */
export function getPageType(currentRoute) {
  const route = getMatchedRoute(currentRoute);

  if (!route) {
    return null;
  }

  switch (route) {
    case FEED.route:
    case PAGE.route:
    case SETTINGS.route:
      return "index";
    case SEARCH_VIEW_ALL.route:
    case SEARCH.route:
      return "search";
    case MOVIE_DETAIL_PAGE.route:
    case SERIES_DETAIL_PAGE.route:
    case EPISODE_DETAIL_PAGE.route:
      return "content";
    case GUIDE.route:
      return "guide";
    case CAST_DETAIL_PAGE.route:
      return "detail";
    case VIEW_ALL_PAGE.route:
    case SVOD_ALL_PAGE.route:
    case SIMILAR_ITEMS.route:
    case SUB_CATEGORIES_PAGE.route:
      return "category";
    case BASICS.route:
    case ACCOUNT.route:
    case SETTING_DETAIL.route:
    case INTERNET_PAGE.route:
    case PURCHASE_PIN.route:
    case PARENTAL_PIN.route:
    case PROFILE_ADD.route:
    case PROFILE_EDIT.route:
    case PROFILE_SELECT.route:
      return "admin";
    case LIVE_PLAYER.route:
    case ON_DEMAND_PLAYER.route:
    case LIVE_PLAYER_EPISODE.route:
    case ON_DEMAND_PLAYER_EPISODE.route:
      return "player";
    default:
      return null;
  }
}

/**
 * Gets section drill-down of current page
 * @param {String} currentRoute
 * @param {Object} pageDetails Variables that help determine the page hierarchy
 * @returns {Array}
 */
export function getPageHierarchy(currentRoute, { hubs, pageTitle, episodeInfo, feedName }) {
  if (!currentRoute) {
    return null;
  }

  if (currentRoute.startsWith(PAGE.route)) {
    if (hubs.length === 0) {
      return null;
    }

    const activeHub = hubs.find((hub) => {
      return currentRoute.startsWith(hub.actions[0].uri);
    });

    return [formatTrackingValue(getHubName(activeHub)) ?? "home", "index"];
  }

  if (currentRoute.startsWith(SEARCH_VIEW_ALL.route)) {
    return [
      pageHierarchyMap[SEARCH_VIEW_ALL.route],
      feedName.toLocaleLowerCase() === "films" || feedName.toLocaleLowerCase() === "movies" ? "movies" : "tv_shows",
    ];
  }

  let route = getMatchedRoute(currentRoute);
  if (!route) {
    return null;
  }

  let pageValue = pageHierarchyMap[route];

  if (pageValue === pageHierarchyMap[LIVE_PLAYER.route]) {
    pageValue = !!episodeInfo ? `${pageValue}/tv_shows` : `${pageValue}/movies`;
  }

  if (!pageValue) {
    return null;
  }

  const sections = pageValue.split("/");
  if (pageTitle?.length > 0) {
    sections.push(formatTrackingValue(pageTitle));
  }

  return sections;
}

export function formatAppProviderLanguage(language) {
  return language.split("-")[0];
}

/**
 * Returns page category for navigation tracking
 * @param {String} currentRoute
 * @returns {String}
 */
function getPageCategory(currentRoute) {
  if (currentRoute.includes(MOVIE_DETAIL_PAGE.route) || currentRoute.includes(MOVIE.route)) {
    return "movies";
  } else if (currentRoute.includes(SERIES_DETAIL_PAGE.route)) {
    return "tv_shows";
  } else if (currentRoute.includes(EPISODE_DETAIL_PAGE.route) || currentRoute.includes(EPISODE.route)) {
    return "episode";
  } else if (currentRoute.includes(TRAILER.route)) {
    return "trailer";
  } else {
    return "";
  }
}

/**
 * Finds the matching route in `routeConstants`
 * @param {String} route
 * @returns {String}
 */
function getMatchedRoute(route) {
  const routes = Object.keys(routeConstants)
    .map((key) => routeConstants[key].route)
    .sort((a, b) => b.length - a.length);

  const matchedRoute =
    route === "/"
      ? FEED.route
      : routes.find((key) => {
          return route.startsWith(key);
        });

  return matchedRoute ?? null;
}

/**
 * Gets the English label of the given hub
 * @param {Object} hub
 * @returns {String}
 */
function getHubName(hub) {
  const name = hub?.metadata?.label?.toLowerCase();
  if (!name) {
    return null;
  }

  return (
    Object.keys(hubNames).find((key) => {
      const names = hubNames[key];
      return names.en.toLowerCase() === name || names.fr.toLowerCase() === name;
    }) ?? null
  );
}

const hubNames = {
  home: {
    en: "Home",
    fr: "Accueil",
  },
  live_tv: {
    en: "Live TV",
    fr: "En direct",
  },
  on_demand: {
    en: "On Demand",
    fr: "Sur demande",
  },
};

const MAPPED_ROUTES = {
  ON_DEMAND_PLAYER_EPISODE: "/player/ondemand/episode",
  LIVE_PLAYER_EPISODE: "/player/live/episode",
};

const pageHierarchyMap = {
  [FEED.route]: "home/index",
  [SEARCH_VIEW_ALL.route]: "search",
  [SEARCH.route]: "search/index",
  [SETTINGS.route]: "settings/index",
  [MOVIE_DETAIL_PAGE.route]: "detail/movies",
  [SERIES_DETAIL_PAGE.route]: "detail/tv_shows",
  [GUIDE.route]: "guide/index",
  [RECORDINGS.route]: "recordings",
  [CAST_DETAIL_PAGE.route]: "cast",
  [VIEW_ALL_PAGE.route]: "view_all",
  [SVOD_ALL_PAGE.route]: "on_demand/svod_package",
  [SIMILAR_ITEMS.route]: "more_like_this",
  [SUB_CATEGORIES_PAGE.route]: "sub_categories",
  [EPISODE_DETAIL_PAGE.route]: "detail/tv_shows",
  [BASICS.route]: "settings/basics",
  [ACCOUNT.route]: "settings/account_info",
  [SETTING_DETAIL.route]: "settings/app_version_info",
  [INTERNET_PAGE.route]: "settings/internet_settings",
  [PARENTAL_PIN.route]: "settings/parental_pin",
  [PURCHASE_PIN.route]: "settings/purchase_pin",
  [MAPPED_ROUTES.ON_DEMAND_PLAYER_EPISODE]: "player/on_demand/tv_shows",
  [MAPPED_ROUTES.LIVE_PLAYER_EPISODE]: "player/live_tv/tv_shows",
  [LIVE_PLAYER.route]: "player/live_tv",
  [ON_DEMAND_PLAYER.route]: "player/on_demand/movies",
  [RECORDING_PLAYER.route]: "player/recording",
  [PROFILE_EDIT.route]: "settings/profiles",
  [PROFILE_ADD.route]: "settings/profiles",
  [PROFILE_SELECT.route]: "settings/profiles/whos_watching",
};

/**
 * Creates a Media node as defined by the Adobe Analytics spec
 * @param {Object} asset
 * @returns {Object}
 */
export function createMediaNode(asset) {
  if (!asset?.metadata) {
    return null;
  }

  const umId = asset.metadata?.extendedMetadata?.dlum?.umId ?? null;
  const genres = asset.metadata?.genres ?? asset.metadata?.extendedMetadata?.dlum?.displayGenres;
  const channelName = formatTrackingValue(asset.channel?.channelName || asset.channel?.item?.metadata?.channelName);
  const firstAirDate =
    asset.metadata?.extendedMetadata?.dlum?.originalAirDate ||
    (asset.metadata?.airingStartTime ? moment(asset.metadata?.airingStartTime).format("YYYY-MM-DD") : null) ||
    null;

  return {
    sessionID: formatTrackingValue(asset?.sessionID || ""),
    customMetaData: {
      playType: asset.metadata?.uaType || asset.playbackType,
      unifiedAssetId: asset.metadata?.extendedMetadata?.dlum?.uaId,
      videoSeasonId: asset.metadata?.season || null,
      videoSeasonUaId: asset.metadata?.extendedMetadata?.dlum?.series?.uaId ?? null,
      umId,
      tmsid: asset.metadata?.extendedMetadata?.dlum?.gracenote?.tmsId ?? null,
      channelId:
        asset.metadata?.channel?.id ||
        asset.channel?.channelId ||
        asset.channel?.id ||
        asset.metadata?.extendedMetadata?.epg?.mediaroom?.channelId,
      videoSeriesId: isItemTypeEpisode(asset.metadata) ? asset.metadata?.extendedMetadata?.dlum?.series?.uaId : null,
      videoTamsId: asset.metadata?.externalId || asset.metadata?.extendedMetadata?.dlum?.gracenote?.tmsId || null,
      dlumContentId: String(asset.metadata?.contentId || asset.contentId || ""),
      contentType: getMappedMediaContentType(asset.isItemLive, asset.metadata?.contentType),
    },
    sessionDetails: {
      channel: formatTrackingValue(
        asset.metadata?.channel?.number ||
          asset?.channel?.channelId ||
          asset.channel?.channelNumber ||
          asset?.channelNumber ||
          null
      ),
      show: asset.metadata?.title,
      streamFormat: asset.metadata
        ? formatTrackingValue(
            getStreamFormat(
              asset.metadata?.isUHD || asset.metadata?.extendedMetadata?.isUHD,
              asset.metadata?.isHDR || asset.metadata?.extendedMetadata?.isHDR
            )
          )
        : null,
      genre: genres?.join(","),
      showType: getMappedShowType(asset.metadata?.extendedMetadata?.dlum?.uaGroupType, asset.playbackType),
      network: asset?.metadata?.channel?.metadata?.channelName || asset?.channel?.channelName || "",
      rating: formatTrackingValue(asset.metadata?.extendedMetadata?.dlum?.rating),
      friendlyName: formatTrackingValue(asset.metadata?.episodeTitle || asset.metadata?.title),
      season: formatTrackingValue(asset.metadata?.season || null),
      length: getMediaContentDuration(
        !asset?.isItemLive || asset.metadata?.duration || asset.metadata?.programDuration,
        {
          duration: asset.metadata?.duration || asset.metadata?.programDuration,
          airingStartTime: asset.metadata?.airingStartTime,
          airingEndTime: asset.metadata?.airingEndTime,
        }
      ),
      contentType: getMappedContentType(asset?.isItemLive, asset?.playbackType, asset?.mappedContentType),
      episode: formatTrackingValue(asset.metadata?.episodeNumber || asset.metadata?.episodeId || null),
      firstAirDate,
      streamType: asset.metadata ? "video" : null,
      originator: formatTrackingValue(asset.metadata?.channel?.metadata?.broadcasterName || null),
      name: asset.metadata?.episodeTitle || asset.metadata?.title,
      assetID: formatTrackingValue(
        asset?.channel?.assets
          ? asset?.channel?.assets[0]?.assetId
          : asset?.metadata?.channel?.assets
          ? asset?.metadata?.channel?.assets[0].assetId
          : null
      ),
      timePlayedSeconds: 0,
    },
  };
}

/**
 * Returns common product details about an asset
 * @param {Object} asset
 * @returns {Object}
 */
export function getProductDetails(product) {
  const asset = product?.asset || product?.media || product;
  const videoName = formatTrackingValue(asset?.title || asset?.metadata?.title);
  const isCampaignClick = asset?.isProductImpressionClicked ? { campaignClick: 1 } : {};
  const isRecordingAsset =
    product.isRecordingAsset || asset?.isRecordingAsset
      ? {
          videoRecording: formatTrackingValue(product.videoName || (asset?.videoName ?? null)),
        }
      : {};

  return {
    // TODO: please update the SKU once all products' id has been set properly
    SKU: formatTrackingValue(asset?.metadata?.extendedMetadata?.dlum?.uaId || ""),
    name: formatTrackingValue(asset?.metadata?.extendedMetadata?.dlum?.umId ?? ""),
    _telus: {
      videoName,
      findingMethodProduct: formatTrackingValue(getSessionStorage(ANALYTICS_STORAGE_KEYS.FINDING_METHOD)),
      findingMethodCategory: formatTrackingValue(getPageCategory((asset?.route ?? window.location.hash).slice(1))),
      internalCampaignName: asset?.isInternalCampaign || asset?.isProductImpressionClicked ? videoName : null,
      ...(asset?.recommendationInfo || {}),
      ...isCampaignClick,
      ...isRecordingAsset,
    },
  };
}

/**
 * Returns common product details about a recommended asset
 * @param {Object} asset
 * @param {Integer} positionInSwimlane
 * @param {String} title
 * @param {Boolean} isViewAllPage
 * @returns {Object}
 */
export function getRecommendationProductDetails(asset, positionInSwimlane, title, isViewAllPage = false) {
  const videoName = formatTrackingValue(asset?.item?.title || asset.metadata?.title);

  return {
    // TODO: please update the SKU once all products' id has been set properly
    SKU: null,
    name: (asset?.item?.extendedMetadata?.dlum?.umId || asset?.metadata?.extendedMetadata?.dlum?.umId) ?? null,
    _telus: {
      videoName,
      recoName: `${videoName}:${positionInSwimlane}`,
      recoLocation: `${isViewAllPage ? LINK_INFO.VIEW_ALL : LINK_INFO.SWIMLANE}:${formatTrackingValue(
        title
      )}:${positionInSwimlane}`,
      recoImpression: 1,
    },
  };
}

/**
 * Returns home status of current user
 * @param {Boolean} isInHome
 * @returns {String}
 */
export function getHomeStatus(isInHome) {
  if (isInHome) return "in-home";
  if (isInHome === false) return "out-of-home";
  return null;
}

/**
 * Returns the row number of a swimlane on the screen
 * @param {String} feedTitle
 * @param {Boolean} isSimilarItemsExist
 * @returns {String}
 */
export const getSwimlaneRowNum = (feedTitle, isSimilarItemsExist = false) => {
  if (feedTitle === SWIMLANE_TITLES.SIMILAR_ITEMS) return 0;
  else if (feedTitle === SWIMLANE_TITLES.CAST_AND_CREW) {
    return isSimilarItemsExist ? 1 : 0;
  }
};

/**
 * Returns the detail of a link that was clicked on the previous screen/page
 * @returns {object} {linkName, linkPosition, linkExtra(channelName|aaVideoPlayType[optional])}
 */
export const getLink = () => {
  const [linkName, linkPosition, linkExtra] = (getSessionStorage(ANALYTICS_STORAGE_KEYS.LINK) || "").split(";");
  return {
    linkName,
    linkPosition,
    linkExtra,
  };
};

/**
 * Returns a formatted linkName
 * @param {String} linkName a link name that was clicked on the previous screen/page
 * @returns {String} linkName
 */
export const getLinkName = (linkName) => (linkName || "").replace(/\s/g, "_").toLowerCase();

/**
 * Adds one to swimlaneNum passed if its type is number, otherwise returns default value
 * @returns {Number|String}
 */
const getSwimlaneNum = (swimlaneNum, defaultValue) => {
  if (typeof swimlaneNum === "number") return swimlaneNum + 1;
  return swimlaneNum ?? defaultValue;
};

/**
 * Builds a string that contains the information about a link.
 * This link was clicked on the previous screen/page.
 * @param {String} feedTitle the title of a swimlane
 * @param {Integer} swimlaneRowNum a swimlane's row number on the screen
 * @param {Integer} swimlaneColumnNum a column number on a swimlane
 * @param {String} channelName (optional)
 * @returns {String}
 */
export const buildLink = (feedTitle, swimlaneRowNum, swimlaneColumnNum, channelName) => {
  // When a user clicks an item from Channels (view all page),
  // we set its feedTitle to be channelName (from previous link),
  // due to an image is used as title within Channels (view all page)
  const { linkPosition, linkExtra: preChannelName } = getLink();
  feedTitle = (linkPosition || "").toLowerCase() === "channels" && !feedTitle ? preChannelName : feedTitle;
  swimlaneRowNum = getSwimlaneNum(swimlaneRowNum, "view_all");
  swimlaneColumnNum = getSwimlaneNum(swimlaneColumnNum, "NA");

  return `${feedTitle};${swimlaneRowNum}:${swimlaneColumnNum};${channelName}`;
};

/**
 * Builds a string that contains the information about a link. (used in SwimlaneTitle component)
 * This link was clicked on the previous page.
 * @param {String} feedTitle the title of a swimlane
 * @param {Integer} swimlaneRowNum a swimlane's row number on the screen
 * @returns {String} `linkName;swimlaneRowNum:view_all`
 */
export const buildLinkInSwimlaneTitle = (feedTitle, swimlaneRowNum) => {
  return `${feedTitle};${getSwimlaneNum(swimlaneRowNum, "NA")}:view_all`;
};

/**
 * Gets the seasonNumber and episodeNumber from a given asset
 * @param {Object} asset
 * @returns {String}
 */
export const getEpisodeInfo = (asset) =>
  asset?.metadata?.season && (asset?.metadata?.episodeNumber || asset?.metadata?.episodeId)
    ? `/s${asset?.metadata?.season}e${asset?.metadata?.episodeNumber || asset?.metadata?.episodeId}`
    : "";

/**
 * Gets the aa.video.playType,
 * if a media starts from the “more like this” swimlane, return `recommendation`, otherwise return `standard_listing`
 * @returns {String}
 */
export const getAAVideoPlayType = () => {
  const { linkName } = getLink();
  return linkName === "More like this"
    ? MEDIA_VIDEO_PLAY_TYPES.RECOMMENDATION
    : MEDIA_VIDEO_PLAY_TYPES.STANDARD_LISTING;
};

/**
 * Returns an analytics callback function which will be triggered if an error occurs during an `pin` event
 * @param {String} eventType event type
 * @param {String} toolName tool usage name
 * @param {String} errorName error name
 * @returns {Function} a callback function
 */
export const getGenericErrorEventHandler = (eventType, toolName, errorName) => {
  return ({ errorMessage, errorCode = null }) => {
    trackGenericAction(eventType, {
      name: errorName,
      toolName,
      errorDetails: errorName,
      errorMessage,
      errorCode,
    });
  };
};

/**
 * Gets the duration of a media content
 * @param {Boolean} isDurationAvailable
 * @param {Number} duration
 * @param {Number} airingStartTime
 * @param {Number} airingEndTime
 * @returns {Number} duration in seconds
 */
export const getMediaContentDuration = (isDurationAvailable, { duration, airingStartTime, airingEndTime }) => {
  if (isDurationAvailable && duration) {
    return moment.duration(moment(duration * 1000)).asSeconds();
  } else if (!isDurationAvailable && airingStartTime && airingEndTime) {
    const startTime = moment(airingStartTime);
    const endTime = moment(airingEndTime);
    return moment.duration(endTime.diff(startTime)).asSeconds();
  }

  return null;
};

/**
 * @todo remove or update this function once the metadata of a MR recording is sufficient for analytics
 *
 * Maps a MR recording metadata to match the data structure of a media container
 * @param {Object} recordingItem
 * @returns {Object} the metadata of a recording
 */
export const mapMRRecordingMediaContent = (recordingItem) => {
  return {
    channel: {
      number: recordingItem?.channelNumber,
      item: {
        layout: "CHANNEL_ITEM",
        metadata: {
          contentType: "LIVE",
          callLetter: recordingItem?.metadata?.callLetter || recordingItem?.callLetter,
          channelName: recordingItem?.metadata?.channelName,
          defaultChannelNumber: recordingItem?.metadata?.channelId,
        },
      },
    },
    metadata: {
      contentId: recordingItem?.metadata?.contentId,
      contentType: recordingItem?.assetType,
      longDescription: recordingItem?.metadata?.longDescription || recordingItem?.description,
      year: (recordingItem?.metadata?.originalAirDate || "").split("-")[0],
      title: recordingItem?.title || recordingItem?.metadata?.seriesTitle || recordingItem?.metadata?.programTitle,
      duration: recordingItem?.duration,
      airingStartTime: recordingItem?.utcStartTime || recordingItem?.programStartTime,
      seriesId: recordingItem?.seriesId,
      episodeTitle: recordingItem?.metadata?.programTitle || recordingItem?.title,
      genres: recordingItem?.metadata?.genres,
      season: recordingItem?.metadata?.seasonNumber,
      episodeId: recordingItem?.metadata?.episodeNumber,
      extendedMetadata: {
        dlum: {
          rating: recordingItem?.metadata?.rating,
          originalAirDate: recordingItem?.metadata?.originalAirDate,
          uaId: recordingItem?.metadata?.uaId,
          uaGroupId: recordingItem?.metadata?.uaGroupId,
          uaGroupType: recordingItem?.metadata?.uaGroupType,
        },
        epg: {
          mediaroom: {
            programId: recordingItem?.programId,
          },
        },
      },
    },
  };
};

/**
 * Maps a series recording content to match the data structure of a media container
 * @param {Object} recordingItem
 * @returns {Object} the metadata of a series recording
 */
export const mapRecordingSeriesMediaContent = (recordingItem) => {
  return {
    channel: {
      number: recordingItem?.channelNumber || recordingItem?.channel?.channelNumber,
      item: {
        metadata: {
          callLetter: recordingItem?.callLetter || recordingItem?.channel?.callLetter,
          defaultChannelNumber:
            recordingItem?.channelId || recordingItem?.metadata?.channelId || recordingItem?.channel?.channelId,
        },
      },
    },
    metadata: {
      uaType: "tvshow",
      title: recordingItem?.title || recordingItem?.metadata?.title,
      layout: recordingItem?.layout,
      seriesId: recordingItem?.seriesId || recordingItem?.metadata?.seriesId,
      season: recordingItem?.metadata?.seasonNumber,
      genres: recordingItem?.metadata?.genres,
      extendedMetadata: {
        dlum: {
          rating: recordingItem?.metadata?.rating,
          defaultLanguage: recordingItem?.metadata?.defaultLanguage,
          series: {
            uaId:
              recordingItem?.metadata?.uaId ||
              recordingItem?.metadata?.uaSeriesId ||
              recordingItem?.metadata?.extendedMetadata?.dlum?.series?.uaId,
          },
        },
      },
    },
  };
};

/**
 * This method is used to avoid triggering double pageLoaded events:
 * 1. when a user visits the search page with a search term
 * 2. when a user clicks the back button to go back to previous search page from subsequent search click page
 *
 * Search page is rendered with cache results initially, but the initial render page is replaced with second render immediately.
 * Since a user only sees the second render, then the first render (pageLoaded event) should be discarded.
 * @param {String} searchTerm
 * @param {Integer} numberOfResults
 * @returns {Boolean}
 */
export const checkShouldTriggerPageLoadedEvent = (searchTerm, numberOfResults) => {
  const events = window.adobeDataLayer.slice(-2).reverse();

  // refresh search page with an empty search term
  if (!searchTerm && events?.length === 0) {
    return true;
  }
  // visit search page with an empty search term
  if (!searchTerm && events.every((event) => event?.xdm?.web?.webPageDetails?.name !== "web/telustv/search/index")) {
    return false;
  }

  const foundSearchClickedEvent = events.find((event) => {
    return (
      event.xdm?.search?._telus?.resultsInstances &&
      event.xdm?.search?._telus?.term === searchTerm &&
      event.xdm?.search?._telus?.numberOfResults !== numberOfResults
    );
  });

  return !foundSearchClickedEvent;
};
