import { getSignInUrl } from "../middleware/login";
import { trackWebAction } from "../analytics/dataLayer";
import constants from "../constants";
import { ANALYTICS_EVENT_TYPES } from "../constants/analytics";
import storageConstants from "../constants/storage";
import errorConstants from "../constants/error";
import { trackConvivaCustomEvent } from "../analytics/media";
import { setSessionStorage } from "./sessionStorage";
import i18n from "../../i18n.js";

const { LOGIN_REDIRECT_URL } = storageConstants;
const { LOGIN_BRANDS, IN_MARKET_OPTIK_URL } = constants;
const { ERROR_MESSAGES, AVS_ERROR_CODES } = errorConstants;

/**
 * Sets the login redirect url in local storage
 * Redirects user to telus identity login
 */
export function handleLogin(appProvider, loginBrand = LOGIN_BRANDS.TELUS, KoodoEnabled = true) {
  const signInUrl = getSignInUrl(appProvider, loginBrand, KoodoEnabled);
  trackWebAction(ANALYTICS_EVENT_TYPES.LOGIN_START, signInUrl);

  trackConvivaCustomEvent(ANALYTICS_EVENT_TYPES.LOGIN_START, { loginStart: true });
  if (window.location.hash && window.location.hash !== "#/")
    setSessionStorage(LOGIN_REDIRECT_URL, window.location.href);
  window.location.href = signInUrl;
}

/**
 * Function to map login error to error modal config
 * @returns login error modal map
 */
export function getLoginErrorModalMap() {
  return {
    [ERROR_MESSAGES.MULTI_GEO_ERROR]: {
      className: "invalid-account-modal",
      title: "cookies_subscribed_optik_tv",
      message: "cookies_optiktv_app",
      isCloseable: false,
      buttons: [
        {
          label: i18n.t("cookies_go_optiktv"),
          onClickHandler: () => (window.location.href = IN_MARKET_OPTIK_URL),
        },
      ],
      endButtonLabelOverride: "", // Removes default end button
    },
    [AVS_ERROR_CODES.INACTIVE_ACCOUNT]: {
      title: "error",
      message: "login_error_inactive_account",
      disableRetry: true,
    },
    [AVS_ERROR_CODES.BLOCKED_ACCOUNT]: {
      title: "error",
      message: "login_error_blocked_account",
      disableRetry: true,
    },
    [AVS_ERROR_CODES.UNKNOWN_TOKEN]: {
      title: "error",
      message: "login_error_token_unknown",
      disableRetry: true,
    },
  };
}
