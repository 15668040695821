/**
 * load core modules
 */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
/* load core modules end */

/**
 * load child components
 */
import OptikButton from "../../components/OptikButton";
/* load child components end */

/**
 * load utilities
 */
import { retrieveMasterAsset, getAssetExpiryString } from "../../shared/utils/feedHelper";
/* load utilities end */

/**
 * load constants
 */
import constants from "../../shared/constants/index";
/* load constants end */

/** declare/destructure constants */
const { WATCH_OPTIONS } = constants;

/**
 * Component to render play CTA for vod assets on detail pages
 * @component
 * @param {Object} props
 */
const WatchOnDemandCTA = React.memo(({ watchOptions, getEntitlementType, onPlayClick, playbackId }) => {
  const { t: translate } = useTranslation();
  const playButton = useMemo(() => {
    let playableContent;
    if (watchOptions?.length) {
      playableContent = watchOptions.find(
        (watchOption) => watchOption?.contentId?.toString() === playbackId?.toString() && watchOption.videoType === "HD"
      );
      if (!playableContent) {
        playableContent = watchOptions.find(
          (watchOption) => watchOption?.contentId?.toString() === playbackId?.toString()
        );
      }
    }
    const entitlement = playableContent?.entitlement;
    const entitlementObj = getEntitlementType(entitlement, "play");
    return (
      <OptikButton
        label={translate(entitlementObj?.label)}
        icon={entitlementObj?.icon}
        className={entitlementObj?.className}
        onClickHandler={() => onPlayClick(entitlementObj?.type, playableContent)}
      />
    );
  }, [watchOptions, playbackId, getEntitlementType, translate, onPlayClick]);

  const getAssetExpiryElement = () => {
    const asset = watchOptions?.length > 0 ? retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch) : null;
    return asset?.rentalEndDate ? (
      <div className="expiry-message">{getAssetExpiryString(asset.rentalEndDate)}</div>
    ) : null;
  };

  return playbackId ? (
    <div>
      <div className="detail-page-cta">{playButton}</div>
      {getAssetExpiryElement()}
    </div>
  ) : null;
});

WatchOnDemandCTA.propTypes = {
  watchOptions: PropTypes.array,
  getEntitlementType: PropTypes.func.isRequired,
  onPlayClick: PropTypes.func.isRequired,
  playbackId: PropTypes.number,
};

export default WatchOnDemandCTA;
