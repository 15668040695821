import request from "../../service/request";
import constants from "../constants";
import storageConstants from "../../shared/constants/storage";
import { setCookie } from "../utils/cookie";
import { getLocalStorage, setLocalStorage } from "../../shared/utils/localStorage";
import { getSessionStorage } from "../../shared/utils/sessionStorage";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogError, logDatadogEvent } from "../analytics/datadog";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../constants/datadog";
import { ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";

const { APP_ENVIRONMENTS, API_BASE_URL, LOGIN_BRANDS, IDENTITY_PROVIDER } = constants;
const { FPID, CACHED_ACCOUNT_BRAND, DEVICE_ID } = storageConstants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;

/**
 * Build a sign-in url for the user to sign-in into Telus IAM
 * @param {Object} appProvider
 * @return {String} Sign-In Url
 */
export const getSignInUrl = (appProvider, loginBrand = LOGIN_BRANDS.TELUS, KoodoEnabled = true) => {
  // const baseUrl = "https//watchoptiktv.com/#/";
  // const redirectUri = window.location.href.startsWith("http://localhost")
  //   ? window.location.href.replace("http://localhost:3000/#/", baseUrl)
  //   : window.location.href;
  let redirectUri = appProvider.config.general.AVS_config.redirectUri;
  const accountBrand = loginBrand;
  if (ENV === APP_ENVIRONMENTS.DEV) {
    redirectUri = appProvider.useOAuth ? "http://localhost" : window.location.origin;
  }
  let url = appProvider.useOAuth
    ? appProvider.config.avs_api_urls.sign_in_url_old.replace("$redirectURI", encodeURIComponent(redirectUri))
    : accountBrand === LOGIN_BRANDS.TELUS
    ? appProvider.config.avs_api_urls.sign_in_url
    : appProvider.config.avs_api_urls.sign_in_url_koodo;
  url = url.replace("$redirectURI", encodeURIComponent(redirectUri));
  url = url.replace(
    "$clientID",
    appProvider.useOAuth
      ? appProvider.config.general.AVS_config.clientId
      : accountBrand === LOGIN_BRANDS.TELUS
      ? appProvider.config.general.AVS_config.clientIdTelus
      : appProvider.config.general.AVS_config.clientIdKoodo
  );
  url += "&switch_login=" + KoodoEnabled;
  const uuid = getLocalStorage(DEVICE_ID);
  setCookie(FPID, uuid, 365);
  url = url.replace("$state", uuid);
  url = url.replace("$lang", appProvider.lang);

  const isDatadogRUMEnabled = appProvider.isDatadogRUMEnabled || false;
  url += "&datadog=" + isDatadogRUMEnabled;
  return url;
};

/**
 * Authenticate a Telus IAM user with the AVS system
 * @param {Object} appProvider
 * @param {String} secret
 * @param {String} state
 * @return {Promise} Return a promise object
 */
export const authenticateUser = (appProvider, secret, state) => {
  let url = appProvider.config.avs_api_urls.login;
  let redirectUri = appProvider.config.general.AVS_config.redirectUri;

  url = url.replace("$TENANT_URL", appProvider.AGL_TENANT_URL);
  url = url.replace("$AGL_VERSION", appProvider.AGL_Version);
  url = url.replace("$LANG", appProvider.AVS_LANG);
  url = url.replace("$PLATFORM", appProvider.AVS_PLATFORM);
  url = url.replace("$PROPERTY", appProvider.AVS_PROPERTY);

  if (ENV === APP_ENVIRONMENTS.DEV) {
    url = url.replace("https://" + appProvider.AGL_TENANT_URL, API_BASE_URL);
    redirectUri = appProvider.useOAuth ? "http://localhost" : window.location.origin;
  }

  const accountBrand = getLocalStorage(CACHED_ACCOUNT_BRAND) ?? LOGIN_BRANDS.TELUS;

  const clientId = appProvider.useOAuth
    ? appProvider.config.general.AVS_config.clientId
    : accountBrand === LOGIN_BRANDS.TELUS
    ? appProvider.config.general.AVS_config.clientIdTelus
    : appProvider.config.general.AVS_config.clientIdKoodo;

  const credentialData = appProvider.useOAuth
    ? {
        credentialsExtAuth: {
          deviceInfo: {
            deviceId: state,
            deviceIdType: "DEVICEID",
            deviceType: appProvider.AVS_PLATFORM,
          },
          credentials: {
            client_id: clientId,
            secret: secret,
            state: state,
            redirect_uri: redirectUri,
          },
        },
      }
    : {
        credentialsExtAuth: {
          deviceInfo: {
            deviceId: state,
            deviceIdType: "DEVICEID",
            deviceType: appProvider.AVS_PLATFORM,
            brand: accountBrand,
          },
          credentials: {
            client_id: clientId,
            secret: secret,
            state: state,
            redirect_uri: redirectUri,
            idp: IDENTITY_PROVIDER,
          },
        },
      };
  return request(
    {
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: credentialData,
      withCredentials: true,
      returnResponseObject: true,
    },
    null,
    appProvider
  )
    .then((response) => {
      if (response?.data?.resultCode !== "KO") {
        // if the login is successful, get the login brand from the response
        const accountBrand =
          response?.data?.resultObj?.profile?.profileData?.customAttributes?.find(
            (item) => item.attributeName === "BRAND"
          )?.attributeValue ?? LOGIN_BRANDS.TELUS;
        setLocalStorage(CACHED_ACCOUNT_BRAND, accountBrand.toLowerCase()); // we cache the user account brand, this can be used to redirect user to brand specific logout url
        // track new relic login event
        logNREvent(NR_PAGE_ACTIONS.LOGIN, {
          brand: accountBrand,
          telusDeviceId: state,
          loginMethod: "manual",
          hasError: false,
          telusUserName: response?.data?.resultObj?.profile?.profileData?.crmAccountId,
          telusSessionId: getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID),
          propertyName: response?.data?.resultObj?.profile?.profileData?.properties?.[0]?.propertyName,
        });
        logDatadogEvent(DD_PAGE_ACTIONS.LOGIN, {
          brand: accountBrand,
          telusDeviceId: state,
          loginMethod: "manual",
          hasError: false,
          telusUserName: response?.data?.resultObj?.profile?.profileData?.crmAccountId,
          telusSessionId: getSessionStorage(ANALYTICS_STORAGE_KEYS.SESSION_ID),
          propertyName: response?.data?.resultObj?.profile?.profileData?.properties?.[0]?.propertyName,
        });
      }
      return response;
    })
    .catch((err) => {
      if (err) {
        // track new relic login errors
        logNREvent(NR_PAGE_ACTIONS.LOGIN, {
          brand: accountBrand,
          telusDeviceId: state,
          loginMethod: "manual",
          hasError: true,
          errorCode: `ERR_${err.data?.errorDescription}`,
        });
        // track login errors in datadog
        logDatadogError(DD_PAGE_ACTIONS.LOGIN, {
          brand: accountBrand,
          telusDeviceId: state,
          loginMethod: "manual",
          hasError: true,
          errorCode: `ERR_${err.data?.errorDescription}`,
        });
        return Promise.reject({
          url: err.config?.url,
          message: err.data?.message,
          type: "Server",
          code: err.data?.errorDescription,
        });
      }
    });
};
