/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
/* load core modules end */

/* Styled Components */
const InfoBlockContainer = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 27px;
  color: ${({ theme }) => theme.colours.white};
`;

const Detail = styled.div`
  font-size: ${({ theme }) => theme.typography.mediumSwimlaneFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 24.3px;
  color: ${({ theme }) => theme.colours.offGrey};
  margin-top: 4px;

  img {
    max-width: 100px;
    height: auto;
  }
`;
/* Styled Components end */

/**
 * Info Block containing label and data in specific style format
 * @component
 */
const InfoBlock = ({ label, detail }) => {
  return (
    <InfoBlockContainer>
      {/* removing colon(:)(if any) at the end of the label and replacing it with a hardcoded one, as POE strings are not consistent with it */}
      <Label>{`${label.replace(/:$/, "")}:`}</Label>
      <Detail>{detail}</Detail>
    </InfoBlockContainer>
  );
};

InfoBlock.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.node.isRequired,
};

export default InfoBlock;
