/**
 * load core modules
 */
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
/* load core modules end */

/** load actions */
import { showModalPopup, showToastNotification } from "../../App/state/actions";

/**
 * load constants
 */
import constants from "../constants";
import playerConstants from "../constants/player";
import errorConstants from "../constants/error";
import routeConstants from "../constants/routes";
import { ANALYTICS_EVENT_TYPES, ACTION_VALUES, WEB_ACTION_EVENT_NAMES } from "../constants/analytics";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
/* load constants end */

/**
 * load middleware
 */
import middleware from "../middleware";
/* load middleware end */

/**
 * load utilities
 */
import { isPlatformAllowToPlay, isPinMaxLimitReach } from "../utils";
import { isPPVChannel } from "../utils/epg";
import { getGenericErrorEventHandler } from "../analytics/helpers";
import { getAVSPlaybackError, getPlaybackErrorMessageInfo } from "../utils/playbackHelpers";
import { getPPVTuneInfo } from "../utils/playerUtils";
/* load utilities end */

/**
 * load custom hooks
 */
import useCancelTokenSource from "./useCancelTokenSource";
import { useReducers } from "./useReducer";

/*
 * Load conviva analytics
 */
import telusConvivaAnalytics from "../utils/convivaAnalytics";

/** load assets */
const unsubscribedIcon = `${process.env.PUBLIC_URL}/images/key-toast-icon.svg`;
/* load assets end */

/** declare/destructure constants */
const { MODAL_TYPES, MVE2_AGL_VERSION, PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE, RESOLUTION_TYPE, PLAY, PURCHASE } =
  constants;
const { PLAYBACK_TYPES, ASSET_TYPES } = playerConstants;
const { ERROR_TYPES, AVS_ERROR_CODES } = errorConstants;
const { LIVE_PLAYER, ON_DEMAND_PLAYER, RECORDING_PLAYER } = routeConstants;

/** destructure middleware */
const { getContentUserData, fetchVideoUrl } = middleware;

/**
 * Custom hook for doing playback content entitlement checks before loading player
 */
export default function usePlaybackChecks() {
  const dispatch = useDispatch();
  const { provider: appProvider, subscribedChannels, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { isChannelTuneTimeEnabled, isVODDisabled, isLIVEDisabled, isCatchUpDisabled, isCPVRDisabled, is4KVodEnabled } =
    featureToggles;

  const cancelTokenSource = useCancelTokenSource();

  // Used to determine if the application is in partial panic for playback
  const passFeatureFlags = useCallback(
    (contentType, lookbackContent) => {
      const modalContent = {
        title: translate("temporary_service_disruption"),
        history,
        isCloseable: true,
      };

      let message = "";

      if (isVODDisabled && contentType === PAGE_CONTENT_ITEM_TYPES.vod) {
        message = translate("panic_vod_playback_msg");
      } else if (isLIVEDisabled && contentType === PAGE_CONTENT_ITEM_TYPES.live && lookbackContent === null) {
        message = translate("panic_live_playback_msg");
      } else if (isCPVRDisabled && contentType === PAGE_CONTENT_ITEM_TYPES.recording) {
        message = translate("panic_cpvr_playback_msg");
      } else if (isCatchUpDisabled && lookbackContent) {
        message = translate("panic_catchup_playback_msg");
      }

      if (message !== "") {
        modalContent.message = message;
        dispatch(showModalPopup(MODAL_TYPES.ERROR, modalContent));
        return false;
      }

      return true;
    },
    [translate, history, dispatch, isCPVRDisabled, isCatchUpDisabled, isLIVEDisabled, isVODDisabled]
  );

  /**
   * Function to convert the fetched entitlements in a model that is consistent across content types
   * @param {Object} contentMetadata an object having metadata such as entitlement, assets and bookmarks for the selected content
   * @param {String} playbackType playback type value such as movie, trailer or episode
   * @param {Number} contentId unique identifier for content to be checked
   * @returns {Object}
   */
  const convertToUserDataModel = (contentMetadata, playbackType, contentId) => {
    const entitlement = contentMetadata.entitlement;
    const assets = entitlement?.assets;
    const bookmarks = contentMetadata?.metadata?.bookmarks;
    let assetToPlay;
    if (playbackType === PLAYBACK_TYPES.TRAILER) {
      assetToPlay = assets.find((asset) => asset.assetType.toLowerCase() === ASSET_TYPES.TRAILER);
    } else {
      assetToPlay = assets.find(
        (asset) => asset.assetType.toLowerCase() === ASSET_TYPES.MASTER && asset.videoType === "HD"
      );
      if (!assetToPlay) {
        assetToPlay = assets.find((asset) => asset.assetType.toLowerCase() === ASSET_TYPES.MASTER);
      }
    }
    return { ...assetToPlay, bookmarks, contentId, entitlement };
  };

  /**
   * Function to return the stream content for the selected content
   * @param {Number} contentId unique identifier for content to be checked
   * @param {Number} assetId unique identifier for the asset to be played
   * @param {String} contentType type of content to be checked such as live or vod
   * @param {Object} subscribedChannel object having details of the subscribed channel
   * @param {Object} lookbackContent object having content for a lookback asset
   * @returns {Object} an object containing playback stream content
   */
  const getStreamContent = useCallback(
    (contentId, assetId, contentType, subscribedChannel, lookbackContent) => {
      const streamContent = {};
      if (contentType === PAGE_CONTENT_ITEM_TYPES.live) {
        if (lookbackContent) {
          streamContent.playbackAssetId = lookbackContent.metadata?.contentId;
        } else if (subscribedChannel || subscribedChannels) {
          const subscribedChannelDetails =
            subscribedChannel ||
            subscribedChannels.containers?.find((channel) => channel?.id.toString() === contentId.toString());
          streamContent.isPPV = isPPVChannel(subscribedChannelDetails);
          const channelAsset = subscribedChannelDetails?.assets[0];
          if (channelAsset) {
            streamContent.playbackAssetId = channelAsset?.assetId;
            streamContent.playbackVideoUrl = channelAsset?.videoUrl;
          } else {
            /**
             * ideally there should not be a way to trigger playback for an unsubscribed channel
             * but there were some instances where I encountered that the watch live button was present
             * therefore displaying "message_not_subscribed" error
             */
            streamContent.isUnsubscribed = true;
            dispatch(showToastNotification(translate("message_not_subscribed"), unsubscribedIcon));
          }
        } else {
          /**
           * showing unsubscribed toast in case there are no subscribed channels for a profile
           */
          streamContent.isUnsubscribed = true;
          dispatch(showToastNotification(translate("message_not_subscribed"), unsubscribedIcon));
        }
      } else {
        streamContent.playbackAssetId = assetId;
      }
      streamContent.tokenOnly = isChannelTuneTimeEnabled && streamContent.playbackVideoUrl ? true : false;
      return streamContent;
    },
    [dispatch, isChannelTuneTimeEnabled, subscribedChannels, translate]
  );

  /**
   * Function to redirect user to video player if all checks are passed
   * @param {Object} res API response having playback token and maybe video url
   * @param {String} route route where user should be navigated to
   * @param {Number} contentId unique identifier for content to be checked
   * @param {String} contentType type of content to be checked such as live or vod
   * @param {Number} externalId unique identifier for recordings metadata
   * @param {String} playbackType playback type value such as movie, trailer or episode
   * @param {Object} bookmark object having bookmark values such as start delta time
   * @param {Object} streamContent object containing playback stream content
   * @param {String} pcPin parental control pin for a user
   * @param {Object} lookbackContent object having content for a lookback asset
   */
  const redirectToPlayer = useCallback(
    (res, route, contentId, contentType, externalId, playbackType, bookmark, streamContent, pcPin, lookbackContent) => {
      const skipTimeSeconds =
        (playbackType !== PLAYBACK_TYPES.TRAILER && (bookmark?.isComplete ? null : bookmark?.startDeltaTime)) || null;
      let searchParams = "";
      if (route === LIVE_PLAYER.route) {
        searchParams = `stationId=${contentId}&contentType=${contentType}&isLookback=${lookbackContent ? true : false}`;
      } else if (route === ON_DEMAND_PLAYER.route) {
        searchParams = `playbackId=${contentId}&contentType=${contentType}`;
      } else if (route === RECORDING_PLAYER.route) {
        searchParams = `playbackId=${contentId}&externalId=${externalId}&contentType=${contentType}`;
      }
      history.push({
        pathname: route === LIVE_PLAYER.route ? `${route}/` : `${route}/${playbackType}/`,
        search: searchParams,
        state: {
          playbackContent: {
            videoUrl: streamContent.playbackVideoUrl === res.src ? streamContent.playbackVideoUrl : res.src,
            authToken: res.token,
            bookmark,
            skipTimeSeconds,
            assetId: streamContent.playbackAssetId,
            pcPin,
            lookbackContent,
            purchasePackage: streamContent.purchasePackage,
          },
        },
      });
    },
    [history]
  );

  /**
   * Checks if user is entitled to play the selected content
   * @param {Object} contentMetadata an object having metadata such as entitlement, assets and bookmarks for the selected content
   * @param {String} playbackType playback type value such as movie, trailer or episode
   * @param {Number} contentId unique identifier for content to be checked
   * @param {String} contentType type of content to be checked such as live or vod
   * @param {Function} getMediaNodeMetaData function to generate analytics events
   * @param {String} route route where the user should be navigated to
   * @param {Number} externalId unique identifier for recordings metadata
   * @param {Object} subscribedChannel object having details of the subscribed channel (not provided for vod playback)
   * @param {Function} updateVodDetails function to update on demand playback data (provided when purchasing movies or playing from resume swimlane)
   * @param {Object} playbackContentUserData object having assets and entitlements of the fetched playback content
   * @param {Object} lookbackContent object having content for a lookback asset
   * @param {String} title title for the asset
   */
  const checkEntitlements = useCallback(
    (
      contentMetadata,
      playbackType,
      contentId,
      contentType,
      getMediaNodeMetaData,
      route,
      externalId,
      subscribedChannel = null,
      updateVodDetails,
      playbackContentUserData,
      lookbackContent,
      title,
      isPlayRestricted
    ) => {
      const { entitlement, assetId, bookmarks } = contentMetadata;
      const bookmark = bookmarks?.[0] || null;

      const streamContent = getStreamContent(
        contentId,
        parseInt(assetId),
        contentType,
        subscribedChannel,
        lookbackContent
      );

      updateVodDetails && playbackContentUserData && updateVodDetails(playbackContentUserData);
      const streamContentType = lookbackContent ? PAGE_CONTENT_ITEM_TYPES.program : contentType;

      // PPV logic
      if (streamContent.isPPV) {
        const ppvTuneInfo = getPPVTuneInfo(entitlement);
        if (ppvTuneInfo) {
          streamContent.playbackAssetId = ppvTuneInfo.assetToPlay.assetId;
          streamContent.playbackVideoUrl = null;
          streamContent.tokenOnly = false;
          // PPV specific field needed for purchase flow
          streamContent.purchasePackage = ppvTuneInfo.purchasePackage;
        }
      }

      // check for avoiding video url call for unsubscribed channels
      if (!streamContent.isUnsubscribed) {
        if (
          entitlement &&
          !entitlement.isPCBlocked &&
          !entitlement.isContentOOHBlocked &&
          !entitlement.isGeofencedBlocked &&
          !entitlement.isSportBlackoutBlocked &&
          !entitlement.isPlatformBlacklisted &&
          !entitlement.isChannelNotSubscribed &&
          !entitlement.isGeoBlocked &&
          !isPlayRestricted.type &&
          (isPlayRestricted.source === PLAY || isPlayRestricted.source === PURCHASE)
        ) {
          return fetchVideoUrl(
            appProvider,
            streamContentType,
            contentId,
            streamContent.playbackAssetId,
            null,
            streamContent.tokenOnly,
            cancelTokenSource.current
          )
            .then((res) => {
              if (res) {
                redirectToPlayer(
                  res,
                  route,
                  contentId,
                  contentType,
                  externalId,
                  playbackType,
                  bookmark,
                  streamContent,
                  null,
                  lookbackContent
                );
              }
            })
            .catch((err) => {
              if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
                dispatch(
                  showToastNotification(
                    translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel)
                  )
                );
              } else {
                console.error(err);
                dispatch(showToastNotification(getAVSPlaybackError(err).message));
              }
            });
        } else {
          if (entitlement.isContentOOHBlocked) {
            dispatch(showToastNotification(translate("message_in_home")));
          } else if (entitlement.isGeofencedBlocked) {
            dispatch(showToastNotification(translate("error_outside_region")));
          } else if (entitlement.isSportBlackoutBlocked) {
            dispatch(showToastNotification(translate("error_program_restricted")));
          } else if (entitlement.isPlatformBlacklisted) {
            dispatch(showToastNotification(translate("error_program_not_available_device")));
          } else if (entitlement.isChannelNotSubscribed) {
            dispatch(showToastNotification(translate("message_subscribe")));
          } else if (entitlement.isGeoBlocked) {
            dispatch(showToastNotification(translate("error_program_not_available_region")));
          } else if (
            entitlement.isPCBlocked &&
            !isPlayRestricted.type &&
            (isPlayRestricted.source === PLAY || isPlayRestricted.source === PURCHASE)
          ) {
            telusConvivaAnalytics.reportParentalPinStart(title, contentType);
            const modalType = MODAL_TYPES.PIN;
            const modalContent = {
              title: translate("enter_parental_pin"),
              history,
              isCloseable: true,
              analyticsInfo: {
                errorCode: null,
                media: getMediaNodeMetaData && getMediaNodeMetaData(),
              },
              pinModalMode: PIN_MODAL_MODES.ACCESS,
              pinModalType: PIN_MODAL_TYPES.PARENTAL,
              pinConfirmHandler: async (pin) => {
                return fetchVideoUrl(
                  appProvider,
                  streamContentType,
                  contentId,
                  streamContent.playbackAssetId,
                  pin,
                  streamContent.tokenOnly,
                  cancelTokenSource.current
                )
                  .then((res) => {
                    telusConvivaAnalytics.reportParentalPinSuccess();
                    redirectToPlayer(
                      res,
                      route,
                      contentId,
                      contentType,
                      externalId,
                      playbackType,
                      bookmark,
                      streamContent,
                      pin,
                      lookbackContent
                    );
                  })
                  .catch((err) => {
                    if (
                      pin &&
                      (appProvider.AGL_Version === MVE2_AGL_VERSION
                        ? err?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS)
                        : AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code))
                    ) {
                      telusConvivaAnalytics.reportParentalPinFailed(false, err);
                      throw err;
                    } else if (pin && isPinMaxLimitReach(err)) {
                      telusConvivaAnalytics.reportParentalPinFailed(true, err);
                      throw err;
                    } else if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
                      telusConvivaAnalytics.reportPlaybackError(err);
                      dispatch(showToastNotification)(
                        translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel)
                      );
                    } else {
                      telusConvivaAnalytics.reportPlaybackError(err);
                      dispatch(showToastNotification(getAVSPlaybackError(err).message));
                    }
                  });
              },
              pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR,
                ACTION_VALUES.PARENTAL_PIN_CONTENT_UNLOCK,
                WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR
              ),
            };
            dispatch(showModalPopup(modalType, modalContent));
          }
        }
      }
    },
    [appProvider, cancelTokenSource, dispatch, getStreamContent, history, redirectToPlayer, translate]
  );

  /**
   * Function to fetch content entitlements if not already provided or if playback is not successful
   * @param {String} playbackType playback type value such as movie, trailer or episode
   * @param {Number} contentId unique identifier for content to be checked
   * @param {String} contentType type of content to be checked such as live or vod
   * @param {Function} getMediaNodeMetaData function to generate analytics events
   * @param {String} route route where the user should be navigated to
   * @param {Number} externalId unique identifier for recordings metadata
   * @param {Object} subscribedChannel object having details of the subscribed channel (not provided for vod playback)
   * @param {Function} updateVodDetails function to update on demand playback data (provided when purchasing movies or playing from resume swimlane)
   * @param {Object} lookbackContent object having content for a lookback asset
   * @param {String} title title for the asset
   */
  const fetchEntitlements = useCallback(
    (
      playbackType,
      contentId,
      contentType,
      getMediaNodeMetaData,
      route,
      externalId,
      subscribedChannel,
      updateVodDetails,
      lookbackContent,
      title,
      isPlayRestricted
    ) => {
      getContentUserData(appProvider, contentId, contentType, cancelTokenSource, lookbackContent?.metadata?.contentId)
        .then((res) => {
          if (res && res.containers && res.containers.length > 0) {
            checkEntitlements(
              convertToUserDataModel(res.containers[0], playbackType, contentId),
              playbackType,
              contentId,
              contentType,
              getMediaNodeMetaData,
              route,
              externalId,
              subscribedChannel,
              updateVodDetails,
              res,
              lookbackContent,
              title,
              isPlayRestricted
            );
          } else if (res.errorDescription) {
            const { errorTitle, errorMessage } = getPlaybackErrorMessageInfo({ code: res.errorDescription });
            const message = errorMessage ? translate(errorMessage) : "";
            const errorDetails = errorTitle ? translate(errorTitle) : "";
            const modalContent = {
              title: errorDetails,
              history,
              isCloseable: true,
              message,
              analyticsInfo: {
                errorCode: res.errorDescription,
                media: getMediaNodeMetaData && getMediaNodeMetaData(),
              },
            };

            dispatch(showModalPopup(MODAL_TYPES.ERROR, modalContent));
          }
        })
        .catch((err) => console.error(err));
    },
    [appProvider, cancelTokenSource, checkEntitlements, dispatch, history, translate]
  );

  /**
   * Performs the checks before initiating playback for the selected content
   * @param {Object} contentMetadata an object having metadata such as entitlement, assets and bookmarks for the selected content
   * @param {String} playbackType playback type value such as movie, trailer or episode
   * @param {Number} contentId unique identifier for content to be checked
   * @param {String} contentType type of content to be checked such as live or vod
   * @param {String} route route where the user should be navigated to
   * @param {Function} getMediaNodeMetaData function to generate analytics events
   * @param {Number} externalId unique identifier for recordings metadata
   * @param {Object} subscribedChannel object having details of the subscribed channel (not provided for vod playback)
   * @param {Function} updateVodDetails function to update on demand playback data (provided when purchasing movies or playing from resume swimlane)
   * @param {Object} lookbackContent object having content for a lookback asset
   * @param {String} title tittle for the asset
   * @param {String} stopDeltaTime for the asset
   * @param {Boolean} isPlayRestricted if 4K device supported or not
   */
  const performPlaybackChecks = useCallback(
    async (
      contentMetadata = null,
      playbackType,
      contentId,
      contentType,
      route,
      getMediaNodeMetaData,
      externalId,
      subscribedChannel = null,
      updateVodDetails,
      lookbackContent,
      title,
      isPlayRestricted
    ) => {
      if (is4KVodEnabled && isPlayRestricted && isPlayRestricted?.type && isPlayRestricted?.source === PLAY) {
        const modalContent = {
          title:
            isPlayRestricted.type === RESOLUTION_TYPE._4KHDR
              ? translate("4k_hdr_warning_title")
              : translate("4k_warning_title"),
          subtitle:
            isPlayRestricted.type === RESOLUTION_TYPE._4KHDR
              ? translate("4k_hdr_warning_body")
              : translate("4k_warning_body"),
          isCloseable: true,
        };
        return dispatch(showModalPopup(MODAL_TYPES.WARNING_4K_PLAY, modalContent));
      }

      if (!passFeatureFlags(contentType, lookbackContent)) {
        return;
      }

      // Check for unsupported platforms
      if (!isPlatformAllowToPlay(appProvider)) {
        const modalContent = {
          title: ERROR_TYPES.INFO,
          history,
          isCloseable: true,
          message: translate("error_playback_browser"),
        };

        dispatch(showModalPopup(MODAL_TYPES.ERROR, modalContent));
        return;
      }

      if (contentMetadata) {
        checkEntitlements(
          contentMetadata.assetId ? contentMetadata : convertToUserDataModel(contentMetadata, playbackType, contentId),
          playbackType,
          contentId,
          contentType,
          getMediaNodeMetaData,
          route,
          externalId,
          subscribedChannel,
          null,
          null,
          lookbackContent,
          title,
          isPlayRestricted
        );
      } else {
        const streamContent = getStreamContent(contentId, null, contentType, subscribedChannel, lookbackContent);
        if (
          isChannelTuneTimeEnabled &&
          !lookbackContent &&
          !streamContent?.isPPV &&
          !streamContent.isUnsubscribed &&
          streamContent?.playbackAssetId &&
          streamContent?.playbackVideoUrl
        ) {
          // playback tune time optimization (currently applicable only for on now non-ppv streams)
          fetchVideoUrl(
            appProvider,
            contentType,
            contentId,
            streamContent?.playbackAssetId,
            null,
            true,
            cancelTokenSource.current
          )
            .then((res) => {
              redirectToPlayer(
                res,
                route,
                contentId,
                contentType,
                externalId,
                playbackType,
                null,
                streamContent,
                null,
                null
              );
            })
            .catch((err) => {
              if (
                err?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS) ||
                AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code)
              ) {
                fetchEntitlements(
                  playbackType,
                  contentId,
                  contentType,
                  getMediaNodeMetaData,
                  route,
                  externalId,
                  subscribedChannel,
                  null,
                  null,
                  title,
                  isPlayRestricted
                );
              } else if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
                dispatch(
                  showToastNotification(
                    translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel)
                  )
                );
              } else {
                dispatch(showToastNotification(getAVSPlaybackError(err).message));
              }
            });
        } else if (!streamContent.isUnsubscribed) {
          fetchEntitlements(
            playbackType,
            contentId,
            contentType,
            getMediaNodeMetaData,
            route,
            externalId,
            subscribedChannel,
            updateVodDetails,
            lookbackContent,
            title,
            isPlayRestricted
          );
        }
      }
    },
    [
      appProvider,
      cancelTokenSource,
      checkEntitlements,
      dispatch,
      fetchEntitlements,
      getStreamContent,
      history,
      isChannelTuneTimeEnabled,
      redirectToPlayer,
      translate,
      passFeatureFlags,
      is4KVodEnabled,
    ]
  );

  return { performPlaybackChecks };
}
