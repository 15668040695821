import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  TextInputContainer,
  TextInputStyled,
  TextInputError,
  InputWithIconWrapper,
  InputIcon,
} from "./TextInput.styles";

/**
 * Text input component
 * @component
 * @param {Object} props
 */
const TextInput = forwardRef(function TextInput({ className = "", errorMessage, icon, onIconClick, ...rest }, ref) {
  return (
    <TextInputContainer>
      {icon ? (
        <InputWithIconWrapper>
          <TextInputStyled className={`${errorMessage ? "invalid" : ""} ${className}`} ref={ref} {...rest} />
          <InputIcon src={icon} alt="icon" onClick={onIconClick} />
        </InputWithIconWrapper>
      ) : (
        <TextInputStyled className={`${errorMessage ? "invalid" : ""} ${className}`} ref={ref} {...rest} />
      )}
      {errorMessage ? <TextInputError>{errorMessage}</TextInputError> : null}
    </TextInputContainer>
  );
});

TextInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
};

export default TextInput;
