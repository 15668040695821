import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReducers } from "../../shared/hooks/useReducer";
import PropTypes from "prop-types";
import moment from "moment";

import PopUpOutline from "../PopUpOutline";
import OptikButton from "../../components/OptikButton";
import Purchase4KWarningModal from "../Purchase4KWarningModal";
import constants from "../../shared/constants";
import playerConstants from "../../shared/constants/player";
import { getPurchaseTransactionType } from "../../shared/utils";
import { getFormattedPrice, getPackagePrice, getChannelInfoString } from "../../shared/utils/feedHelper";
import { handleImageError } from "../../shared/utils/image";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import "./style.scss";

const { TRANSACTION_TYPES, DEFAULT_RENTAL_DURATION, PACKAGE_TYPE, PLAY_RESTRICTION, RESOLUTION_TYPE, REDUCER_TYPE } =
  constants;
const { ASSET_TYPES } = playerConstants;
const portraitL = process.env.PUBLIC_URL + "/images/swimlane-portrait-166px.jpg";
const PurchaseModal = ({
  channel,
  closeModal = () => {},
  itemImage,
  itemMetadata,
  purchaseItem = () => {},
  purchasePackage,
}) => {
  const [disablePurchaseCTA, setDisablePurchaseCTA] = useState(false);

  const { t: translate } = useTranslation();
  const { appLanguage } = useAppLanguage();
  const { featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { is4KVodEnabled } = featureToggles;

  const isPPVEvent = purchasePackage?.type?.toLowerCase() === PACKAGE_TYPE.PPV_EVENT;
  const transactionType = isPPVEvent ? TRANSACTION_TYPES.BUY : getPurchaseTransactionType(purchasePackage?.name);
  const isRentTransaction = transactionType === TRANSACTION_TYPES.RENT;
  const selectedPrice = getPackagePrice(purchasePackage) ?? 0;
  const language = itemMetadata?.metadata?.language || itemMetadata?.metadata?.extendedMetadata?.dlum?.defaultLanguage;
  const [showWarningModal, setShowWarningModal] = useState(false);
  const isPlayRestricted =
    itemMetadata?.assets[0]?.extendedMetadata?.playRestriction?.toLowerCase() === PLAY_RESTRICTION.DEVICERESTRICT &&
    itemMetadata?.assets[0]?.assetType?.toLowerCase() === ASSET_TYPES.MASTER;

  const getHeaderString = () => {
    let headerKey;
    if (isPPVEvent) {
      headerKey = "ppv_purchase_prompt_title";
    } else {
      headerKey = transactionType === TRANSACTION_TYPES.BUY ? translate("buy_for") : translate("rent_for");
    }

    return translate(headerKey);
  };

  const getAccessMessage = () => {
    if (isPPVEvent) {
      if (itemMetadata) {
        const startTimeMoment = moment(itemMetadata?.metadata?.airingStartTime);
        const startDate = startTimeMoment.format("LL");
        const startTime = startTimeMoment.format("LT");
        const endTime = moment(itemMetadata?.metadata?.airingEndTime).format("LT");

        return `${startDate} - ${translate("date_time_from_to").replace("%s", startTime).replace("%s", endTime)}`;
      }
    } else {
      return isRentTransaction
        ? translate("order_available_hours").replace("<duration>", DEFAULT_RENTAL_DURATION)
        : translate("order_title");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const disallowPurchase = (value) => {
    setDisablePurchaseCTA(value);
  };

  const handlePurchaseClick = () => {
    setShowWarningModal(true);
  };

  const handleWarningConfirm = () => {
    purchaseItem(purchasePackage, disallowPurchase);
    setShowWarningModal(false);
  };

  const handleWarningCancel = () => {
    setShowWarningModal(false);
  };

  const purchaseVideoTypes = [
    ...new Set(
      itemMetadata?.assets?.map((pkg) => {
        if (pkg.assetType?.toLowerCase() === ASSET_TYPES.MASTER) {
          if (is4KVodEnabled) {
            if (pkg.videoType === RESOLUTION_TYPE._4K) {
              return pkg.extendedMetadata?.hdrType === RESOLUTION_TYPE.HDR10
                ? RESOLUTION_TYPE._4KHDR
                : RESOLUTION_TYPE._4K;
            }
            return pkg.videoType;
          } else {
            return pkg.videoType !== RESOLUTION_TYPE._4K ? pkg.videoType : null;
          }
        }
        return null;
      })
    ),
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <React.Fragment>
      <div className="purchase-modal-backdrop"></div>
      <div className="purchase-modal-container">
        <div className="purchase-modal-popup-container">
          <PopUpOutline className="purchase-modal-popup">
            <div className="purchase-modal-header">{translate("confirm_order")}</div>
            <div className="purchase-item">
              <div className="purchase-item-image">
                <img
                  src={itemImage ? `${itemImage}?w=166` : portraitL}
                  alt=""
                  data-testid="thumbnail"
                  className="thumbnail"
                  width="100%"
                  height="100%"
                  onError={(e) => handleImageError(e, portraitL)}
                />
              </div>
              <div className="purchase-item-details">
                <div className="purchase-information">
                  <div className="purchase-parameters">
                    <div className="purchase-parameter">
                      <span className="parameter-title">{translate("title_capitalized")}</span>
                      <span className="parameter-value">{itemMetadata?.metadata?.title}</span>
                    </div>
                    <div className="purchase-parameter">
                      <span className="parameter-title">{translate("order")}</span>
                      <div>
                        <span className="price parameter-value">
                          {getHeaderString()} {getFormattedPrice(selectedPrice, appLanguage)}
                        </span>
                        <span className="access-message">{getAccessMessage()}</span>
                      </div>
                    </div>
                    <div className="purchase-parameter">
                      {channel && (
                        <>
                          <span className="parameter-title">{translate("channel")}</span>
                          <span className="parameter-value">{getChannelInfoString(channel)}</span>
                        </>
                      )}
                    </div>
                    {purchaseVideoTypes && (
                      <div className="purchase-parameter">
                        <>
                          <span className="parameter-title">{translate("order_resolution")}</span>
                          <span className="parameter-value">{purchaseVideoTypes}</span>
                        </>
                      </div>
                    )}
                    <div className="purchase-parameter">
                      {language?.length > 0 && (
                        <>
                          <span className="parameter-title">{translate("language")}</span>
                          <span className="parameter-value">
                            {language.toLowerCase().startsWith("en")
                              ? translate("canada_english")
                              : translate("canada_french")}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="purchase-parameter">
                      {itemMetadata?.metadata?.extendedMetadata?.dlum?.rating && (
                        <>
                          <span className="parameter-title">{translate("order_rating")}</span>
                          <span className="parameter-value">
                            {itemMetadata?.metadata?.extendedMetadata.dlum.rating}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="purchase-cta">
              <button
                className="purchase-cancel-cta"
                onClick={() => {
                  logNREvent(NR_PAGE_ACTIONS.PURCHASE_CANCELLED);
                  logDatadogEvent(DD_PAGE_ACTIONS.PURCHASE_CANCELLED);
                  closeModal();
                }}
              >
                {translate("cancel")}
              </button>
              <OptikButton
                label={translate(isRentTransaction ? "order_rent" : "order_now")}
                onClickHandler={
                  (itemMetadata?.metadata?.extendedMetadata?.isUHD ||
                    itemMetadata?.metadata?.extendedMetadata?.isHDR) &&
                  !itemMetadata?.metadata?.extendedMetadata?.isHD &&
                  isPlayRestricted
                    ? handlePurchaseClick
                    : () => purchaseItem(purchasePackage, disallowPurchase)
                }
                disabled={disablePurchaseCTA}
              />

              {showWarningModal && (
                <Purchase4KWarningModal
                  title={
                    itemMetadata?.metadata?.extendedMetadata?.isHDR
                      ? translate("4k_hdr_warning_title_purchase")
                      : translate("4k_warning_title_purchase")
                  }
                  subtitle={
                    itemMetadata?.metadata?.extendedMetadata?.isHDR
                      ? translate("4k_hdr_warning_body")
                      : translate("4k_warning_body")
                  }
                  onConfirm={handleWarningConfirm}
                  onCancel={handleWarningCancel}
                  isClosable={false}
                />
              )}
            </div>
          </PopUpOutline>
        </div>
      </div>
    </React.Fragment>
  );
};

PurchaseModal.propTypes = {
  channelMetadata: PropTypes.object,
  closeModal: PropTypes.func,
  itemImage: PropTypes.string,
  itemMetadata: PropTypes.object,
  purchaseItem: PropTypes.func,
  purchasePackage: PropTypes.object,
};

export default PurchaseModal;
