/**
 * load core modules
 */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
/* load core modules end */

/**
 * load actions
 */
import {
  hideTopNav,
  showModalPopup,
  showPlaybackError,
  showToastNotification,
  loadChannels,
  loadUserSubscribedChannels,
  resetAction,
  showRecordingSettingsPanel,
  toggleSpinningLoaderAction,
} from "../../App/state/actions";
import {
  SHOW_PURCHASE_ACKNOWLEDGEMENT,
  loadProgramDetail,
  loadRecordingProgramDetail,
  loadCurrentSeriesDetails,
  LOAD_CURRENT_SERIES_DETAILS,
  loadSeasons,
  LOAD_PLAYBACK_SEASON_DATA,
  loadCurrentSeasonDetails,
  LOAD_CURRENT_SEASON_DETAILS,
  LOAD_CURRENT_PROGRAM_DETAILS,
  loadCurrentSeasonEntitlements,
  LOAD_CURRENT_SEASON_ENTITLEMENTS,
} from "./state/actions";
import {
  setRecordingAction,
  getRecordingAction,
  SET_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
  GET_RECORDINGS,
  DELETE_RECORDINGS,
  EDIT_RECORDINGS,
  toggleSettingsPanelAction,
} from "../RecordingsPage/state/actions";
/* load actions end */

/**
 * load constants
 */
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import errorConstants from "../../shared/constants/error";
import PlayerConstants from "../../shared/constants/player";
import parentalControlConstants from "../../shared/constants/parentalControls";
import storageConstants from "../../shared/constants/storage";
import {
  ACTION_VALUES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  EXTRA_METADATA_TYPES,
  LINK_INFO,
  WEB_ACTION_EVENT_NAMES,
  MAPPED_CONTENT_TYPES,
} from "../../shared/constants/analytics";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_CUSTOM_ATTRIBUTES, DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
/* load constants end */

/**
 * load middleware
 */
import middleware from "../../shared/middleware";
/* load middleware end */

/**
 * load util functions
 */
import {
  createVideoStream,
  createMetadataInfo,
  getAVSPlaybackError,
  getPlaybackErrorMessageInfo,
  getStreamType,
  getNextLiveStream,
} from "../../shared/utils/playbackHelpers";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import {
  channelCompareFunction,
  createEpgChannelObject,
  getRegionalChannelNumber,
  isPPVChannel,
  checkIsLookbackSupported,
  isUserSubscribedChannel,
} from "../../shared/utils/epg";
import {
  getAutoGeneratedObject,
  getAutogeneratedEpisodeString,
  isPinMaxLimitReach,
  isPlatformAllowToPlay,
  numberStringCompareFunction,
  isBookmarkComplete,
} from "../../shared/utils";
import {
  getRecordingSystemType,
  setRecording,
  getRecordingInfo,
  getCPVRRecordingStatus,
  navigateToPVRManager,
  showCPVRConflictModal,
  checkCPVRConflicts,
  isRecordingPendingStateCheck,
  isCPVRRecordingRecorded,
  isCPVRRecordingInProgress,
} from "../../shared/utils/recordingHelper";
import { isItemTypeEpisode } from "../../shared/utils/content";
import { getEpisodeDetail } from "../../shared/utils/swimlane";
import useGuidePrograms, { getSegmentTimestamp, getNextSegmentTimestamp } from "../../shared/hooks/useGuidePrograms";
import { setLocalStorage, removeLocalStorage } from "../../shared/utils/localStorage";
import { getPPVTuneInfo } from "../../shared/utils/playerUtils";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";
/* load util functions end */

/**
 * load analytics functions
 */
import { trackMediaStart, trackMediaEnd, trackMediaPlay } from "../../shared/analytics/media";
import { trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { getEpisodeInfo, getGenericErrorEventHandler } from "../../shared/analytics/helpers";
import { setNRAttribute, logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent, setDatadogViewAttribute } from "../../shared/analytics/datadog";
/* load analytics functions end */

/**
 * load components
 */
import VideoPlayer from "../../components/VideoPlayer";
import VideoPlayerSideBar from "../../components/VideoPlayerSideBar";
import ContentLockedOverlay from "../../components/ContentLockedOverlay";
import SeoPageTags from "../../components/SeoPageTags";
import VodPlayerSideBar from "../../components/VodPlayerSideBar";
import MovieSideBar from "../../components/MovieSideBar";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import telusConvivaAnalytics from "../../shared/utils/convivaAnalytics";
import RecordingModal from "../../components/RecordingModal";
/* load components end */

/**
 * load assets
 */
import { getAVSKeyArtImage } from "../../shared/utils/image";
import "./style.scss";
const outHomeIcon = process.env.PUBLIC_URL + "/images/Out_of_home_toast_icon.svg";
/* load assets end */

/** declare/destructure constants */
const { IMAGES, MODAL_TYPES, PAGE_CONTENT_ITEM_TYPES, MVE2_AGL_VERSION, APP_ENVIRONMENTS } = constants;
const ENV = process.env.NODE_ENV || APP_ENVIRONMENTS.PROD;
const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS, CPVR_INTERNAL_RECORDING_STATUS } =
  recordingConstants;
const {
  PLAYER_TYPE: { VOD, LIVE, RECORDING },
  STREAM_MODE,
  PLAYER_STREAM_CONFIG,
  STREAM_FORMAT_TYPES,
  DRM_SYSTEM_TYPES,
  ASSET_TYPES,
  PLAYBACK_TYPES,
  NON_FATAL_ERROR_CODES: nonFatalErrorCodes,
  PLAYER_ERROR_CODES: playerErrorCodes,
  PLAYER_CONTROLS: { GET_NEXT_LIVE_PROGRAM, PAUSE, RESUME },
} = PlayerConstants;
const { PLAYER_PIN_REFRESH_INTERVAL_MS } = parentalControlConstants;
const { ERROR_TYPES, AVS_ERROR_CODES } = errorConstants;
const { VIDEO_AUTH_TOKEN } = storageConstants;

/** destructure middleware */
const { fetchVideoUrl, fetchVideoToken, getContentUserData, getNextProgram, videoStreamHeartBeat } = middleware;

/** declare variables */
let isTVShow;

/**
 * Creates a page that streams videos
 *
 * @component
 * @param {*} props - Player props
 */
function PlayerPage({
  appProvider,
  channelMapInfo,
  hideTopNav,
  resetAction,
  isRented,
  showModalPopup,
  showPlaybackError,
  loadCurrentSeasonDetails,
  seasonData,
  seasonFeedContent,
  loadChannels,
  showToastNotification,
  playbackError,
  programContent,
  loadProgramDetail,
  loadRecordingProgramDetail,
  loadSeasons,
  loadCurrentSeriesDetails,
  seriesDetails,
  loadCurrentSeasonEntitlements,
  isInHome,
  userProfile,
  convivaContentSubType,
  loadUserSubscribedChannels,
  subscribedChannels,
  currentPrograms,
  setRecordingAction,
  getRecordingAction,
  recordingsList,
  setRecordingResponse,
  manipulatedRecordingParams,
  getRecordingError,
  deleteRecordingResponse,
  featureToggles,
  showRecordingSettingsPanel,
  toggleSettingsPanelAction,
  toggleSpinningLoaderAction,
  editRecordingResponse,
}) {
  const {
    isChannelTuneTimeEnabled: isCTTEnabled,
    isParentalPINEnabled,
    isRecordingEnabled,
    isPPVFeatureEnabled: isPPVEnabled,
    isUserProfilesEnabled,
    isConvivaAppTrackerEnabled,
    isVODDisabled,
    isLIVEDisabled,
    isFASTDisabled,
    isRestartDisabled,
    isCatchUpDisabled,
    isCPVRDisabled,
  } = featureToggles;
  const history = useHistory();
  const match = useRouteMatch();
  const playbackType = match.params.playbackType ?? LIVE;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const playbackID = query.get("playbackId");
  const externalId = query.get("externalId");
  const channelID = query.get("stationId");
  const contentType = query.get("contentType") ?? (channelID ? "LIVE" : "VOD");
  const isLookback = query.get("isLookback") ? JSON.parse(query.get("isLookback")) : false;
  const playbackContent = location?.state?.playbackContent;
  const playbackVideoUrl = playbackContent?.videoUrl;
  const playbackAuthToken = playbackContent?.authToken;
  const playbackBookmark = isBookmarkComplete(playbackContent?.bookmark) ? null : playbackContent?.bookmark;
  const playbackSkipTimeSeconds = playbackContent?.skipTimeSeconds;
  const playbackAssetId = playbackContent?.assetId;
  const playbackPcPin = query.get("pcPin") || playbackContent?.pcPin;

  const { t: translate } = useTranslation();
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();
  const isLivePlayer = useMemo(() => contentType === "LIVE", [contentType]);
  const isVodPlayer = useMemo(() => contentType === "VOD", [contentType]);
  const isRecordingPlayer = useMemo(() => contentType === "RECORDING", [contentType]);

  /** declare states */
  const [streamInfo, setStreamInfo] = useState(null);
  const [metadataInfo, setMetadataInfo] = useState(null);
  // Currently using it only if the asset is conflicted.
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const [currentChannelId, setCurrentChannelId] = useState(channelID);
  const [liveProgramDetails, setLiveProgramDetails] = useState(null);
  const [miniGuideOnNowPrograms, setMiniGuideOnNowPrograms] = useState(null);
  const [miniGuideOnNextPrograms, setMiniGuideOnNextPrograms] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInFullScreen, setIsInFullScreen] = useState(true);
  const [authToken, setAuthToken] = useState(playbackAuthToken || null);
  const [videoUrl, setVideoUrl] = useState(playbackVideoUrl || null);
  const [assetId, setAssetId] = useState(playbackAssetId || null);
  const [fatalPlaybackError, setFatalPlaybackError] = useState(null);
  const [playerControlState, setPlayerControlState] = useState(null);
  const [showStillWatchingPrompt, setShowStillWatchingPrompt] = useState(false);
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [recordingActionError, setRecordingActionError] = useState(null);
  // isReadyToChangeStreams acts as a lock that blocks fetching a new stream until the current stop content call has completed.
  const [isReadyToChangeStreams, setIsReadyToChangeStreams] = useState(true);
  const [isParentalPinEnabled, setIsParentalPinEnabled] = useState(false);
  // isCallParentalPinLock is used as a dependency within a useEffect to call parental_pin_content_event once programContent is ready
  const [isCallParentalPinLock, setIsCallParentalPinLock] = useState(false);
  const [showContentLockedOverlay, setShowContentLockedOverlay] = useState(false);
  const [isPinLocked, setIsPinLocked] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [nextProgramInfo, setNextProgramInfo] = useState({});
  const [currentProgram, setCurrentProgram] = useState(null);
  const [currentEpisodeId, setCurrentEpisodeId] = useState(playbackID);
  const [season, setSeason] = useState(null);
  const [bookmark, setBookmark] = useState(playbackBookmark || null);
  const [bingeWatchEpisodeCounter, setBingeWatchEpisodeCounter] = useState(0);
  const [vodProgress, setVodProgress] = useState(0);
  const [hasChannelTuneInfo, setHasChannelTuneInfo] = useState(false);
  const [progressTime, setProgressTime] = useState(playbackSkipTimeSeconds || null);
  const [livePlaybackCanStart, setLivePlaybackCanStart] = useState(
    playbackVideoUrl && playbackAuthToken ? true : false
  );
  const [PCBlocked, setPCBlocked] = useState(false);
  // liveProgramCanUpdate acts as a liveProgramDetails lock. It's useful when a user pauses live playback. In such scenario, we don't need premature liveProgramDetails update
  const [liveProgramCanUpdate, setLiveProgramCanUpdate] = useState(true);
  const [restartLiveStream, setRestartLiveStream] = useState(false);
  const [isLookbackStream, setIsLookbackStream] = useState(isLookback); // flag to indicate a lookback stream
  const [updateLookbackStream, setUpdateLookbackStream] = useState(false); // flag to indicate update of stream on same channel when a lookback stream ends
  const [ppvPurchasePackage, setPpvPurchasePackage] = useState(playbackContent?.purchasePackage);
  const initPlaybackTimestamp = useRef(moment().valueOf()); // timestamp when user initiates playback default to player page load time

  /** declare refs */
  const buttonRef = useRef(null);
  const userInactivityTimer = useRef(null);
  const isRecordingActionInProgress = useRef(false);
  // isLoading prevents changing episodes in the sidebar while a fetchVideoUrl call is being made.
  const isLoading = useRef(false);
  const pcPin = useRef(playbackPcPin || null);
  const pcPinTimer = useRef(null);
  const isProgramChangedRef = useRef(false);
  // isLoading prevents changing channels in the sidebar while a fetchVideoUrl call is being made.
  const currentAssetId = useRef(null);
  const onSwitchProgramEndSessionRef = useRef(null);
  const isSwitchChannelRef = useRef(false);
  const isInitMediaSessionTriggeredRef = useRef(false);
  const liveVideoPlayheadFuncRef = useRef(null);
  const toastNotificationTimer = useRef(null); // Timer ref for unmount clean up
  const isInitMediaSessionTriggeredTimer = useRef(null); // Timer ref for unmount clean up
  // We need to store the value of liveProgramDetails/programContent in a ref to pass into the tracking for pin authorize complete,
  // but liveProgramDetails itself cannot be a ref because we depend on its value changing in an effect
  const programDetailsRef = useRef(isLivePlayer ? liveProgramDetails : programContent);
  const recordingEventTypeRef = useRef(null);
  const cancelTokenSource = useRef(axios.CancelToken.source()); // cancelTokenSource ref for requests unmount clean up
  const prevContentIdForApiRef = useRef(null); // Persist previous contentId to prevent additional middleware API calls in update.
  // TCDWC-2189 TODO: create one mechanism for triggering playback retuning that supports all of the different scenarios (restart, lookback, PPV, etc.)
  const prevPurchasePackageForApiRef = useRef(ppvPurchasePackage); // Persist previous purchase package to know when to retrigger the tuning logic after a purchase (since channel & program IDs remain the same)
  const prevAuthToken = useRef(null); // Persist previous authToken to prevent additional streamInfo set. Additional streamInfo set will trigger unnecessary player source load.
  const prevSeason = useRef(null); // Persist previous season state to prevent additional API calls.
  const prevSeriesId = useRef(null); // Persist previous seriesId to prevent additional API calls.
  const isSidebarUpdated = useRef(false);
  const sidebarLastUpdateTimestamp = useRef(null);
  const isRestartedStream = useRef(false); // boolean ref to act as check if current stream is a start over stream
  const prevVideoUrl = useRef(null); // Persist previous video url to prevent duplicate video stream creation
  const isNextProgramNonRestartable = useRef(false); // boolean ref to determine if next live program should start over automatically or not
  const restartAssetId = useRef(null); // Persist asset id of start over stream
  const preventReadyToChangeStreams = useRef(false);
  const useRoutePlaybackState = useRef(playbackContent ? true : false); // boolean ref to act as check for using the route state for loading playback
  const exitLookbackMode = useRef(false); // flag to exit the lookback stream
  const lookbackContent = useRef(playbackContent?.lookbackContent); // ref to persist and update the lookback content
  const nextLookbackProgram = useRef(false); // ref to persist and update next program for a lookback stream
  const playBookmarkedEpisode = useRef(false);
  const prevLiveProgramDetails = useRef(null); // ref to preserve details of the live stream across the re-renders, to be used for comparison with next stream during stream transition
  const didSeasonChange = useRef(false);
  const nrContentType =
    contentType !== "LIVE"
      ? contentType
      : isLookbackStream
      ? "LOOKBACK"
      : isRestartedStream.current
      ? "RESTART"
      : "LIVE"; // New Relic content type required for PLAY_START event

  // use local development domain origin when developing locally to allow playback on Safari
  const redirectUri =
    ENV === APP_ENVIRONMENTS.DEV ? window.location.origin : appProvider.config.general.AVS_config.redirectUri;

  const { loadGuidePrograms } = useGuidePrograms();

  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );
  const isPlayerControlsDisable = useMemo(() => {
    if (appProvider.panicMode && isLivePlayer) return true;
  }, [appProvider.panicMode, isLivePlayer]);

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  const getMediaNodeMetaData = useCallback(() => {
    if (isLivePlayer && programDetailsRef.current?.metadata && programDetailsRef.current?.channel) {
      return {
        isItemLive: true,
        playbackType,
        mappedContentType: isRestartedStream ? MAPPED_CONTENT_TYPES.RESTART : null,
        metadata: {
          ...programDetailsRef.current?.metadata,
          channel: programDetailsRef.current?.channel,
        },
      };
    }
    if (lookbackContent.current?.metadata) {
      return {
        isItemLive: true,
        playbackType,
        ...lookbackContent.current,
        mappedContentType: MAPPED_CONTENT_TYPES.LOOKBACK,
      };
    }
    if (isVodPlayer && programDetailsRef.current?.[0]?.metadata && convivaContentSubType && playbackType) {
      return {
        isItemLive: false,
        playbackType,
        mappedContentType: convivaContentSubType,
        metadata: programDetailsRef.current?.[0]?.metadata,
      };
    }
    if (isRecordingPlayer && programDetailsRef.current?.[0]?.metadata && playbackType && programContent?.[0]) {
      /**
       * @todo replace returned data here once recording backend got changed
       */
      return {
        playbackType,
        isItemLive: false,
        mappedContentType: isMR ? MAPPED_CONTENT_TYPES.LPVR : MAPPED_CONTENT_TYPES.CPVR,
        ...programContent[0],
        channel: {
          ...programContent?.[0]?.channel,
          ...programContent?.[0],
        },
      };
    }
    return {};
  }, [convivaContentSubType, isLivePlayer, isMR, isRecordingPlayer, isVodPlayer, playbackType, programContent]);

  const restartUserInactivityTimer = useCallback(() => {
    if (userInactivityTimer.current) {
      clearTimeout(userInactivityTimer.current);
    }
    setShowStillWatchingPrompt(false);
    if (isLivePlayer) {
      userInactivityTimer.current = setTimeout(() => {
        setShowStillWatchingPrompt(true);
      }, appProvider.config.player_inactivity.live_timeout * 1000);
    } else if (isVodPlayer || isRecordingPlayer) setBingeWatchEpisodeCounter(0);
  }, [appProvider.config.player_inactivity.live_timeout, isLivePlayer, isVodPlayer, isRecordingPlayer]);

  // Concatenate current and future programs from redux store for use in mini guide
  const miniGuidePrograms = useMemo(() => {
    if (!isLivePlayer) {
      return null;
    }
    const currentSegmentTimestamp = getSegmentTimestamp();
    const segmentTimestamps = Object.keys(currentPrograms).sort(numberStringCompareFunction);
    return segmentTimestamps.reduce((result, timestamp) => {
      if (parseInt(timestamp, 10) < currentSegmentTimestamp) {
        // Ignore segments before the current segment
        return result;
      }

      const segmentPrograms = currentPrograms[timestamp] ?? {};
      for (const channelId in segmentPrograms) {
        if (!result[channelId]) {
          result[channelId] = [];
        }
        const channelPrograms = segmentPrograms[channelId];
        channelPrograms &&
          Array.isArray(channelPrograms) &&
          channelPrograms.forEach((program) => {
            // Ignore duplicates
            if (result[channelId].some((previouslyAddedProgram) => previouslyAddedProgram.id === program.id)) {
              return;
            }
            result[channelId].push(program);
          });
      }
      return result;
    }, {});
  }, [isLivePlayer, currentPrograms]);

  // List of subscribed user channels, sorted by channel number
  const miniGuideChannels = useMemo(() => {
    if (!(isLivePlayer && subscribedChannels?.containers)) {
      return null;
    }
    return subscribedChannels.containers
      ?.map((channel) => createEpgChannelObject(channel, appProvider.channelMapID))
      .sort(channelCompareFunction);
  }, [isLivePlayer, subscribedChannels, appProvider.channelMapID]);

  // Channel item that is being tuned to
  const currentChannel = useMemo(() => {
    if (isLivePlayer && channelMapInfo) {
      const channel = channelMapInfo.containers?.find((channel) => channel.id === currentChannelId);
      if (channel) return createEpgChannelObject(channel, appProvider.channelMapId);
    }

    return null;
  }, [appProvider, channelMapInfo, currentChannelId, isLivePlayer]);

  const isPPV = isPPVChannel(currentChannel);
  if (
    isLivePlayer &&
    currentChannel &&
    ((isPPV && !isPPVEnabled) || !isUserSubscribedChannel(currentChannel, subscribedChannels))
  ) {
    // If user has bookmarked the player page in a state that is no longer supported, kick them out
    history.replace("/");
  }

  const handleListIconClick = (onSwitchProgramEndConvivaSession) => {
    setShowSideNav(!showSideNav);
    if (isLivePlayer) setCurrentIndex(0);
    restartUserInactivityTimer();
    if (!onSwitchProgramEndSessionRef.current) {
      onSwitchProgramEndSessionRef.current = { onSwitchProgramEndConvivaSession };
    }
  };

  const updateVodProgress = (progress) => {
    setVodProgress(progress);
  };

  const contentSubtype = useMemo(
    () =>
      programContent && programContent[0] && programContent[0].metadata && programContent[0].metadata.contentSubtype,
    [programContent]
  );

  if (contentSubtype !== "MOVIE") {
    isTVShow = true;
  } else {
    isTVShow = false;
  }

  const onSeasonChange = (item) => {
    loadCurrentSeasonDetails(appProvider, item);
    restartUserInactivityTimer();
    loadCurrentSeasonEntitlements(appProvider, `${item?.id}?season=${item?.metadata?.season}`);
    if (parseInt(metadataInfo?.extendedMetadata?.season) !== item?.metadata?.season) {
      didSeasonChange.current = true;
    } else {
      didSeasonChange.current = false;
    }
  };

  const recordingsToDisplay = useMemo(() => {
    if (programContent?.[0]?.metadata?.programSeriesId && isRecordingPlayer && recordingsList?.length > 0) {
      for (const recording of recordingsList) {
        if (recording.recordingType === RECORDING_PARAMS.EVENT && recording.containers?.length > 0) {
          return recording.containers.filter((container) => {
            const recordingStatus = getCPVRRecordingStatus({ eventRecordingItem: container });
            return (
              (isCPVRRecordingRecorded(recordingStatus) || isCPVRRecordingInProgress(recordingStatus)) &&
              container.metadata?.programSeriesId === programContent[0].metadata.programSeriesId
            );
          });
        }
      }
    }
  }, [programContent, recordingsList, isRecordingPlayer]);

  // BE doesn't give us the recordings in sorted order hence this needs to be done on the client side.
  const sortedRecordings = useMemo(() => {
    if (isRecordingPlayer) {
      if (recordingsToDisplay?.length > 0) {
        return recordingsToDisplay.sort(
          (recA, recB) =>
            recA.metadata?.season - recB.metadata?.season || recA.metadata?.episodeId - recB.metadata?.episodeId
        );
      } else {
        return programContent;
      }
    }
  }, [recordingsToDisplay, programContent, isRecordingPlayer]);

  const upNextRecording = useMemo(() => {
    if (sortedRecordings?.length > 1) {
      const currentRecordingIndex = sortedRecordings.findIndex((rec) => rec.id === programContent?.[0]?.id);
      return sortedRecordings[currentRecordingIndex + 1];
    }
  }, [programContent, sortedRecordings]);

  /**
   * PlayerPage will reload more than once when user clicks play button,
   * thus use callback to trigger analytics calls
   */
  const triggerAnalyticsCalls = useCallback(() => {
    const pageName = isLivePlayer ? programDetailsRef.current?.metadata?.title : programContent?.[0]?.metadata?.title;
    const contentId = isLivePlayer
      ? programDetailsRef.current?.metadata?.contentId
      : programContent?.[0]?.metadata?.contentId;
    const episodeInfo = getEpisodeInfo(isLivePlayer ? programDetailsRef.current : programContent?.[0]);
    const assetProps = isLivePlayer ? programDetailsRef.current : programContent?.[0];
    const mappedContentType = isVodPlayer
      ? convivaContentSubType
      : isRecordingPlayer
      ? isMR
        ? MAPPED_CONTENT_TYPES.LPVR
        : MAPPED_CONTENT_TYPES.CPVR
      : isLookbackStream
      ? MAPPED_CONTENT_TYPES.LOOKBACK
      : isRestartedStream.current
      ? MAPPED_CONTENT_TYPES.RESTART
      : MAPPED_CONTENT_TYPES.LIVE;
    const regionalChannelNumber = getRegionalChannelNumber(assetProps.channel?.item, appProvider.channelMapID);
    const asset = {
      isItemLive: isLivePlayer,
      ...assetProps,
      mappedContentType,
      playbackType,
      channelNumber: regionalChannelNumber,
    };
    if (isRecordingPlayer) asset.channel = { ...programContent?.[0]?.channel, ...programContent?.[0] };
    trackPageView({
      pageName,
      contentId,
      episodeInfo,
      extraMetadataList: [
        {
          type: EXTRA_METADATA_TYPES.MEDIA_CONTENT,
          asset,
        },
      ],
    });
    // Reset page view tracking to let the component handle when to trigger these analytics calls
    resetIsPageViewTracked();
    if (isLivePlayer) {
      trackMediaStart(
        {
          ...assetProps?.metadata,
          channel: assetProps?.channel,
          mappedContentType,
        },
        LIVE,
        userProfile,
        appProvider,
        isInHome
      );
    } else {
      trackMediaStart(
        { ...assetProps?.metadata, channel: asset.channel, mappedContentType, playbackType },
        isRecordingPlayer ? RECORDING : VOD,
        userProfile,
        appProvider,
        isInHome,
        !!progressTime
      );
    }

    isProgramChangedRef.current = false;
    if (isSwitchChannelRef.current) isSwitchChannelRef.current = false;
    if (!isInitMediaSessionTriggeredRef.current) {
      // use setTimeout to postpone updating isInitMediaSessionTriggeredRef.current
      // in order to avoid recalling start session when livePlayerPage renders at the first time
      isInitMediaSessionTriggeredTimer.current = setTimeout(
        () => (isInitMediaSessionTriggeredRef.current = true),
        2000
      );
    }
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, "");
  }, [
    appProvider,
    convivaContentSubType,
    isLivePlayer,
    isMR,
    isRecordingPlayer,
    isVodPlayer,
    playbackType,
    programContent,
    progressTime,
    userProfile,
    trackPageView,
    resetIsPageViewTracked,
    isInHome,
    isLookbackStream,
  ]);

  const onEpisodeItemClick = useCallback(
    async (
      itemInfo,
      userCanPlay,
      isAutoplay = false,
      itemRowNumber = null,
      playNextEpisodeCallback = null,
      playNextBookmarkedEpisode = false
    ) => {
      if (
        itemInfo &&
        userCanPlay &&
        isLoading.current === false &&
        itemInfo.metadata?.contentId !== parseInt(currentEpisodeId)
      ) {
        // Block changing episodes from the sidebar while stream is loading
        isLoading.current = true;
        useRoutePlaybackState.current = false;
        playBookmarkedEpisode.current = playNextBookmarkedEpisode;
        setCurrentEpisodeId(JSON.stringify(itemInfo.metadata?.contentId));
        const currentHash = window.location.hash;
        const updatedHash = currentHash.replace(
          currentHash.substring(currentHash.indexOf("?"), currentHash.length),
          `?playbackId=${itemInfo.metadata.contentId}${
            isRecordingPlayer ? `&externalId=${itemInfo.metadata.externalId}` : ""
          }&contentType=${isRecordingPlayer ? "RECORDING" : "VOD"}`
        );
        if (typeof itemRowNumber === "number")
          setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${itemRowNumber + 1};${LINK_INFO.EPISODE_SIDEBAR}`);
        window.history.replaceState({}, currentHash, updatedHash);
        isRecordingPlayer
          ? loadRecordingProgramDetail(appProvider, JSON.stringify(itemInfo.metadata?.contentId), contentType)
          : loadProgramDetail(appProvider, JSON.stringify(itemInfo.metadata?.contentId), "vod");

        isProgramChangedRef.current = true;
        if (playNextEpisodeCallback) {
          // change content from `skipToNextEpisode`, `playNextEpisode` or `autoPlay`
          playNextEpisodeCallback();
        } else if (onSwitchProgramEndSessionRef.current) {
          // change content from sidebar
          onSwitchProgramEndSessionRef.current.onSwitchProgramEndConvivaSession();
          trackMediaEnd();
        }
        resetPlayerAttributes();
      }
      if (isAutoplay) {
        setBingeWatchEpisodeCounter((count) => count + 1);
      } else {
        restartUserInactivityTimer();
      }
    },
    [
      currentEpisodeId,
      isRecordingPlayer,
      loadRecordingProgramDetail,
      appProvider,
      contentType,
      loadProgramDetail,
      restartUserInactivityTimer,
    ]
  );

  const resetIsLoading = () => {
    isLoading.current = false;
  };

  const resetPlayerPage = () => {
    prevVideoUrl.current = "";
    resetIsLoading();
    setHasChannelTuneInfo(true);
    setIsReadyToChangeStreams(true);
    preventReadyToChangeStreams.current = true;
  };

  const handlePlaybackStateChange = (playbackState) => {
    if (isLivePlayer && playbackState === GET_NEXT_LIVE_PROGRAM) {
      updateSidePanelDetails(moment().valueOf());
    }
    if (playbackState.errorType) {
      if (playerErrorCodes.includes(playbackState.code)) {
        console.error("Player error:", playbackState);
        // 2003 is throw when DRM license request failure, this is likely due to stale JWT token that's pass to verimatrix.
        // Short term solution is to request a new token and pass it along to the player to resume playback.
        /*if (playbackState?.code === 2003 && IsSafari()) {
           isTokenRotation.current = true;
          fetchStream(currentAssetId.current, pcPin.current, true);
        }*/
      } else if (nonFatalErrorCodes.includes(playbackState.code)) {
        showToastNotification(playbackState.message);
      } else {
        if (!playbackError || Object.values(playbackError).every((value) => value === null)) {
          showPlaybackError(playbackState.code, playbackState.message);
        }
      }
    }
  };

  const onFullScreenClick = (isFullScreen) => {
    setIsInFullScreen(isFullScreen);
    isFullScreen ? setShowSideNav(showSideNav) : setShowSideNav(false);
  };

  const updateSeasonEntitlements = () => {
    const seriesMetadata = currentProgram?.metadata;
    const seriesUaId = seriesMetadata?.extendedMetadata?.dlum?.uaGroupId;
    const seasonNumber = seriesMetadata?.season;
    if (!didSeasonChange.current) {
      loadCurrentSeasonEntitlements(appProvider, `${seriesUaId}?season=${seasonNumber}`);
    }
    if (didSeasonChange.current) {
      // reset the flag to resume season entitlement updates again
      didSeasonChange.current = false;
    }
  };

  /* Live player merge specific functions */

  /**
   * Calls the heartbeat API to check if the current program is parental control blocked,
   * in which case an error is thrown.
   * @param {String|Number} channelId
   * @param {String|Number} assetId
   * @param {String} parentalPin
   * @returns {Promise}
   */
  const getHeartBeatEntitlements = useCallback(
    (channelId, assetId, parentalPin = null) => {
      class PcError extends Error {
        constructor(message, code, entitlement) {
          super(message);
          this.code = code;
          this.entitlement = entitlement;
        }
      }
      return videoStreamHeartBeat(
        appProvider,
        LIVE.toUpperCase(),
        channelId,
        assetId,
        parentalPin,
        cancelTokenSource.current
      ).then((result) => {
        if (result?.data?.resultCode === "KO") {
          return Promise.reject({
            message: result.data.message,
            type: "Server",
            code: result.data.errorDescription,
            params: result.data.resultObj,
          });
        }

        const entitlement = result?.data?.resultObj?.entitlement;
        if (entitlement?.isPCBlocked) {
          // The heartbeat endpoint does not return an error if an incorrect PIN is passed,
          // so we need to throw an error manually
          throw new PcError(AVS_ERROR_CODES.USER_NO_RIGHTS, AVS_ERROR_CODES.USER_NO_RIGHTS, entitlement);
        } else {
          setPCBlocked(false);
        }
      });
    },
    [appProvider]
  );

  /**
   * If Parental PIN is enabled, check if the up next program is PC blocked. If it is, show a
   * message and allow the user to choose to enter the parental PIN or change channels.
   */
  const checkProgramPCBlocked = useCallback(() => {
    // If Parental PIN is enabled, check if the up next program is PC blocked
    const currentContentId = currentEpisodeId || currentChannelId;
    if (isParentalPinEnabled && currentContentId && assetId) {
      getHeartBeatEntitlements(currentContentId, assetId).catch((err) => {
        if (err?.entitlement?.isPCBlocked) {
          // If previous program was not blocked and next program is blocked, or if saved PIN
          // has expired and next program is blocked, pause the player and ask user to input PIN
          setPCBlocked(true);
          setShowSideNav(false);
          setShowContentLockedOverlay(true);
          //setPlayerControlState(PAUSE); // Not WORKING for all cases
        }
      });
    }
  }, [isParentalPinEnabled, currentEpisodeId, currentChannelId, assetId, getHeartBeatEntitlements]);

  const restartPcPinTimer = () => {
    if (pcPinTimer.current) {
      clearInterval(pcPinTimer.current);
    }
    pcPinTimer.current = setInterval(() => {
      pcPin.current = null;
    }, PLAYER_PIN_REFRESH_INTERVAL_MS);
  };

  /**
   * Shows the PIN modal for the user to enter their parental PIN to unlock the up next program.
   */
  const unlockOnNextProgram = useCallback(() => {
    const currentContentId = currentEpisodeId || currentChannelId;
    const modalContent = {
      title: translate("enter_parental_pin"),
      isCloseable: true,
      pinModalMode: PIN_MODAL_MODES.ACCESS,
      pinModalType: PIN_MODAL_TYPES.PARENTAL,
      pinConfirmHandler: (pin) => {
        return getHeartBeatEntitlements(currentContentId, assetId, pin)
          .then(() => {
            // Resume playback now that content is no longer blocked
            trackGenericAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK, getMediaNodeMetaData());
            if (isConvivaAppTrackerEnabled) {
              trackConvivaCustomEvent(
                ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK,
                getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
              );
            }
            setPlayerControlState(RESUME);
            setShowContentLockedOverlay(false);
            pcPin.current = pin;
            restartPcPinTimer();
          })
          .catch((e) => {
            if (
              e?.code?.includes(AVS_ERROR_CODES.MAX_ATTEMPTS_INCORRECT_PARENTAL_PIN) ||
              (AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(e?.code) && e.params?.remainingAttempts === 0)
            ) {
              setIsPinLocked(true);
            } else {
              throw e;
            }
          });
      },
      pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
        ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR,
        ACTION_VALUES.PARENTAL_PIN_CONTENT_UNLOCK,
        WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR
      ),
    };
    telusConvivaAnalytics.reportParentalPinStart(assetId, contentType);
    setIsCallParentalPinLock(true);
    showModalPopup(MODAL_TYPES.PIN, modalContent);
  }, [
    assetId,
    currentEpisodeId,
    currentChannelId,
    getHeartBeatEntitlements,
    getMediaNodeMetaData,
    showModalPopup,
    translate,
    appProvider,
    isInHome,
    userProfile,
    isConvivaAppTrackerEnabled,
    contentType,
  ]);

  const updateMiniGuidePrograms = useCallback(() => {
    if (!isLivePlayer || !miniGuidePrograms) {
      return;
    }
    const currentTimestamp = moment().valueOf();
    const onNowPrograms = {};
    const onNextPrograms = {};
    for (const channelId in miniGuidePrograms) {
      onNowPrograms[channelId] = miniGuidePrograms[channelId].find(
        (program) =>
          program.metadata.airingStartTime <= currentTimestamp && program.metadata.airingEndTime >= currentTimestamp
      );
      onNextPrograms[channelId] = miniGuidePrograms[channelId].find(
        (program) => program.metadata.airingStartTime === onNowPrograms[channelId]?.metadata.airingEndTime
      );
    }

    setMiniGuideOnNowPrograms(onNowPrograms);
    setMiniGuideOnNextPrograms(onNextPrograms);
  }, [miniGuidePrograms, isLivePlayer]);

  const updateLiveProgramDetails = useCallback(() => {
    // only update the program details when liveProgramCanUpdate is true
    if (liveProgramCanUpdate) {
      if (isLookbackStream && lookbackContent.current && currentChannel) {
        !updateLookbackStream &&
          setLiveProgramDetails({
            ...lookbackContent.current,
            // TODO: refactor analytics to accept channel separately so we can remove it from liveProgramDetails
            channel: currentChannel,
          });
      } else if (miniGuideOnNowPrograms && currentChannel && pcPinTimer) {
        setLiveProgramDetails({
          ...miniGuideOnNowPrograms[currentChannelId],
          // TODO: refactor analytics to accept channel separately so we can remove it from liveProgramDetails
          channel: currentChannel,
        });
      }
    }
  }, [
    currentChannelId,
    miniGuideOnNowPrograms,
    liveProgramCanUpdate,
    currentChannel,
    isLookbackStream,
    updateLookbackStream,
  ]);

  const onTabItemSelected = (index) => {
    setCurrentIndex(index);
    restartUserInactivityTimer();
  };

  // Switched the player out of lookback mode
  const exitLookback = useCallback(() => (exitLookbackMode.current = true), []);

  const onLiveItemClick = (id, isContentOOHBlocked, itemRowNumber, programId) => {
    if (currentIndex === 0 && isLoading.current === false && id) {
      if (preventReadyToChangeStreams.current) {
        preventReadyToChangeStreams.current = false;
        isLookbackStream &&
          id === currentChannelId &&
          setAssetId(
            currentChannel?.assets?.find((asset) => asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER)?.assetId
          );
      } else {
        setIsReadyToChangeStreams(false);
      }
      if (isContentOOHBlocked) {
        showToastNotification(translate("message_in_home"), outHomeIcon);
      } else if (id !== currentChannelId || liveProgramDetails?.id !== programId) {
        useRoutePlaybackState.current = false;
        isRestartedStream.current && switchLive();
        if (isLookbackStream) exitLookbackMode.current = true;
        // Block changing channels from the sidebar while stream is loading
        isLoading.current = true;
        showPlaybackError(null, null);
        setShowContentLockedOverlay(false);
        setIsPinLocked(false);
        if (id !== currentChannelId) {
          setCurrentChannelId(id);
          const currentHash = window.location.hash;
          const updatedHash = currentHash.replace(
            currentHash.substring(currentHash.indexOf("?"), currentHash.length),
            `?stationId=${id}&contentType=LIVE`
          );
          window.history.replaceState({}, currentHash, updatedHash);
        }
        if (typeof itemRowNumber === "number") {
          setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, LINK_INFO.MINI_GUIDE);
          setSessionStorage(ANALYTICS_STORAGE_KEYS.FINDING_METHOD, LINK_INFO.MINI_GUIDE);
        }
        isSwitchChannelRef.current = true;
        if (onSwitchProgramEndSessionRef.current) {
          onSwitchProgramEndSessionRef.current.onSwitchProgramEndConvivaSession();
          trackMediaEnd();
        }
        resetPlayerAttributes();
      }
    }
    restartUserInactivityTimer();
  };

  const updateSidePanelDetails = (timestamp) => {
    if (timestamp) {
      if (sidebarLastUpdateTimestamp.current) {
        // Only update sidebar if it has been at least 1 minute since the last update
        const timeDiff = moment(timestamp).diff(sidebarLastUpdateTimestamp.current, "minutes");
        if (timeDiff >= 1) {
          sidebarLastUpdateTimestamp.current = timestamp;
          isSidebarUpdated.current = false;
        }
      } else {
        sidebarLastUpdateTimestamp.current = timestamp;
      }

      if (!isSidebarUpdated.current) {
        isSidebarUpdated.current = true;
        updateMiniGuidePrograms();
        const nextSegmentTimestamp = getNextSegmentTimestamp();
        if (!currentPrograms[nextSegmentTimestamp]) {
          loadGuidePrograms(nextSegmentTimestamp);
        }
      }
    }
  };

  /**
   * Triggers a retuning after purchase flow is complete
   */
  const purchaseCallback = () => {
    setPpvPurchasePackage(null);
    useRoutePlaybackState.current = false;
  };

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/cancel)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType, recordingType) => {
      let toastMsg;
      if (recordingInfo.code) {
        const triggerTrackRecordingErrorEvent = (toastMsg) => {
          trackRecordingError(recordingEventTypeRef.current, toastMsg, {
            errorCode: setRecordingResponse?.code,
            errorMessage: setRecordingResponse?.message,
          });
          recordingEventTypeRef.current = null;
        };
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
          triggerTrackRecordingErrorEvent(translate(RECORDING_PARAMS.RECORDING_NOT_SET));
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_NOT_UPDATED" : "RECORDING_NOT_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
          triggerTrackRecordingErrorEvent(translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING));
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(
            RECORDING_PARAMS[recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_SET" : "RECORDING_SET"]
          );
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_UPDATED" : "RECORDING_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS["RECORDING_CANCELLED"]);
        }
      }

      return toastMsg;
      // Do not want setRecordingResponse as a dependency which is used for analytics
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(
          recordingInfo,
          manipulatedRecordingParams.actionType,
          manipulatedRecordingParams.recordingType
        );
        if (toastMsg) {
          showToastNotification(toastMsg);
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, showToastNotification]
  );

  /**
   * Fetch the user's recordings and save the response in redux
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(
          appProvider,
          true,
          null,
          null,
          null,
          MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
          recordingParamsMR
        );
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR]
  );

  /**
   * Opens the modal used for deleting recorded recordings or cancelling scheduled/in progress recordings.
   *
   * @param {Object} recordingInfo Recording information associated with the current airing program (if triggered from VideoPlayer) or the selected program (if triggered from the Mini-EPG)
   * @param {Object} programDetails Represents the current airing program if triggered from the VideoPlayer or the selected program if triggered from the Mini-EPG
   * @param {String} recordGUID Needed if we are trying to cancel an episode from a series recording
   */
  const openCancelRecordingModal = useCallback(() => {
    const recordingInfo = selectedAssetRecordingInfo;
    const programDetails = selectedAssetRecordingInfo?.assetToRecord;
    const modalContent = {
      recordingInfo,
      programDetails,
      isRecordingRecorded: false,
      recordingType: RECORDING_PARAMS.EVENT,
      isMR,
      isRecordingNow: recordingInfo.isRecordingNow,
    };
    showModalPopup(MODAL_TYPES.RECORDING, modalContent);
  }, [showModalPopup, isMR, selectedAssetRecordingInfo]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      const isScheduleNeeded = !isMR;
      // Spinning loader implementation on the recordingSettingsPanel.
      isScheduleNeeded && toggleSpinningLoaderAction(true, "loader-wrapper setting-spinner-loader");
      showRecordingSettingsPanel(typeOfRecording, recordingInfo, null, false, null, isScheduleNeeded);
      toggleSettingsPanelAction(true);
    },
    [showRecordingSettingsPanel, toggleSettingsPanelAction, isMR, toggleSpinningLoaderAction]
  );

  /**
   * @param {Object} recordingInfo - Object containing response of particular asset from recordingsList object to compare and edit the recordings.
   * @param {String} typeOfRecording - EVENT/SERIES
   */
  const editRecordingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      showRecordingSettingHandler(recordingInfo, typeOfRecording);
    },
    [showRecordingSettingHandler]
  );

  /**
   * Used by the VideoPlayer and Mini-EPG to determine what action should occur when a recording button is selected.
   * @param {Object} recordingInfo Recording information associated with the current airing program (if triggered from VideoPlayer) or the selected program (if triggered from the Mini-EPG)
   */
  const recordingButtonClickHandler = useCallback(
    (recordingInfo) => {
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        const seriesId = isMR ? recordingInfo.mediaRoom?.seriesId : recordingInfo.assetToRecord?.metadata?.seriesId;
        if (seriesId) {
          setShowRecordingModal(true);
        } else {
          if (isMR) {
            const recordingEventScheduledCheck = recordingInfo.recordingEventScheduledCheck;
            const recordingEventConflictCheck = recordingInfo.recordingEventConflictCheck ? true : false;
            const canScheduleRecording = !recordingEventScheduledCheck && !recordingEventConflictCheck ? true : false;
            if (canScheduleRecording) {
              recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
              trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START, {
                ...getMediaNodeMetaData(),
                mappedContentType: MAPPED_CONTENT_TYPES.LPVR,
              });
              setRecording(RECORDING_PARAMS.EVENT, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR);
              logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
              logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_START);
            } else if (recordingEventConflictCheck) {
              setShowRecordingModal(true);
            } else {
              editRecordingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
            }
          } else {
            const cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
            if (cpvrRecordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_DOES_NOT_EXIST) {
              recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
              trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START, {
                ...getMediaNodeMetaData(),
                mappedContentType: MAPPED_CONTENT_TYPES.CPVR,
              });
              setRecording(RECORDING_PARAMS.EVENT, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR);
              logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
              logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_START);
            } else {
              openCancelRecordingModal(recordingInfo, recordingInfo.assetToRecord);
            }
          }
        }
      }
    },
    [isMR, getMediaNodeMetaData, appProvider, editRecordingHandler, setRecordingAction, openCancelRecordingModal]
  );

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    resetAction(SET_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Used to determine if the application is in partial panic for playback
  const passFeatureFlags = useCallback(() => {
    const modalContent = {
      title: translate("temporary_service_disruption"),
      history,
      isCloseable: true,
    };

    let message = "";

    if (isVODDisabled && isVodPlayer) {
      message = translate("panic_vod_playback_msg");
    } else if (isLIVEDisabled && isLivePlayer) {
      message = translate("panic_live_playback_msg");
    } else if (isCPVRDisabled && isRecordingPlayer) {
      message = translate("panic_cpvr_playback_msg");
    } else if (isRestartDisabled && restartLiveStream) {
      message = translate("panic_cpvr_playback_msg");
    } else if (isCatchUpDisabled && isLookbackStream) {
      message = translate("panic_catchup_playback_msg");
    } else if (isFASTDisabled && liveProgramDetails?.channel?.metadata?.extendedMetadata?.isFast === "Y") {
      message = translate("panic_fast_playback_msg");
    }

    if (message !== "") {
      modalContent.message = message;
      showModalPopup(MODAL_TYPES.ERROR, modalContent);
      return false;
    }
    return true;
  }, [
    history,
    isCPVRDisabled,
    isCatchUpDisabled,
    isFASTDisabled,
    isLIVEDisabled,
    isLivePlayer,
    isLookbackStream,
    isRecordingPlayer,
    isRestartDisabled,
    isVODDisabled,
    isVodPlayer,
    restartLiveStream,
    showModalPopup,
    translate,
    liveProgramDetails,
  ]);

  const isPlaybackInPanic = useMemo(() => {
    if (
      (isVODDisabled && isVodPlayer) ||
      (isLIVEDisabled && isLivePlayer) ||
      (isCPVRDisabled && isRecordingPlayer) ||
      (isRestartDisabled && restartLiveStream) ||
      (isCatchUpDisabled && isLookbackStream) ||
      (isFASTDisabled && liveProgramDetails?.channel?.metadata?.extendedMetadata?.isFast === "Y")
    ) {
      return true;
    }
    return false;
  }, [
    isVODDisabled,
    isVodPlayer,
    isCPVRDisabled,
    isCatchUpDisabled,
    isFASTDisabled,
    isLIVEDisabled,
    isLivePlayer,
    isLookbackStream,
    isRecordingPlayer,
    isRestartDisabled,
    liveProgramDetails?.channel?.metadata?.extendedMetadata?.isFast,
    restartLiveStream,
  ]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetDeleteRecordingResponse();
    resetEditRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
    resetEditRecordingResponse,
  ]);

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              () => {
                showToastNotification(translate("recording_not_set"));
              },
              MODAL_TYPES.ERROR,
              showModalPopup
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [
    setRecordingResponse,
    isMR,
    fetchRecordings,
    showModalPopup,
    resetRecordingActionValues,
    showToastNotification,
    translate,
  ]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  const closeRecordingModal = () => {
    setShowRecordingModal(false);
  };

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event vs series)
   */
  const scheduleRecording = useCallback(
    (typeOfRecording) => {
      if (selectedAssetRecordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, selectedAssetRecordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, selectedAssetRecordingInfo, setRecordingAction]
  );

  const getRecordingModal = () => {
    return (
      <RecordingModal
        closeModal={closeRecordingModal}
        recordingInfo={selectedAssetRecordingInfo}
        scheduleRecordingHandler={scheduleRecording}
        editRecordingHandler={editRecordingHandler}
        openCancelRecordingModal={openCancelRecordingModal}
      />
    );
  };

  /** Fetches next live stream in queue when a restarted stream is finished
   * @return {Object} next stream in queue
   */
  const fetchNextLiveStream = useCallback(async () => {
    const nextLiveProgram = await getNextLiveStream(
      appProvider,
      cancelTokenSource,
      channelID,
      prevLiveProgramDetails.current.prevLiveProgram.metadata.airingEndTime
    );
    return nextLiveProgram;
  }, [appProvider, channelID]);

  /**
   * Starts over the stream for the given live program
   * @param {Object} liveProgramDetails
   */
  const triggerRestart = (liveProgramDetails) => {
    useRoutePlaybackState.current = false;
    restartAssetId.current = liveProgramDetails?.metadata?.contentId;
    isLookbackStream && setIsLookbackStream(false);
    !restartLiveStream && setRestartLiveStream(true);
    trackGenericAction(ANALYTICS_EVENT_TYPES.RESTART, getMediaNodeMetaData());
    if (isConvivaAppTrackerEnabled) {
      trackConvivaCustomEvent(
        ANALYTICS_EVENT_TYPES.RESTART,
        getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
      );
    }
  };

  /* Switches the player to PLTV mode */
  const switchLive = () => {
    if (useRoutePlaybackState.current) useRoutePlaybackState.current = false;
    isRestartedStream.current = false;
    restartAssetId.current = null;
    restartLiveStream && setRestartLiveStream(false);
    isLookbackStream && setIsLookbackStream(false);
  };

  /**
   * Switches the player to lookback mode
   * @param {Object} nextLookbackProgramDetails
   */
  const triggerLookback = useCallback(
    (nextLookbackProgramDetails) => {
      if (checkIsLookbackSupported(nextLookbackProgramDetails?.metadata, currentChannel, isInHome)) {
        nextLookbackProgram.current = nextLookbackProgramDetails;
        setUpdateLookbackStream(true);
      }
    },
    [currentChannel, isInHome]
  );

  /**
   * Updated the details of the live stream to be used for comparison with the next stream during transition
   * @param {Object} liveProgramDetails
   * @param {Boolean} isLookbackStream
   * @param {Boolean} isRestartedStream
   */
  const updatePrevLiveProgramDetails = useCallback((liveProgramDetails, isLookbackStream, isRestartedStream) => {
    prevLiveProgramDetails.current = {
      prevLiveProgram: { ...liveProgramDetails },
      isLookback: isLookbackStream,
      isRestarted: isRestartedStream,
    };
  }, []);

  /**
   * Resets the live player to PLTV mode if next program does not support start over
   * @param {Boolean} isNextProgramRestartable
   */
  const transitionProgram = (isNextProgramRestartable) => {
    isNextProgramNonRestartable.current = !isNextProgramRestartable;
    if (!isNextProgramRestartable) {
      switchLive();
    }
  };

  /**
   * Changes the lookback stream on the same channel after current lookback stream ends
   * @param {Object} lookbackProgramDetails object having details for the new lookback stream
   */
  const changeLookbackStream = (lookbackProgramDetails) => {
    restartAssetId.current = lookbackProgramDetails?.metadata?.contentId;
    useRoutePlaybackState.current = false;
    setHasChannelTuneInfo(true);
  };

  /* Live player merge specific functions end */

  /** Side Effects begin */
  useEffect(() => {
    const unmountTokenSource = cancelTokenSource.current;
    return () => {
      unmountTokenSource.cancel(); // silent cancellation of middleware requests
    };
  }, []);

  useEffect(() => {
    return () => {
      resetAction(SHOW_PURCHASE_ACKNOWLEDGEMENT, "content");
      resetPlayerAttributes();
    };
  }, [resetAction]);

  useEffect(() => {
    hideTopNav(true);
    return () => {
      hideTopNav(false);
      if (userInactivityTimer.current) {
        clearTimeout(userInactivityTimer.current);
      }
      if (pcPinTimer.current) {
        clearInterval(pcPinTimer.current);
      }
    };
  }, [hideTopNav]);

  useEffect(() => {
    if (appProvider && !channelMapInfo) loadChannels(appProvider);
  }, [appProvider, channelMapInfo, loadChannels]);

  useEffect(() => {
    if (!isLivePlayer) {
      if (isVodPlayer && playbackID && contentType)
        loadProgramDetail(appProvider, playbackID, contentType?.toLowerCase());
      else loadRecordingProgramDetail(appProvider, playbackID, contentType);
      return () => {
        // Clean up the Redux states on player page unmount, required for both VOD and Recording
        resetAction(LOAD_CURRENT_PROGRAM_DETAILS, "content");
        resetAction(LOAD_CURRENT_SERIES_DETAILS, "content");
        resetAction(LOAD_PLAYBACK_SEASON_DATA, "content");
        resetAction(LOAD_CURRENT_SEASON_DETAILS, "content");
        resetAction(LOAD_CURRENT_SEASON_ENTITLEMENTS, "content");
        isTVShow = false;
      };
    }
  }, [
    appProvider,
    contentType,
    isLivePlayer,
    isVodPlayer,
    loadProgramDetail,
    loadRecordingProgramDetail,
    playbackID,
    resetAction,
  ]);

  useEffect(() => {
    if (!isLivePlayer && programContent && programContent.length) {
      const extendedMetadata = programContent[0]?.metadata?.extendedMetadata ?? null;
      const seriesId = extendedMetadata?.dlum?.series?.uaId || extendedMetadata?.dlum?.uaGroupId;
      if (seriesId && seriesId !== prevSeriesId.current) {
        prevSeriesId.current = seriesId;
        loadCurrentSeriesDetails(appProvider, PAGE_CONTENT_ITEM_TYPES.tvShow, seriesId);
      }
    }
  }, [appProvider, isLivePlayer, loadCurrentSeriesDetails, programContent]);

  useEffect(() => {
    if (!isLivePlayer && programContent && programContent.length) {
      programDetailsRef.current = programContent;
      let title = "";
      let subTitle = "";
      const extendedMetadata = programContent[0]?.metadata?.extendedMetadata ?? null;
      const uaEpisodeObject = programContent[0]?.metadata && getAutoGeneratedObject(programContent[0].metadata);
      let deltaTime = null;
      setCurrentProgram(programContent[0]);
      if (programContent[0]?.metadata) {
        title = programContent[0].metadata.title;
        if (
          programContent[0].metadata.contentSubtype === "EPISODE" ||
          (programContent[0].metadata.season && programContent[0].metadata.episodeNumber)
        ) {
          subTitle = getAutogeneratedEpisodeString(uaEpisodeObject, programContent[0].metadata);
          if (extendedMetadata) {
            extendedMetadata.season = programContent[0].metadata.season;
            extendedMetadata.episodeNumber = programContent[0].metadata.episodeNumber;
          }
          setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, subTitle);
          setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, subTitle);
        } else {
          setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, title);
          setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, title);
        }
        /* We need to use recordingStartTime, startDeltaTime and stopDeltaTime to determine the user specific recording's playable duration and start time */
        if (
          isRecordingPlayer &&
          programContent[0].metadata.recordingStartTime !== undefined &&
          programContent[0].metadata.startDeltaTime !== undefined &&
          programContent[0].metadata.stopDeltaTime !== undefined
        ) {
          deltaTime = {
            recordingStartTime: programContent[0].metadata.recordingStartTime,
            startDeltaTime: programContent[0].metadata.startDeltaTime,
            stopDeltaTime: programContent[0].metadata.stopDeltaTime,
          };
        }
      }
      title = title ? title : currentEpisodeId;
      const duration = isVodPlayer ? 1280 : programContent[0]?.metadata?.programDuration ?? 0;
      const startTime = isVodPlayer ? {} : { programStartTime: programContent[0].metadata.programStartTime ?? 0 };
      setMetadataInfo(
        createMetadataInfo(title, subTitle, null, duration, startTime, null, "", extendedMetadata, deltaTime)
      );
      setPageTitle(title);

      if (isProgramChangedRef.current) triggerAnalyticsCalls();
    }
  }, [
    appProvider,
    contentSubtype,
    currentEpisodeId,
    isLivePlayer,
    isRecordingPlayer,
    isVodPlayer,
    programContent,
    translate,
    triggerAnalyticsCalls,
  ]);

  useEffect(() => {
    if (!isLivePlayer && seriesDetails && seriesDetails.metadata?.uaType !== "MOVIE") {
      if (seriesDetails.containers?.length > 0) {
        const sortedSeasonContainer = [...seriesDetails.containers]?.sort(
          (previousSeason, nextSeason) => nextSeason.metadata.season - previousSeason.metadata.season
        );
        loadSeasons(appProvider, seriesDetails.id, sortedSeasonContainer);
      }
      if (programContent?.[0]?.metadata?.contentSubtype === "EPISODE") {
        const metadataTitle = seriesDetails.title || programContent?.[0]?.metadata?.extendedMetadata?.dlum?.sortTitle;
        setPageTitle(metadataTitle);
        setMetadataInfo((metadataInfo) => ({ ...metadataInfo, title: metadataTitle }));
        setNRAttribute(NR_CUSTOM_ATTRIBUTES.SERIES_NAME, metadataTitle);
        setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.SERIES_NAME, metadataTitle);
      }
    }
  }, [seriesDetails, appProvider, loadSeasons, programContent, isLivePlayer]);

  useEffect(() => {
    setVideoStreamAttributes(streamInfo);
  }, [streamInfo]);

  useEffect(() => {
    if (!isLivePlayer && seasonData && Array.isArray(seasonData)) {
      const currentSeason = seasonData.find(
        (season) => season?.metadata?.season === parseInt(programContent?.[0]?.metadata?.season)
      );
      if (currentSeason) {
        setSeason(currentSeason);
      }
    }
  }, [isLivePlayer, programContent, seasonData]);

  useEffect(() => {
    if (!isLivePlayer && season?.id && season?.metadata?.season) {
      if (season.id !== prevSeason.current?.id && season.metadata?.season !== prevSeason.current?.metadata?.season) {
        loadCurrentSeasonDetails(appProvider, season);
        loadCurrentSeasonEntitlements(appProvider, `${season.id}?season=${season.metadata?.season}`);
        prevSeason.current = season;
      }
    }
  }, [appProvider, isLivePlayer, loadCurrentSeasonDetails, loadCurrentSeasonEntitlements, season]);

  useEffect(() => {
    if (!isLivePlayer && playbackType && playbackType === "episode" && currentEpisodeId && !isRecordingPlayer) {
      getNextProgram(appProvider, currentEpisodeId, cancelTokenSource.current)
        .then((res) => {
          const nextProgram = res?.containers?.[0];
          setNextProgramInfo({
            nextProgram,
            nextEpisodePoster: nextProgram && getAVSKeyArtImage(nextProgram.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16),
            loadNextProgram: (isAutoplay = false, playNextEpisodeCallback) =>
              onEpisodeItemClick(nextProgram, true, isAutoplay, null, playNextEpisodeCallback),
          });
        })
        .catch((e) => {
          console.error("Failed to get next program for current episode, error = ", e);
          setNextProgramInfo({});
        });
    }
  }, [appProvider, currentEpisodeId, isLivePlayer, onEpisodeItemClick, playbackType, isRecordingPlayer]);

  useEffect(() => {
    // live player stream logic is separated for channel tune performance optimization, with the exception of PPV content
    if (isLivePlayer && !isPPV) return; // TODO: merge start streaming logic once the tune performance backend support is added to VOD/Recording playback, see TCDWC-1474

    /**
     * Fetch the video url and auth token for a given asset
     * @param {Number} assetId The asset ID
     * @param {String} parentalPin The pin that was entered by the user if the content was blocked by parental controls
     * @returns promise
     */
    const fetchStream = (assetId, parentalPin, tokenOnly = false) => {
      currentAssetId.current = assetId;
      const contentId = isLivePlayer ? currentChannelId : currentEpisodeId;
      let promise = fetchVideoUrl(
        appProvider,
        contentType,
        contentId,
        assetId,
        parentalPin,
        tokenOnly,
        cancelTokenSource.current
      )
        .then((res) => {
          if (!tokenOnly) {
            if (pcPin.current === null) {
              pcPin.current = parentalPin;

              // User's successful pin entry is valid for a period of time OR until they close the player
              if (!pcPinTimer.current) {
                restartPcPinTimer();
              }

              // parental_pin_content_unlock should only be called when there was a modal popup,
              // without checking `pcPin.current === null` content_unlock event will be triggered multiple times if there was an error occurred
              if (parentalPin) {
                trackGenericAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK, getMediaNodeMetaData());
                if (isConvivaAppTrackerEnabled) {
                  trackConvivaCustomEvent(
                    ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK,
                    getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
                  );
                }
              }
            }
            setVideoUrl(res.src);
            showPlaybackError(null, null);
          }
          setAuthToken(res.token);
          if (isLivePlayer) {
            setHasChannelTuneInfo(true);
            setLivePlaybackCanStart(true);
          }
        })
        .catch((err) => {
          if (
            parentalPin &&
            (appProvider.AGL_Version === MVE2_AGL_VERSION
              ? err?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS)
              : AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code))
          ) {
            telusConvivaAnalytics.reportParentalPinFailed(false, err);
            throw err;
          } else if (parentalPin && isPinMaxLimitReach(err)) {
            telusConvivaAnalytics.reportParentalPinFailed(true, err);
            throw err;
          } else if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
            telusConvivaAnalytics.reportPlaybackError(err);

            const modalContent = {
              title: translate("programming_unavailable"),
              history,
              isCloseable: true,
              message: translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel),
            };

            showModalPopup(MODAL_TYPES.ERROR, modalContent);
          } else {
            telusConvivaAnalytics.reportPlaybackError(err);

            console.error(err);
            const AVSError = getAVSPlaybackError(err);
            setFatalPlaybackError(AVSError);
            showPlaybackError(AVSError.code, AVSError.message);
          }
        });
      return promise;
    };
    if (isReadyToChangeStreams && !fatalPlaybackError) {
      const contentId = isLivePlayer ? currentChannelId : currentEpisodeId;
      if (
        contentId &&
        (contentId !== prevContentIdForApiRef.current ||
          (isPPV && ppvPurchasePackage !== prevPurchasePackageForApiRef.current))
      ) {
        prevContentIdForApiRef.current = contentId;
        if (!useRoutePlaybackState.current) {
          getContentUserData(appProvider, contentId, contentType, cancelTokenSource.current, restartAssetId.current)
            .then((res) => {
              if (res?.containers?.length > 0) {
                const contentUserData = res.containers[0];
                const entitlement = contentUserData.entitlement;
                if (entitlement?.assets) {
                  let assetToPlay;
                  // TCDWC-1474 TODO: determine if condition needs update to include isPPV check when tune logic is consolidated
                  if (isLivePlayer) {
                    // PPV logic
                    const ppvTuneInfo = getPPVTuneInfo(entitlement);
                    if (ppvTuneInfo) {
                      assetToPlay = ppvTuneInfo.assetToPlay;
                      setPpvPurchasePackage(ppvTuneInfo.purchasePackage);
                      prevPurchasePackageForApiRef.current = ppvTuneInfo.purchasePackage;
                    }
                  } else {
                    // VOD and recording playback logic
                    if (playbackType === PLAYBACK_TYPES.TRAILER) {
                      assetToPlay = entitlement.assets.find(
                        (asset) => asset.assetType.toLowerCase() === ASSET_TYPES.TRAILER
                      );
                    } else {
                      assetToPlay = entitlement.assets.find(
                        (asset) => asset.assetType.toLowerCase() === ASSET_TYPES.MASTER
                      );
                    }

                    let skipTimeSeconds = 0;
                    if (contentUserData.metadata?.bookmarks?.length > 0) {
                      // BE always pushes the newest bookmark at the front of the array on the json.
                      // Fetching at element of 0 ensures the latest bookmark
                      const bookmark = contentUserData.metadata.bookmarks[0];
                      if (isBookmarkComplete(bookmark)) {
                        setBookmark(null);
                      } else {
                        skipTimeSeconds = bookmark.startDeltaTime;
                        setBookmark(bookmark);
                      }
                    } else {
                      setBookmark(null);
                    }

                    setProgressTime(playBookmarkedEpisode.current && skipTimeSeconds);
                  }

                  if (assetToPlay) {
                    setAssetId(assetToPlay.assetId);
                    //checks before initiating playback
                    if (
                      !entitlement.isPCBlocked &&
                      !entitlement.isContentOOHBlocked &&
                      !entitlement.isGeofencedBlocked &&
                      !entitlement.isSportBlackoutBlocked &&
                      !entitlement.isPlatformBlacklisted &&
                      !entitlement.isChannelNotSubscribed &&
                      !entitlement.isGeoBlocked
                    ) {
                      fetchStream(assetToPlay.assetId);
                    } else {
                      let modalContent = {
                        title: ERROR_TYPES.INFO,
                        history,
                        isCloseable: true,
                        analyticsInfo: {
                          errorCode: res.errorDescription,
                          media: getMediaNodeMetaData(),
                        },
                      };
                      let modalType = MODAL_TYPES.ERROR;

                      if (entitlement.isPCBlocked) {
                        if (pcPin.current && !nextProgramInfo) {
                          modalContent = null;
                          fetchStream(assetToPlay.assetId, pcPin.current);
                        } else {
                          telusConvivaAnalytics.reportParentalPinStart(assetToPlay.assetName, contentType);
                          modalType = MODAL_TYPES.PIN;
                          Object.assign(modalContent, {
                            pinModalMode: PIN_MODAL_MODES.ACCESS,
                            pinModalType: PIN_MODAL_TYPES.PARENTAL,
                            pinConfirmHandler: (pin) => {
                              return fetchStream(assetToPlay.assetId, pin);
                            },
                            pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                              ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR,
                              ACTION_VALUES.PARENTAL_PIN_CONTENT_UNLOCK,
                              WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR
                            ),
                          });

                          modalContent.title = translate("enter_parental_pin");
                          setIsCallParentalPinLock(true);
                        }
                      } else {
                        if (entitlement.isContentOOHBlocked) {
                          modalContent.message = translate("message_in_home");
                        } else if (entitlement.isGeofencedBlocked) {
                          modalContent.message = translate("error_outside_region");
                        } else if (entitlement.isSportBlackoutBlocked) {
                          modalContent.message = translate("error_program_restricted");
                        } else if (entitlement.isPlatformBlacklisted) {
                          modalContent.message = translate("error_program_not_available_device");
                        } else if (entitlement.isChannelNotSubscribed) {
                          modalContent.message = translate("message_subscribe");
                        } else if (entitlement.isGeoBlocked) {
                          modalContent.message = translate("error_program_not_available_region");
                        }
                      }

                      if (modalContent) {
                        showModalPopup(modalType, modalContent);
                      }
                    }
                  }
                }
              } else if (res.errorDescription) {
                const { errorTitle, errorMessage } = getPlaybackErrorMessageInfo({ code: res.errorDescription });
                const message = errorMessage ? translate(errorMessage) : "";
                const errorDetails = errorTitle ? translate(errorTitle) : "";
                const modalContent = {
                  title: errorDetails,
                  history,
                  isCloseable: true,
                  message,
                  analyticsInfo: {
                    errorCode: res.errorDescription,
                    media: getMediaNodeMetaData(),
                  },
                };

                showModalPopup(MODAL_TYPES.ERROR, modalContent);
              }
            })
            .catch((err) => console.error(err));
        }
      }
    }
  }, [
    appProvider,
    contentType,
    currentEpisodeId,
    fatalPlaybackError,
    getMediaNodeMetaData,
    history,
    isLivePlayer,
    isReadyToChangeStreams,
    isVodPlayer,
    playbackType,
    showModalPopup,
    showPlaybackError,
    translate,
    updateLiveProgramDetails,
    isPPV,
    currentChannelId,
    ppvPurchasePackage,
    isInHome,
    programContent,
    userProfile,
    isConvivaAppTrackerEnabled,
    nextProgramInfo,
  ]);

  useEffect(() => {
    // Live player streamInfo logic separated for channel tune performance feature
    if (isLivePlayer) return; // TODO: merge streamInfo logic once the tune performance backend support is added to VOD/Recording playback, see TCDWC-1474
    if (!videoUrl || !authToken || prevAuthToken.current === authToken) return;
    // Check for unsupported platforms
    if (!isPlatformAllowToPlay(appProvider)) {
      const modalContent = {
        title: ERROR_TYPES.INFO,
        history,
        isCloseable: true,
        message: translate("error_playback_browser"),
      };

      showModalPopup(MODAL_TYPES.ERROR, modalContent);
      return;
    }
    const uniContentId = currentEpisodeId;
    const streamMode = STREAM_MODE.VOD;
    const isHLS = getStreamType(videoUrl) === STREAM_FORMAT_TYPES.HLS;
    const playbackType = isHLS ? STREAM_FORMAT_TYPES.HLS : STREAM_FORMAT_TYPES.DASH;
    const drmType = isHLS ? DRM_SYSTEM_TYPES.FP : DRM_SYSTEM_TYPES.WV;
    let licenseURL, licenseCertUrl;
    if (isHLS) {
      licenseURL = PLAYER_STREAM_CONFIG.vod.licenseURL.hls;
      licenseCertUrl = redirectUri + PLAYER_STREAM_CONFIG.vod.licenseCertUrl.hls;
    } else {
      licenseURL = PLAYER_STREAM_CONFIG.vod.licenseURL.dash;
      licenseCertUrl = null;
    }
    const externalContentId = isRecordingPlayer ? externalId : "";

    initPlaybackTimestamp.current = moment().valueOf(); // Set the initial playback timestamp for the current VOD asset

    // Check if partial panic is turn on
    if (!passFeatureFlags()) return;

    setStreamInfo(
      createVideoStream(
        streamMode,
        videoUrl,
        playbackType,
        drmType,
        licenseURL,
        licenseCertUrl,
        authToken,
        assetId,
        uniContentId,
        bookmark,
        externalContentId
      )
    );
    prevAuthToken.current = authToken;
  }, [
    assetId,
    authToken,
    bookmark,
    currentEpisodeId,
    externalId,
    isLivePlayer,
    isRecordingPlayer,
    videoUrl,
    appProvider,
    history,
    showModalPopup,
    translate,
    passFeatureFlags,
    redirectUri,
  ]);

  // The PlaybackError component exists in the VideoPlayer, so we need to set the stream info
  // so the VideoPlayer renders to show the playback error
  useEffect(() => {
    if (fatalPlaybackError) {
      initPlaybackTimestamp.current = null; // Reset initial playback timestamp on fatal error
      setStreamInfo({});
      if (!isLivePlayer) setMetadataInfo({});
    }
  }, [fatalPlaybackError, isLivePlayer]);

  useEffect(() => {
    if (!isLivePlayer && appProvider.config?.player_inactivity && bingeWatchEpisodeCounter > 0) {
      const { vod_episode_limit, vod_timeout } = appProvider.config.player_inactivity;
      if (bingeWatchEpisodeCounter === vod_episode_limit) {
        // start a timer to show the still watching prompt after a certain number of episodes
        userInactivityTimer.current = setTimeout(() => {
          setShowStillWatchingPrompt(true);
        }, vod_timeout * 1000);
        setBingeWatchEpisodeCounter(0);
      }
    }
  }, [appProvider, bingeWatchEpisodeCounter, isLivePlayer]);

  useEffect(() => {
    const shouldExecute = isLivePlayer
      ? isCallParentalPinLock && liveProgramDetails
      : isCallParentalPinLock && programContent && playbackType;
    if (shouldExecute) {
      setIsCallParentalPinLock(false);
      trackGenericAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_LOCK, getMediaNodeMetaData());
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_LOCK,
          getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
        );
      }
    }
  }, [
    isLivePlayer,
    isCallParentalPinLock,
    liveProgramDetails,
    getMediaNodeMetaData,
    programContent,
    playbackType,
    appProvider,
    isInHome,
    userProfile,
    isConvivaAppTrackerEnabled,
  ]);

  useEffect(() => {
    if ((!isLivePlayer || (isLivePlayer && isPPV)) && isRented) {
      showToastNotification(translate("purchase_success_notification"));
    }
  }, [isLivePlayer, isPPV, isRented, showToastNotification, translate]);

  /** Live specific side effects */

  useEffect(() => {
    if (isLivePlayer) {
      restartUserInactivityTimer();
      return () => {
        if (toastNotificationTimer.current) {
          clearTimeout(toastNotificationTimer.current);
        }
        if (isInitMediaSessionTriggeredTimer.current) {
          clearTimeout(isInitMediaSessionTriggeredTimer.current);
        }

        resetRecordingActionValues();
      };
    }
  }, [isLivePlayer, resetRecordingActionValues, restartUserInactivityTimer]);

  useEffect(() => {
    if (isLivePlayer) {
      loadGuidePrograms(getSegmentTimestamp());
      loadGuidePrograms(getNextSegmentTimestamp());
    }
  }, [isLivePlayer, loadGuidePrograms]);

  useEffect(() => {
    if (isLivePlayer && miniGuideOnNowPrograms) {
      updateLiveProgramDetails();
    }
  }, [isLivePlayer, miniGuideOnNowPrograms, updateLiveProgramDetails]);

  useEffect(() => {
    if (isLivePlayer && userProfile?.isLoggedIn) {
      // TODO: Add back the recordings data refresh on page load when the backend isn't garbage?
      /* if (isRecordingEnabled && recordingSystemType) {
        // Fetch user's recordings
        getRecordingAction(appProvider, recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP,
          true, null, [RECORDING_PARAMS.EVENT], (MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS));
      } */
      if (!subscribedChannels && appProvider) {
        loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource.current, isUserProfilesEnabled);
      }
    }
    setIsParentalPinEnabled(
      isParentalPINEnabled && userProfile?.user?.profile?.profileData?.parentalControlPinEnabled === "Y"
    );
  }, [
    isLivePlayer,
    appProvider,
    isParentalPINEnabled,
    userProfile,
    subscribedChannels,
    loadUserSubscribedChannels,
    isUserProfilesEnabled,
  ]);

  useEffect(() => {
    if (!miniGuideOnNowPrograms) return;
    if (isLivePlayer) {
      const currentLiveProgram = miniGuideOnNowPrograms[currentChannelId];
      if (currentLiveProgram?.metadata?.Entitlements?.includes("oh") && userProfile?.isLoggedIn) {
        showModalPopup(MODAL_TYPES.ERROR, {
          title: ERROR_TYPES.ERROR,
          message: translate("message_in_home"),
          history,
        });
        setLivePlaybackCanStart(false);
      }
    }
  }, [isLivePlayer, miniGuideOnNowPrograms, userProfile, currentChannelId, showModalPopup, translate, history]);

  useEffect(() => {
    if (isLivePlayer && liveProgramDetails) {
      const channel = liveProgramDetails.channel;
      const programMetadata = liveProgramDetails.metadata;
      let channelLogo = "",
        channelNumber;
      if (channel) {
        channelLogo = channel.assets?.[0]?.logoSmall ?? "";
        channelNumber = channel.number;

        if (isRecordingEnabled && !channel.metadata?.isRecordable) {
          toastNotificationTimer.current = setTimeout(() => {
            showToastNotification(translate("error_program_not_available_recording"));
          }, 2000);
        }
      }
      setMetadataInfo(createMetadataInfo(programMetadata?.title ?? "", "", channelLogo, 0, {}, channelNumber));
      setPageTitle(programMetadata?.title ?? "");
      if (programMetadata) {
        if (isItemTypeEpisode(programMetadata)) {
          setNRAttribute(NR_CUSTOM_ATTRIBUTES.SERIES_NAME, programMetadata.title);
          setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, getEpisodeDetail(programMetadata));
          setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.SERIES_NAME, programMetadata.title);
          setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, getEpisodeDetail(programMetadata));
        } else {
          setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, programMetadata.title);
          setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, programMetadata.title);
        }
      }
    }
  }, [
    isLivePlayer,
    appProvider.channelMapID,
    isRecordingEnabled,
    liveProgramDetails,
    showToastNotification,
    translate,
  ]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/cancel recording action.
   */
  useEffect(() => {
    if (selectedAssetRecordingInfo && manipulatedRecordingParams && isRecordingActionInProgress.current) {
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      const selectedRecordingInfo = getRecordingInfo(
        isMR,
        selectedAssetRecordingInfo.assetToRecord,
        recordingsList,
        appProvider?.channelMapID
      );
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (isMR && selectedRecordingInfo.recordingEventConflictCheck) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }
      resetRecordingActionValues();
    }
  }, [
    selectedAssetRecordingInfo,
    isMR,
    displayRecordingToast,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    recordingsList,
    appProvider,
  ]);

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * Live channel fast tuning
   */
  useEffect(() => {
    if (isLivePlayer && useRoutePlaybackState.current && playbackAuthToken) {
      removeLocalStorage(VIDEO_AUTH_TOKEN);
      setLocalStorage(VIDEO_AUTH_TOKEN, playbackAuthToken, 90000);
    }
    if (
      appProvider &&
      isLivePlayer &&
      currentChannel?.assets &&
      !isPPV &&
      isReadyToChangeStreams &&
      !useRoutePlaybackState.current
    ) {
      exitLookbackMode.current = false;
      const masterAsset = currentChannel.assets.find((asset) => asset.assetType?.toLowerCase() === ASSET_TYPES.MASTER);
      if (masterAsset) {
        setAssetId(masterAsset.assetId);
        !restartLiveStream && isCTTEnabled && !isLookbackStream && setVideoUrl(masterAsset.videoUrl);
        setHasChannelTuneInfo(true);
        setLivePlaybackCanStart(false);
        showPlaybackError(null, null);
        isCTTEnabled &&
          !restartLiveStream &&
          !isLookbackStream &&
          fetchVideoToken(appProvider, contentType, currentChannel.id, cancelTokenSource)
            .then((res) => {
              if (res.token) setAuthToken(res.token);
              removeLocalStorage(VIDEO_AUTH_TOKEN);
              setLocalStorage(VIDEO_AUTH_TOKEN, res.token, 90000);
            })
            .catch((e) => {
              console.error("Failed to get auth token, error = ", e);
            });
      } else {
        console.error("Live channel is missing master asset. Could not fetch stream info");
      }
    }
  }, [
    isLivePlayer,
    appProvider,
    isPPV,
    currentChannel,
    contentType,
    showPlaybackError,
    restartLiveStream,
    isReadyToChangeStreams,
    isCTTEnabled,
    isLookbackStream,
    playbackAuthToken,
  ]);

  /**
   * This useEffect is used for the optimized live channel tuning flow.
   * Note that content userdata is fetched AFTER the video URL call, and only if there was a problem.
   */
  useEffect(() => {
    if (!isLivePlayer || (isLivePlayer && isPPV) || !hasChannelTuneInfo) return;
    exitLookbackMode.current = false;
    const streamContentType = restartLiveStream || isLookbackStream ? PAGE_CONTENT_ITEM_TYPES.program : contentType;
    const streamAssetId = restartLiveStream || isLookbackStream ? restartAssetId.current : assetId;

    /** function to restart stream if the init start stream call failed  */
    const refetchStream = (assetId, parentalPin, tokenOnly = false) => {
      const streamAssetId = restartLiveStream || isLookbackStream ? restartAssetId.current : assetId;
      const promise = fetchVideoUrl(
        appProvider,
        streamContentType,
        currentChannelId,
        streamAssetId,
        parentalPin,
        tokenOnly,
        cancelTokenSource.current
      )
        .then((res) => {
          if (!tokenOnly) {
            if (pcPin.current === null) {
              pcPin.current = parentalPin;
              // User's successful pin entry is valid for a period of time OR until they close the player
              if (!pcPinTimer.current) {
                restartPcPinTimer();
              }

              // parental_pin_content_unlock should only be called when there was a modal popup,
              // without checking `pcPin.current === null` content_unlock event will be triggered multiple times if there was an error occurred
              if (parentalPin) {
                trackGenericAction(ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK, getMediaNodeMetaData());
                if (isConvivaAppTrackerEnabled) {
                  trackConvivaCustomEvent(
                    ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK,
                    getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
                  );
                }
              }
            }
            if (!isCTTEnabled || restartLiveStream || isLookbackStream) {
              setVideoUrl(res.src);
              if (res.token) {
                setAuthToken(res.token);
                removeLocalStorage(VIDEO_AUTH_TOKEN);
                setLocalStorage(VIDEO_AUTH_TOKEN, res.token, 90000);
              }
            }
            setLivePlaybackCanStart(true);
            showPlaybackError(null, null);
          }
        })
        .catch((err) => {
          if (
            parentalPin &&
            (appProvider.AGL_Version === MVE2_AGL_VERSION
              ? err?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS)
              : AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code))
          ) {
            telusConvivaAnalytics.reportParentalPinFailed(false, err);
            throw err;
          } else if (parentalPin && isPinMaxLimitReach(err)) {
            telusConvivaAnalytics.reportParentalPinFailed(true, err);
            throw err;
          } else if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
            telusConvivaAnalytics.reportPlaybackError(err);

            const modalContent = {
              title: translate("programming_unavailable"),
              history,
              isCloseable: true,
              message: translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel),
            };

            showModalPopup(MODAL_TYPES.ERROR, modalContent);
          } else {
            telusConvivaAnalytics.reportPlaybackError(err);

            console.error(err);
            let AVSError = getAVSPlaybackError(err);
            setFatalPlaybackError(AVSError);
            showPlaybackError(AVSError.code, AVSError.message);
          }
        });
      return promise;
    };
    if (isReadyToChangeStreams && !fatalPlaybackError && !useRoutePlaybackState.current) {
      fetchVideoUrl(
        appProvider,
        streamContentType,
        currentChannelId,
        streamAssetId,
        null,
        false,
        cancelTokenSource.current
      )
        .then((res) => {
          if (!isCTTEnabled || restartLiveStream || isLookbackStream) {
            setVideoUrl(res.src);
            if (res.token) {
              setAuthToken(res.token);
              removeLocalStorage(VIDEO_AUTH_TOKEN);
              setLocalStorage(VIDEO_AUTH_TOKEN, res.token, 90000);
            }
          }
          setLivePlaybackCanStart(true); // stream can start, live playback start signal to pass to player component
        })
        .catch((err) => {
          if (
            err?.code?.includes(AVS_ERROR_CODES.USER_NO_RIGHTS) ||
            AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code)
          ) {
            // user right and pin validation error code
            // client calls GET USERDATA/LIVE to determine reason of failure
            getContentUserData(
              appProvider,
              currentChannelId,
              contentType,
              cancelTokenSource.current,
              restartAssetId.current
            )
              .then((res) => {
                if (res && res.containers && res.containers.length > 0) {
                  const entitlement = res.containers[0].entitlement;
                  const assets = entitlement?.assets;
                  const assetToPlay = assets.find((asset) => asset.assetType.toLowerCase() === ASSET_TYPES.MASTER);
                  let modalContent = {
                    title: ERROR_TYPES.INFO,
                    history,
                    isCloseable: true,
                    analyticsInfo: {
                      errorCode: res.errorDescription,
                      media: getMediaNodeMetaData(),
                    },
                  };
                  let modalType = MODAL_TYPES.ERROR;
                  if (entitlement.isPCBlocked) {
                    // if pcBlocked, restart stream with pcPin or pop up pcPin modal to capture pcPin then restart stream
                    if (pcPin.current) {
                      modalContent = null;
                      refetchStream(assetToPlay.assetId, pcPin.current);
                    } else {
                      telusConvivaAnalytics.reportParentalPinStart(assetToPlay.assetName, contentType);
                      modalType = MODAL_TYPES.PIN;
                      Object.assign(modalContent, {
                        pinModalMode: PIN_MODAL_MODES.ACCESS,
                        pinModalType: PIN_MODAL_TYPES.PARENTAL,
                        pinConfirmHandler: (pin) => {
                          return refetchStream(assetToPlay.assetId, pin);
                        },
                        pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                          ANALYTICS_EVENT_TYPES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR,
                          ACTION_VALUES.PARENTAL_PIN_CONTENT_UNLOCK,
                          WEB_ACTION_EVENT_NAMES.PARENTAL_PIN_CONTENT_UNLOCK_ERROR
                        ),
                      });

                      modalContent.title = translate("enter_parental_pin");
                      setIsCallParentalPinLock(true);
                    }
                  } else {
                    // display appropriate failure modal message
                    if (entitlement.isContentOOHBlocked) {
                      modalContent.message = translate("message_in_home");
                    } else if (entitlement.isGeofencedBlocked) {
                      modalContent.message = translate("error_outside_region");
                    } else if (entitlement.isSportBlackoutBlocked) {
                      modalContent.message = translate("error_program_restricted");
                    } else if (entitlement.isPlatformBlacklisted) {
                      modalContent.message = translate("error_program_not_available_device");
                    } else if (entitlement.isChannelNotSubscribed) {
                      modalContent.message = translate("message_subscribe");
                    } else if (entitlement.isGeoBlocked) {
                      modalContent.message = translate("error_program_not_available_region");
                    }
                  }
                  if (modalContent) {
                    showModalPopup(modalType, modalContent);
                  }
                }
              })
              .catch((err) => console.error(err));
          } else if (err?.code === AVS_ERROR_CODES.PANIC_MODE) {
            // handle panic mode AVS KO: 503-10000, stop playback and show error message
            const modalContent = {
              title: translate("programming_unavailable"),
              history,
              isCloseable: true,
              message: translate("programming_unavailable_body")?.replace("%s", appProvider.pcLevelLabel),
            };
            showModalPopup(MODAL_TYPES.ERROR, modalContent);
          } else if (
            ((err?.status && err.status !== 200) ||
              (err?.code && AVS_ERROR_CODES.SERVICE_OUTAGE_CODES.includes(err.code))) &&
            isCTTEnabled
          ) {
            // any Http error/Socket timeout or service outage error code ["500-10000", "500-10022", "500-10015"], we assume user is entitled for content, allows live playback
            setLivePlaybackCanStart(true);
          } else {
            // Any other error codes: stop playback and show error
            console.error(err);
            const AVSError = getAVSPlaybackError(err);
            setFatalPlaybackError(AVSError);
            showPlaybackError(AVSError.code, AVSError.message);
            setLivePlaybackCanStart(true); // here we still need to toggle the livePlaybackCanStart state to render the live player. Because the playbackError has been set, the player will not start the playback but display the error message instead. See src/components/VideoPlayer for details
          }
        });
    }
  }, [
    appProvider,
    assetId,
    contentType,
    currentChannelId,
    getMediaNodeMetaData,
    hasChannelTuneInfo,
    history,
    isLivePlayer,
    showModalPopup,
    showPlaybackError,
    translate,
    restartLiveStream,
    isReadyToChangeStreams,
    fatalPlaybackError,
    isCTTEnabled,
    isLookbackStream,
    isPPV,
    isInHome,
    programContent,
    userProfile,
    isConvivaAppTrackerEnabled,
  ]);

  useEffect(() => {
    if (isReadyToChangeStreams && !fatalPlaybackError) {
      if (isLivePlayer) updateLiveProgramDetails();
    }
  }, [
    appProvider,
    fatalPlaybackError,
    history,
    isLivePlayer,
    isReadyToChangeStreams,
    showModalPopup,
    translate,
    updateLiveProgramDetails,
  ]);

  useEffect(() => {
    if (miniGuideOnNowPrograms) {
      // Check for unsupported platforms
      if (!isPlatformAllowToPlay(appProvider)) {
        const modalContent = {
          title: ERROR_TYPES.INFO,
          history,
          isCloseable: true,
          message: translate("error_playback_browser"),
        };

        showModalPopup(MODAL_TYPES.ERROR, modalContent);
        return;
      }
      if (!isLivePlayer || prevVideoUrl.current === videoUrl) return;
      const uniContentId = currentChannelId;
      const streamMode = STREAM_MODE.LIVE;
      const isHLS = getStreamType(videoUrl) === STREAM_FORMAT_TYPES.HLS;
      const playbackType = isHLS ? STREAM_FORMAT_TYPES.HLS : STREAM_FORMAT_TYPES.DASH;
      const drmType = isHLS ? DRM_SYSTEM_TYPES.FP : DRM_SYSTEM_TYPES.WV;
      let licenseURL, licenseCertUrl;
      if (isHLS) {
        licenseURL = PLAYER_STREAM_CONFIG.live.licenseURL.hls;
        licenseCertUrl = redirectUri + PLAYER_STREAM_CONFIG.live.licenseCertUrl.hls;
      } else {
        licenseURL = PLAYER_STREAM_CONFIG.live.licenseURL.dash;
        licenseCertUrl = null;
      }

      initPlaybackTimestamp.current = moment().valueOf(); // Set the initial playback timestamp for the current Live asset

      // Check if partial panic is turn on
      if (!passFeatureFlags()) return;

      setStreamInfo(
        createVideoStream(
          streamMode,
          videoUrl,
          playbackType,
          drmType,
          licenseURL,
          licenseCertUrl,
          null,
          assetId,
          uniContentId
        )
      );
      setHasChannelTuneInfo(false);
      prevVideoUrl.current = videoUrl;
      if (restartLiveStream) {
        isRestartedStream.current = true;
      }
    }
  }, [
    assetId,
    currentChannelId,
    videoUrl,
    restartLiveStream,
    appProvider,
    currentChannel,
    isInHome,
    isLookbackStream,
    miniGuideOnNowPrograms,
    isLivePlayer,
    history,
    showModalPopup,
    translate,
    passFeatureFlags,
    redirectUri,
  ]);

  useEffect(() => {
    if (isLivePlayer && miniGuidePrograms) {
      updateMiniGuidePrograms();
    }
  }, [isLivePlayer, miniGuidePrograms, updateMiniGuidePrograms]);

  // In order to call triggerAnalyticsCalls(), this useEffect must be located after it
  useEffect(() => {
    if (isLivePlayer && liveProgramDetails && programDetailsRef.current?.id !== liveProgramDetails?.id) {
      // Check entitlements of subsequent programs on the same channel
      if (programDetailsRef.current?.id && !isSwitchChannelRef.current) {
        checkProgramPCBlocked();
      }

      programDetailsRef.current = liveProgramDetails;

      if (isSwitchChannelRef.current) {
        // triggers a pageLoaded event when switching channel from sidebar
        triggerAnalyticsCalls();
      } else if (isInitMediaSessionTriggeredRef.current) {
        trackMediaStart(
          {
            ...programDetailsRef.current.metadata,
            channel: programDetailsRef.current.channel,
          },
          LIVE,
          userProfile,
          appProvider,
          isInHome
        );
        trackMediaPlay({ isAutomationBuild: appProvider?.isAutomationBuild });
      }
    }
  }, [
    isLivePlayer,
    appProvider,
    checkProgramPCBlocked,
    liveProgramDetails,
    triggerAnalyticsCalls,
    userProfile,
    isInHome,
  ]);

  useEffect(() => {
    if (isLookbackStream) {
      if (!lookbackContent.current) {
        history.push("/"); // re-directing to home page in case user bookmarks a lookback player page and loads player directly
      } else {
        restartAssetId.current = lookbackContent.current.metadata?.contentId;
        trackGenericAction(ANALYTICS_EVENT_TYPES.LOOKBACK, getMediaNodeMetaData());
        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.LOOKBACK,
            getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
          );
        }
      }
    }
  }, [isLookbackStream, history, getMediaNodeMetaData, appProvider, isInHome, userProfile, isConvivaAppTrackerEnabled]);

  useEffect(() => {
    if (updateLookbackStream && nextLookbackProgram.current) {
      lookbackContent.current = nextLookbackProgram.current;
      if (isRestartedStream.current) {
        setRestartLiveStream(false);
        setLiveProgramCanUpdate(true);
      }
      !isLookbackStream && setIsLookbackStream(true);
      setUpdateLookbackStream(false);
    }
  }, [updateLookbackStream, isLookbackStream, isRestartedStream]);

  useEffect(() => {
    if (useRoutePlaybackState.current && liveProgramDetails?.metadata && !livePlaybackCanStart) {
      setLivePlaybackCanStart(true);
    }
  }, [liveProgramDetails, livePlaybackCanStart]);

  /** Live specific side effects end*/

  /** Side Effects end */

  /** Conditional rendering  */
  const renderPlayer = () => {
    const nextProgramInfoProp = isVodPlayer
      ? nextProgramInfo
      : isRecordingPlayer
      ? {
          nextProgram: upNextRecording,
          nextEpisodePoster:
            upNextRecording && getAVSKeyArtImage(upNextRecording.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16),
          loadNextProgram: (isAutoplay = false, playNextEpisodeCallback) =>
            onEpisodeItemClick(upNextRecording, true, isAutoplay, null, playNextEpisodeCallback),
        }
      : { nextProgram: null, nextEpisodePoster: null, loadNextProgram: null };
    const shouldRenderPlayer =
      streamInfo && metadataInfo && (isVodPlayer || isRecordingPlayer || (isLivePlayer && livePlaybackCanStart));
    return (
      shouldRenderPlayer && (
        <VideoPlayer
          className={`video-player ${showSideNav ? "shrink" : ""}`}
          type={isVodPlayer ? VOD : isLivePlayer ? LIVE : RECORDING}
          contentType={contentType}
          streamInfo={streamInfo}
          handleListIconClick={handleListIconClick}
          buttonRef={!isLivePlayer ? buttonRef : null}
          metadataInfo={metadataInfo}
          control={playerControlState}
          nextProgramInfo={nextProgramInfoProp}
          onPlaybackStateChange={handlePlaybackStateChange}
          onFullScreenClick={onFullScreenClick}
          showListButton={isLivePlayer || isTVShow ? true : false}
          resumeTimeSeconds={!isLivePlayer && playbackType !== PLAYBACK_TYPES.TRAILER ? progressTime : null}
          isSideNavOpen={showSideNav}
          currentContentId={isLivePlayer ? currentChannelId : currentEpisodeId}
          isReadyCallback={resetIsLoading}
          playbackType={!isLivePlayer ? playbackType : null}
          showStillWatchingPrompt={showStillWatchingPrompt}
          restartUserInactivityTimer={restartUserInactivityTimer}
          updateVodProgress={
            isVodPlayer || (isRecordingPlayer && sortedRecordings?.length > 1) ? updateVodProgress : null
          }
          setIsReadyToChangeStreams={setIsReadyToChangeStreams}
          hideListChannel={sortedRecordings?.length === 1 ? true : false}
          getMediaNodeMetaData={getMediaNodeMetaData}
          triggerAnalyticsCalls={triggerAnalyticsCalls}
          isProgramChangedRef={
            isVodPlayer || (isRecordingPlayer && sortedRecordings?.length > 1) ? isProgramChangedRef : null
          }
          mappedContentType={isRecordingPlayer ? (isMR ? MAPPED_CONTENT_TYPES.LPVR : MAPPED_CONTENT_TYPES.CPVR) : null}
          liveProgramDetails={isLivePlayer ? liveProgramDetails : null}
          recordingButtonClickHandler={isLivePlayer ? recordingButtonClickHandler : null}
          programDetailsRef={programDetailsRef}
          liveVideoPlayheadFuncRef={isLivePlayer ? liveVideoPlayheadFuncRef : null}
          isMR={isLivePlayer ? isMR : null}
          bookmark={bookmark}
          contentLockedOverlay={
            isLivePlayer && showContentLockedOverlay ? (
              <ContentLockedOverlay
                isSideNavOpen={showSideNav}
                isPinLocked={isPinLocked}
                unlockContentHandler={unlockOnNextProgram}
              />
            ) : null
          }
          livePlaybackCanStart={livePlaybackCanStart}
          liveProgramCanUpdate={liveProgramCanUpdate}
          setLiveProgramCanUpdate={setLiveProgramCanUpdate}
          triggerRestart={triggerRestart}
          isRestartedStream={restartLiveStream}
          switchLive={switchLive}
          isReadyToChangeStreams={isReadyToChangeStreams}
          transitionProgram={transitionProgram}
          isNextProgramNonRestartable={isNextProgramNonRestartable.current}
          isLookbackStream={isLookbackStream}
          resetPlayerPage={resetPlayerPage}
          exitLookbackMode={exitLookbackMode.current}
          changeLookbackStream={changeLookbackStream}
          ppvPurchasePackage={ppvPurchasePackage}
          purchaseCallback={purchaseCallback}
          updateSeasonEntitlements={updateSeasonEntitlements}
          initPlaybackTimestamp={initPlaybackTimestamp}
          nrContentType={nrContentType}
          userProfile={userProfile}
          convivaContentSubType={convivaContentSubType}
          isPlayerControlsDisable={isPlayerControlsDisable}
          PCBlocked={PCBlocked}
          isPlaybackInPanic={isPlaybackInPanic}
          parentalPin={pcPin.current}
          fetchNextLiveStream={fetchNextLiveStream}
          triggerLookback={triggerLookback}
          exitLookback={exitLookback}
          updatePrevLiveProgramDetails={updatePrevLiveProgramDetails}
          prevLiveProgramDetails={prevLiveProgramDetails.current}
        />
      )
    );
  };

  const renderSideBar = showSideNav ? (
    isTVShow && ((isVodPlayer && seasonFeedContent) || (isRecordingPlayer && sortedRecordings?.length > 1)) ? (
      <div>
        <VodPlayerSideBar
          onSeasonChange={onSeasonChange}
          onEpisodeItemClick={onEpisodeItemClick}
          currentEpisodeId={currentEpisodeId}
          handleHideButtonClick={handleListIconClick}
          currentProgram={currentProgram}
          currentSeason={season}
          vodProgress={vodProgress}
          recordingsToDisplay={sortedRecordings}
          isRecordingPlayer={isRecordingPlayer}
        />
      </div>
    ) : isVodPlayer ? (
      <MovieSideBar handleHideButtonClick={handleListIconClick} content={currentProgram} />
    ) : isLivePlayer && miniGuideOnNowPrograms ? (
      <div>
        <VideoPlayerSideBar
          channels={miniGuideChannels}
          programs={currentIndex === 0 ? miniGuideOnNowPrograms : miniGuideOnNextPrograms}
          currentPlayingProgram={liveProgramDetails}
          currentChannelId={currentChannelId}
          onLiveItemClick={onLiveItemClick}
          handleHideButtonClick={handleListIconClick}
          isInFullScreen={isInFullScreen}
          onTabItemSelected={onTabItemSelected}
          onProgressComplete={updateSidePanelDetails}
          recordingButtonClickHandler={recordingButtonClickHandler}
          isMR={isMR}
          isPlayerControlsDisable={isPlayerControlsDisable}
        />
      </div>
    ) : null
  ) : null;

  const renderRecordingModal = isLivePlayer && showRecordingModal && selectedAssetRecordingInfo && getRecordingModal();

  return (
    <>
      <SeoPageTags title={pageTitle} keywords={["optik", "telus"]} />
      <div className="player-with-sidebar">
        {renderPlayer()}
        <div className={!showSideNav ? "video-sidebar closed" : "video-sidebar"}>{renderSideBar}</div>
      </div>
      {renderRecordingModal}
    </>
  );
}

function mapStateToProps({ app, epg, recording, onDemandPlayer, recordingPlayer }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    isInHome: app.isInHome,
    playbackError: app.playbackError,
    subscribedChannels: app.subscribedChannels,
    currentPrograms: epg.currentPrograms,
    recordingsList: recording.recordingsList,
    getRecordingError: recording.getRecordingError,
    setRecordingResponse: recording.setRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    channelMapInfo: app.channelMapInfo,
    seasonFeedContent: onDemandPlayer.seasonContent,
    isRented: onDemandPlayer.isRented,
    seasonData: onDemandPlayer.seasonDetails,
    programContent: onDemandPlayer?.programContent || recordingPlayer?.programContent,
    seriesDetails: onDemandPlayer.seriesDetails,
    convivaContentSubType: app.convivaContentSubType,
    featureToggles: app.featureToggles,
    editRecordingResponse: recording.editRecordingResponse,
  };
}

const mapDispatchToProps = {
  hideTopNav,
  showModalPopup,
  showPlaybackError,
  showToastNotification,
  loadChannels,
  loadUserSubscribedChannels,
  setRecordingAction,
  getRecordingAction,
  resetAction,
  loadCurrentSeasonDetails,
  loadSeasons,
  loadProgramDetail,
  loadRecordingProgramDetail,
  loadCurrentSeriesDetails,
  loadCurrentSeasonEntitlements,
  toggleSettingsPanelAction,
  showRecordingSettingsPanel,
  toggleSpinningLoaderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage);

/**
 * Set New Relic custom attributes that are specific to the stream
 * @param {Object} streamInfo
 */
const setVideoStreamAttributes = (streamInfo) => {
  if (streamInfo) {
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.LICENSE_URL, streamInfo.licenseURL);
    setNRAttribute(NR_CUSTOM_ATTRIBUTES.DRM_TOKEN, streamInfo.authToken);
    setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.LICENSE_URL, streamInfo.licenseURL);
    setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.DRM_TOKEN, streamInfo.authToken);
  }
};

/**
 * Reset New Relic custom attributes that are asset-specific
 */
const resetPlayerAttributes = () => {
  // Clean up New Relic custom attributes
  setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, null);
  setNRAttribute(NR_CUSTOM_ATTRIBUTES.SERIES_NAME, null);
  setNRAttribute(NR_CUSTOM_ATTRIBUTES.DRM_TOKEN, null);
  setNRAttribute(NR_CUSTOM_ATTRIBUTES.LICENSE_URL, null);
  setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, null);
  setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.SERIES_NAME, null);
  setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.DRM_TOKEN, null);
  setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.LICENSE_URL, null);
};
