import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import "./style.scss";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import {
  getRecordingImageAsset,
  checkRecordingStatus,
  isCPVRRecordingInProgress,
  getCPVRExpiryString,
  calculateRecordingProgressPercentage,
} from "../../shared/utils/recordingHelper";
import { showToastNotification } from "../../App/state/actions";
import ImageButton from "../ImageButton";
import { getGenreInOneLine } from "../../shared/utils/feedHelper";
import { getAVSKeyArtImage, getAVSPosterArtImage } from "../../shared/utils/image";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { isItemTypeEpisode } from "../../shared/utils/content";
import routeConstants from "../../shared/constants/routes";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS } from "../../shared/constants/analytics";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { is4KChannel } from "../../shared/utils/epg";
import playerConstants from "../../shared/constants/player";
import { useReducers } from "../../shared/hooks/useReducer";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import i18n from "../../i18n.js";
import { getAutoGeneratedObject, getAutogeneratedEpisodeString } from "../../shared/utils/index.js";
import { convertToPercentageString } from "../../shared/utils";
import classNames from "classnames";

const { MODAL_TYPES, IMAGES, PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE, PLAY } = constants;
const { RECORDING_PARAMS, MR_RECORDING_STATUS } = recordingConstants;
const { RECORDING_PLAYER } = routeConstants;
const { PLAYBACK_TYPES } = playerConstants;
const EDIT_ICON = process.env.PUBLIC_URL + "/images/Pen_Icon.svg";
const DELETE_ICON = process.env.PUBLIC_URL + "/images/Delete_Icon.svg";
const CANCEL_ICON = process.env.PUBLIC_URL + "/images/Recording_Cancel_Episode.svg";
const CONFLICT_ICON = process.env.PUBLIC_URL + "/images/warning-icon.svg";
const CHANNEL_4K_ICON = process.env.PUBLIC_URL + "/images/4K_icon.svg";
const TOAST_4K_ICON = process.env.PUBLIC_URL + "/images/4K_toast_icon.svg";
const defaultImage = process.env.PUBLIC_URL + "/images/swimlane-landscape-233px.jpg";

// TODO: While rendering this component at times we are getting console error for key
// Even though the key we are using is unique. So we need to investigate this(24/06/24)

/**
 * Display recordings which are scheduled/recorded for recordings page in list
 * @param {Object} props
 * @returns RecordingItem component
 */
const RecordingItem = ({
  itemIndex,
  recordingResponse,
  isRecordingScheduled,
  isProgramOfSeries,
  openSeriesViewAll,
  selectedSeriesId,
  recordingType,
  editRecordingClickHandler,
  cancelRecordingClickHandler,
  showModalPopup,
  isMR,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { provider: appProvider } = useReducers(REDUCER_TYPE.APP);
  const { bookmarks } = useReducers(REDUCER_TYPE.RECORDING);
  const { isAppLanguageFrench } = useAppLanguage();
  const [showHover, setShowHover] = useState(false);
  const [titleEllipsisActive, setTitleEllipsisActive] = useState(false);
  const [infoEllipsisActive, setInfoEllipsisActive] = useState(false);
  const titleRef = useRef(null);
  const infoRef = useRef(null);

  const { performPlaybackChecks } = usePlaybackChecks();

  useEffect(() => {
    const checkEllipsis = () => {
      const titleElement = titleRef.current;
      const infoElement = infoRef.current;

      if (titleElement) {
        setTitleEllipsisActive(titleElement.offsetWidth < titleElement.scrollWidth);
      }

      if (infoElement) {
        setInfoEllipsisActive(infoElement.offsetWidth < infoElement.scrollWidth);
      }
    };
    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);
    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, []);

  const recordingAsset =
    recordingResponse?.recordings?.length > 0 && recordingResponse.recordings.find((recording) => recording.metadata);
  const recordingMetadata = recordingAsset ? recordingAsset.metadata : recordingResponse?.metadata;

  let titleImage,
    isRecordingNow = false;
  if (isMR) {
    if (recordingResponse) {
      // Refer to shared/utils/recordingHelper.js file for info.
      const assetWithPictureUrl = getRecordingImageAsset(recordingResponse);
      titleImage = assetWithPictureUrl
        ? assetWithPictureUrl.metadata.pictureUrl
        : recordingResponse.metadata?.pictureUrl;

      const currentTime = moment().valueOf();
      const recordingEndTime = recordingResponse.programStartTime + recordingResponse.duration * 1000;
      isRecordingNow =
        checkRecordingStatus(recordingResponse, MR_RECORDING_STATUS.RECORDING) ||
        (recordingResponse.status !== MR_RECORDING_STATUS.RECORDED &&
          recordingResponse.status !== MR_RECORDING_STATUS.CANCELLED &&
          recordingResponse.programStartTime <= currentTime &&
          currentTime <= recordingEndTime) ||
        false;
    }
  } else {
    if (recordingType === RECORDING_PARAMS.SERIES) {
      titleImage = recordingMetadata?.pictureUrl;
      // If any episodes are recording now, we consider the series recording as in progress
      for (let i = 0; i < recordingResponse.episodeRecordings?.length; i++) {
        const episodeRecording = recordingResponse.episodeRecordings[i];
        isRecordingNow = isCPVRRecordingInProgress(episodeRecording);
        if (isRecordingNow) break;
      }
    } else {
      if (isProgramOfSeries) {
        titleImage = getAVSKeyArtImage(recordingMetadata, IMAGES.ASPECT_RATIOS.DIM_9x16);
      } else {
        titleImage = getAVSPosterArtImage(recordingMetadata, IMAGES.ASPECT_RATIOS.DIM_9x16);
      }
      isRecordingNow = isCPVRRecordingInProgress(recordingResponse);
    }
  }
  const showEditButton = isMR || (!isMR && isRecordingScheduled);
  const showRemoveButton = !isRecordingScheduled;

  const conflictClickHandler = () => {
    const modalContent = {
      title: RECORDING_PARAMS.RECORDING_CONFLICT,
      isCloseable: true,
      message: translate("recordings_waitlist_remove"),
      endButtonLabelOverride: translate("close"),
      endButtonClassOverride: "error-modal-btn-gray",
    };
    showModalPopup(MODAL_TYPES.ERROR, modalContent);
  };

  const handleTileClick = (event) => {
    if (is4KChannel(recordingResponse)) {
      event.preventDefault();
      dispatch(showToastNotification(translate("recordings_restriction_telustvplus"), TOAST_4K_ICON));
    } else {
      performPlaybackChecks(
        null,
        PLAYBACK_TYPES.EPISODE,
        recordingMetadata.contentId,
        PAGE_CONTENT_ITEM_TYPES.recording,
        RECORDING_PLAYER.route,
        null,
        recordingMetadata.externalId,
        null,
        null,
        null,
        null,
        { type: "", source: PLAY }
      );
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${itemIndex + 1};${LINK_INFO.RECORDING_ITEM_PLAY}`);
    }
  };

  // Purpose of the function is to determine if the current recording item is eligible for playback
  const allowToPlayback = () => {
    return (
      !isMR &&
      !isRecordingScheduled &&
      recordingType !== RECORDING_PARAMS.SERIES &&
      recordingMetadata.contentId &&
      recordingMetadata.externalId
    );
  };

  /**
   * Get recording asset image
   * @returns Recording asset image if present else default image
   */
  const getImage = () => {
    if (isMR && selectedSeriesId) {
      return recordingResponse.metadata?.pictureUrl || defaultImage;
    } else {
      return titleImage ? `${titleImage}?w=800` : defaultImage;
    }
  };

  let title = isMR ? recordingResponse?.title : recordingMetadata?.title;
  let subtitle, subtitleClassName, description;
  if (recordingType === RECORDING_PARAMS.SERIES) {
    const episodeCount = isMR ? recordingResponse.recordedItems?.length : recordingResponse.episodeRecordings?.length;
    const episodeString = translate(episodeCount === 1 ? "episode_nr" : "total_episode_nr");
    subtitle = isRecordingScheduled ? translate("series_recording") : `${episodeCount} ${episodeString}`;
    subtitleClassName = "series-info";
    description = null;
  } else {
    subtitleClassName = "event-info";
    if (isMR) {
      if (recordingMetadata?.contentId) {
        subtitle = !selectedSeriesId
          ? recordingMetadata?.seasonNumber && recordingMetadata?.episodeNumber
            ? getFormattedEpisodeString(recordingMetadata, isMR)
            : `${recordingMetadata?.rating} | ${recordingMetadata?.genres?.[0]}`
          : null;
        description = recordingMetadata?.longDescription;
      } else {
        subtitle = null;
        description = recordingAsset?.description ?? recordingResponse?.description;
      }
    } else {
      if (recordingMetadata) {
        description = recordingMetadata.longDescription;
        if (!selectedSeriesId) {
          if (isItemTypeEpisode(recordingMetadata)) {
            subtitle = getFormattedEpisodeString(recordingMetadata, isMR);
          } else {
            if (recordingMetadata.extendedMetadata?.dlum) {
              const genreString = getGenreInOneLine(recordingMetadata, isAppLanguageFrench);
              subtitle = `${recordingMetadata.extendedMetadata.dlum.rating} | ${genreString}`;
            }
          }
        }
      }
    }
  }
  /**
   * @returns conflict badge and is called if recording is conflicted.
   */
  const getConflictBadge = () => {
    return (
      <ImageButton
        src={CONFLICT_ICON}
        className="conflict-PVR-info"
        buttonName={translate("conflict")}
        onClickHandler={conflictClickHandler}
        alt=""
      />
    );
  };

  /**
   * @returns recording badge
   */
  const getRecordingsBadge = () => {
    const recordingNowImg = isAppLanguageFrench ? "/images/Recording_Now_FR.svg" : "/images/Recording_Now_EN.svg";
    return (
      <ImageButton
        src={process.env.PUBLIC_URL + recordingNowImg}
        className="record-badge"
        alt={translate("recording_now")}
      />
    );
  };

  /**
   * Determines what elements to display for the metadata on the far right of the recording item
   * @returns array of JSX elements
   */
  const getSecondaryMetadataInfo = () => {
    const elements = [];
    let line1Text = "",
      line2Text = "",
      expiryString = "",
      show4kBadge = false;

    if (isRecordingScheduled) {
      const isMRRecordingConflicted =
        recordingResponse &&
        ((recordingResponse.recordings?.length > 0 &&
          recordingResponse.recordings.some((recording) => recording.status === MR_RECORDING_STATUS.CONFLICTED)) ||
          recordingResponse.status === MR_RECORDING_STATUS.CONFLICTED);

      if (recordingType === RECORDING_PARAMS.EVENT) {
        let channelNumber, channelName;
        if (isMR) {
          line1Text = getFormattedRecordingDate(recordingResponse.programStartTime ?? recordingAsset?.programStartTime);
          channelNumber = recordingResponse.channelNumber;
          //TODO: Sometimes the MR recording response is a recording definition ID, sometimes its a recording object. We should clean up our MR data at some point
          if (recordingResponse.recordings?.length > 0) {
            channelName = recordingResponse.recordings[0].metadata.channelName || "";
          } else {
            channelName = recordingResponse.metadata.channelName || "";
          }
          line2Text = isMRRecordingConflicted
            ? getConflictBadge()
            : channelName !== ""
            ? channelNumber + " - " + channelName
            : channelNumber;
        } else {
          if (recordingResponse) {
            if (recordingMetadata) {
              line1Text = getFormattedRecordingDate(recordingMetadata.programStartTime, false);
              show4kBadge = is4KChannel(recordingResponse);
            }

            if (recordingResponse.channel) {
              channelNumber =
                getRegionalChannelNumber(recordingResponse.channel, appProvider?.channelMapID)?.toString() || "";
              channelName = recordingResponse.channel.channelName;

              if (channelNumber || channelName) {
                // Ideal state is to display channel number and call letters
                let channelNamePrefix = "";
                if (channelNumber?.length > 0) {
                  line2Text = channelNumber;
                  channelNamePrefix = " - ";
                }

                if (channelName) line2Text += channelNamePrefix + channelName;
              } else {
                // Default to displaying the channel name if missing channel number and call letters
                line2Text = recordingResponse.channel.channelName || "";
              }
            }
          }
        }
      } else {
        if (isMR) {
          line1Text = isMRRecordingConflicted ? getConflictBadge() : "";
        }
      }
    } else {
      if (isMR) {
        line2Text =
          recordingType === RECORDING_PARAMS.EVENT
            ? getFormattedRecordingDuration(
                recordingResponse.recordings?.[0]?.duration * 1000 || recordingResponse?.duration * 1000
              )
            : "";
      } else {
        if (recordingType === RECORDING_PARAMS.EVENT && recordingMetadata) {
          // Calculating the user-specific recording duration ourself, thanks AVS
          const recordingDuration = recordingMetadata.stopDeltaTime - recordingMetadata.startDeltaTime; // duration in milliseconds
          line2Text = getFormattedRecordingDuration(recordingDuration);
        }
      }
      if (recordingType === RECORDING_PARAMS.EVENT) {
        if (isMR) {
          line1Text = getFormattedRecordingDate(
            recordingResponse.programStartTime ?? recordingAsset?.programStartTime,
            true
          );
          line2Text = getFormattedRecordingDuration(
            recordingResponse.recordings?.[0]
              ? recordingResponse.recordings[0].duration * 1000
              : recordingResponse?.duration * 1000
          );
        } else {
          if (recordingMetadata) {
            line1Text = getFormattedRecordingDate(recordingMetadata.programStartTime, true);
            // Calculating the user-specific recording duration ourself, thanks AVS
            const recordingDuration = recordingMetadata.stopDeltaTime - recordingMetadata.startDeltaTime; // duration in milliseconds
            line2Text = getFormattedRecordingDuration(recordingDuration);
            show4kBadge = is4KChannel(recordingResponse);
            expiryString = getCPVRExpiryString(recordingMetadata);
          }
        }
      }
    }

    if (expiryString !== "") {
      elements.push(
        <span key="expiration" className="expiration-text">
          {expiryString}
        </span>
      );
    }

    if (isRecordingNow || recordingType === RECORDING_PARAMS.SERIES) {
      elements.push(
        <span key="lineOne" className="line-one">
          {isRecordingNow ? getRecordingsBadge() : ""}
        </span>
      );
    } else if (line1Text !== "") {
      elements.push(
        <span key="lineOne" className="line-one">
          {isRecordingNow ? getRecordingsBadge() : line1Text}
        </span>
      );
    }

    if (line2Text !== "") {
      elements.push(
        <span key="lineTwo" className="line-two">
          {line2Text}
        </span>
      );
    }

    if (show4kBadge) {
      elements.push(<img src={CHANNEL_4K_ICON} className="channel-4k-badge" alt="4k icon" />);
    }

    return elements;
  };
  const redirectToEpisodeList = () => {
    let seriesRecordId;
    window.scrollTo(0, 0);
    if (isMR) {
      seriesRecordId = recordingResponse.recordingDefinitionId;
    } else {
      // For CPVR, scheduled recordings are grouped by the recordingSeriesId, but recorded recordings are grouped by
      // the programSeriesId
      seriesRecordId = isRecordingScheduled ? recordingResponse.id : recordingResponse.metadata?.programSeriesId;
    }
    openSeriesViewAll(seriesRecordId);
  };

  const onHoverIn = () => {
    setShowHover(true);
  };

  const onHoverOut = () => {
    setShowHover(false);
  };

  return (
    <li
      className={classNames({
        "has-play-icon": recordingType == RECORDING_PARAMS.SERIES || allowToPlayback(),
      })}
    >
      <div className="recording-wrap">
        <div
          className={classNames("recording-main", "mr-cursor", {
            "cpvr-cursor": showHover && recordingType === RECORDING_PARAMS.SERIES,
          })}
          onClick={recordingType === RECORDING_PARAMS.SERIES ? redirectToEpisodeList : null}
        >
          <div
            className={classNames("program-image", {
              "cpvr-cursor": recordingType === RECORDING_PARAMS.SERIES || allowToPlayback(),
              "event-cursor": allowToPlayback() && recordingType !== RECORDING_PARAMS.SERIES,
              "mr-cursor": !allowToPlayback(),
            })}
            onMouseEnter={() => onHoverIn()}
            onMouseLeave={() => onHoverOut()}
            onClick={allowToPlayback() ? handleTileClick : null}
          >
            {<img src={getImage()} alt={title} />}
            {showHover && recordingType !== RECORDING_PARAMS.SERIES && allowToPlayback() ? (
              <img
                className="recording-play-icon"
                src={process.env.PUBLIC_URL + "/images/CTA-PlayCircle.svg"}
                alt="playIcon"
              />
            ) : (
              ""
            )}
            {recordingType !== RECORDING_PARAMS.SERIES &&
            allowToPlayback() &&
            convertToPercentageString(calculateRecordingProgressPercentage(recordingMetadata, bookmarks)) !== "0%" ? (
              <div className="recording-progress-bar-container">
                <div
                  className="recording-progress-bar"
                  style={{
                    width: convertToPercentageString(
                      calculateRecordingProgressPercentage(recordingResponse?.metadata, bookmarks)
                    ),
                  }}
                ></div>
              </div>
            ) : null}
          </div>
          <div
            className={classNames("program-info", { "cpvr-cursor": allowToPlayback() })}
            onMouseEnter={() => onHoverIn()}
            onMouseLeave={() => onHoverOut()}
            onClick={allowToPlayback() ? handleTileClick : null}
          >
            <div className="program-info-wrapper">
              {recordingType === RECORDING_PARAMS.SERIES ? (
                <span
                  className={classNames("program-title-info", { "hover-active": titleEllipsisActive })}
                  ref={titleRef}
                >
                  <span className="program-title series">{title}</span>
                </span>
              ) : (
                <span
                  className={classNames("program-title-info", { "hover-active": titleEllipsisActive })}
                  ref={titleRef}
                >
                  <span className="program-title episode-title">
                    {selectedSeriesId ? getFormattedEpisodeString(recordingMetadata, isMR) : title}
                  </span>
                </span>
              )}
            </div>
            <div className={subtitleClassName}>{subtitle}</div>
            {description && <div className="program-info-description">{description}</div>}
            <div className="program-running-info-wrapper">
              <div className={classNames("program-running-info", { "hover-active": infoEllipsisActive })} ref={infoRef}>
                {getSecondaryMetadataInfo()}
              </div>
            </div>
          </div>
        </div>
        <div className="program-recording-actions">
          {recordingResponse && (
            <>
              {showEditButton && recordingType === RECORDING_PARAMS.EVENT ? (
                <div className="edit-button-container">
                  <ImageButton
                    src={EDIT_ICON}
                    buttonContainerStyles="button-container"
                    alt={translate(RECORDING_PARAMS.EDIT)}
                    tooltipDirection="top"
                    className="wall-content-icon edit-icon"
                    onClickHandler={() =>
                      editRecordingClickHandler(
                        isMR ? recordingResponse : { eventRecordingItem: recordingResponse },
                        RECORDING_PARAMS.EVENT
                      )
                    }
                  />
                </div>
              ) : null}
              {showRemoveButton ? (
                <ImageButton
                  src={isRecordingNow && recordingType === RECORDING_PARAMS.EVENT ? CANCEL_ICON : DELETE_ICON}
                  buttonContainerStyles="button-container"
                  alt={translate(
                    isRecordingNow && recordingType === RECORDING_PARAMS.EVENT
                      ? RECORDING_PARAMS.CANCEL
                      : RECORDING_PARAMS.DELETE
                  )}
                  tooltipDirection="top"
                  className="wall-content-icon delete-icon"
                  onClickHandler={() =>
                    cancelRecordingClickHandler(
                      recordingResponse,
                      isProgramOfSeries,
                      !isRecordingScheduled &&
                        (isRecordingNow && recordingType === RECORDING_PARAMS.EVENT ? false : true),
                      isRecordingNow
                    )
                  }
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </li>
  );
};

RecordingItem.propTypes = {
  itemIndex: PropTypes.number,
  recordingResponse: PropTypes.object,
  isRecordingScheduled: PropTypes.bool,
  isProgramOfSeries: PropTypes.bool,
  openSeriesViewAll: PropTypes.func,
  selectedSeriesId: PropTypes.string,
  recordingType: PropTypes.string,
  editRecordingClickHandler: PropTypes.func,
  cancelRecordingClickHandler: PropTypes.func,
  showModalPopup: PropTypes.func,
  isMR: PropTypes.bool,
};

RecordingItem.defaultProps = {
  isRecordingScheduled: false,
  isProgramOfSeries: false,
  openSeriesViewAll: () => {},
  editRecordingClickHandler: () => {},
  cancelRecordingClickHandler: () => {},
  showModalPopup: () => {},
};

export default RecordingItem;

/**
 * @param {Object} recordingMetadata
 * @returns Formatted recording episode info string
 */
function getFormattedEpisodeString(recordingMetadata) {
  const autoGeneratedObject = getAutoGeneratedObject(recordingMetadata);
  if (recordingMetadata) {
    return getAutogeneratedEpisodeString(autoGeneratedObject, recordingMetadata);
  }
}

/**
 * Takes a recording timestamp and tries to determine the appropriate string to display in the secondary metadata info
 * @param {Long} recordingTimestamp Unix timestamp in milliseconds
 * @param {Boolean} isRecorded
 * @returns {string}
 */
function getFormattedRecordingDate(recordingTimestamp, isRecorded) {
  if (recordingTimestamp) {
    const timestamp = moment(recordingTimestamp);
    const recordingTime = timestamp.format("LT");
    const currentDate = moment().format("[date_format]");
    const recordingDate = timestamp.format("[date_format]");
    let dateString;
    if (currentDate === recordingDate) {
      dateString = i18n.t("today");
    } else if (recordingDate === moment().add(1, "d").format("[date_format]")) {
      dateString = i18n.t("tomorrow");
    } else {
      dateString = timestamp.format("[schedule_date_format]");
    }
    if (dateString) {
      let recordingDateString;
      if (isRecorded) {
        dateString =
          recordingDate === moment().subtract(1, "d").format("[date_format]") ? i18n.t("yesterday") : dateString;
        if (dateString === i18n.t("today") || dateString === i18n.t("tomorrow") || dateString === i18n.t("yesterday")) {
          recordingDateString = dateString;
        } else {
          recordingDateString = timestamp.format("ll");
        }
      } else {
        recordingDateString = dateString + ", " + recordingTime;
      }
      return recordingDateString;
    }
  }

  return "";
}

/**
 * Takes a recording duration in milliseconds and returns a formatted duration string.
 *
 * The format is "#h" if duration is over 1 hour and is a whole number of hours.
 * The format is "#h #min" if duration is over 1 hour and is not a whole number of hours.
 * The format is "#min" if the duration is less than an hour and greater than one minute.
 * The format is "1min" if the duration is less than or equal to one minute.
 *
 * @param {Long} duration Recording duration in milliseconds
 * @returns {String} Formatted duration string if duration is not falsey, else empty string
 */
function getFormattedRecordingDuration(duration = 0) {
  let formattedDuration = "";
  if (duration) {
    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;

    if (hours === 0 && minutes === 0) {
      formattedDuration = "1min";
    } else {
      let separator = "";
      if (hours) {
        formattedDuration += `${hours}h`;
        separator = " ";
      }

      if (minutes) {
        formattedDuration += separator + `${minutes}min`;
      }
    }
  }

  return formattedDuration;
}
