import React from "react";
import { useReducers } from "../../shared/hooks/useReducer";

import VodSidebarListItem from "./VodSideBarListItem";
import constants from "../../shared/constants";
const { REDUCER_TYPE } = constants;

const Index = (props) => {
  const { provider: appProvider, channelMapInfo } = useReducers(REDUCER_TYPE.APP);
  const { seasonEntitlements } = useReducers(REDUCER_TYPE.ON_DEMAND_PLAYER);
  const { bookmarks } = useReducers(REDUCER_TYPE.RECORDING);

  return (
    <VodSidebarListItem
      appProvider={appProvider}
      channelMapInfo={channelMapInfo}
      seasonEntitlements={seasonEntitlements}
      bookmarks={bookmarks}
      {...props}
    />
  );
};

export default Index;
