import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import styles from "./style.scss";

import { convertFromPixelString, convertToPercentageString, getQueryParamsByName } from "../../shared/utils";
import { useReducers } from "../../shared/hooks/useReducer";
import { handleImageError } from "../../shared/utils/image";
import { showToastNotification } from "../../App/state/actions";
import playerConstants from "../../shared/constants/player";
import routeConstants from "../../shared/constants/routes";

import constants from "../../shared/constants";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";

const PLAY_ICON = process.env.PUBLIC_URL + "/images/CTA-PlayCircle.svg";
const RESTART_ICON = process.env.PUBLIC_URL + "/images/Restart.svg";
const KEY_ICON = process.env.PUBLIC_URL + "/images/key-icon.svg";
const WATCHED_ICON = process.env.PUBLIC_URL + "/images/tick-icon.svg";
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";

const { PLAYBACK_TYPES } = playerConstants;
const { ON_DEMAND_PLAYER, LIVE_PLAYER } = routeConstants;
const { PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE, PLAY } = constants;

function EpisodeSwimlaneItem(props) {
  const {
    description,
    entitlements,
    id,
    itemClickAnalyticsCallback = () => null,
    progressPercentage,
    routes,
    subtitle,
    thumbnail,
    title,
    priorityEpisode,
    channelInfo,
    lookbackContent,
  } = props;
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { isRestartLiveTVEnabled } = useReducers(REDUCER_TYPE.APP).featureToggles;
  const { performPlaybackChecks } = usePlaybackChecks();

  const [overImageText, setOverImageText] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const isInHome = useSelector(({ app }) => app.isInHome);
  const isBlockedByOOH = entitlements.isInHomeRequired && !isInHome;
  const isPlayable =
    routes.playback && !isBlockedByOOH && !entitlements.isFromGhostChannel && !entitlements.isUnsubscribed;
  const ThumbnailParentTag = isPlayable ? "a" : "div";

  const getLineOneStatusIcon = () => {
    let statusIcon;
    if (entitlements) {
      let src, altText;
      if (entitlements.isUnsubscribed) {
        src = KEY_ICON;
        altText = "Key";
      } else if (
        !lookbackContent &&
        entitlements.isRestartable &&
        isRestartLiveTVEnabled &&
        !(isBlockedByOOH || entitlements.isFromGhostChannel)
      ) {
        src = RESTART_ICON;
        altText = "Restart";
      }

      if (src) {
        statusIcon = <img key={"lineOneStatusIcon-" + id} className="status-icon" src={src} alt={altText} />;
      }
    }

    return statusIcon;
  };

  const getMetadataComponents = () => {
    const metadataChildren = [];
    if (title) {
      metadataChildren.push(
        <p key={"title-" + id} className="title">
          {title}
        </p>
      );
    }

    if (subtitle.lineOne) {
      metadataChildren.push(
        <div key={"subtitleLineOneContainer-" + id} className="subtitle-container">
          <p key={"subtitleLineOne-" + id} className="subtitle" style={subtitle.lineOneStyleOverride}>
            {subtitle.lineOne}
          </p>
          {getLineOneStatusIcon()}
        </div>
      );
    }

    if (subtitle.lineTwo) {
      metadataChildren.push(
        <div key={"subtitleLineTwoContainer-" + id} className="subtitle-container">
          <p key={"subtitleLineTwo-" + id} className="subtitle">
            {subtitle.lineTwo}
          </p>
        </div>
      );
    }

    if (description) {
      metadataChildren.push(
        <p key={"description-" + id} className="description">
          {description}
        </p>
      );
    }

    return metadataChildren;
  };

  /**
   * Handles thumbnail fallback when the thumbnail fails to load
   * @param {Error} e Error from img failing to load
   * @param {Object} thumbnailInfo The item's thumbnail properties
   */
  const handleThumbnailError = (e, thumbnailInfo) => {
    handleImageError(e, thumbnailInfo.fallbackSrc);
    setOverImageText(thumbnailInfo.alt);
  };

  const onHoverIn = () => {
    if (isPlayable) setIsHovering(true);
  };

  const onHoverOut = () => {
    if (isPlayable) setIsHovering(false);
  };

  /**
   * Thumbnail click handler used to prevent navigation when necessary or to trigger other navigational side effects
   * such as analytics
   * @param {SyntheticEvent} event onClick event
   */
  const onThumbnailClick = (event) => {
    event.preventDefault();
    if (entitlements.isGeoBlocked) {
      dispatch(showToastNotification(translate("error_program_not_available_region")));
    } else {
      const contentType = getQueryParamsByName("contentType", routes?.playback);
      const contentId =
        contentType === PAGE_CONTENT_ITEM_TYPES.live
          ? parseInt(getQueryParamsByName("stationId", routes?.playback))
          : parseInt(getQueryParamsByName("playbackId", routes?.playback));
      const priorityEpisodeUserData = contentType === PAGE_CONTENT_ITEM_TYPES.live ? null : priorityEpisode?.userData;
      performPlaybackChecks(
        parseInt(priorityEpisodeUserData?.id) === parseInt(contentId) ? priorityEpisodeUserData : null,
        contentType === PAGE_CONTENT_ITEM_TYPES.live ? null : PLAYBACK_TYPES.EPISODE,
        contentId,
        contentType,
        contentType === PAGE_CONTENT_ITEM_TYPES.live ? LIVE_PLAYER.route : ON_DEMAND_PLAYER.route,
        undefined,
        undefined,
        channelInfo ? channelInfo : undefined,
        null,
        lookbackContent ? lookbackContent : null,
        null,
        { type: "", source: PLAY }
      );
      itemClickAnalyticsCallback();
    }
  };

  /**
   * Tracks the navigation event when the details link is clicked
   */
  const trackDetailsNavigation = () => {
    itemClickAnalyticsCallback(true);
  };

  return (
    <div
      id={id}
      className={`item-container ${isPlayable ? "" : "no-hover"}`}
      style={{
        width: thumbnail.dimensions.width + 2 * convertFromPixelString(styles.thumbnailBorderThickness),
        marginRight: thumbnail.dimensions.itemSpacing - 2 * convertFromPixelString(styles.thumbnailBorderThickness),
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
    >
      <ThumbnailParentTag href={routes.playback ? `#${routes.playback}` : undefined} onClick={onThumbnailClick}>
        <div
          className="thumbnail-container"
          style={{
            borderRadius: thumbnail.dimensions.borderRadius,
            height: thumbnail.dimensions.height + 2 * convertFromPixelString(styles.thumbnailBorderThickness),
          }}
        >
          <img
            className="thumbnail"
            src={thumbnail.src ? `${thumbnail.src}?w=${thumbnail.dimensions.width}` : ""}
            alt={thumbnail.alt ?? ""}
            onError={(e) => handleThumbnailError(e, thumbnail)}
            style={{
              height: thumbnail.dimensions.height,
              width: thumbnail.dimensions.width,
              opacity: !!overImageText ? 0.5 : undefined,
              backgroundColor: !!overImageText ? "#000" : undefined,
            }}
          />
          {!!progressPercentage && (
            <div
              className="progress-bar-container"
              style={{
                width: thumbnail.dimensions.width,
              }}
            >
              <div className="progress-bar-background">
                <div
                  className="progress-bar"
                  style={{
                    width: convertToPercentageString(progressPercentage),
                  }}
                ></div>
              </div>
            </div>
          )}
          {isPlayable && isHovering && <img className="thumbnail-icon" src={PLAY_ICON} alt="Play icon" />}
          {!!overImageText && <span className="text-over-image">{overImageText}</span>}
        </div>
      </ThumbnailParentTag>
      <div className="metadata-container-episode">{getMetadataComponents()}</div>
      <a href={`#${routes.details}`} className={"details-link"} onClick={trackDetailsNavigation}>
        <span>{translate("details")}</span>
        <img src={VIEW_ALL_ICON} alt="" />
      </a>
    </div>
  );
}

EpisodeSwimlaneItem.propTypes = {
  description: PropTypes.string,
  entitlements: PropTypes.shape({
    isRestartable: PropTypes.bool,
    isInHomeRequired: PropTypes.bool,
    isFromGhostChannel: PropTypes.bool,
    isUnsubscribed: PropTypes.bool,
  }),
  id: PropTypes.string,
  itemClickAnalyticsCallback: PropTypes.func,
  progressPercentage: PropTypes.number,
  routes: PropTypes.shape({
    details: PropTypes.string,
    playback: PropTypes.string,
  }),
  subtitle: PropTypes.shape({
    lineOne: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lineTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lineOneStyleOverride: PropTypes.object,
  }),
  thumbnail: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    dimensions: PropTypes.object,
    fallbackSrc: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default EpisodeSwimlaneItem;
