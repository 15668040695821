import React, { useMemo, useRef, useCallback, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import epgConstants from "../../shared/constants/epg";
import routeConstants from "../../shared/constants/routes";
import storageConstants from "../../shared/constants/storage";
import error from "../../shared/constants/error";
import { calculateLookbackHoursLeft, isPastProgram, checkUnifiedAsset } from "../../shared/utils";
import { isItemTypeEpisode, isItemTypeMovie } from "../../shared/utils/content";
import classNames from "classnames";
import {
  checkIsStartOverSupported,
  convertDurationToWidth,
  isGhostChannel,
  isUserSubscribedChannel,
  checkIsLookbackSupported,
} from "../../shared/utils/epg";
import {
  getRecordingCTAs,
  getRecordingInfo,
  setRecording,
  getRecordingSystemType,
} from "../../shared/utils/recordingHelper";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import {
  showToastNotification,
  toggleSpinningLoaderAction,
  addFavourites,
  deleteFavourites,
} from "../../App/state/actions";
import ImageButton from "../ImageButton";
import ContextualMenu from "../ContextualMenu";
import { setRecordingAction } from "../../pages/RecordingsPage/state/actions";
import { getAutoGeneratedObject, getAutogeneratedEpisodeString } from "../../shared/utils";
import { showModalPopup } from "../../App/state/actions";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";

const { ACTION_KEYS, CONTENT_ITEM_TYPES, PAGE_CONTENT_ITEM_TYPES, MODAL_TYPES, PLAY } = constants;
const { RECORDING_PARAMS, RECORDING_PACKAGES } = recordingConstants;
const { CHANNEL_CELL_WIDTH } = epgConstants;
const { LIVE_PLAYER, MOVIE_DETAIL_PAGE, EPISODE_DETAIL_PAGE } = routeConstants;
const { EPG_SELECTED_CHANNEL_NUMBER } = storageConstants;
const { API_STATUS_CODES } = error;

const outOfHomeIcon = `${process.env.PUBLIC_URL}/images/Out_of_home.svg`;
const inHomeIcon = `${process.env.PUBLIC_URL}/images/In_Home.svg`;
const restartIcon = `${process.env.PUBLIC_URL}/images/Restart.svg`;
const playIcon = `${process.env.PUBLIC_URL}/images/play-icon.svg`;
const recordEpisodeIcon = `${process.env.PUBLIC_URL}/images/set_recording.svg`;
const recordSeriesIcon = `${process.env.PUBLIC_URL}/images/record_series.svg`;
const infoIcon = `${process.env.PUBLIC_URL}/images/info-icon.svg`;
const favouriteIcon = `${process.env.PUBLIC_URL}/images/favourite_white_update.svg`;
const favouriteActiveIcon = `${process.env.PUBLIC_URL}/images/favourite_active_channel.svg`;

/**
 * EPG program cell component
 *
 * @component
 */
function ProgramCell({
  rowNumber,
  columnNumber,
  appProvider,
  userProfile,
  subscribedChannels,
  program,
  currentTime,
  epgRange,
  channel,
  entitlements,
  recordingsList,
  setRecordingAction,
  showToastNotification,
  setSelectedAssetRecordingInfo,
  updateRecordingHandler,
  style,
  addFavourites,
  deleteFavourites,
  toggleSpinningLoaderAction,
  favouriteAssets,
  featureToggles,
  showModalPopup,
}) {
  const regionId = appProvider?.channelMapID;
  const {
    isGuideContextMenuEnabled: isContextMenuEnabled,
    isFavouritesEnabled,
    isLookbackEnabled,
    isRecordingEnabled,
    isRestartLiveTVEnabled,
  } = featureToggles;

  const history = useHistory();
  const { t: translate } = useTranslation();
  const [isAddedToFavourites, setIsAddedToFavourites] = useState(false);
  const { performPlaybackChecks } = usePlaybackChecks();
  const isOnNow = program?.metadata?.airingStartTime <= currentTime && currentTime < program?.metadata?.airingEndTime;
  const isOnNowOutOfHome = program?.metadata?.isNotAvailableOutOfHome && channel?.metadata?.isNotAvailableOutOfHome;

  const duration =
    Math.min(program?.metadata?.airingEndTime, epgRange.end) -
    Math.max(epgRange.start, program?.metadata?.airingStartTime);
  const cellWidth = convertDurationToWidth(duration);
  const offsetLeft =
    convertDurationToWidth(Math.max(program?.metadata?.airingStartTime, epgRange.start) - epgRange.start) +
    CHANNEL_CELL_WIDTH;
  const contextMenuPlayBtnHref = useRef(null);
  const contextMenuDetailsBtnHref = useRef(null);

  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;
  const isUnifiedAsset = checkUnifiedAsset(program.metadata?.extendedMetadata?.dlum);

  const uaGroupType = useMemo(() => program?.metadata?.extendedMetadata?.dlum?.uaGroupType, [program]);

  const isNew = program?.metadata?.extendedMetadata?.epg?.isNew;
  const isLive = program?.metadata?.extendedMetadata?.epg?.isLive;

  const titleBadge =
    duration >= 15 * 60 * 1000 &&
    (isNew || isLive) &&
    (isLive ? translate("epg_badge_live") : translate("epg_badge_new"));

  const schedule = useMemo(() => {
    return channel && program
      ? {
          id: program.metadata?.contentId, // If the program has lookback enabled, this id is required for lookback content playback analytics at player conponent, see pages/PlayerPage/index.js programDetailsRef reference logic and triggerAnalyticsCalls function logic
          channel: channel,
          metadata: program.metadata,
        }
      : null;
  }, [channel, program]);

  const isSubscribedChannel = useMemo(() => {
    return isUserSubscribedChannel(channel, subscribedChannels);
  }, [channel, subscribedChannels]);

  const isInHome = useSelector(({ app }) => app.isInHome);
  const isGhost = channel?.metadata?.extendedMetadata?.isGhost || "N";
  const isBlockedByOOH = (entitlements?.isInHomeRequired && !isInHome) || isGhost === "Y" || !isSubscribedChannel;
  // To determine if a cell item has lookback asset, only happens when lookback is enabled
  const hasLookback = useMemo(() => {
    if (isLookbackEnabled && schedule) {
      const lookbackHoursLeft = calculateLookbackHoursLeft(schedule);
      const isLookbackSuported = checkIsLookbackSupported(schedule.metadata, schedule.channel, isInHome);
      if (
        userProfile?.isLoggedIn &&
        isSubscribedChannel &&
        schedule.metadata?.airingEndTime < currentTime &&
        lookbackHoursLeft > 0 &&
        isLookbackSuported
      ) {
        return true;
      }
    }
    return false;
  }, [isLookbackEnabled, schedule, userProfile, isSubscribedChannel, currentTime, isInHome]);

  // To determine if a cell item has already aired and consider in the past
  const isProgramInPast = useMemo(() => {
    if (schedule) {
      if (schedule.metadata?.airingEndTime < currentTime) {
        return true;
      }
    }
    return false;
  }, [schedule, currentTime]);

  const programRecordingInfo = useMemo(() => {
    if (isSubscribedChannel && schedule?.channel?.metadata?.isRecordable && recordingsList && isRecordingEnabled) {
      return getRecordingInfo(isMR, schedule, recordingsList, regionId);
    }
    return null;
  }, [schedule, recordingsList, isRecordingEnabled, isMR, regionId, isSubscribedChannel]);

  const recordingCTA = useMemo(() => {
    if (programRecordingInfo) {
      const eventRecordingSet = programRecordingInfo.recordingEventScheduledCheck;
      const seriesRecordingSet = programRecordingInfo.recordingSeriesScheduledCheck;
      const seriesRecordingConflicted = programRecordingInfo.recordingSeriesConflictCheck;
      const eventRecordingConflicted = programRecordingInfo.recordingEventConflictCheck;
      const cPVRRecordingItem = programRecordingInfo.eventRecordingItem;
      const isRecordingIconAvailable =
        seriesRecordingSet ||
        seriesRecordingConflicted ||
        eventRecordingSet ||
        eventRecordingConflicted ||
        cPVRRecordingItem
          ? true
          : false;
      /**
       * Using the recording status (set or conflict) to check if the icon needs to be provide or not.
       * Currently we are not showing hollow recording icon.
       */
      if (isRecordingIconAvailable) {
        return getRecordingCTAs(
          isMR,
          programRecordingInfo,
          schedule,
          eventRecordingConflicted,
          eventRecordingSet,
          seriesRecordingConflicted,
          seriesRecordingSet
        );
      }
    }
    return null;
  }, [schedule, programRecordingInfo, isMR]);

  const recordingButton = useMemo(() => {
    return recordingCTA ? (
      <ImageButton
        key="recording-button"
        src={recordingCTA.recordingIcon}
        className="guide-record-icon"
        // RecordingModal component appearing on guide page has been de-scoped for now and only the recording status can be seen (schedule and conflicted)
        buttonContainerStyles="button-container"
        alt="recording button"
      />
    ) : null;
  }, [recordingCTA]);

  const programCellType = useMemo(() => {
    const programInfo = program?.metadata;
    if (isItemTypeMovie(programInfo)) {
      return CONTENT_ITEM_TYPES.movie;
    } else if (isItemTypeEpisode(programInfo)) {
      return CONTENT_ITEM_TYPES.episode;
    }
    return null;
  }, [program]);

  const detailHref = useMemo(() => {
    const programInfo = program?.metadata;
    const programClickAction = program?.actions?.find(
      (programAction) => programAction?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK
    );
    return isItemTypeMovie(programInfo)
      ? `${MOVIE_DETAIL_PAGE.route}${programClickAction?.uri}/${programInfo.contentId}`
      : isItemTypeEpisode(programInfo)
      ? `${EPISODE_DETAIL_PAGE.route}${programClickAction?.uri}/${programInfo.contentId}`
      : null;
  }, [program]);

  const programCellHref = useMemo(() => {
    const programInfo = program?.metadata;
    const playerHref = `${LIVE_PLAYER.route}/?stationId=${channel.id}&contentType=LIVE`;
    contextMenuDetailsBtnHref.current = detailHref;
    if (
      (userProfile?.isLoggedIn &&
        isSubscribedChannel &&
        !isGhostChannel(channel) &&
        programInfo.airingStartTime <= currentTime &&
        programInfo.airingEndTime >= currentTime) ||
      hasLookback
    ) {
      contextMenuPlayBtnHref.current = playerHref;
      return "#" + playerHref;
    } else {
      return "#" + detailHref;
    }
  }, [program, channel, currentTime, userProfile, isSubscribedChannel, hasLookback, detailHref]);

  const indicatorIcons = useMemo(() => {
    const recordingIndicator = program?.metadata?.airingEndTime > currentTime && recordingButton;
    const inHomeOutOfHomeIndicator = program?.metadata?.isNotAvailableOutOfHome &&
      !channel?.metadata?.isNotAvailableOutOfHome && (
        <img key="in-home-icon" src={isInHome ? inHomeIcon : outOfHomeIcon} alt="" />
      );
    const restartIndicator = ((isOnNow &&
      isRestartLiveTVEnabled &&
      checkIsStartOverSupported(program?.metadata, channel, isInHome)) ||
      hasLookback) && <img key="restart-icon" src={restartIcon} alt="" />;

    const displayIcons = [recordingIndicator, inHomeOutOfHomeIndicator, restartIndicator].filter((item) => item);

    // If program duration is 15 minutes or less then show only one icon, otherwise show 2 icons
    if (duration <= 15 * 60 * 1000) {
      return displayIcons.slice(0, 1);
    } else {
      return displayIcons.slice(0, 2);
    }
  }, [
    program,
    channel,
    currentTime,
    isInHome,
    isRestartLiveTVEnabled,
    recordingButton,
    duration,
    isOnNow,
    hasLookback,
  ]);

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event)
   * @param {Object} recordingInfo - recording information used to set the recording
   */
  const scheduleRecording = useCallback(
    (typeOfRecording, recordingInfo) => {
      if (recordingInfo) {
        setRecording(typeOfRecording, appProvider, recordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, setRecordingAction]
  );

  const onProgramCellClick = (event) => {
    if (!isUnifiedAsset) {
      event.preventDefault();
      showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true }); // showing error for non-UA assets
    } else if (
      userProfile.isLoggedIn &&
      isSubscribedChannel &&
      (channel?.metadata?.isNotAvailableOutOfHome || program?.metadata?.isNotAvailableOutOfHome) &&
      program.metadata.airingStartTime <= currentTime &&
      program.metadata.airingEndTime >= currentTime &&
      !isInHome
    ) {
      event.preventDefault();
      history.push(detailHref);
    } else if (program.metadata?.isGeoBlocked) {
      event.preventDefault();
      showToastNotification(translate("error_program_not_available_region"));
    } else {
      if (event.currentTarget.href) {
        if (event.currentTarget.href.includes(LIVE_PLAYER.route)) {
          event.preventDefault();
          performPlaybackChecks(
            null,
            null,
            channel?.id,
            PAGE_CONTENT_ITEM_TYPES.live,
            LIVE_PLAYER.route,
            undefined,
            undefined,
            isSubscribedChannel ? channel : undefined,
            null,
            hasLookback ? program : null,
            program.metadata.title,
            { type: "", source: PLAY }
          );
        }
        setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.GUIDE};${rowNumber}:${columnNumber}`);
        setSessionStorage(EPG_SELECTED_CHANNEL_NUMBER, channel?.number);
      } else {
        event.preventDefault();
        showToastNotification(translate("error_try_again_later"));
      }
    }
  };

  useEffect(() => {
    if (favouriteAssets?.length > 0) {
      const isAssetAdded = favouriteAssets.some((fav) => {
        return uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.movie
          ? fav.metadata?.extendedMetadata?.dlum?.uaId === program.metadata.extendedMetadata.dlum.uaId
          : fav.metadata?.extendedMetadata?.dlum?.uaGroupId === program.metadata.extendedMetadata.dlum.uaGroupId;
      });
      setIsAddedToFavourites(isAssetAdded);
    }
  }, [favouriteAssets, program, uaGroupType]);

  const favouriteAction = useCallback(() => {
    toggleSpinningLoaderAction(true, "spinner");
    isAddedToFavourites
      ? deleteFavourites(appProvider, program, uaGroupType)
          .then(() => {
            showToastNotification(
              translate("favourite_program_removed").replace("{Program name}", program.metadata.title)
            );
          })
          .catch(() => console.error("Failed to remove", program.metadata.title, "from favourites"))
          .finally(() => toggleSpinningLoaderAction(false))
      : addFavourites(appProvider, program, uaGroupType)
          .then((res) => {
            if (res === API_STATUS_CODES.OK) {
              showToastNotification(
                translate("favourite_program_added").replace("{Program name}", program.metadata.title)
              );
            }
          })
          .catch(() => console.error("Failed to add", program.metadata.title, "to favourites"))
          .finally(() => toggleSpinningLoaderAction(false));
  }, [
    isAddedToFavourites,
    deleteFavourites,
    appProvider,
    program,
    addFavourites,
    showToastNotification,
    translate,
    toggleSpinningLoaderAction,
    uaGroupType,
  ]);

  const contextMenuItems = useMemo(() => {
    // if program or channel or schedule is not ready, we don't have data to feed context menu
    if (!program || !channel || !schedule) return null;

    const programInfo = program?.metadata;
    const menuItems = [];
    // Play button logic
    if (
      (userProfile?.isLoggedIn &&
        isSubscribedChannel &&
        !isGhostChannel(channel) &&
        programInfo?.airingStartTime <= currentTime &&
        programInfo?.airingEndTime >= currentTime &&
        (isInHome ||
          (!isInHome && !(channel?.metadata?.isNotAvailableOutOfHome || programInfo?.isNotAvailableOutOfHome)))) ||
      hasLookback
    ) {
      menuItems.push({
        iconKey: "play-icon",
        iconSrc: playIcon,
        label: translate("play"),
        onClickHandler: () => {
          setSessionStorage(EPG_SELECTED_CHANNEL_NUMBER, channel?.number);
          performPlaybackChecks(
            null,
            null,
            channel?.id,
            PAGE_CONTENT_ITEM_TYPES.live,
            LIVE_PLAYER.route,
            undefined,
            undefined,
            isSubscribedChannel ? channel : undefined,
            null,
            hasLookback ? schedule : null,
            null,
            { type: "", source: PLAY }
          );
        },
      });
    }
    // Recording buttons logic
    if (programRecordingInfo && !isPastProgram(programInfo?.airingEndTime)) {
      if (schedule.channel && schedule.metadata) {
        // Set/Edit single recording button
        const autoGeneratedObject = getAutoGeneratedObject(programRecordingInfo.assetToRecord?.metadata);
        const episodeInfo = getAutogeneratedEpisodeString(
          autoGeneratedObject,
          programRecordingInfo.assetToRecord?.metadata ?? programRecordingInfo,
          false
        );
        if (
          !recordingCTA &&
          (programCellType === CONTENT_ITEM_TYPES.movie || programCellType === CONTENT_ITEM_TYPES.episode)
        ) {
          const labelText = episodeInfo
            ? `${translate(RECORDING_PARAMS.RECORD)} ${episodeInfo}`
            : translate(RECORDING_PARAMS.RECORD);
          menuItems.push({
            iconKey: "set-single-recording-icon",
            iconSrc: recordEpisodeIcon,
            label: labelText,
            onClickHandler: (event) => {
              event.stopPropagation();
              setSelectedAssetRecordingInfo(programRecordingInfo);
              scheduleRecording(RECORDING_PARAMS.EVENT, programRecordingInfo);
            },
          });
        } else if (
          recordingCTA?.actionType === RECORDING_PARAMS.EDIT_REC ||
          recordingCTA?.actionType === RECORDING_PARAMS.CANCEL_REC
        ) {
          const labelText =
            recordingCTA.actionType === RECORDING_PARAMS.EDIT_REC
              ? isMR
                ? translate(RECORDING_PARAMS.EDIT)
                : `${translate(RECORDING_PARAMS.EDIT)} ${episodeInfo}`
              : translate(recordingCTA.actionType);
          menuItems.push({
            iconKey: "edit-single-recording-icon",
            iconSrc: recordingCTA.recordingIcon,
            label: labelText,
            onClickHandler: (event) => {
              event.stopPropagation();
              updateRecordingHandler(programRecordingInfo);
            },
          });
        }
        // Set/Edit series recording button
        // Note: for MR recording, recordingCTA is returned as RECORDING_PARAMS.EDIT_REC no matter the recording is series or event, so we need to check the actionType for RECORDING_PARAMS.EDIT_REC, too
        if (
          recordingCTA?.actionType !== RECORDING_PARAMS.EDIT_SERIES &&
          recordingCTA?.actionType !== RECORDING_PARAMS.EDIT_REC &&
          recordingCTA?.actionType !== RECORDING_PARAMS.CANCEL_REC &&
          programCellType === CONTENT_ITEM_TYPES.episode
        ) {
          menuItems.push({
            iconKey: "set-series-recording-icon",
            iconSrc: recordSeriesIcon,
            label: translate(RECORDING_PARAMS.RECORD_SERIES),
            onClickHandler: (event) => {
              event.stopPropagation();
              setSelectedAssetRecordingInfo(programRecordingInfo);
              scheduleRecording(RECORDING_PARAMS.SERIES, programRecordingInfo);
            },
          });
        } else if (recordingCTA?.actionType === RECORDING_PARAMS.EDIT_SERIES) {
          menuItems.push({
            iconKey: "edit-series-recording-icon",
            iconSrc: recordingCTA.recordingIcon,
            label: translate(RECORDING_PARAMS.EDIT),
            onClickHandler: (event) => {
              event.stopPropagation();
              updateRecordingHandler(programRecordingInfo);
            },
          });
        }
      }
    }
    // detail button
    menuItems.push({
      iconKey: "details-icon",
      iconSrc: infoIcon,
      label: translate("details"),
      onClickHandler: () => {
        setSessionStorage(EPG_SELECTED_CHANNEL_NUMBER, channel?.number);
        if (!isUnifiedAsset) {
          showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true }); // showing error for non-UA assets
        } else {
          history.push(contextMenuDetailsBtnHref.current);
        }
      },
    });

    const shouldHaveFavOption =
      uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.movie ||
      (uaGroupType?.toLowerCase() === CONTENT_ITEM_TYPES.tvshow && program.metadata.extendedMetadata.dlum.uaGroupId);

    if (userProfile?.isLoggedIn && isFavouritesEnabled && shouldHaveFavOption) {
      menuItems.push({
        iconKey: "favourite-icon",
        iconSrc: isAddedToFavourites ? favouriteActiveIcon : favouriteIcon,
        label: translate(isAddedToFavourites ? "action_unfavourite" : "action_favourite"),
        onClickHandler: (event) => {
          event.stopPropagation();
          favouriteAction();
        },
      });
    }

    return menuItems;
  }, [
    program,
    channel,
    schedule,
    userProfile,
    isSubscribedChannel,
    currentTime,
    isInHome,
    hasLookback,
    programRecordingInfo,
    translate,
    isFavouritesEnabled,
    uaGroupType,
    performPlaybackChecks,
    recordingCTA,
    programCellType,
    setSelectedAssetRecordingInfo,
    scheduleRecording,
    isMR,
    updateRecordingHandler,
    history,
    isAddedToFavourites,
    favouriteAction,
    isUnifiedAsset,
    showModalPopup,
  ]);

  return program ? (
    isContextMenuEnabled ? (
      <>
        <ContextualMenu
          id={`guide-context-menu-${program?.id}`}
          contextMenuItems={contextMenuItems}
          menuAppendTo=".epg-container"
        >
          <a
            href={programCellHref}
            className={classNames(
              "cell",
              "program-cell",
              {
                "on-now": isOnNow,
                "on-now-out-of-home": isBlockedByOOH,
              },
              { lookback: hasLookback }
            )}
            data={`${
              isOnNow
                ? "on-now-"
                : hasLookback
                ? "lookback-"
                : isProgramInPast
                ? "aired-date-past-"
                : isOnNowOutOfHome
                ? "outOfHome-"
                : "future-"
            }channel-id-${channel.id}`}
            style={{
              ...style,
              width: cellWidth,
              left: offsetLeft,
            }}
            onClick={onProgramCellClick}
          >
            <div className="program-name">
              <div className="sticky-header">
                <span className="title-badge">{titleBadge}</span>
                <span>{program?.metadata?.title}</span>
              </div>
            </div>
            {indicatorIcons.length > 0 ? <div className="icon-container">{indicatorIcons}</div> : null}
          </a>
        </ContextualMenu>
      </>
    ) : (
      <a
        href={programCellHref}
        className={classNames("cell", "program-cell", {
          "on-now": isOnNow && userProfile?.isLoggedIn,
          // "on-now-out-of-home": isOnNow && isOnNowOutOfHome,
          "on-now-out-of-home": isBlockedByOOH,
        })}
        data={`${
          isOnNow ? "on-now-" : hasLookback ? "lookback-" : isProgramInPast ? "aired-date-past-" : "future-"
        }channel-id-${channel.id}`}
        style={{
          ...style,
          width: cellWidth,
          left: offsetLeft,
        }}
        onClick={onProgramCellClick}
      >
        <div className="program-name">
          <div className="sticky-header">
            <span className="title-badge">{titleBadge}</span>
            <span>{program?.metadata?.title}</span>
          </div>
        </div>
        {indicatorIcons.length > 0 ? <div className="icon-container">{indicatorIcons}</div> : null}
      </a>
    )
  ) : null;
}

ProgramCell.propTypes = {
  rowNumber: PropTypes.number,
  columnNumber: PropTypes.number,
  program: PropTypes.object,
  currentTime: PropTypes.number,
  epgRange: PropTypes.object,
  channel: PropTypes.object,
  recordingSystemType: PropTypes.string,
  isRecordingEnabled: PropTypes.bool,
  isRestartLiveTVEnabled: PropTypes.bool,
  style: PropTypes.object,
};

function mapStateToProps({ app, recording }) {
  return {
    appProvider: app.provider,
    userProfile: app.userProfile,
    isInHome: app.isInHome,
    subscribedChannels: app.subscribedChannels,
    recordingsList: recording.recordingsList,
    favouriteAssets: app.favouriteAssets,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  setRecordingAction,
  showToastNotification,
  addFavourites,
  deleteFavourites,
  toggleSpinningLoaderAction,
  showModalPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCell);
