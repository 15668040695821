import styled from "styled-components";

import PopUpOutline from "../PopUpOutline";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ModalContentContainer = styled(PopUpOutline)`
  color: ${({ theme }) => theme.colours.white};
  width: 70vw;
  height: 70vh;
  max-height: 756px;
  max-width: 1344px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 2px;

  @media (min-width: 1366px) and (max-width: 1600px) {
    max-height: 900px;
    max-width: 956px;
  }
  @media (min-width: 1024px) and (max-width: 1365px) {
    max-height: 756px;
    max-width: 717px;
  }
  @media (max-width: 1023px) {
    max-height: 1178px;
    max-width: 538px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
`;

export const SeriesTitle = styled.div`
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 27px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typography.extraLargeFontSizeHd};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 38.4px;
`;

export const Content = styled.div`
  display: flex;
  gap: 100px;
  overflow-y: scroll;
  padding: 38px;

  &::before {
    content: "";
    position: absolute;
    height: 40px;
    top: 0;
    left: 0;
    width: 95%;
    background: linear-gradient(to bottom, #232323 0%, rgba(35, 35, 35, 0) 100%);
    border-radius: 10px;
  }

  &::after {
    content: "";
    position: absolute;
    height: 80px;
    bottom: 0;
    left: 0;
    width: 95%;
    background: linear-gradient(to top, #232323 0%, rgba(35, 35, 35, 0) 100%);
    border-radius: 10px;
  }

  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: ${({ theme }) => theme.colours.scrollGrey} transparent; /* For Firefox */

  &::-webkit-scrollbar {
    width: 5px;
    color: ${({ theme }) => theme.colours.scrollGrey};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.scrollGrey};
    border-radius: 10px;
    border: 4px solid ${({ theme }) => theme.colours.mineShaftGray};
  }

  @media (max-width: 1600px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const MetadataSection = styled.div`
  width: 772px;
  @media (max-width: 1600px) {
    width: 85%;
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 27px;
`;

export const DetailsSection = styled.div`
  margin-top: 20px;
`;

export const Genres = styled.p`
  color: ${({ theme }) => theme.colours.offWhite};
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 27px;
  margin: 0 0 5px 0;
`;

export const Badges = styled.div`
  display: flex;
  gap: 8px;
`;

export const Ratings = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 25px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const FormatsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-top: 8px;
`;

export const FormatBadge = styled.span`
  &._4k,
  &._4kHDR {
    display: flex;
    margin-bottom: 4px;
    height: 100%;
    max-height: 17.55px;
  }
`;
