import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_CURRENT_PROGRAMS } from "../../pages/GuidePage/state/actions";
import middleware from "../middleware";
import epgConstants from "../constants/epg";

const { getChannelPrograms } = middleware;
const { EPG_DATA_CHUNK_SIZE } = epgConstants;

/**
 * Custom hook for fetching EPG data and storing it in redux store
 */
export default function useGuidePrograms() {
  const appProvider = useSelector(({ app }) => app.provider);
  const currentPrograms = useSelector(({ epg }) => epg.currentPrograms);
  const currentProgramsRef = useRef(currentPrograms);
  const dispatch = useDispatch();

  /**
   * Loads a segment of EPG program data into the Redux store
   * @param {Number} segmentTimestamp
   */
  const loadGuidePrograms = useCallback(
    async (segmentTimestamp) => {
      if (currentProgramsRef.current[segmentTimestamp]) {
        return;
      }

      const startTime = segmentTimestamp;
      const endTime = getNextSegmentTimestamp(segmentTimestamp);

      // Set a new object to prevent loading the same chunk while the request is in progress
      currentProgramsRef.current[segmentTimestamp] = { loading: true };
      dispatch({
        type: LOAD_CURRENT_PROGRAMS,
        segmentTimestamp,
        programs: { loading: true }, // mark as loading to prevent retry loops in useEffect
        isEpgFetched: false, // adding a boolean to indicate call is in progress
      });

      let programs;
      try {
        // Do not pass a cancel token because EPG data is used across multiple pages and
        // sometimes preloaded in the background, so there is no need to cancel the request
        programs = await getChannelPrograms(startTime, endTime, appProvider);
        currentProgramsRef.current[segmentTimestamp].loading = false;
      } catch (error) {
        currentProgramsRef.current[segmentTimestamp].loading = false;
        console.error(`Failed to load programs for segment ${segmentTimestamp}`);
        // Mark as failed to prevent retry loops in useEffect
        currentProgramsRef.current[segmentTimestamp].failed = true;
        dispatch({
          type: LOAD_CURRENT_PROGRAMS,
          segmentTimestamp,
          programs: { loading: false, failed: true },
          isEpgFetched: true, // adding a boolean to indicate call is completed
        });
      } finally {
        dispatch({
          type: LOAD_CURRENT_PROGRAMS,
          segmentTimestamp,
          programs: programs
            ? { ...currentProgramsRef.current[segmentTimestamp], ...programs }
            : { ...currentProgramsRef.current[segmentTimestamp] },
          isEpgFetched: true, // adding a boolean to indicate call is completed
        });
      }
    },
    [appProvider, dispatch]
  );

  useEffect(() => {
    currentProgramsRef.current = currentPrograms;
  }, [currentPrograms]);

  return { loadGuidePrograms };
}

/**
 * Returns the hour of the current chunk, i.e. round down to the nearest multiple of EPG_DATA_CHUNK_SIZE
 * @param {Number} currentHour Hour between 0 and 24
 * @returns {Number}
 */
const getSegmentStartHour = (currentHour) => {
  if (currentHour === 24) {
    return 0;
  }
  return Math.floor(currentHour / EPG_DATA_CHUNK_SIZE) * EPG_DATA_CHUNK_SIZE;
};

/**
 * Returns the timestamp key of the 3-hour segment to which the given timestamp belongs
 *
 * @param {Number} timestamp
 * @returns {Number}
 */
export const getSegmentTimestamp = (timestamp = null) => {
  const date = timestamp ? moment.utc(timestamp) : moment.utc();
  date.set({
    hour: getSegmentStartHour(date.hour()),
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return date.valueOf();
};

/**
 * Returns the timestamp key of the next 3-hour segment after the given timestamp
 *
 * @param {Number} timestamp
 * @returns {Number}
 */
export const getNextSegmentTimestamp = (timestamp = null) => {
  const currentSegmentTimestamp = getSegmentTimestamp(timestamp);
  const segmentMoment = moment.utc(currentSegmentTimestamp);
  return segmentMoment.add(EPG_DATA_CHUNK_SIZE, "hours").valueOf();
};

/**
 * Returns the timestamp key of the 3-hour lookback segment before the given timestamp
 *
 * @param {Number} timestamp
 * @returns {Number}
 */
export const getLookbackSegmentTimestamp = (timestamp = null) => {
  const leftEdgeSegmentTimestamp = getSegmentTimestamp(timestamp);
  const segmentMoment = moment.utc(leftEdgeSegmentTimestamp);
  return segmentMoment.subtract(EPG_DATA_CHUNK_SIZE, "hours").valueOf();
};
