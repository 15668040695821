import React from "react";
import ImageButton from "../ImageButton";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import { convertToPercentageString, getPurchaseTransactionType } from "../../shared/utils";
import { getPackagePrice, getAssetExpiryString, getFormattedPrice } from "../../shared/utils/feedHelper";
import useAppLanguage from "../../shared/hooks/useAppLanguage";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";

const unsubscribedIcon = process.env.PUBLIC_URL + "/images/key-icon.svg";
const { ON_DEMAND_PLAYER } = routeConstants;
const { ON_DEMAND_OPTIONS, PAGE_CONTENT_ITEM_TYPES, TRANSACTION_TYPES, DEFAULT_RENTAL_DURATION } = constants;

const OnDemandItem = ({
  content,
  onItemClick,
  toastClickHandler,
  vodPlaybackType,
  progressPercentage,
  isComplete,
  isGeoBlocked,
  is4KPlayRestricted,
}) => {
  const { t: translate } = useTranslation();
  const { appLanguage } = useAppLanguage();

  const { performPlaybackChecks } = usePlaybackChecks();

  const onClickHandler = () => {
    switch (content.btnType) {
      case ON_DEMAND_OPTIONS.SUBSCRIBE:
        toastClickHandler(ON_DEMAND_OPTIONS.SUBSCRIBE);
        break;
      case ON_DEMAND_OPTIONS.PLAY:
        if (isGeoBlocked) {
          toastClickHandler("geoblock");
        } else {
          setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.ON_DEMAND_PLAY};${LINK_INFO.SCHEDULES}`);
          performPlaybackChecks(
            content,
            vodPlaybackType,
            content.contentId,
            PAGE_CONTENT_ITEM_TYPES.vod,
            ON_DEMAND_PLAYER.route,
            null,
            null,
            null,
            null,
            null,
            null,
            is4KPlayRestricted
          );
        }
        break;
      case ON_DEMAND_OPTIONS.RENT:
      case ON_DEMAND_OPTIONS.BUY:
        onItemClick(content);
        break;
      default:
        return null;
    }
  };
  const getActionText = () => {
    switch (content.btnType) {
      case ON_DEMAND_OPTIONS.PLAY: {
        const isInProgress = progressPercentage && !isComplete;
        return content?.channel?.name ? (
          `${translate(isInProgress ? "resume_from" : "play_from")} ${content?.channel?.name}`
        ) : (
          <>
            <div>{translate(isInProgress ? "resume_on_demand" : "play_on_demand")}</div>
            {content?.rentalEndDate && <div>{getAssetExpiryString(content.rentalEndDate)}</div>}
          </>
        );
      }
      case ON_DEMAND_OPTIONS.RENT:
      case ON_DEMAND_OPTIONS.BUY: {
        const isRentTransaction = getPurchaseTransactionType(content.name) === TRANSACTION_TYPES.RENT;
        const orderPrice = getPackagePrice(content) ?? 0;
        return (
          <>
            <div>
              {translate(isRentTransaction ? "rent_for" : "buy_for")} {getFormattedPrice(orderPrice, appLanguage)}
            </div>
            <div>
              {isRentTransaction
                ? translate("order_available_hours").replace("<duration>", DEFAULT_RENTAL_DURATION)
                : translate("order_title")}
            </div>
          </>
        );
      }
      default:
        if (content?.channel?.name) {
          return `${translate("subscribe_to")}${" " + content.channel.name}`;
        } else if (content?.title) {
          return `${translate("subscribe_to")}${" " + content.title}`;
        } else {
          return "";
        }
    }
  };

  const getActionWrapper = () => {
    switch (content.btnType) {
      case ON_DEMAND_OPTIONS.SUBSCRIBE:
        return <ImageButton src={unsubscribedIcon} alt={translate("subscribe")} testID="playIcon" />;
      case ON_DEMAND_OPTIONS.PLAY:
        return (
          <ImageButton
            src={process.env.PUBLIC_URL + "/images/play-icon.svg"}
            alt={translate("play")}
            className="play-icon"
            testID="playIcon"
          />
        );
      case ON_DEMAND_OPTIONS.RENT:
      case ON_DEMAND_OPTIONS.BUY:
        return (
          <ImageButton
            src={`${process.env.PUBLIC_URL}/images/order.svg`}
            alt={translate(content.btnType === ON_DEMAND_OPTIONS.RENT ? "rent" : "buy")}
            className="play-icon order-icon"
            testID="rentIcon"
          />
        );
      default:
        return null;
    }
  };
  return (
    <li>
      <div className="onDemand-wrap">
        <div className="details-wrapper">
          {content?.channel?.image43 && (
            <div className="network-logo">
              <img src={`${content?.channel?.image43}?w=60`} alt={content?.channel?.name} />
            </div>
          )}
          <div className="onDemand-action">{getActionText()}</div>
        </div>
        <div className="action-wrapper" onClick={onClickHandler}>
          {getActionWrapper()}
        </div>
        {progressPercentage > 0 && (
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: convertToPercentageString(progressPercentage) }} />
          </div>
        )}
      </div>
    </li>
  );
};

OnDemandItem.defaultProps = {
  onItemClick: () => {},
  progressPercentage: undefined,
  isComplete: false,
};
export default OnDemandItem;
