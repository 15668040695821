import { createGlobalStyle } from "styled-components";

const webClientColours = {
  darkGreen: "#458B00",
  lightGreen: "#60BF02",
  mutedPurple: "#4E1657",
  red: "#E93A24",
  orange: "#E17A00",
  white: "#FFFFFF",
  offWhite: "#B0B0B0",
  black: "#000000",
  black90: "#00000090",
  black80: "#00000080",
  black60: "#00000060",
  black50: "#00000050",
  dark80: "#0B0B0B80",
  semiMedium80: "#18181880",
  medium80: "#1E1E1E80",
  semiLight80: "#34343480",
  light80: "#37373780",
  extraLight80: "#6F6F6F80",
  white95: "#FFFFFF95",
  white60: "#FFFFFF60",
  white25: "#FFFFFF25",
  white15: "#FFFFFF15",
  white10: "#FFFFFF10",
  purple: "#4B286D",
  pink: "#E53293",
  greyDark: "#0B0B0B",
  greySemiMedium: "#181818",
  greyMedium: "#1E1E1E",
  greySemiLight: "#343434",
  greyLight: "#373737",
  greyExtraLight: "#6F6F6F",
  scarpaFlow: "#59565c",
  strongGreen: "#66cc00",
  sharkGray: "#2a2c2e",
  translucentCloudyGray: "rgba(255, 255, 255, 0.5)",
  lightGray: "#aeaeae",
  translucentWhite: "rgba(255, 255, 255, 0.15)",
  dimGray: "#696969",
  osloGray: "#878b91",
  paleSky: "#7b7b81",
  mineShaftGray: "#232323",
  scheduleBg: "#343434",
  abbeyGray: "#54595f",
  gray: "#b0b0b0",
  offGrey: "#8c8c8c",
  lightOrange: "#ffc145",
  creamyWhite: "#e5e5e5",
  scrollGrey: "#565656",
  doveGray: "#6f6f6f",
};

export const WebClientTheme = {
  colours: {
    focusedButtonBackground: webClientColours.darkGreen,
    focusedRecordingButtonBackground: webClientColours.greyMedium,
    recordingButtonBackground: webClientColours.greySemiLight,
    buttonBackground: webClientColours.darkGreen,
    checkboxChecked: webClientColours.strongGreen,
    white: webClientColours.white,
    red: webClientColours.red,
    black: webClientColours.black,
    sharkGrayBackground: webClientColours.sharkGray,
    translucentCloudyGray: webClientColours.translucentCloudyGray,
    subscribeLink: webClientColours.lightGreen,
    secondaryFontColor: webClientColours.lightGray,
    translucentWhite: webClientColours.translucentWhite,
    dimGray: webClientColours.dimGray,
    secondaryTableFontColor: webClientColours.osloGray,
    paleSky: webClientColours.paleSky,
    mineShaftGray: webClientColours.mineShaftGray,
    scheduleBg: webClientColours.scheduleBg,
    abbeyGray: webClientColours.abbeyGray,
    playerProgressBg: webClientColours.scarpaFlow,
    playerBufferIndicator: webClientColours.gray,
    playerProgressIndicator: webClientColours.strongGreen,
    knobTooltip: webClientColours.mineShaftGray,
    knob: webClientColours.white,
    hoverIndicator: webClientColours.white,
    greyExtraLight: webClientColours.greyExtraLight,
    greyLight: webClientColours.greyLight,
    offWhite: webClientColours.offWhite,
    offGrey: webClientColours.offGrey,
    lightOrange: webClientColours.lightOrange,
    creamyWhite: webClientColours.creamyWhite,
    scrollGrey: webClientColours.scrollGrey,
    orange: webClientColours.orange,
    doveGray: webClientColours.doveGray,
  },

  typography: {
    fontFamily: '"OpenSans"',
    regularFontFamily: '"Open Sans", sans-serif',
    mainHeadingFontFamily: '"OpenSans Bold"',
    fontWeightRegular: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    extraLargeFontSizeHd: "32px",
    largeFontSizeHd: "25px",
    fontSize: "24px",
    largeFontSize: "22px",
    mediumFontSize: "20px",
    mediumFontSizeHd: "normal 22px/26px Open Sans, sans-serif",
    rentButtonFontSize: "19px",
    mediumSwimlaneFontSize: "18px",
    smallFontSwimlane: "16px",
    smallFontSize: "14px",
    smallFontSizeHd: "12px",
    primaryFont: "Bold 24px/29px OpenSans, sans-serif",
    regularFont: "normal 24px/29px Open Sans, sans-serif",
    regularFontSize: "normal 20px/27px Open Sans, sans-serif",
    dropdownPrimaryFont: "Bold 20px/27px Open Sans, sans-serif",
    regularFontHd: "normal 16px/29px Open Sans, sans-serif",
    primaryFontHd: "Bold 16px/29px Open Sans, sans-serif",
  },
};
