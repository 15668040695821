import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./style.scss";

import PopUpOutline from "../PopUpOutline";
import constants from "../../shared/constants";
import recordingConstants from "../../shared/constants/recordingConstants";
import {
  deleteRecording,
  getCPVRRecordingStatus,
  doesCPVREventRecordingExist,
  doesCPVRSeriesRecordingExist,
  navigateToPVRManager,
  isCPVRRecordingInProgress,
  getRecordingSystemType,
} from "../../shared/utils/recordingHelper";
import { useReducers } from "../../shared/hooks/useReducer";
import { deleteRecordingAction } from "../../pages/RecordingsPage/state/actions";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { getAutoGeneratedObject, getAutogeneratedEpisodeString } from "../../shared/utils";
const { RECORDING_PARAMS, RECORDING_PACKAGES } = recordingConstants;
const { REDUCER_TYPE } = constants;

const RecordingModal = ({
  closeModal,
  scheduleRecordingHandler,
  recordingInfo,
  editRecordingHandler,
  deleteRecordingAction,
  openCancelRecordingModal = () => {},
}) => {
  const { t: translate } = useTranslation();
  const { provider: appProvider, userProfile } = useReducers(REDUCER_TYPE.APP);
  const isMR = getRecordingSystemType(userProfile) === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  let recordingEventScheduledCheck, recordingSeriesScheduledCheck, recordingEventConflictCheck;
  let cpvrRecordingStatus, seriesId;
  let isSeriesConflict = false,
    isProgramConflict = false,
    isMREditable = false,
    isCPVREditable = false,
    isRecordingNow = false,
    isConflict = false;

  let episodeInfo = null;
  if (recordingInfo) {
    const autoGeneratedObject = getAutoGeneratedObject(recordingInfo.assetToRecord.metadata);

    if (isMR) {
      recordingEventScheduledCheck = recordingInfo.recordingEventScheduledCheck;
      recordingSeriesScheduledCheck = recordingInfo.recordingSeriesScheduledCheck;
      recordingEventConflictCheck = recordingInfo.recordingEventConflictCheck;

      // Currently recordings in a "conflict" status is specific to MediaRoom. CPVR simply does not create a recording if max concurrent recordings limit is reached
      isSeriesConflict = recordingInfo.recordingSeriesConflictCheck;
      isProgramConflict = recordingEventConflictCheck ? true : false;
      isConflict = isSeriesConflict || isProgramConflict;
      isMREditable = !isConflict && (recordingEventScheduledCheck || recordingSeriesScheduledCheck);
      isRecordingNow = false;
      seriesId = recordingInfo.mediaRoom?.seriesId;
    } else {
      cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
      isCPVREditable =
        doesCPVREventRecordingExist(cpvrRecordingStatus) || doesCPVRSeriesRecordingExist(cpvrRecordingStatus);
      isRecordingNow = isCPVRRecordingInProgress(cpvrRecordingStatus);
      if (recordingInfo.assetToRecord?.metadata) {
        seriesId = recordingInfo.assetToRecord.metadata.seriesId;
      }
    }
    episodeInfo = getAutogeneratedEpisodeString(
      autoGeneratedObject,
      isMR ? recordingInfo : recordingInfo.assetToRecord?.metadata,
      false
    );
  }
  let modalHeaderText, firstCTAText;

  const episodeInfoExists = episodeInfo?.trim().length !== 0;
  if (isMREditable || isCPVREditable) {
    modalHeaderText = translate("edit_recording");
    // As per TCDWC-960, in progress CPVR recordings will not be editable, so instead we allow the user to cancel the recording
    const action = RECORDING_PARAMS[isRecordingNow ? "CANCEL" : "EDIT"];
    firstCTAText = episodeInfoExists ? `${translate(action)} ${episodeInfo}` : translate(`${action}_program`);
  } else if (isConflict) {
    modalHeaderText = translate("recordings_added_waitlist");
    firstCTAText = translate(RECORDING_PARAMS.MANAGE_RECORDINGS);
  } else {
    modalHeaderText = translate("recording_configure");
    firstCTAText = episodeInfoExists
      ? `${translate(RECORDING_PARAMS.RECORD)} ${episodeInfo}`
      : translate(`${RECORDING_PARAMS.RECORD}_program`);
  }

  const manageRecordingHandler = () => {
    navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`);
    closeModal();
  };

  const conflictRecordingTitle = () => {
    const conflictTextHead = translate("recordings_many");
    const conflictText = translate("recordings_reschedule");
    return (
      <>
        <p>{conflictTextHead}</p>
        <p>{conflictText}</p>
      </>
    );
  };

  const eventRecordingHandler = (typeOfRecording) => {
    if (isMREditable || isCPVREditable) {
      // As per TCDWC-960, in progress CPVR recordings will not be editable, so instead we allow the user to cancel the recording
      if (isRecordingNow && typeOfRecording === RECORDING_PARAMS.EVENT) {
        openCancelRecordingModal();
      } else {
        (typeOfRecording === RECORDING_PARAMS.SERIES && recordingSeriesScheduledCheck) ||
        (typeOfRecording === RECORDING_PARAMS.EVENT && isMREditable) ||
        doesCPVRSeriesRecordingExist(cpvrRecordingStatus)
          ? editRecordingHandler(recordingInfo, typeOfRecording)
          : scheduleRecordingHandler(typeOfRecording, recordingInfo);
      }
      closeModal();
    } else if (isConflict) {
      manageRecordingHandler();
    } else {
      scheduleRecordingHandler(typeOfRecording);
      closeModal();
    }
  };

  const closeRecordingModal = () => {
    if (isConflict) {
      const recordingInfoDetails = recordingInfo.recordingSeriesCheck || recordingEventConflictCheck;
      recordingInfoDetails.recordingInfo = recordingInfo;
      deleteRecording(appProvider, true, recordingInfoDetails, null, deleteRecordingAction, true);
    }
    closeModal();
  };

  // Missing POE translation for some keys hence using this method to replace the strings with the desired ones.
  const recordingCTALabelFormatter = (message) => {
    const ctaText = translate(RECORDING_PARAMS.SERIES_TEXT);
    if (message?.includes(ctaText.toLowerCase())) {
      message = message.replace(ctaText.toLowerCase(), ctaText);
    }
    return message;
  };

  const getCTAElements = () => {
    const firstCTA = (
      <button
        className="recording-item-title shaded"
        title={firstCTAText}
        onClick={() => eventRecordingHandler(RECORDING_PARAMS.EVENT)}
      >
        {firstCTAText}
      </button>
    );

    let secondCTA;
    if ((seriesId && !isConflict) || isConflict) {
      let secondCTAMsgKey;
      if (isConflict) {
        secondCTAMsgKey = "recordings_cancel";
      } else if (seriesId && (recordingSeriesScheduledCheck || doesCPVRSeriesRecordingExist(cpvrRecordingStatus))) {
        secondCTAMsgKey = RECORDING_PARAMS.EDIT_SERIES;
      } else {
        secondCTAMsgKey = RECORDING_PARAMS.RECORD_SERIES;
      }

      secondCTA = (
        <button
          className="recording-item-title shaded"
          onClick={() => (isConflict ? closeRecordingModal() : eventRecordingHandler(RECORDING_PARAMS.SERIES))}
        >
          {recordingCTALabelFormatter(translate(secondCTAMsgKey))}
        </button>
      );
    }

    const thirdCTA = (
      <button className="recording-item-title" onClick={() => closeModal()}>
        {translate(isConflict ? "close" : RECORDING_PARAMS.CANCEL)}
      </button>
    );

    return (
      <>
        {firstCTA}
        {secondCTA}
        {thirdCTA}
      </>
    );
  };

  return modalHeaderText && firstCTAText ? (
    <>
      <div className="recording-modal-backdrop" />
      <div className="recording-modal-container">
        <div className="recording-modal-popup-container">
          <PopUpOutline className="recording-modal-popup">
            <div className="recording-type">{modalHeaderText}</div>
            <div className="recording-item">
              <div className="recording-item-details">
                {isConflict ? <div className="recording-subtitle">{conflictRecordingTitle()}</div> : null}
                {getCTAElements()}
              </div>
            </div>
          </PopUpOutline>
        </div>
      </div>
    </>
  ) : null;
};

const mapDispatchToProps = {
  deleteRecordingAction,
};

export default connect(null, mapDispatchToProps)(RecordingModal);
