import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "../Schedules/style.scss";

import recordingConstants from "../../shared/constants/recordingConstants";
import routeConstants from "../../shared/constants/routes";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { convertToPercentageString, calculateLookbackHoursLeft, isPastProgram } from "../../shared/utils";
import ImageButton from "../ImageButton";
import { getRecordingCTAs, getRecordingInfo, getRecordingSystemType } from "../../shared/utils/recordingHelper";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { checkIsStartOverSupported } from "../../shared/utils/epg";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import { getLookbackAvailabilityString } from "../../shared/utils/feedHelper";
import constants from "../../shared/constants";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import { useReducers } from "../../shared/hooks/useReducer";

const { RECORDING_PACKAGES } = recordingConstants;
const { LIVE_PLAYER } = routeConstants;
const { PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE, PLAY } = constants;
const RESTART_ICON_SRC = process.env.PUBLIC_URL + "/images/Restart.svg";
const unsubscribed = process.env.PUBLIC_URL + "/images/key-icon.svg";
const DefaultChannelImage = process.env.PUBLIC_URL + "/images/default-channel-logo.png";

const ScheduleItem = ({ schedule, toastClickHandler, openRecordingModal }) => {
  const { provider: appProvider, isInHome, userProfile, featureToggles } = useReducers(REDUCER_TYPE.APP);
  const { recordingsList, manipulatedRecordingParams } = useReducers(REDUCER_TYPE.RECORDING);
  const { isRestartLiveTVEnabled, isRecordingEnabled } = featureToggles;
  const { t: translate } = useTranslation();

  const { performPlaybackChecks } = usePlaybackChecks();

  const [progress, setProgress] = useState(0);
  const [isChannelSubscribed, setIsChannelSubscribed] = useState(schedule?.isChannelSubscribed ?? false);
  const [isChannelRecordable, setIsChannelRecordable] = useState(schedule?.isChannelRecordable ?? false);
  const isAssetAiringNow = progress > 0 && progress <= 100;
  const currentTime = moment().valueOf();
  const isPastSchedule = isPastProgram(schedule?.metadata?.airingEndTime);
  const lookbackHoursLeft = isPastSchedule ? calculateLookbackHoursLeft(schedule) : 0;
  const regionId = appProvider?.channelMapID;

  useEffect(() => {
    if (schedule) {
      setIsChannelSubscribed(schedule.isChannelSubscribed);
      setIsChannelRecordable(schedule.isChannelRecordable);
    }
    const interval = setInterval(() => {
      let progress = 0;
      if (schedule?.metadata) {
        progress = getLiveProgress(schedule.metadata.airingStartTime, schedule.metadata.airingEndTime);
      }
      setProgress(progress);
    }, 1000);
    return () => clearInterval(interval);
  }, [schedule]);

  const getPlayAction = () => {
    if (schedule && schedule.isPlayable && schedule.channel && schedule.channel?.metadata?.channelId) {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.LIVE_PLAY};${LINK_INFO.SCHEDULES}`);
      performPlaybackChecks(
        null,
        null,
        schedule.channel.metadata.channelId,
        PAGE_CONTENT_ITEM_TYPES.live,
        LIVE_PLAYER.route,
        null,
        null,
        schedule.channel,
        null,
        isPastSchedule ? schedule : null,
        null,
        { type: "", source: PLAY }
      );
    }
  };

  const isMR = useMemo(
    () => getRecordingSystemType(userProfile) === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP,
    [userProfile]
  );
  const showRecordingCTALoading =
    manipulatedRecordingParams && schedule && manipulatedRecordingParams.targetAssetInfo.id === schedule.id;

  // Recording information for this specific schedule
  const recordingInfo = useMemo(() => {
    if (isRecordingEnabled && isChannelSubscribed && isChannelRecordable && recordingsList) {
      return getRecordingInfo(isMR, schedule, recordingsList, regionId);
    }
    return null;
  }, [isRecordingEnabled, isChannelSubscribed, isChannelRecordable, recordingsList, schedule, isMR, regionId]);

  // Recording button CTA details
  const recordingCTA = useMemo(() => {
    if (recordingInfo) {
      let recordingEventSchedule,
        recordingSeriesSchedule,
        recordingSeriesConflictSchedule,
        recordingEventConflictSchedule;
      if (isMR) {
        recordingEventSchedule = recordingInfo.recordingEventScheduledCheck;
        recordingSeriesSchedule = recordingInfo.recordingSeriesScheduledCheck && recordingInfo.recordingSeriesCheck;
        recordingSeriesConflictSchedule =
          !recordingInfo.recordingSeriesScheduledCheck && recordingInfo.recordingSeriesConflictCheck;
        recordingEventConflictSchedule =
          !recordingInfo.recordingEventScheduledCheck && recordingInfo.recordingEventConflictCheck;
      }

      return getRecordingCTAs(
        isMR,
        recordingInfo,
        schedule,
        recordingEventConflictSchedule,
        recordingEventSchedule,
        recordingSeriesConflictSchedule,
        recordingSeriesSchedule
      );
    }
    return null;
  }, [isMR, recordingInfo, schedule]);

  const getRecordingButton = useCallback(() => {
    if (recordingCTA) {
      return (
        <ImageButton
          src={recordingCTA.recordingIcon}
          className="record-icon"
          testID="wallRecordIcon"
          buttonContainerStyles="button-container"
          onClickHandler={(event) => openRecordingModal(event, recordingInfo)}
          alt={recordingCTA.altHead}
        />
      );
    }

    return null;
  }, [openRecordingModal, recordingCTA, recordingInfo]);

  const returnIndicatorIcons = (schedule) => {
    if (isAssetAiringNow) {
      const recordingButtonIcon = recordingCTA?.recordingExists && <img src={recordingCTA.recordingIcon} alt="" />;
      const startOverSupported = checkIsStartOverSupported(schedule?.metadata, schedule?.channel, isInHome);
      const restartIcon =
        isRestartLiveTVEnabled && startOverSupported ? (
          <img className="restart-icon-schedule" key="restart-icon" src={RESTART_ICON_SRC} alt="restartIcon" />
        ) : null;
      return [recordingButtonIcon, restartIcon].filter((item) => !!item);
    }
    return null;
  };

  const playIconClickHandler = () => {
    if (schedule.isBlockedOutOfHome && !isInHome) {
      toastClickHandler("outHome");
    } else {
      getPlayAction();
    }
  };
  const shouldShowPlayButton =
    (isAssetAiringNow || isPastSchedule) &&
    schedule.isChannelSubscribed &&
    !schedule.is4KChannel &&
    schedule.isPlayable;
  return (
    <li>
      <div
        className={
          "schedule-wrap " + (isAssetAiringNow || !schedule.isChannelSubscribed || schedule.isHome ? "live-card" : "")
        }
        onClick={() =>
          schedule?.isChannelSubscribed === false
            ? toastClickHandler("subscribe")
            : schedule.isBlockedOutOfHome && !isInHome
            ? toastClickHandler("outHome")
            : ""
        }
      >
        <div className="channel-logo">
          {schedule.channelImg ? (
            <img src={schedule.channelImg + "?w=76"} alt="" />
          ) : (
            <img src={DefaultChannelImage} alt="" />
          )}
        </div>
        <div>
          <div className="air-info">
            {isPastSchedule ? (
              <span>{translate("restart_live_tv")}</span>
            ) : (
              <>
                <span>{formatScheduleDay(schedule.metadata.airingStartTime, translate)},</span>
                <span>{moment(schedule.metadata.airingStartTime).format("LT")}</span>
              </>
            )}
            <div className="icon-wrapper">{returnIndicatorIcons(schedule)}</div>
          </div>
          <div className="channel-info">
            {isPastSchedule ? (
              <span>{getLookbackAvailabilityString(lookbackHoursLeft)}</span>
            ) : (
              <>
                <span>{getRegionalChannelNumber(schedule.channel, regionId)}</span>
                <span className={schedule.isChannelSubscribed && schedule.isBlockedOutOfHome ? "icon-ooh" : undefined}>
                  {schedule?.channel?.metadata?.channelName}
                  {schedule.isChannelSubscribed && schedule.isBlockedOutOfHome && (
                    <span className="ooh-icon">
                      <img
                        src={
                          isInHome
                            ? process.env.PUBLIC_URL + "/images/In_Home.svg"
                            : process.env.PUBLIC_URL + "/images/Out_of_home.svg"
                        }
                        alt=""
                      />
                    </span>
                  )}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="action-wrapper">
          {shouldShowPlayButton && (
            <ImageButton
              src={process.env.PUBLIC_URL + "/images/play-icon.svg"}
              alt={translate("play")}
              className="live-icon"
              testID="liveIcon"
              onClickHandler={playIconClickHandler}
            />
          )}
          {schedule?.isChannelSubscribed === false && (
            <ImageButton
              src={unsubscribed}
              alt={translate("subscribe")}
              className="unsubscribed-icon"
              testID="unsubscribedIcon"
              onClickHandler={() => toastClickHandler("subscribe")}
            />
          )}
          {!shouldShowPlayButton && recordingCTA ? (
            <div className="recording-wrapper">
              {getRecordingButton()}
              {showRecordingCTALoading && (
                <img src={process.env.PUBLIC_URL + "/images/Rec_Progress.svg"} alt="" className="record-icon-loading" />
              )}
            </div>
          ) : null}
        </div>
        {isAssetAiringNow && (
          <div className="progress-wrapper">
            <span style={{ width: convertToPercentageString(progress) }}></span>
          </div>
        )}
      </div>
    </li>
  );
};

ScheduleItem.defaultProps = {
  openRecordingModal: () => {},
};

export default ScheduleItem;

function getLiveProgress(startTime, endTime) {
  const currentTimeMs = moment().valueOf();
  if (currentTimeMs >= startTime && currentTimeMs <= endTime) {
    return ((currentTimeMs - startTime) / (endTime - startTime)) * 100;
  } else {
    return 0;
  }
}

/**
 * Returns formatted date string
 * @param {Number} airingStartTime
 * @param {Function} translate
 * @returns {String}
 */
const formatScheduleDay = (airingStartTime, translate) => {
  const day = moment(airingStartTime).format("[schedule_date_format]");
  if (day === moment().format("[schedule_date_format]")) {
    return translate("today");
  } else if (day === moment().add(1, "days").format("[schedule_date_format]")) {
    return translate("tomorrow");
  } else {
    return day;
  }
};
