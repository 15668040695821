import React from "react";
import { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";
const crossIcon = `${process.env.PUBLIC_URL}/images/cross-icon.svg`;
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { useTranslation } from "react-i18next";
import { showToastNotification } from "../../App/state/actions";
import { getLocalStorage, setLocalStorage } from "../../shared/utils/localStorage";
import storageConstants from "../../shared/constants/storage";
import epgConstants from "../../shared/constants/epg";
import classNames from "classnames";
import "moment/locale/fr";
import "./style.scss";

const { EPG_SELECTED_DATE, EPG_SELECTED_TIME, EPG_SELECTED_TIME_FORMAT } = storageConstants;
const { AM, PM, DAY, DAYS, HOUR, WEEK, FRENCH_TIME_FORMAT, ENGLISH_TIME_FORMAT, FRENCH_LANG_ABBR, ENGLISH_LANG_ABBR } =
  epgConstants.EPG_CALENDER;

const DateTimePicker = ({ showCalendarPicker, onCloseCalendar, onApply, onReset, epgRange, showToastNotification }) => {
  const { isAppLanguageFrench } = useAppLanguage();
  const { t: translate } = useTranslation();
  const initialDate = moment();
  const initialTime = moment()
    .startOf(HOUR)
    .set({ minutes: initialDate.minute() >= 30 ? "30" : "00" })
    .format(isAppLanguageFrench ? FRENCH_TIME_FORMAT : ENGLISH_TIME_FORMAT);
  const initialIsAM = moment().format("A") === AM;

  const [initialValues] = useState({
    date: initialDate,
    time: initialTime,
    isAM: initialIsAM,
  });

  const storedDate = getLocalStorage(EPG_SELECTED_DATE);
  const storedTime = getLocalStorage(EPG_SELECTED_TIME);
  const storedAMPM = getLocalStorage(EPG_SELECTED_TIME_FORMAT);

  const [selectedDate, setSelectedDate] = useState(storedDate ? moment(storedDate) : initialDate);
  const [time, setTime] = useState(storedTime || initialTime);
  const [isAM, setIsAM] = useState(storedAMPM ? storedAMPM === AM : initialIsAM);

  const timeConstraints = useMemo(
    () => ({
      hours: isAppLanguageFrench ? { min: 0, max: 23 } : { min: 1, max: 12, step: 1 },
      minutes: { step: 30 },
    }),
    [isAppLanguageFrench]
  );

  const shouldEnableReset = useMemo(
    () =>
      time !== initialValues.time ||
      moment(selectedDate).format("ddd MMM D") !== moment(initialDate).format("ddd MMM D") ||
      isAM !== initialIsAM,
    [selectedDate, time, isAM, initialDate, initialIsAM, initialValues.time]
  );

  const handleDateChange = useCallback((newDate) => {
    setSelectedDate(newDate);
  }, []);

  const handleTimeChange = useCallback(
    (newTime) => {
      setTime(newTime.format(isAppLanguageFrench ? FRENCH_TIME_FORMAT : ENGLISH_TIME_FORMAT));
    },
    [isAppLanguageFrench]
  );

  const handleToggleAMPM = useCallback(() => {
    setIsAM((prevIsAM) => !prevIsAM);
  }, []);

  const handleReset = useCallback(() => {
    setSelectedDate(initialDate);
    setTime(initialTime);
    setIsAM(initialIsAM);
    onReset();
  }, [initialDate, initialTime, initialIsAM, onReset]);

  const formattedDate = useMemo(() => {
    return selectedDate && moment(selectedDate).isValid()
      ? moment(selectedDate).format(isAppLanguageFrench ? "ddd D MMM" : "ddd, MMM D")
      : moment().format("ddd, MMM D");
  }, [selectedDate, isAppLanguageFrench]);

  const formattedSelectedTime = useCallback(() => {
    const date = new Date(moment(`${selectedDate.format("YYYY-MM-DD")} ${time}`));
    if (!isAppLanguageFrench) {
      const format = isAM ? AM : PM;
      let hours = date.getHours();

      if (format === PM && hours < 12) {
        hours += 12;
      }
      if (format === AM && hours >= 12) {
        hours -= 12;
      }
      date.setHours(hours);
    }
    return date;
  }, [selectedDate, time, isAM, isAppLanguageFrench]);

  const handleApply = useCallback(() => {
    const selectedDateTime = formattedSelectedTime();
    const startTime = moment(epgRange.start).toDate();
    const endTime = moment(epgRange.end).toDate();

    if (selectedDateTime >= startTime && selectedDateTime <= endTime) {
      const currentYear = moment().year();
      const formattedDateTime = `${moment(selectedDate)
        .format("ddd D MMM YYYY")
        .replace(/\d{4}/, currentYear)} ${time} ${isAM && !isAppLanguageFrench ? AM : isAppLanguageFrench ? "" : PM}`;
      setLocalStorage(EPG_SELECTED_DATE, selectedDate);
      setLocalStorage(EPG_SELECTED_TIME, time);
      setLocalStorage(EPG_SELECTED_TIME_FORMAT, isAM ? AM : PM);
      onApply({ value: formattedDateTime });
    } else {
      showToastNotification(translate("select_future_date"));
    }
  }, [
    selectedDate,
    time,
    isAM,
    onApply,
    isAppLanguageFrench,
    epgRange.start,
    epgRange.end,
    showToastNotification,
    translate,
    formattedSelectedTime,
  ]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const renderView = () => {
    const startDate = moment(epgRange.start);
    const endDate = moment(epgRange.end);
    const threeWeeksEndDate = startDate.clone().add(20, DAYS);

    const days = Array.from({ length: 21 }, (_, i) => {
      const currentDate = startDate.clone().startOf(WEEK).add(i, DAYS);
      const isCurrentDate = currentDate.isSame(moment(), DAY);
      const isSelectedDate = selectedDate && currentDate.isSame(selectedDate, DAY);
      const isWithinThreeWeekRange = currentDate.isBetween(startDate, threeWeeksEndDate, DAY, "[]");
      const isWithinOriginalRange = currentDate.isBetween(startDate, endDate, DAY, "[]");
      const isDisabled = !isWithinThreeWeekRange || !isWithinOriginalRange;

      const dayClassName = classNames("rdtDay", {
        "current-date": isCurrentDate,
        "selected-date": isSelectedDate,
      });

      return (
        <td
          key={currentDate.format("YYYY-MM-DD")}
          onClick={() => !isDisabled && handleDateClick(currentDate)}
          className={`${dayClassName} ${isDisabled ? "disabled" : ""}`}
        >
          {currentDate.date()}
        </td>
      );
    });

    // Split the days into rows of 7 days each (one week per row)
    const weeks = Array.from({ length: Math.ceil(days.length / 7) }, (_, i) => (
      <tr key={i}>{days.slice(i * 7, i * 7 + 7)}</tr>
    ));

    return (
      <table>
        <thead>
          <tr>
            {moment.weekdaysMin(true).map((day, index) => (
              <th key={index}>{day.charAt(0).toUpperCase() + day.slice(1)}</th>
            ))}
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    );
  };

  return (
    <>
      <div className="select-date-text">
        {translate("select_date")}
        <img src={crossIcon} alt="close-calender" onClick={onCloseCalendar} />
      </div>
      <div className="selected-date">{formattedDate}</div>
      <div className="date-picker">
        <Datetime
          open={showCalendarPicker}
          value={selectedDate}
          input={false}
          timeFormat={false}
          renderView={renderView}
          onChange={setSelectedDate}
          locale={isAppLanguageFrench ? FRENCH_LANG_ABBR : ENGLISH_LANG_ABBR}
        />
      </div>
      <div className="line-seprator"></div>
      <span className="select-time-text">{translate("select_time")}</span>
      <div className={classNames("time-picker", { "center-time-picker": isAppLanguageFrench })}>
        <span>
          <Datetime
            open={showCalendarPicker}
            input={false}
            dateFormat={false}
            timeFormat={isAppLanguageFrench ? FRENCH_TIME_FORMAT : ENGLISH_TIME_FORMAT}
            value={time}
            timeConstraints={timeConstraints}
            onChange={handleTimeChange}
            locale={isAppLanguageFrench ? FRENCH_LANG_ABBR : ENGLISH_LANG_ABBR}
          />
        </span>
        {!isAppLanguageFrench && (
          <div className="ampm-container">
            <span className="am-pm-container">
              <span className={classNames("am-label", { active: isAM })} onClick={handleToggleAMPM}>
                {AM}
              </span>
              <span className={classNames("pm-label", { active: !isAM })} onClick={handleToggleAMPM}>
                {PM}
              </span>
            </span>
          </div>
        )}
      </div>
      <div className="line-seprator"></div>
      <div className="calender-button-container">
        <div
          className={classNames("calender-reset-button", { enabled: shouldEnableReset, disabled: !shouldEnableReset })}
          onClick={shouldEnableReset ? handleReset : undefined}
        >
          {translate("reset")}
        </div>
        <div className="calender-apply-button" onClick={handleApply}>
          {translate("apply")}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  showToastNotification,
};

export default connect(null, mapDispatchToProps)(DateTimePicker);
