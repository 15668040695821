/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
/* load core modules end */

/* Styled Components */
const RatingDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.typography.mediumSwimlaneFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 24.3px;
  color: ${({ theme }) => theme.colours.offGrey};
  margin-top: 4px;
`;

const RTScore = styled.span`
  font-size: ${({ theme }) => theme.typography.mediumSwimlaneFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 24.3px;
  color: ${({ theme }) => theme.colours.creamyWhite};
  margin-top: 4px;
`;

const CinocheScore = styled.span`
  font-size: ${({ theme }) => theme.typography.mediumSwimlaneFontSize};
  font-family: ${({ theme }) => theme.typography.regularFontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 24.3px;
  color: ${({ theme }) => theme.colours.lightOrange};
  margin-top: 4px;
`;
/* Styled Components end */

/**
 * Component to show rating detail on more information modal
 * @component
 */
const RatingDetail = ({ icon, score, label, isCinocheRating }) => {
  return (
    <RatingDetailContainer>
      <Label>{label}</Label>
      {isCinocheRating ? (
        <>
          <CinocheScore>{score}</CinocheScore>
          {icon && <img width={18} height={18} src={icon} alt="Rating Icon" />}
        </>
      ) : (
        <>
          {icon && <img width={20} height={20} src={icon} alt="Rating Icon" />}
          <RTScore>{score}</RTScore>
        </>
      )}
    </RatingDetailContainer>
  );
};

RatingDetail.propTypes = {
  icon: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isCinocheRating: PropTypes.bool,
};

export default RatingDetail;
