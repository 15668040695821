import moment from "moment";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import recordingConstants from "../constants/recordingConstants";
import routes from "../../shared/constants/routes";
import errorConstants from "../../shared/constants/error";
import storageConstants from "../constants/storage";
import { getSessionStorage, setSessionStorage } from "./sessionStorage.js";
import i18n from "../../i18n.js";

const {
  RECORDING_PARAMS,
  MR_RECORDING_STATUS,
  MR_RECORDING_DEFINITION_STATUS,
  CPVR_INTERNAL_RECORDING_STATUS,
  CPVR_EXTERNAL_RECORDING_STATUS,
  RECORDING_PACKAGES,
} = recordingConstants;
const { RECORDINGS } = routes;
const { AVS_ERROR_CODES } = errorConstants;
const { RECORDINGS_FILTER_VALUES } = storageConstants;

/**
 * Builds a CPVR recording params object for the schedule API
 *
 * @param {Object} assetToRecord - program info required to schedule a recording
 * @param {String} recordingType - type of recording (event or series)
 * @returns {Object} params for the CPVR schedule API
 */
function getCPVRSetRecordingParams(assetToRecord, recordingType) {
  let recordingParams;
  if (assetToRecord.channel && assetToRecord.metadata) {
    /**
     * When scheduling a recording, the following defaults are applied:
     * isAutoDeletionEnabled = true (maps to the "Keep Until" recording setting)
     * episodeScope = "ALL" (maps to the "Show type" recording setting)
     *
     * isAutoDeletionEnabled is always true for CPVR and cannot be edited by a user
     * episodeScope can be changed by editing the recording while its status = scheduled
     */
    recordingParams = {
      recordingType: recordingType,
      channelId: assetToRecord.channel.channelId || assetToRecord.channel.id,
      isAutoDeletionEnabled: true,
    };

    if (typeof recordingParams.channelId === "string") {
      recordingParams.channelId = parseInt(recordingParams.channelId, 10);
    }

    if (recordingType === RECORDING_PARAMS.EVENT) {
      recordingParams["programId"] = assetToRecord.metadata.programId;
      recordingParams["programStartTime"] = assetToRecord.metadata.airingStartTime;
    } else {
      // This seriesId refers to the program series ID
      recordingParams["seriesId"] = assetToRecord.metadata.seriesId.toString(); //seriesId needs to be String
      recordingParams["episodeScope"] = assetToRecord.episodeScope || "ALL";
    }
  }

  return recordingParams;
}

/**
 * Schedules either a MR or CPVR recording
 *
 * @param {String} type - The type of recording. Can be "event" or "series"
 * @param {Object} appProvider - app provider information
 * @param {Object} assetToRecord - program info required to schedule a recording
 * @param {Function} setRecordingAction - Redux action for scheduling recordings
 * @param {Boolean} isMR - Flag indicating the recording system type
 * @param {Function} dispatch - function to dispatch actions to redux store
 */
export const setRecording = (type, appProvider, assetToRecord, setRecordingAction, isMR, dispatch) => {
  if (type && appProvider && assetToRecord && setRecordingAction) {
    let recordingParams;
    if (isMR) {
      if (assetToRecord.metadata?.extendedMetadata?.epg?.mediaroom && assetToRecord.channel) {
        const mediaRoom = assetToRecord.metadata.extendedMetadata.epg.mediaroom;
        const channelNumber =
          assetToRecord.channel.number ?? getRegionalChannelNumber(assetToRecord.channel, appProvider.channelMapID);

        if (channelNumber) {
          let episodeScope, schedulingTime, seriesId;
          if (type === RECORDING_PARAMS.SERIES) {
            seriesId = mediaRoom.seriesId;
            episodeScope = "ALL";
            schedulingTime = 0;
          }

          recordingParams = {
            recordingType: type,
            channelId: mediaRoom.channelId,
            programId: mediaRoom.programId,
            programStartTime: assetToRecord.metadata.airingStartTime,
            title: assetToRecord.metadata.title,
            channelNumber: channelNumber,
            duration: assetToRecord.metadata.duration,
            seriesId: seriesId,
            episodeScope: episodeScope,
            schedulingTime: schedulingTime,
            isAutoDeletionEnabled: true,
            postPadding: 0,
          };
        }
      }
    } else {
      recordingParams = getCPVRSetRecordingParams(assetToRecord, type);
    }
    if (recordingParams) {
      const dispatchSetRecordingAction = (appProvider, isMR, recordingParams, assetToRecord) => {
        if (dispatch) {
          dispatch(setRecordingAction(appProvider, isMR, recordingParams, assetToRecord));
        } else {
          setRecordingAction(appProvider, isMR, recordingParams, assetToRecord);
        }
      };
      dispatchSetRecordingAction(appProvider, isMR, recordingParams, assetToRecord);
    }
  }
};

/**
 * Function to edit MediaRoom recordings
 *
 * @param {String} recordingType The type of recording. Can be "event" or "series"
 * @param {Object} appProvider App provider information
 * @param {Object} updatedRecordingData Recording data needed for editing a recording
 * @param {Function} editRecordingAction Redux action for editing recordings
 * @param {Function} onEditRecordingFulfilled Callback function to use when edit recording promise is fulfilled
 */
export const editMRRecording = (recordingType, appProvider, updatedRecordingData, editRecordingAction) => {
  if (editRecordingAction && recordingType && updatedRecordingData) {
    const isSeriesRecording = recordingType === RECORDING_PARAMS.SERIES;
    const recordingParams = {
      recordingType: recordingType,
      channelId: updatedRecordingData.channelId,
      programId: updatedRecordingData.programId,
      programStartTime: updatedRecordingData.airingStartTime,
      title: updatedRecordingData.title,
      channelNumber: updatedRecordingData.channelNumber,
      duration: updatedRecordingData.duration,
      seriesId: updatedRecordingData.seriesId ? updatedRecordingData.seriesId : null,
      episodeScope: isSeriesRecording ? updatedRecordingData.episodeScope : null,
      schedulingTime: isSeriesRecording ? updatedRecordingData.schedulingTime : null,
      isAutoDeletionEnabled: updatedRecordingData.isAutoDeletionEnabled,
      postPadding: updatedRecordingData.postPadding,
      recordId: updatedRecordingData.recordGUID,
      isProgramOfSeries: updatedRecordingData.isProgramOfSeries,
      recordingInfo: updatedRecordingData.recordingInfo,
      updatedToFirstRun: updatedRecordingData.updatedToFirstRun,
    };

    editRecordingAction(appProvider, true, recordingParams);
  }
};

/**
 * Function to edit CPVR recordings. BE supports PUT calls only for episode scope value alone
 * so if we have channel and time value then we need to delete the current recording
 * and create a new one with the new settings
 *
 * @param {Object} appProvider App provider information
 * @param {String} recordingType The type of recording. Can be "event" or "series"
 * @param {Object} updatedRecordingData Recording information needed for editing a recording
 * @property {Object} updatedRecordingData.assetToRecord Object with content info required to edit the channel and time setting.
 * @property {Long} updatedRecordingData.currentRecordId ID of the record to be edited
 * @property {Long} updatedRecordingData.currentRecordStartDeltaTime Delta between the program start time and the actual recording start time (from recording item metadata)
 * @param {Object} actions Object containing redux API actions & their callback functions
 * @param {Boolean} isRecordingScheduled
 * @property {Function} actions.setRecordingAction Redux action for setting recordings
 * @property {Function} actions.onSetRecordingFulfilled Callback function to use when set recording promise is fulfilled
 * @property {Function} actions.deleteRecordingAction Redux action for deleting recordings
 */
export const editCPVRRecording = (appProvider, recordingType, updatedRecordingData, actions, isRecordingScheduled) => {
  if (appProvider && recordingType && updatedRecordingData && actions) {
    const assetToRecord = updatedRecordingData.assetToRecord;
    if (assetToRecord) {
      const recordingInfo = {};
      if (recordingType === RECORDING_PARAMS.EVENT) {
        recordingInfo["recordingsToDelete"] = [
          {
            recordId: updatedRecordingData.currentRecordId,
            startDeltaTime: updatedRecordingData.currentRecordStartDeltaTime,
          },
        ];
      } else {
        recordingInfo["recordId"] = updatedRecordingData.currentRecordId;
        recordingInfo["seriesId"] = assetToRecord.metadata.seriesId;
        // Since we are deleting the previous series recording, previously recorded recordings and in-progress recordings will be converted
        // to single event recordings (no recordingSeriesId associated with them)
        recordingInfo["keepRecordedEpisodes"] = true;
      }

      const CPVREditParams = {
        ...getCPVRSetRecordingParams(assetToRecord, recordingType),
        assetToRecord: assetToRecord,
        setRecordingAction: actions.setRecordingAction,
        episodeScopeSeriesId: assetToRecord.onlyEpisodeScopeUpdated ? updatedRecordingData.currentRecordId : null, // PUT call series id value
      };

      deleteRecording(
        appProvider,
        false,
        recordingInfo,
        recordingType,
        actions.deleteRecordingAction,
        isRecordingScheduled,
        CPVREditParams
      );
    }
  }
};

/**
 * Used to stop an in progress CPVR recording and keep the recorded portion.
 *
 * @param {Object} appProvider - App provider information
 * @param {Object} recordingInfo - Recording info object. Please refer to getRecordingInfo() for details
 * @param {Function} editRecordingAction - Redux action for editing recordings
 */
export const stopCPVRRecording = (appProvider, recordingInfo, editRecordingAction) => {
  if (appProvider && recordingInfo?.eventRecordingItem?.metadata && editRecordingAction) {
    const recordingParams = {
      recordId: recordingInfo.eventRecordingItem.metadata.contentId,
      recordingType: RECORDING_PARAMS.EVENT,
      startDeltaTime: recordingInfo.eventRecordingItem.metadata.startDeltaTime,
      recordingStartTime: recordingInfo.eventRecordingItem.metadata.recordingStartTime,
      recordingInfo,
    };

    editRecordingAction(appProvider, false, recordingParams, true);
  }
};

/**
 * Used to cancel scheduled and delete recorded recordings for both MR and CPVR
 *
 * @param {Object} appProvider - App provider information
 * @param {Boolean} isMR - Flag indicating the recording system type
 * @param {Object} recordingInfo - Dynamic object with info required to delete recordings
 * @param {String} recordingType - The type of recording. Can be "event" or "series"
 * @param {Function} deleteRecordingAction - Redux action for deleting/cancelling recordings
 * @param {Boolean} isRecordingScheduled - If true the recording is scheduled, else the recording is recorded
 * @param {Object} CPVREditParams - Object with params for the CPVR set recording API. This is required when editing a CPVR recording, but unused in all other scenarios
 */
export const deleteRecording = (
  appProvider,
  isMR,
  recordingInfo,
  recordingType,
  deleteRecordingAction,
  isRecordingScheduled,
  CPVREditParams
) => {
  if (recordingInfo && deleteRecordingAction) {
    let recordingParams;
    let recordingArray = [];
    if (isMR) {
      let episodeRecordings = null;
      if (recordingType === RECORDING_PARAMS.SERIES && (recordingInfo.scheduledItems || recordingInfo.recordedItems)) {
        if (isRecordingScheduled && recordingInfo.scheduledItems) {
          episodeRecordings = recordingInfo.scheduledItems;
        } else if (recordingInfo.recordedItems) {
          episodeRecordings = recordingInfo.recordedItems;
        }
      } else {
        episodeRecordings = recordingInfo.recordings;
      }
      if (episodeRecordings?.length > 0) {
        for (let i = 0; i < episodeRecordings.length; i++) {
          const recordings = episodeRecordings[i];
          const data = {
            recordingGuid: recordings.recordGUID,
            programStartTime: recordings.programStartTime,
            duration: recordings.duration,
          };
          recordingArray.push(data);
        }
      } else if (recordingInfo.recordGUID) {
        const data = {
          recordingGuid: recordingInfo.recordGUID,
          programStartTime: recordingInfo.programStartTime,
          duration: recordingInfo.duration,
        };
        recordingArray.push(data);
      }
      recordingParams = {
        // recordingdefinitionGuid: not in CamelCase as mentioned in the docs
        recordingdefinitionGuid: !recordingInfo.isProgramOfSeries ? recordingInfo.recordingDefinitionId : "",
        action: isRecordingScheduled ? "cancel" : "delete",
        recordings: recordingArray,
      };
    } else {
      if (recordingType) {
        if (recordingType === RECORDING_PARAMS.EVENT) {
          if (recordingInfo.recordingsToDelete?.length > 0) {
            recordingParams = {
              recordingsToDelete: recordingInfo.recordingsToDelete,
            };
          }
        } else {
          if (isRecordingScheduled) {
            recordingParams = {
              seriesIds: [recordingInfo.recordId], // These series IDs refer to recording series IDs
              keepRecordedEpisodes: recordingInfo.keepRecordedEpisodes, // Should be true if cancelling scheduled recordings, false if deleting recorded recordings
            };
          } else {
            const episodeRecordings = recordingInfo?.recordingInfo?.seriesRecordingItem?.episodeRecordings;
            if (episodeRecordings?.length > 0) {
              const recordingDeletePayload = [];
              for (let i = 0; i < episodeRecordings.length; i++) {
                const recording = episodeRecordings[i];
                const recordingData = {
                  recordId: recording.metadata?.contentId,
                  startDeltaTime: recording.metadata?.startDeltaTime,
                };
                recordingDeletePayload.push(recordingData);
              }
              recordingParams = { recordingDeletePayload };
            }
          }
        }
      }
    }
    if (recordingParams) {
      recordingParams["recordingType"] = recordingType;
      recordingParams["recordingInfo"] = isMR ? recordingInfo : recordingInfo.recordingInfo;
      deleteRecordingAction(appProvider, isMR, recordingParams, CPVREditParams);
    }
  }
};

export const getRecordingCTAs = (
  isMR,
  recordingInfo,
  assetToRecord,
  recordingEventConflictSchedule,
  recordingEventSchedule,
  recordingSeriesConflictSchedule,
  recordingSeriesSchedule,
  defaultToSeries = false,
  isLivePlayer = false
) => {
  const seriesConflictIconURI = process.env.PUBLIC_URL + "/images/Series_Recording_Conflict.svg";
  const eventConflictIconURI = process.env.PUBLIC_URL + "/images/Recording_Conflict_mini_epg.svg";
  const seriesRecordingExistsIconURI = process.env.PUBLIC_URL + "/images/Series_Recording_Set.svg";
  const eventRecordingExistsIconURI = process.env.PUBLIC_URL + "/images/mini_epg_set.svg";
  const seriesRecordingNotSetIconURI = process.env.PUBLIC_URL + "/images/record_series.svg";
  const eventRecordingNotSetIconURI = process.env.PUBLIC_URL + "/images/set_recording.svg";
  const recordingParams = {};
  recordingParams.recordingExists = false;
  if (isMR) {
    if (recordingSeriesConflictSchedule) {
      // Series recording conflict (conflict series icon)
      recordingParams.recordingIcon = seriesConflictIconURI;
      recordingParams.recordingStatus = null;
      recordingParams.altHead = i18n.t(RECORDING_PARAMS.EDIT_REC);
      recordingParams.actionType = RECORDING_PARAMS.EDIT_REC;
      recordingParams.recordingExists = true;
    } else if (recordingEventConflictSchedule) {
      // Event recording conflict (conflict event icon)
      recordingParams.recordingIcon = eventConflictIconURI;
      recordingParams.recordingStatus = recordingInfo?.recordingEventConflictCheck;
      recordingParams.altHead = assetToRecord && i18n.t(RECORDING_PARAMS.EDIT_REC);
      recordingParams.actionType = assetToRecord && RECORDING_PARAMS.EDIT_REC;
      recordingParams.recordingExists = true;
    } else if (recordingSeriesSchedule) {
      // Series recording exists (filled series icon)
      recordingParams.recordingIcon = seriesRecordingExistsIconURI;
      recordingParams.recordingStatus = null;
      recordingParams.altHead = i18n.t(RECORDING_PARAMS.EDIT_REC);
      recordingParams.actionType = RECORDING_PARAMS.EDIT_REC;
      recordingParams.recordingExists = true;
    } else if (recordingEventSchedule) {
      // Event recording exists (filled event icon)
      recordingParams.recordingIcon = eventRecordingExistsIconURI;
      recordingParams.recordingStatus = recordingInfo?.recordingEventScheduledCheck;
      recordingParams.altHead = assetToRecord && i18n.t(RECORDING_PARAMS.EDIT_REC);
      recordingParams.actionType = assetToRecord && RECORDING_PARAMS.EDIT_REC;
      recordingParams.recordingExists = true;
    } else if (defaultToSeries && !recordingSeriesSchedule) {
      // Series recording doesn't exists (unfilled series icon)
      recordingParams.recordingIcon = seriesRecordingNotSetIconURI;
      recordingParams.altHead = i18n.t(RECORDING_PARAMS.REC_SERIES);
      recordingParams.actionType = RECORDING_PARAMS.REC_SERIES;
    } else {
      // Event recording doesn't exist (unfilled event icon)
      recordingParams.recordingIcon = eventRecordingNotSetIconURI;
      recordingParams.altHead = i18n.t(RECORDING_PARAMS.RECORD);
      recordingParams.actionType = RECORDING_PARAMS.RECORD;
    }
  } else {
    if (recordingInfo) {
      const status = getCPVRRecordingStatus(recordingInfo, defaultToSeries);
      if (doesCPVRSeriesRecordingExist(status)) {
        if (
          isCPVRSeriesEpisodeRecording(status) &&
          !isCPVRRecordingInProgress(status) &&
          !isCPVRRecordingScheduled(status)
        ) {
          // Partial recordings can exist while an episode is airing, but we would still want the user to be able to begin recording
          // from a new point in time (meaning we can have multiple partial recordings for one episode, even while its still airing)
          recordingParams.recordingIcon = eventRecordingNotSetIconURI;
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.RECORD);
          recordingParams.actionType = RECORDING_PARAMS.RECORD;
        } else {
          // If we have a scheduled/in progress episode recording created as part of a series recording or if we only have a series recording, use the series UI
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.EDIT_SERIES);
          recordingParams.actionType = RECORDING_PARAMS.EDIT_SERIES;
          recordingParams.recordingIcon = seriesRecordingExistsIconURI;
          recordingParams.recordingExists = true;
        }
      } else if (doesCPVREventRecordingExist(status)) {
        // If we have a single event recording
        if (isCPVRRecordingInProgress(status)) {
          // In progress recordings are cancellable, but not editable
          recordingParams.altHead = isLivePlayer
            ? i18n.t(RECORDING_PARAMS.EDIT_REC)
            : i18n.t(RECORDING_PARAMS.CANCEL_REC);
          recordingParams.actionType = isLivePlayer ? RECORDING_PARAMS.EDIT_REC : RECORDING_PARAMS.CANCEL_REC;
          recordingParams.recordingIcon = eventRecordingExistsIconURI;
          recordingParams.recordingExists = true;
        } else if (isCPVRRecordingScheduled(status)) {
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.EDIT_REC);
          recordingParams.actionType = RECORDING_PARAMS.EDIT_REC;
          recordingParams.recordingIcon = eventRecordingExistsIconURI;
          recordingParams.recordingExists = true;
        } else {
          // Partial recordings can exist while a program is airing, but we would still want the user to be able to begin recording
          // from a new point in time (meaning we can have multiple partial recordings for one program, even while its still airing)
          recordingParams.recordingIcon = eventRecordingNotSetIconURI;
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.RECORD);
          recordingParams.actionType = RECORDING_PARAMS.RECORD;
        }
      } else {
        // If we don't have any event or series recording
        if (status === CPVR_INTERNAL_RECORDING_STATUS.SERIES_DOES_NOT_EXIST) {
          recordingParams.recordingIcon = seriesRecordingNotSetIconURI;
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.RECORD_SERIES);
          recordingParams.actionType = RECORDING_PARAMS.RECORD_SERIES;
        } else {
          recordingParams.recordingIcon = eventRecordingNotSetIconURI;
          recordingParams.altHead = i18n.t(RECORDING_PARAMS.RECORD);
          recordingParams.actionType = RECORDING_PARAMS.RECORD;
        }
      }
    }
  }
  return recordingParams;
};

/**
 * Determines the internal CPVR recording status for a specific program.
 *
 * @param {Object} recordingInfo RecordingInfo object for a specific program
 * @property {Object} recordingInfo.eventRecordingItem The EVENT recording object associated with the specific program
 * @property {Array} recordingInfo.seriesRecordingItem The SERIES recording object associated with the specific program
 * @param {Boolean} defaultToSeries Flag used to determine which "doesn't exist" status to use (SERIES vs EVENT)
 * @returns CPVR_INTERNAL_RECORDING_STATUS string if status could be determined, undefined otherwise
 */
export const getCPVRRecordingStatus = (recordingInfo, defaultToSeries = false) => {
  let status;
  if (recordingInfo) {
    const eventRecordingItem = recordingInfo.eventRecordingItem;
    const seriesRecordingItem = recordingInfo.seriesRecordingItem;
    if (eventRecordingItem?.metadata && !defaultToSeries) {
      const currentTime = moment().valueOf();
      // User recording start and end times calculated as per the notes in https://telusvideoservices.atlassian.net/wiki/spaces/AD/pages/1680998507/Metadata+for+NPVR#:~:text=of%202%20minutes-,startDeltaTime,-Integer
      const userRecordingStartTime =
        eventRecordingItem.metadata.recordingStartTime + eventRecordingItem.metadata.startDeltaTime;
      const userRecordingEndTime =
        eventRecordingItem.metadata.recordingStartTime + eventRecordingItem.metadata.stopDeltaTime;

      if (userRecordingStartTime <= currentTime && currentTime <= userRecordingEndTime) {
        // In Progress
        status =
          eventRecordingItem.metadata.recordingSeriesId && seriesRecordingItem
            ? CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_IN_PROGRESS
            : CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_IN_PROGRESS;
      } else if (userRecordingEndTime <= currentTime) {
        // Recorded
        status = eventRecordingItem.metadata.recordingSeriesId
          ? CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_RECORDED
          : CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_RECORDED;
      } else {
        // Scheduled
        status = eventRecordingItem.metadata.recordingSeriesId
          ? CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_SCHEDULED
          : CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_SCHEDULED;
      }
    } else if (seriesRecordingItem && defaultToSeries) {
      // In this case we know we scheduled a SERIES recording, but we haven't found an EVENT recording that is linked to the SERIES recording
      status = CPVR_INTERNAL_RECORDING_STATUS.SERIES_RECORDING_SCHEDULED;
    } else {
      if (defaultToSeries) {
        // No recording exists for this SERIES
        status = CPVR_INTERNAL_RECORDING_STATUS.SERIES_DOES_NOT_EXIST;
      } else {
        // No recording exists for this EVENT
        status = CPVR_INTERNAL_RECORDING_STATUS.EVENT_DOES_NOT_EXIST;
      }
    }
  }

  return status;
};

/**
 * Determines if a series recording exists using the provided CPVR recording status.
 * @param {String} recordingStatus CPVR recording status string
 * @returns {Boolean} True if the recording status is from a series recording that is scheduled, in progress, or recorded
 */
export const doesCPVRSeriesRecordingExist = (recordingStatus) => {
  return (
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_IN_PROGRESS ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_RECORDED
  );
};

/**
 * Determines if an event recording exists using the provided CPVR recording status.
 * @param {String} recordingStatus CPVR recording status string
 * @returns {Boolean} True if the recording status is from an event recording that is scheduled, in progress, or recorded
 */
export const doesCPVREventRecordingExist = (recordingStatus) => {
  return (
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_IN_PROGRESS ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_RECORDED
  );
};

/**
 * Determines if a CPVR recording item is considered recorded.
 * Note: We consider in progress recordings as "recorded".
 * @param {String} recordingStatus CPVR recording status string
 * @returns {Boolean} True if the event/series recording is recorded/in progress
 */
export const isCPVRRecordingRecorded = (recordingStatus) => {
  return (
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_RECORDED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_RECORDED ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.RECORD_SUCCESS ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.INCOMPLETE
  );
};

/**
 * Determines if a CPVR recording item is considered scheduled.
 *
 * @param {String} recordingStatus CPVR recording status string
 * @returns {Boolean} True if the event/series recording is scheduled
 */
export const isCPVRRecordingScheduled = (recordingStatus) => {
  return (
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.SCHEDULING ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.RESCHEDULING ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.SCHEDULE_SUCCESS ||
    recordingStatus === CPVR_EXTERNAL_RECORDING_STATUS.RESCHEDULE_SUCCESS
  );
};

/**
 * Determines if a CPVR recording is currently in progress. You can pass in either a
 * CPVR recording status or an EVENT recording item.
 *
 * @param {(String|Object)} input CPVR recording status or an EVENT recording item
 * @returns {Boolean} True if the EVENT/SERIES recording is in progress
 */
export const isCPVRRecordingInProgress = (input) => {
  let result = false;
  if (input) {
    const inputType = typeof input;
    if (inputType === "string") {
      result =
        input === CPVR_INTERNAL_RECORDING_STATUS.EVENT_RECORDING_IN_PROGRESS ||
        input === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_IN_PROGRESS;
    } else if (inputType === "object") {
      if (input.metadata) {
        const currentTime = moment().valueOf();
        // User recording start and end times calculated as per the notes in https://telusvideoservices.atlassian.net/wiki/spaces/AD/pages/1680998507/Metadata+for+NPVR#:~:text=of%202%20minutes-,startDeltaTime,-Integer
        const userRecordingStartTime = input.metadata.recordingStartTime + input.metadata.startDeltaTime;
        const userRecordingEndTime = input.metadata.recordingStartTime + input.metadata.stopDeltaTime;
        result = userRecordingStartTime <= currentTime && currentTime <= userRecordingEndTime;
      }
    } else {
      console.debug("[isCPVRRecordingInProgress] - Unsupported input type");
    }
  }

  return result;
};

/**
 * @param {String} recordingStatus CPVR recording status string
 * @returns True if the status is one of the statuses for an episode recording that was created as part of a series recording
 */
export const isCPVRSeriesEpisodeRecording = (recordingStatus) => {
  return (
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_SCHEDULED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_RECORDED ||
    recordingStatus === CPVR_INTERNAL_RECORDING_STATUS.SERIES_EPISODE_RECORDING_IN_PROGRESS
  );
};

/**
 * If the user has recordings enabled, this function checks the user profile's package list for CPVR and MediaRoom packages.
 * If both packages are found, CPVR is prioritized. If no packages are found, the user does not have access to any recording features.
 *
 * @param {Object} userProfile A user profile object
 * @returns A string denoting the recording system type or undefined if the user doesn't have access to recordings
 *  Values can be:
 *   - "CPVR_TP" if user has the CPVR technical package available
 *   - "LPVRMediaroom_TP" if the user has the MediaRoom LPVR technical package available
 *   - undefined if neither CPVR or LPVR are available or if the recordings feature has been disabled for the user
 */
export const getRecordingSystemType = (userProfile) => {
  let systemType;
  if (userProfile?.user?.profile?.profileData?.packageList?.length > 0) {
    for (let i = 0; i < userProfile.user.profile.profileData.packageList.length; i++) {
      const userPackage = userProfile.user.profile.profileData.packageList[i];
      if (userPackage.packageType === RECORDING_PACKAGES.PACKAGE_TYPE.NPVR) {
        if (userPackage.packageName === RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP) {
          systemType = RECORDING_PACKAGES.PACKAGE_NAME.CPVR_TP;
          break;
        } else if (userPackage.packageName === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP) {
          systemType = RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;
        }
      }
    }
  }

  return systemType;
};

// Checks and returns the corresponding recording 'event' object which is not conflicted.
const getRecordingEventCheck = (recordingDefinitions, mediaRoom, recordingAsset) => {
  if (recordingDefinitions?.length > 0 && mediaRoom && recordingAsset?.metadata) {
    return recordingDefinitions.find(
      (recordingDefinition) =>
        recordingDefinition.recordingDefinitionStatus !== MR_RECORDING_STATUS.CANCELLED &&
        recordingDefinition.recordingType === RECORDING_PARAMS.EVENT.toUpperCase() &&
        recordingDefinition.recordings?.length > 0 &&
        recordingDefinition.channelId === mediaRoom.channelId &&
        recordingDefinition.title === recordingAsset.metadata.title &&
        recordingDefinition.recordings[0].programStartTime === recordingAsset.metadata.airingStartTime &&
        recordingDefinition.recordings[0].status !== MR_RECORDING_STATUS.CONFLICTED &&
        recordingDefinition.recordings[0].status !== MR_RECORDING_STATUS.CANCELLED
    );
  }
};

// Checks and returns the corresponding recording 'event' object which is getting recorded now.
const getRecordingNowEventCheck = (recordingDefinitions, mediaRoom, recordingAsset) => {
  if (recordingDefinitions?.length > 0 && mediaRoom && recordingAsset?.metadata) {
    return recordingDefinitions.find((recordingDefinition) => {
      let result = false;
      if (
        recordingDefinition?.recordings[0] &&
        recordingDefinition.recordingDefinitionStatus !== MR_RECORDING_STATUS.CANCELLED
      ) {
        const recording = recordingDefinition.recordings[0];
        // Using client side calculation to determine if recording is recording now since the LPVR system sucks
        const currentTime = moment().valueOf();
        // The duration field supposedly includes the post padding
        const isRecordingNow =
          recording.status !== MR_RECORDING_STATUS.RECORDED &&
          recording.status !== MR_RECORDING_STATUS.CANCELLED &&
          recording.programStartTime <= currentTime &&
          currentTime <= recording.programStartTime + recording.duration * 1000;

        result =
          recordingDefinition.recordingType === RECORDING_PARAMS.EVENT.toUpperCase() &&
          recordingDefinition.channelId === mediaRoom.channelId &&
          recordingDefinition.title === recordingAsset.metadata.title &&
          recordingDefinition.recordings[0].programStartTime === recordingAsset.metadata.airingStartTime &&
          isRecordingNow;
      }

      return result;
    });
  }
};

// Checks and returns the corresponding recording 'event' object which is conflicted.
const getRecordingEventConflictCheck = (recordingDefinitions, mediaRoom, recordingAsset, regionalChannelNumber) => {
  if (recordingDefinitions?.length > 0 && mediaRoom && recordingAsset?.metadata) {
    return recordingDefinitions.find(
      (recordingDefinition) =>
        recordingDefinition.recordings?.length > 0 &&
        recordingDefinition.recordingType === RECORDING_PARAMS.EVENT.toUpperCase() &&
        recordingDefinition.channelNumber === regionalChannelNumber &&
        recordingDefinition.recordings[0].programStartTime === recordingAsset.metadata.airingStartTime &&
        recordingDefinition.title === recordingAsset.metadata.title &&
        recordingDefinition.recordingDefinitionStatus === MR_RECORDING_DEFINITION_STATUS.KNOWN_SCHEDULE &&
        recordingDefinition.recordings[0]?.status === MR_RECORDING_STATUS.CONFLICTED
    );
  }
};

// Checks and returns the corresponding recording 'series' object in question.
const getRecordingSeriesCheck = (recordingDefinitions, mediaRoom, recordingAsset, regionalChannelNumber) => {
  if (recordingDefinitions?.length > 0 && mediaRoom && recordingAsset?.metadata) {
    return recordingDefinitions.find((recordingDefinition) => {
      return (
        recordingDefinition.recordingDefinitionStatus !== MR_RECORDING_STATUS.CANCELLED &&
        recordingDefinition.recordingType === RECORDING_PARAMS.SERIES.toUpperCase() &&
        recordingDefinition.recordings?.length > 0 &&
        recordingDefinition.channelNumber === regionalChannelNumber &&
        recordingDefinition.title === recordingAsset.metadata.title &&
        recordingDefinition.recordings.find((recording) => {
          if (recording) {
            return (
              recording.seriesId === mediaRoom.seriesId &&
              recording.programStartTime === recordingAsset.metadata.airingStartTime
            );
          } else {
            return false;
          }
        })
      );
    });
  }
};

// Creates a recordingInfo object containing the recording data('series' or 'event') and the mediaroom metadata used for setting a recording.
export const getRecordingInfo = (isMR, assetToRecord, recordingsList, channelMapId, defaultToSeries = false) => {
  let recordingInfo = {};
  if (assetToRecord && recordingsList) {
    if (isMR) {
      recordingInfo.assetToRecord = assetToRecord;
      const recordingDefinitions = recordingsList;
      const currentTime = moment().valueOf();
      let mediaRoom, recordingAssetExtMetadata;
      const recordingAssetMetadata = assetToRecord.metadata;
      if (recordingAssetMetadata) {
        recordingInfo.title = recordingAssetMetadata.title;
        recordingInfo.duration = recordingAssetMetadata.duration;
        recordingInfo.seasonNumber = recordingAssetMetadata.season;
        recordingInfo.episodeNumber = recordingAssetMetadata.episodeId;
        recordingAssetExtMetadata = recordingAssetMetadata.extendedMetadata;
        mediaRoom = recordingAssetExtMetadata?.epg?.mediaroom;
        recordingInfo.airingStartTime = recordingAssetMetadata.airingStartTime;
      }

      recordingInfo.mediaRoom = mediaRoom;
      let recordingChannel;
      if (assetToRecord.channel) {
        recordingChannel = assetToRecord.channel?.item ? assetToRecord.channel.item : assetToRecord.channel;
        recordingInfo.channelName = assetToRecord.channel?.channelName ?? assetToRecord.channel?.metadata?.channelName;
        recordingInfo.callLetter = assetToRecord.channel?.callLetter ?? assetToRecord.channel?.metadata?.callLetter;
        recordingInfo.channelNumber =
          recordingChannel.number ?? getRegionalChannelNumber(recordingChannel, channelMapId);
      }

      let isSpecificRecordingRecorded = false;
      if (recordingDefinitions?.length > 0 && mediaRoom) {
        recordingInfo.recordingEventScheduledCheck = getRecordingEventCheck(
          recordingDefinitions,
          mediaRoom,
          assetToRecord
        );
        recordingInfo.recordingNowEventCheck = getRecordingNowEventCheck(
          recordingDefinitions,
          mediaRoom,
          assetToRecord
        );
        recordingInfo.recordingEventConflictCheck = getRecordingEventConflictCheck(
          recordingDefinitions,
          mediaRoom,
          assetToRecord,
          recordingInfo.channelNumber
        );
        recordingInfo.recordingSeriesCheck = getRecordingSeriesCheck(
          recordingDefinitions,
          mediaRoom,
          assetToRecord,
          recordingInfo.channelNumber
        );

        // Note: You may see the status as "scheduled" even if you're recording a currently airing program. MR recordings will only begin recording if the
        // account has a set to box associated with it and that set top box is running.
        if (recordingInfo.recordingEventScheduledCheck) {
          const recording = recordingInfo.recordingEventScheduledCheck.recordings[0];
          if (recording) {
            isSpecificRecordingRecorded = recording.programStartTime <= currentTime;
          }
        }
      }

      if (recordingInfo.recordingSeriesCheck?.recordings) {
        let isAnyEpisodeRecordingNow = false,
          isSpecificRecordingScheduled = false,
          isSpecificRecordingConflicted = false;
        let nonCancelledEpisodesList = [];
        for (let i = 0; i < recordingInfo.recordingSeriesCheck.recordings.length; i++) {
          const recording = recordingInfo.recordingSeriesCheck.recordings[i];
          const recordingEndTime = recording.programStartTime + recording.duration * 1000;

          // Using client side calculation to determine if recording is scheduled, recording now, or recorded since the LPVR system sucks.
          // We will still use the server response status for other recording states
          let recordingState = null;
          if (recording.status !== MR_RECORDING_STATUS.CANCELLED) {
            if (recording.programStartTime <= currentTime && currentTime <= recordingEndTime) {
              recordingState = MR_RECORDING_STATUS.RECORDING;
            } else if (currentTime <= recordingEndTime) {
              recordingState = MR_RECORDING_STATUS.SCHEDULED;
            } else {
              recordingState = MR_RECORDING_STATUS.RECORDED;
            }
          }

          isAnyEpisodeRecordingNow = isAnyEpisodeRecordingNow || recordingState === MR_RECORDING_STATUS.RECORDING;

          if (recording.status !== MR_RECORDING_STATUS.CANCELLED) {
            nonCancelledEpisodesList.push(recording);
          }

          const isChannelIdSame = recordingInfo.recordingSeriesCheck.channelId === mediaRoom?.channelId;
          const isProgramAirTimeSame = recording.programStartTime === assetToRecord.metadata?.airingStartTime;

          if (isChannelIdSame && isProgramAirTimeSame) {
            /**
             * We will consider a recording as scheduled even if it is recording now.
             * Because as per our implementation the asset that is recording now should have a filled recording icon.
             * and if we try to edit such recording, the user is shown with the cancel option. Refer to RecordingSetting index.js for detailed info.
             */
            isSpecificRecordingScheduled =
              recordingState === MR_RECORDING_STATUS.SCHEDULED || recordingState === MR_RECORDING_STATUS.RECORDING;
            isSpecificRecordingConflicted = recording.status === MR_RECORDING_STATUS.CONFLICTED;
            isSpecificRecordingRecorded =
              recording.status === MR_RECORDING_STATUS.RECORDED || recordingState === MR_RECORDING_STATUS.RECORDED;
          }
        }

        // True if the asset in question is in the scheduled state
        recordingInfo.recordingSeriesScheduledCheck = isSpecificRecordingScheduled;
        // True if any episode in the series recording is recording now
        recordingInfo.recordingNowSeriesCheck = isAnyEpisodeRecordingNow;
        // True if the asset in question is in the conflicted state
        recordingInfo.recordingSeriesConflictCheck = isSpecificRecordingConflicted;
        // Removes all cancelled episodes from the series recording
        recordingInfo.recordingSeriesCheck.recordings = nonCancelledEpisodesList;
      }

      recordingInfo.isRecordingNow =
        recordingInfo.recordingNowSeriesCheck || recordingInfo.recordingNowEventCheck ? true : false;
      recordingInfo.isSpecificRecordingRecorded = isSpecificRecordingRecorded;
      recordingInfo.assetToRecord = assetToRecord;
    } else {
      const recordingResponseArray = recordingsList;
      let eventRecordingsList = [],
        seriesRecordingsList = [],
        eventRecordingItem,
        seriesRecordingItem;

      if (recordingResponseArray?.length > 0) {
        for (const response of recordingResponseArray) {
          if (response.recordingType === RECORDING_PARAMS.EVENT) {
            eventRecordingsList = response.containers || [];
          } else {
            seriesRecordingsList = response.containers || [];
          }
        }
      }

      if (assetToRecord.metadata && assetToRecord.channel) {
        // Additional check added as we have 'channel.id' object as string from EPG 'assetToRecord'.
        const assetChannelId = assetToRecord.channel.channelId || parseInt(assetToRecord.channel.id);
        const currentTime = moment().valueOf();
        // Try to find an EVENT recording that matches the assetToRecord
        eventRecordingItem = eventRecordingsList.find((recording) => {
          if (recording.metadata && recording.channel) {
            // Now that partial recordings exist, we may have multiple EVENT recordings that match the assetToRecord.
            // The getRecordingInfo() function is used throughout the app in places that only really care about in progress and scheduled recordings, so
            // filtering out items where the recording end time has already passed should work.
            // User recording end time calculated as per the notes in https://telusvideoservices.atlassian.net/wiki/spaces/AD/pages/1680998507/Metadata+for+NPVR#:~:text=of%202%20minutes-,startDeltaTime,-Integer
            const userRecordingEndTime = recording.metadata.recordingStartTime + recording.metadata.stopDeltaTime;
            return (
              recording.metadata.programId === assetToRecord.metadata.programId &&
              recording.channel.channelId === assetChannelId &&
              recording.metadata.programStartTime === assetToRecord.metadata.airingStartTime &&
              userRecordingEndTime > currentTime
            );
          }
          return false;
        });

        const recordingSeriesId = eventRecordingItem?.metadata?.recordingSeriesId;

        if (recordingSeriesId && !defaultToSeries) {
          seriesRecordingItem = seriesRecordingsList.find((seriesRecording) => {
            if (seriesRecording.metadata) {
              return recordingSeriesId && recordingSeriesId === seriesRecording.metadata.recordingSeriesId;
            }
            return false;
          });
        } else if (assetToRecord.metadata.extendedMetadata?.dlum) {
          seriesRecordingItem = seriesRecordingsList.find((seriesRecording) => {
            if (seriesRecording.metadata) {
              // defaultToSeries check added for series detail page where we are checking only uaGroupId present in any series recordings.
              if (defaultToSeries) {
                return (
                  seriesRecording.metadata.uaSeriesId === assetToRecord.metadata.extendedMetadata.dlum.uaGroupId || // uaGroupId instead of uaSeriesId from BE
                  seriesRecording.metadata.uaSeriesId.toString() ===
                    assetToRecord.metadata.extendedMetadata.dlum.series?.uaId?.toString?.()
                );
              } else {
                return (
                  (seriesRecording.metadata.uaSeriesId === assetToRecord.metadata.extendedMetadata.dlum.uaGroupId || // uaGroupId instead of uaSeriesId from BE
                    seriesRecording.metadata.uaSeriesId.toString() ===
                      assetToRecord.metadata.extendedMetadata.dlum.series?.uaId?.toString?.()) &&
                  seriesRecording.metadata.channelId === assetChannelId
                );
              }
            }
            return false;
          });
        }
        recordingInfo = {
          assetToRecord,
          eventRecordingItem,
          seriesRecordingItem,
          eventRecordingsList,
          seriesRecordingsList,
        };
      }
    }
  }

  return recordingInfo;
};

// To match the channel which is recordable with the schedule inside the series details page and filter out all those objects for schedules.
export const getRecordablePrograms = (schedules, channelMapInfo) => {
  return (
    schedules?.length > 0 &&
    schedules.filter(
      (schedule) =>
        channelMapInfo?.containers?.length > 0 &&
        channelMapInfo.containers.some(
          (channel) =>
            channel.metadata?.isRecordable === true && parseInt(channel.id) === parseInt(schedule.channel?.channelId)
        )
    )
  );
};
/**
 *
 * @param {object} recordingResponse
 * @returns first object from the recordings object inside the GET recordings MR call containing pictureUrl value.
 */
export const getRecordingImageAsset = (recordingResponse) =>
  recordingResponse?.recordings?.length > 0 &&
  recordingResponse.recordings.find((recording) => recording.metadata?.pictureUrl);

/**
 * Used to update the browser location to the recordings page.
 * @param {Boolean} goToScheduled Flag indicating which recording page section to use.
 * @param {String} queryParams Query param string to append to the URL (should not include '?')
 */
export const navigateToPVRManager = (goToScheduled = false, queryParams = "") => {
  window.location.href = "#" + getRouteToPVRManager(goToScheduled) + queryParams;
};

/**
 * @param {Boolean} isScheduledSection Flag indicating which recording page section to use.
 * @returns Returns route of the PVRManager.
 */
export const getRouteToPVRManager = (isScheduledSection = false) => {
  return RECORDINGS.route + (isScheduledSection ? "?scheduled" : "?recorded");
};

/**
 * Method to check the recording status of the recordings on PVR manager
 * @param {object} recordingResponse
 * @param {string} status
 * @returns {Boolean}
 */
export const checkRecordingStatus = (recordingResponse, status) => {
  if (
    recordingResponse?.recordingDefinitionStatus !== MR_RECORDING_STATUS.CANCELLED &&
    recordingResponse.recordings?.length > 0 &&
    status
  ) {
    const currentTime = moment().valueOf();
    if (status === MR_RECORDING_STATUS.RECORDING) {
      return recordingResponse.recordings.some((recording) => {
        const recordingEndTime = recording.programStartTime + recording.duration * 1000;
        return (
          recording.status !== MR_RECORDING_STATUS.RECORDED &&
          recording.status !== MR_RECORDING_STATUS.CANCELLED &&
          recording.programStartTime <= currentTime &&
          currentTime <= recordingEndTime
        );
      });
    } else {
      let evalFunc;
      if (status === MR_RECORDING_STATUS.SCHEDULED) {
        evalFunc = (recording) => {
          return recording.status !== MR_RECORDING_STATUS.CANCELLED && currentTime <= recording.programStartTime;
        };
      } else if (status === MR_RECORDING_STATUS.RECORDED) {
        evalFunc = (recording) => {
          const recordingEndTime = recording.programStartTime + recording.duration * 1000;
          return (
            recording.status !== MR_RECORDING_STATUS.CANCELLED &&
            (recording.status === status || recordingEndTime < currentTime)
          );
        };
      } else {
        evalFunc = (recording) => {
          return recording.status === status;
        };
      }

      return recordingResponse.recordings.every(evalFunc);
    }
  }

  return false;
};

/**
 * Function to check CPVR conflict status - currently, when there are 6 concurring recordings, setting the 7th recording will be in conflict.
 * @param {Object} setRecordingResponse - CPVR set recording API response
 * @param {String} errorCode - conflict error code.
 * @returns {Boolean} - true if there is a conflict, false otherwise.
 */

export const checkCPVRConflicts = (setRecordingResponse, errorCode = AVS_ERROR_CODES.CPVR_CONCURRENT_LIMIT_REACHED) => {
  return setRecordingResponse?.code?.includes(errorCode) || false;
};

/**
 * Function to show CPVR conflict modal
 * @param {Function} onClickHandler - modal onClick handler
 * @param {Function} onClose - modal close handler
 * @param {String} modalType - modal type
 * @param {Function} showModalPopup - Redux action for modal popup
 * @param {Function} dispatch - function to dispatch actions to redux store
 */
export const showCPVRConflictModal = (onClickHandler, onClose, modalType, showModalPopup, dispatch) => {
  const modalContent = {
    isCloseable: true,
    title: "recording_cannot_be_set",
    message: i18n.t("recordings_cannot_set"),
    buttons: [
      {
        label: i18n.t("manage_recordings"),
        onClickHandler: onClickHandler,
        classOverride: "error-modal-btn-gray error-modal-btn-focus-gray",
      },
      {
        label: i18n.t("close"),
        onClickHandler: onClose,
        classOverride: "error-modal-btn-gray",
      },
    ],
    endButtonLabelOverride: "",
  };
  const dispatchCPVRConflictModal = (modalType, modalContent) => {
    if (dispatch) {
      dispatch(showModalPopup(modalType, modalContent));
    } else {
      showModalPopup(modalType, modalContent);
    }
  };
  if (modalType && showModalPopup) dispatchCPVRConflictModal(modalType, modalContent);
};

/**
 *
 * @param {Object} recordingResponse - set/edit recording response
 * @returns {Boolean}
 */
export const isRecordingPendingStateCheck = (recordingResponse) =>
  recordingResponse?.recordingDefinitionStatus === RECORDING_PARAMS.PENDING.toUpperCase();

/**
 * Determines the appropriate expiration message (if any) to use based on the provided CPVR EVENT
 * recording's expiration time.
 * If the recording availability is greater than 3 days, we don't display any message.
 * If the recording availability is less than or equal to 3 days but greater than 24 hours, we use the ceiling function to display days remaining.
 * If the recording availability is less than or equal to 24 hours, we display the remaining hours instead of days.
 * If the recording availability is greater than or equal to 1 hour, we round to the nearest hour to display the hours remaining.
 * If the recording availability is less than one hour, we display a static message.
 *
 * @param {Object} recordingMetadata CPVR EVENT recording item metadata object
 * @returns {String} Expiry string message or empty string if no expiry message should be displayed
 */
export function getCPVRExpiryString(recordingMetadata) {
  let result = "";
  if (recordingMetadata?.recordingExpiryTime) {
    const expiryDate = moment(recordingMetadata.recordingExpiryTime);
    const currentDate = moment();
    const timeDiff = expiryDate.diff(currentDate);
    let hoursLeft = timeDiff / 3600000;

    if (hoursLeft >= 0) {
      const daysLeft = hoursLeft > 24 ? Math.ceil(timeDiff / 86400000) : 0;
      hoursLeft = hoursLeft >= 1 ? Math.round(hoursLeft) : 0;

      if (daysLeft > 0) {
        if (daysLeft <= 3) {
          result = i18n.t("cloud_pvr_availability_limit_reached_days_card")?.replace("%s", daysLeft);
        }
      } else if (hoursLeft > 0) {
        result =
          hoursLeft === 1
            ? i18n.t("cloud_pvr_availability_limit_reached_hour_card")
            : i18n.t("cloud_pvr_availability_limit_reached_day_card")?.replace("%s", hoursLeft);
      } else {
        result = `< ${i18n.t("cloud_pvr_availability_limit_reached_hour_card")}`;
      }
    } else {
      //As per the discussion in TSQA-13119, the background job that deletes the expired recordings runs at 12:00 am UTC everyday. The decision was made to update the expiry string instead of updating our recordingExpiryTime calculation.
      result = i18n.t("expiring_soon");
    }
  }

  return result;
}

/**
 * Function to update sessionStorage with recording filter values
 */
export function updateFilterSessionStorage(category, filterBy, sortBy) {
  // Retrieve existing data from sessionStorage or initialize an empty object
  const sessionStorageData = getSessionStorage(RECORDINGS_FILTER_VALUES) || {};

  // Update the values for the specified category
  sessionStorageData[category] = {
    filterBy: filterBy,
    sortBy: sortBy,
  };

  setSessionStorage(RECORDINGS_FILTER_VALUES, sessionStorageData);
}

/**
 * Function to get sessionStorage with recording filter values
 */
export function getFilterSessionStorage() {
  // Retrieve existing values from sessionStorage
  const storedData = getSessionStorage(RECORDINGS_FILTER_VALUES) || {};
  return storedData;
}

/**
 * Function used to calculate progress percentage for recordings.
 * @param {Object} program Object containing recording metadata.
 * @param {Array} bookmarks
 * @returns Number
 */
export const calculateRecordingProgressPercentage = (program, bookmarks) => {
  let progressPercentage = 0;
  if (bookmarks && Array.isArray(bookmarks.bookmarks)) {
    for (let bookmark of bookmarks.bookmarks) {
      if (
        bookmark.bookmarkSet.contentId === program.contentId &&
        bookmark.bookmarkSet.contentType.toLowerCase().includes(RECORDING_PARAMS.RECORDING)
      ) {
        const progressSeconds = bookmark.startDeltaTime;
        if (progressSeconds > 0) {
          const durationSeconds = (program.stopDeltaTime - program.startDeltaTime) / 1000;
          if (durationSeconds > 0) {
            progressPercentage = (progressSeconds / durationSeconds) * 100;
            progressPercentage = Math.ceil(progressPercentage) < 100 ? progressPercentage : 0;
          }
        }
        break;
      }
    }
  }
  return progressPercentage;
};
