import React from "react";
import Dropdown from "react-dropdown";
import "./style.scss";
import useAppLanguage from "../../shared/hooks/useAppLanguage";

// The default environment that was set during the build process
const BUILD_ENV = process.env.REACT_APP_DATA_ENV;
// List of config file names (excluding file extensions) that can be selected
const CONFIG_SELECT_OPTIONS =
  BUILD_ENV === "avs-staging" ||
  BUILD_ENV === "avs-staging-montreal" ||
  BUILD_ENV === "avs-staging-oregon" ||
  BUILD_ENV === "avs-staging-qapreprod" ||
  BUILD_ENV === "avs-staging-ptpreprod"
    ? ["avs-staging", "avs-staging-montreal", "avs-staging-oregon", "avs-staging-qapreprod", "avs-staging-ptpreprod"]
    : [];

const GateAccess = ({ gatePassError, gatePassChange, checkGatedPassLogging, config, avsConfigName }) => {
  const { isAppLanguageFrench } = useAppLanguage();
  const isEnvironmentalSelectionEnabled = config?.general?.AVS_config?.avsConfigSelection;

  const getTopCopy = () => {
    return isAppLanguageFrench
      ? "En raison d'une activité temporaire de maintenance à telustvplus.com, veuillez visiter "
      : "Due to temporary service maintenance to telustvplus.com, please visit ";
  };
  const getBottomCopy = () => {
    return isAppLanguageFrench ? " pour accéder à vos services télé." : " to access your TV service.";
  };

  return (
    <>
      <div className="getLoggedIn">
        <div className="form-wrapper">
          {isEnvironmentalSelectionEnabled ? (
            <div className="environment">
              <h3>SELECT ENVIRONMENT</h3>
              <Dropdown
                options={CONFIG_SELECT_OPTIONS}
                onChange={avsConfigName}
                placeholder={`Click to select - default is ${BUILD_ENV}`}
              />
            </div>
          ) : (
            ""
          )}
          <h3>
            {getTopCopy()}
            <a href="https://watchoptik.telus.com/">watchoptik.telus.com</a>
            {getBottomCopy()}
          </h3>
          {!gatePassError && <div className="error">Invalid password. Please try again.</div>}
          <input type="password" onChange={gatePassChange} placeholder="Password"></input>
          <button onClick={checkGatedPassLogging}>Submit</button>
        </div>
      </div>
    </>
  );
};
export default GateAccess;
