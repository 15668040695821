const proxyProtocol = window.location.protocol;

export default Object.freeze({
  API_BASE_URL: `${proxyProtocol}//localhost:8010/proxy`,
  TELUS_BASE_PROXY_URL: `${proxyProtocol}//localhost:8011/proxy`,
  OPUS_BASE_PROXY_URL: `${proxyProtocol}//localhost:8012/proxy`,
  APP_ENVIRONMENTS: {
    DEV: "development",
    PROD: "production",
    TEST: "test",
  },
  MVE2_AGL_VERSION: "1.5",
  MVE3_AGL_VERSION: "T2.0",
  FEED_NAVIGATION_TYPES: {
    LIVE_GUIDE: "livetvguide",
  },
  FEED_LAYOUT_TYPES: {
    CATEGORY: "Category",
    GALLERY: "Gallery",
  },
  IMAGES: {
    ASPECT_RATIOS: {
      DIM_2x3: "2x3",
      DIM_9x16: "16x9",
      DIM_3x4: "3x4",
    },
    SIZES: {
      LARGE: "large.jpg",
      SMALL: "small.jpg",
      MEDIUM: "medium.jpg",
      XX_LARGE: "xxlarge.jpg",
      X_LARGE: "xlarge.jpg",
    },
    TYPES: {
      svodpackage: "package",
      series: "series",
      program: "program",
      tvshow: "series",
      movie: "program",
      person: "person",
    },
  },
  BUNDLE_LAYOUT_ITEM_TYPE: ["GROUP_OF_BUNDLES", "TVSHOW", "BUNDLE", "SPORTSERIES"],
  CONTENT_ITEM_TYPES: {
    svodpackage: "svodpackage",
    series: "series",
    program: "program",
    channel: "channel",
    tvshow: "tvshow",
    movie: "movie",
    viewall: "viewall",
    programs: "programs",
    liveProgram: "liveprogram",
    liveSeries: "liveseries",
    title: "title",
    bundle: "bundle",
    collection: "collection",
    vod: "vod",
    episode: "episode",
    groupOfBundles: "group_of_bundles",
    contentItem: "content_item",
    bundleItem: "bundle_item",
    trailer: "trailer",
    network: "network",
    launcher: "launcher",
    recording: "recording",
    sportsCard: "sportscard",
    team: "team",
  },
  PAGE_CONTENT_ITEM_TYPES: {
    program: "PROGRAM",
    series: "SERIES",
    vod: "VOD",
    group_of_bundles: "GROUP_OF_BUNDLES",
    movie: "MOVIE",
    tvShow: "TVSHOW",
    episode: "EPISODE",
    live: "LIVE",
    season: "SEASON",
    recording: "RECORDING",
    sportsSeries: "SPORTSERIES",
  },
  TOP_NAV_ACTIVITY_TYPES: {
    hub: "hub",
    page: "page",
  },
  SETTINGS_CONFIG: {
    CLOSED_CAPTION: "caption",
    DESCRIBED_VIDEO: "described",
  },
  TRANSACTION_TYPES: {
    RENT: "rent",
    PURCHASE: "Purchase",
    SUBSCRIPTION: "subscription",
    BUY: "buy",
  },
  FAVOURITE_CHANNELS: {
    TITLE: "Favourite channels",
    LAYOUT: "169large",
  },
  RESUME_ASSETS: {
    TITLE: "Resume",
    LAYOUT: "SMALL_CARDS",
  },
  FAVOURITE_ASSETS: {
    TITLE: "Favourite movies & series",
    LAYOUT: "LARGE_CARDS",
  },
  PURCHASE_ASSETS: {
    TITLE: "Purchases",
    LAYOUT: "LARGE_CARDS",
  },
  FEED_ACTIONS: {
    PURCHASE_ACTIONS: "PurchaseActions",
    TRAILER_ACTIONS: "Id",
    PLAY_ACTIONS: "PlayActions",
  },
  ON_DEMAND_OPTIONS: {
    PLAY: "play",
    SUBSCRIBE: "subscribe",
    RENT: "rent",
    BUY: "buy",
  },
  WATCH_OPTIONS: {
    watch: "watch",
    buy: "buy",
    none: "none",
  },
  PACKAGE_TYPE: {
    TVOD: "tvod",
    LIVE_SUB: "live_sub",
    VOD_LIVE_SUB: "vod_live_sub",
    SVOD: "svod",
    RVOD: "rvod",
    PPV_EVENT: "ppv_event",
  },
  PACKAGE_NAME: {
    PPV: "ppv_enabler",
  },
  OUT_OF_HOME: "outofhome",
  OPTIK_ACCOUNT_TYPE: "TV2",
  OPTIK_WEB_SITE: "https://watchoptiktv.com/",
  PIK_WEB_SITE: "https://watchpiktv.com/",
  TELUS_TV_PLUS: "http://telustvplus.com/",
  IN_MARKET_OPTIK_URL: "https://watchoptik.telus.com/",
  OPTIK_APP_PROPERTY: "OPTIK",
  USER_SESSION_RENEWAL_INTERVAL: 600,
  MODAL_TYPES: {
    ERROR: "error",
    PIN: "pin",
    PURCHASE: "purchase",
    RECORDING: "recording",
    WARNING_4K_PLAY: "warning_4k_play",
  },
  PLAY_RESTRICTION: {
    DEVICERESTRICT: "devicerestrict",
  },
  PLAY: "play",
  PURCHASE: "purchase",
  RESOLUTION_TYPE: {
    _4K: "4K",
    _4KHDR: "4K HDR",
    HDR10: "HDR10",
  },
  CONTAINER_TYPES: {
    CONTAINERS: "containers",
    CONTENT: "content",
    SCHEDULES: "schedules",
    ON_DEMAND: "onDemand",
    EPISODES: "episodes",
    MORE_LIKE_THIS: "moreLikeThis",
    LIST_OF_TEAMS: "listOfTeams",
    RECENT_AIRINGS: "recentAirings",
    GAMES: "games",
  },
  CONTAINER_LAYOUTS: {
    CONTENT_DETAIL: "content_detail",
    BUNDLE_ITEM: "bundle_item",
    SHOW_TIMES: "showtimes",
    CONTENT_ITEM: "content_item",
    ON_DEMAND: "ondemand",
    CHANNEL_ITEM: "channel_item",
    GRID: "grid",
    MORE_LIKE_THIS: "morelikethis",
    LIST_OF_TEAMS: "listofteams",
    RECENT_AIRINGS: "169sportsvod",
    GAMES: "games",
    RECENT_RECORDINGS: "169sportsrecording",
  },
  CONTAINER_ACTION_TYPE: {
    WALL_PAGE: "WALL_PAGE",
  },
  ACTION_KEYS: {
    ON_CLICK: "onclick",
  },
  DEFAULT_RENTAL_DURATION: 48, // hours,
  MAX_ACCOUNT_PROFILES: 6,
  DEFAULT_AVATAR_CATEGORY: "butterflies",
  SPINNING_LOADER_TIMER: 2500,
  DEFAULT_REGION_ID: 25,
  PROVINCE: {
    QUEBEC: "Quebec",
  },
  USER_PROPERTY_TYPES: {
    PIK: "pik",
    OPTIK_AVS: "optikavs",
    MEDIA_ROOM: "optikmr",
    TECH_TRIAL: "techtrial",
    QUEBEC_TRIAL: "optiktqavs",
    QUEBEC_NULL: "optiktqmr",
  },
  USER_PROPERTY_NAMES: {
    PIK: "PIK",
    OPTIK: "OPTIK",
    SPLUS: "SPLUS",
  },
  USER_VISIBILITY_CONTROL: {
    PIK: "pik",
    AVS: "avs",
    TTP: "ttp",
    SPLUS: "splus",
  },
  CASSANDRA_KEEPALIVE_INTERVAL: 60,
  LOOKBACK_AVAILABILITY_HOURS: 30,
  TOKEN_NOT_VALID: "Token not valid",
  REDUCER_TYPE: {
    APP: "app",
    RECORDING: "recording",
    EPG: "epg",
    ON_DEMAND_PLAYER: "onDemandPlayer",
    RECORDING_PLAYER: "recordingPlayer",
    FEED: "feed",
    TOP_NAV: "topNav",
    MOVIE_DETAILS: "movieDetails",
    SERIES_DETAILS: "seriesDetails",
    CAST_DETAILS: "castDetails",
    VIEW_ALL: "viewAll",
    SVOD_PACKAGE: "svodPackage",
    SEARCH: "search",
    SIMILAR_ITEMS: "similarItems",
    SUB_CATEGORY: "subCategory",
    EPISODE_DETAIL: "episodeDetail",
    CONTENT_ITEM: "contentItem",
  },
  LOGIN_BRANDS: {
    TELUS: "telus",
    KOODO: "koodo",
  },
  IDENTITY_PROVIDER: "CI",
  UA_STANDALONE: "STANDALONE",
  SPORTEVENT: "SPORTEVENT",
  SKELETON_ITEMS_COUNT: {
    VIEW_ALL: 22,
    HOME_PAGE_TILES: 10,
    ON_DEMAND_TILES: 15,
    DETAILS_PAGE: 4,
  },
});
