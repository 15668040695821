import styled from "styled-components";
import PopUpOutline from "../PopUpOutline";

export const ModalContentContainer = styled.div`
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 16;
`;

export const PopUpContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const ModalPopUp = styled(PopUpOutline)`
  width: 395px;
  z-index: 14;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colours.mineShaftGray};
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
`;

export const Title = styled.div`
  text-align: center;
  font: ${({ theme }) => theme.typography.regularFont};
  font-size: 24px;
  font-weight: 600;
  width: 330px;
`;

export const Content = styled.div`
  display: table;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 15px;
`;

export const Subtitle = styled.div`
  font: ${({ theme }) => theme.typography.regularFontSize};
  color: ${({ theme }) => theme.colours.playerBufferIndicator};
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  text-align: center;
  font-weight: 400;
`;

export const Button = styled.button`
  background-color: ${({ type, theme }) =>
    type === "confirm" ? theme.colours.focusedButtonBackground : theme.colours.doveGray};
  font: ${({ theme }) => theme.typography.regularFont};
  color: ${({ theme }) => theme.colours.white};
  font-size: ${({ theme }) => theme.typography.mediumFontSize};
  text-align: center;
  height: 46px !important;
  width: 335px !important;
  border: none;
  margin-top: 15px !important;
  margin-bottom: 0px;
  cursor: pointer;
  border-radius: 8px;
  border: solid 2px ${({ theme }) => theme.colours.doveGray};
`;

export const ButtonContent = styled.p`
  margin: 5px 0 8px 0;
  height: 27px;
`;
