import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useAppLanguage from "../../shared/hooks/useAppLanguage";
import KidsLabelEnglish from "./assets/kids-label-en.svg";
import KidsLabelFrench from "./assets/kids-label-fr.svg";
import EditIcon from "./assets/profile-edit-icon.svg";

import "./style.scss";

/* Styled components */
const PanicAlertDot = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colours.orange};
  border-radius: 50%;
`;
/* Styled components end */

/**
 * Profile avatar component that can function as a link or button
 * @component
 * @param {Object} props
 */
const AvatarButton = forwardRef(function AvatarButton(
  {
    avatarInfo,
    title,
    subtitle,
    to,
    onClick,
    alt,
    className = "",
    isKids = false,
    isEditMode = false,
    isMenuMode = false,
    inPanic,
    ...rest
  },
  ref
) {
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  const { isAppLanguageFrench } = useAppLanguage();
  const { t: translate } = useTranslation();

  const altText = alt ?? avatarInfo?.displayName;

  return (
    <ActionComponent className={`avatar-button ${className}`} to={to} onClick={onClick} {...rest} ref={ref}>
      {avatarInfo?.displayImages?.[0]?.imageUrl && !isImageLoadError ? (
        <img
          src={avatarInfo.displayImages[0].imageUrl}
          className="avatar"
          alt={altText}
          onError={() => setIsImageLoadError(true)}
        />
      ) : (
        <div className="avatar-placeholder">
          <span>{altText}</span>
        </div>
      )}
      {isEditMode && <img src={EditIcon} className="edit-icon" alt="" />}
      {title && <span className={`avatar-title ${title.length > 12 ? "overflow" : ""}`}>{title}</span>}
      {subtitle && <span className="avatar-subtitle">{subtitle}</span>}
      {isKids && (
        <img
          src={isAppLanguageFrench ? KidsLabelFrench : KidsLabelEnglish}
          alt={translate("kids_profile")}
          className="kids-icon"
        />
      )}
      {isMenuMode && inPanic ? <PanicAlertDot /> : <></>}
    </ActionComponent>
  );
});

AvatarButton.propTypes = {
  avatarInfo: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  onClick: PropTypes.func,
  alt: PropTypes.string,
  className: PropTypes.string,
  isKids: PropTypes.bool,
  isEditMode: PropTypes.bool,
};

export default AvatarButton;

/**
 * Wraps children in a Link component if the `to` prop is specified, or button otherwise
 * @component
 */
function ActionComponent({ to, children, ...props }) {
  return to ? (
    <Link to={to} {...props}>
      {children}
    </Link>
  ) : (
    <button type="button" {...props}>
      {children}
    </button>
  );
}
