import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Wall from "../Wall";
import WallContent from "../WallContent";
import OptikButton from "../OptikButton";
import Billboard from "../Billboard";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getSubTextLine1,
  getSubTextLine2,
  getLiveEpisodesInfo,
  getLiveProgramInfo,
  getEpisodeCount,
  getMovieDuration,
} from "../../shared/utils/feedHelper";
import constants from "../../shared/constants";
import routeConstants from "../../shared/constants/routes";
import { getAVSKeyArtImage } from "../../shared/utils/image";
import "./style.scss";
import { isItemTypeMovie, isItemTypeSeries, isItemTypeEpisode, isItemTypeLive } from "../../shared/utils/content";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import useAppLanguage from "../../shared/hooks/useAppLanguage";

import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import { useReducers } from "../../shared/hooks/useReducer";
import { checkUnifiedAsset } from "../../shared/utils";
import { showModalPopup } from "../../App/state/actions";

const { IMAGES, ACTION_KEYS, PAGE_CONTENT_ITEM_TYPES, REDUCER_TYPE, MODAL_TYPES, PLAY } = constants;
const { MOVIE_DETAIL_PAGE, SERIES_DETAIL_PAGE, EPISODE_DETAIL_PAGE, LIVE_PLAYER } = routeConstants;
/**
 * Component that renders a FeedPage Wall
 * @component
 * @param {Object} props
 */

function Carousel(props) {
  const { data, setProductImpressions } = props;
  const { provider: appProvider, userProfile } = useReducers(REDUCER_TYPE.APP);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [stallBillboardChange, setStallBillboardChange] = useState(false);
  const [wallImgSrc, setWallImgSrc] = useState(null);
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const slideIndex = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { performPlaybackChecks } = usePlaybackChecks();
  useEffect(() => {
    if (data?.length) {
      slideIndex.current = 0;
      setCurrentSlide(data[0]);
      if (setProductImpressions) {
        // product impressions will be sent with pageLoaded event
        setProductImpressions(data);
      }
    }
  }, [data, setProductImpressions]);

  useEffect(() => {
    if (currentSlide?.metadata?.extendedMetadata?.dlum?.iconicImage169) {
      const imgUrl = getAVSKeyArtImage(currentSlide.metadata, IMAGES.ASPECT_RATIOS.DIM_9x16);
      setWallImgSrc(imgUrl);
    }
  }, [currentSlide]);

  const onBillboardChange = (index) => {
    slideIndex.current = index;
    setCurrentSlide(data[index]);
  };

  const onBillboardPointerEnter = () => setStallBillboardChange(true);
  const onBillboardPointerLeave = () => setStallBillboardChange(false);

  const getLine1Text = () => {
    if (
      isItemTypeMovie(currentSlide.metadata, currentSlide.layout) &&
      isItemTypeLive(currentSlide.metadata, currentSlide.layout)
    ) {
      return getLiveProgramInfo(currentSlide.metadata, currentSlide.channel, appProvider?.channelMapID);
    } else if (isItemTypeMovie(currentSlide.metadata, currentSlide.layout)) {
      return (
        getSubTextLine1(currentSlide, isAppLanguageFrench) +
        (userProfile?.isLoggedIn ? "\xa0\xa0" + getMovieDuration(currentSlide.metadata) : "")
      );
    } else if (isItemTypeSeries(currentSlide.metadata, currentSlide.layout)) {
      return getEpisodeCount(currentSlide);
    } else if (isItemTypeLive(currentSlide.metadata, currentSlide.layout)) {
      return getLiveEpisodesInfo(currentSlide.metadata);
    }
  };

  const getLine2Text = () => {
    if (
      isItemTypeMovie(currentSlide.metadata, currentSlide.layout) &&
      isItemTypeLive(currentSlide.metadata, currentSlide.layout)
    ) {
      return null;
    } else if (isItemTypeMovie(currentSlide.metadata, currentSlide.layout)) {
      return getSubTextLine2(currentSlide.metadata, isAppLanguageFrench);
    } else if (isItemTypeSeries(currentSlide.metadata, currentSlide.layout)) {
      return getSubTextLine1(currentSlide, isAppLanguageFrench);
    } else if (isItemTypeLive(currentSlide.metadata, currentSlide.layout)) {
      return getLiveProgramInfo(currentSlide.metadata, currentSlide.channel, appProvider?.channelMapID);
    }
  };
  /*
   * If content is a Live item, button should begin playback of the appropriate channel
   * Otherwise the appropriate details page should be loaded.
   */

  const onButtonClick = () => {
    if (currentSlide) {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `hero_${slideIndex.current + 1};${LINK_INFO.CAROUSEL}`);
      if (isItemTypeLive(currentSlide.metadata) && userProfile?.isLoggedIn) {
        performPlaybackChecks(
          null,
          null,
          currentSlide.channel.channelId,
          PAGE_CONTENT_ITEM_TYPES.live,
          LIVE_PLAYER.route,
          null,
          null,
          null,
          null,
          null,
          null,
          { type: "", source: PLAY }
        );
      } else {
        let pageRoute = "";

        if (!checkUnifiedAsset(currentSlide.metadata?.extendedMetadata?.dlum)) {
          dispatch(showModalPopup(MODAL_TYPES.ERROR, { message: "error_non_unified", isCloseable: true })); // showing error for non-UA assets
        } else {
          if (isItemTypeMovie(currentSlide.metadata, currentSlide.layout)) {
            pageRoute = MOVIE_DETAIL_PAGE.route;
          } else if (isItemTypeSeries(currentSlide.metadata, currentSlide.layout)) {
            pageRoute = SERIES_DETAIL_PAGE.route;
          } else if (isItemTypeEpisode(currentSlide.metadata, currentSlide.layout)) {
            pageRoute = EPISODE_DETAIL_PAGE.route;
          }
          const clickAction = currentSlide.actions?.find(
            (action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK
          );
          history.push(pageRoute + clickAction?.uri);
        }
      }
    }
  };

  return currentSlide ? (
    <div className="billboard-wrapper">
      <Wall cover={wallImgSrc} />
      <div className="content-wrapper">
        <div
          className="billboard-content"
          onMouseEnter={onBillboardPointerEnter}
          onMouseLeave={onBillboardPointerLeave}
        >
          <WallContent
            title={currentSlide.metadata.title}
            description={currentSlide.metadata.longDescription}
            line1={getLine1Text()}
            line2={getLine2Text()}
          >
            <OptikButton
              label={
                isItemTypeLive(currentSlide.metadata) && userProfile?.isLoggedIn
                  ? translate("play")
                  : translate("details")
              }
              icon={
                isItemTypeLive(currentSlide.metadata) && userProfile?.isLoggedIn
                  ? process.env.PUBLIC_URL + "/images/play-icon.svg"
                  : null
              }
              testID="wallPlayButton"
              className="wallButton"
              onClickHandler={onButtonClick}
            />
          </WallContent>

          <Billboard
            noOfItems={data?.length}
            onChange={onBillboardChange}
            stallBillboardChange={stallBillboardChange}
          />
        </div>
      </div>
    </div>
  ) : null;
}

export default Carousel;
