import React, { useState, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import swimlaneConstants from "../../shared/constants/swimlane";
import routeConstants from "../../shared/constants/routes";
import searchConstants from "../../shared/constants/search";
import { createSwimlaneConfig } from "../../shared/utils/swimlane";
import createSwimlaneModels from "../../shared/utils/dataFilters";
import SwimlaneItem from "../../components/SwimlaneItem";
import Swimlane from "../../components/Swimlane";
import SeoPageTags from "../../components/SeoPageTags";
import SearchCollection from "../../components/SearchCollection";
import { getQueryParamsByName } from "../../shared/utils";
import { ANALYTICS_EVENT_TYPES } from "../../shared/constants/analytics";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { checkShouldTriggerPageLoadedEvent } from "../../shared/analytics/helpers";
import useTrackPageView from "../../shared/hooks/useTrackPageView";

const { ITEM_TYPES } = swimlaneConstants;
const { SEARCH_VIEW_ALL } = routeConstants;
const { SEARCH_ORDER_OPTIONS } = searchConstants;

/**
 * Search page component
 *
 * @component
 * @param {Object} props
 */

const SearchPage = ({ content, featureToggles, manualSearchTriggered, userProfile }) => {
  const { isSearchCollectionEnabled, isUserProfilesEnabled } = featureToggles;
  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  const [swimlanes, setSwimlanes] = useState([]);
  const [noResultsLabel, setNoResultsLabel] = useState(null);
  const [showSearchCollection, setShowSearchCollection] = useState(isSearchCollectionEnabled && !isKidsProfile);

  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const { trackPageView } = useTrackPageView();
  const currentUrl = window.location.href;

  useEffect(() => {
    setSwimlanes([]);
    !content && setNoResultsLabel("");
    content && loadResults();

    const searchTerm = getQueryParamsByName("searchTerm");
    if (content && searchTerm) {
      logNREvent(NR_PAGE_ACTIONS.SEARCH, { searchTerm });
      logDatadogEvent(DD_PAGE_ACTIONS.SEARCH, { searchTerm });
    }
    if (searchTerm?.trim().length >= 3 || manualSearchTriggered) {
      setShowSearchCollection(false);
    } else if (isSearchCollectionEnabled && !isKidsProfile) {
      setShowSearchCollection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, manualSearchTriggered, currentUrl, isSearchCollectionEnabled, isKidsProfile]);

  const loadResults = () => {
    const searchTerm = getQueryParamsByName("searchTerm") || "";
    const layoutType = ITEM_TYPES.TITLE_ITEM.PORTRAIT;
    let swimlaneConfigs = [];
    let hasResults = false;
    for (const feed of content) {
      const { feedTitleKey, feedData, totalItems } = feed;
      hasResults = hasResults || feedData.length > 0;
      if (feedData.length > 0) {
        const feedTitle = translate(feedTitleKey);
        const feed = createSwimlaneModels({
          data: feedData.length > 20 ? feedData.slice(0, 20) : feedData,
          avsComponent: { metadata: { label: feedTitle }, feedTitle },
          addParams: {
            DIMENSIONS: layoutType.DIMENSIONS,
            viewAllUrl:
              totalItems > 20
                ? `${SEARCH_VIEW_ALL.route}?searchTerm=${encodeURIComponent(searchTerm)}&title=${feedTitle}` +
                  `&${SEARCH_ORDER_OPTIONS[0].value}`
                : undefined,
            subtitle: `${totalItems} ${translate(totalItems > 1 ? "results" : "search_result_single")}`,
          },
          itemsCount: totalItems,
          isAppLanguageFrench,
          appendFrenchTag: true,
        });

        swimlaneConfigs.push(createSwimlaneConfig(feed, layoutType.DIMENSIONS, layoutType.CONFIG, SwimlaneItem));
      }
    }

    if (hasResults && swimlaneConfigs?.length) {
      setSwimlanes(swimlaneConfigs);
    } else {
      setNoResultsLabel(translate("search_no_results"));
    }

    const searchResultCount =
      hasResults && swimlaneConfigs?.length > 0
        ? swimlaneConfigs.reduce((sum, feed) => sum + feed.feedObj?.itemsCount ?? 0, 0)
        : 0;

    searchTerm &&
      checkShouldTriggerPageLoadedEvent(searchTerm, searchResultCount) &&
      trackPageView({
        extraMetadataList: [
          { type: ANALYTICS_EVENT_TYPES.SEARCH, asset: { searchResultCount, searchTerm }, preventAnalytic: false },
        ],
        byPass: true,
      });
  };

  return (
    <div
      className={classNames("search-swimlanes", {
        "search-tray-view": swimlanes && swimlanes.length && !showSearchCollection,
      })}
    >
      <SeoPageTags title={translate("search")} keywords={["optik", "telus"]} />
      {
        // Show the search collection if the search term is less than 3 characters
        showSearchCollection && isSearchCollectionEnabled && !isKidsProfile && <SearchCollection />
      }
      {swimlanes && swimlanes.length && !showSearchCollection
        ? swimlanes.map((swimlaneConfig, id) => {
            if (swimlaneConfig.feedObj && swimlaneConfig.feedObj.items && swimlaneConfig.feedObj.items.length) {
              return (
                <Swimlane
                  getSwimlaneRowNum={() => id}
                  key={swimlaneConfig.feedObj.title}
                  feed={swimlaneConfig.feedObj}
                  dimensionConfig={swimlaneConfig.dimensionConfig}
                  sliderSettings={swimlaneConfig.sliderSettings}
                  ThumbnailComponent={swimlaneConfig.ThumbnailComponent}
                  searchTerm={getQueryParamsByName("searchTerm")}
                />
              );
            } else {
              return null;
            }
          })
        : noResultsLabel && !showSearchCollection && <h1 className="no-results-label">{noResultsLabel}</h1>}
    </div>
  );
};

function mapStateToProps({ search, app }) {
  return {
    content: search.content,
    featureToggles: app.featureToggles,
    manualSearchTriggered: search.manualSearchTriggered,
    userProfile: app.userProfile,
  };
}

export default connect(mapStateToProps)(SearchPage);
