/**
 * load core modules
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
/* load core modules end */

/**
 * load components
 */
import InformationCluster from "../InformationCluster";
/* load components end */

/**
 * load utilities
 */
import { convertToPercentageString } from "../../shared/utils";
/* load utilities end */

import "./style.scss";

/**
 * Display information about a specific
 * title( Live, Movie, Series or Episode )
 * along with a catchy artwork to the user
 * @component
 * @param {Object} props
 */

function WallContent({
  recordingBadge,
  title,
  description,
  line1,
  line2,
  children,
  progressPercentage,
  line3,
  useInformationCluster,
  onInformationClusterClick,
}) {
  const { t: translate } = useTranslation();

  const wallContentUI = () => {
    return (
      <div className="content">
        <div className="description-wrapper">
          <div>{recordingBadge}</div>
          <h1 data-testid="wallTitle">{title}</h1>
          <div className="wall-progressBar-wrap">
            {progressPercentage && (
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{
                    width: convertToPercentageString(progressPercentage),
                  }}
                ></div>
              </div>
            )}
          </div>
          {line1 && (
            <h2 data-testid="wallContentCategory" className="details-badge-wrap">
              {line1}
            </h2>
          )}
          {line2 && (
            <h2 data-testid="wallContentDetail" className="details-badge-wrap">
              {line2}
            </h2>
          )}
          <p id="desc" data-testid="wallDescription">
            {description || translate("no_information_available")}
          </p>
          {line3 && <h3 data-testid="wallContentCategory">{line3}</h3>}
        </div>
      </div>
    );
  };
  return (
    <div>
      {useInformationCluster ? (
        <InformationCluster isWallContentUI={true} onInformationClusterClick={onInformationClusterClick}>
          {wallContentUI()}
        </InformationCluster>
      ) : (
        wallContentUI()
      )}
      <div className="wall-button-wrapper">{children != null && children}</div>
    </div>
  );
}

WallContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  line1: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  line2: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  progressPercentage: PropTypes.number,
  useInformationCluster: PropTypes.bool,
};

WallContent.defaultProps = {
  description: null,
  line1: null,
  line2: null,
  children: null,
  progressPercentage: null,
};

export default WallContent;
