import { Player, PlayerEvent } from "bitmovin-player";

/**
 * Creates a new player instance within the given view container and registers needed events for the player
 * @param {Element} container A react element to render <video/>
 * @param {Boolean} isMuted
 * @param {Function} playerEventHandler Event Handler callback
 * @returns {Player}
 */
export function createPlayer(container, isMuted, playerEventHandler, networkConfig, autoPlay = true) {
  var config = {
    key: process.env.REACT_APP_PLAYER_KEY,
    ui: false,
    playback: {
      autoplay: autoPlay,
      muted: isMuted,
    },
    network: networkConfig,
  };

  const player = new Player(container, config);

  addPlayerEvent(player, PlayerEvent.Seek, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Ready, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Play, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Playing, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Paused, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Seek, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Seeked, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.TimeShift, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.TimeShifted, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.VolumeChanged, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Muted, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Unmuted, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.PlaybackFinished, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Error, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.AudioChanged, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.TimeChanged, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.SubtitleAdded, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.Destroy, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.CueEnter, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.CueExit, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.SourceLoaded, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.VideoQualityChanged, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.VideoPlaybackQualityChanged, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.StallStarted, playerEventHandler);
  addPlayerEvent(player, PlayerEvent.StallEnded, playerEventHandler);

  return player;
}

/**
 * Add Observable player events
 * @param {Player} player
 * @param {PlayerEvent} eventType type enum
 * @param {Function} event Handler function
 */
function addPlayerEvent(player, eventType, event) {
  player.on(eventType, event);
}

/**
 * Initiates Playback of source
 * @param {Player} player
 * @param {SourceObject} source for playback. Contains playback technology such as stream format and drm.
 * @return {Promise} Returns a promise object on success of player load.
 */
export function loadPlayback(player, source) {
  player.preload();
  return player.load(source);
}
