/**
 * load core modules
 */
import moment from "moment";
/** load core modules end */

/**
 * load utilities
 */
import {
  findContainerByLayout,
  getNewestLookbackProgram,
  calculateLookbackHoursLeft,
  checkIfAnyScheduleIsSubscribed,
  get4KAsset,
} from "../utils";
import {
  getPurchaseOptions,
  getTrailerAsset,
  getWatchOptions,
  prioritizeTvodCompareFunction,
  retrieveMasterAsset,
  getFirstInHomeLiveSchedule,
  getFirstOutOfHomeLiveSchedule,
} from "./feedHelper";
/** load utilities end */

/**
 * load constants
 */
import constants from "../constants";
import playerConstants from "../constants/player";
/** load constants end */

/** destructure/declare constants */
const { CONTAINER_LAYOUTS, WATCH_OPTIONS, _4K } = constants;
const { ASSET_TYPES } = playerConstants;

/**
 * Function to return the bookmarked time of the asset
 * @param {Object} preferredOnDemandOption vod option to be used for calculating progress
 * @param {Object} apiResponses an object with entitlements of all vod options
 * @returns bookmark time of the asset
 */
const setBookmark = (preferredOnDemandOption, apiResponses) => {
  let response = {};
  if (apiResponses && preferredOnDemandOption?.metadata?.contentId) {
    response = apiResponses[preferredOnDemandOption?.metadata?.contentId] ?? {};
  }

  const preferredOnDemandOptionUserContentContainer = response;
  const bookmark = preferredOnDemandOptionUserContentContainer?.metadata?.bookmarks?.[0];
  if (bookmark && !bookmark.isComplete) {
    return bookmark.startDeltaTime;
  }
};

/**
 * Function to return the playback info for the first available vod option
 * @param {Array} watchOptions an array of watch options
 * @param {Array} onDemandContent an array of vod content options
 * @param {Object} apiResponses an object with entitlements of all vod options
 * @returns playback info for the first available vod option
 */
const setFirstOnDemandOptionMetadata = (watchOptions, onDemandContent, apiResponses) => {
  let playbackId = 0;
  let progressTime = null;
  let preferredOnDemandOption;
  if (watchOptions) {
    const firstPlayableAsset = retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch);
    if (firstPlayableAsset) {
      preferredOnDemandOption = onDemandContent.find(
        (onDemandOption) => onDemandOption?.metadata?.contentId === parseInt(firstPlayableAsset.contentId)
      );
    }
    if (preferredOnDemandOption) {
      progressTime = setBookmark(preferredOnDemandOption, apiResponses);
      playbackId = preferredOnDemandOption?.metadata?.contentId;
    } else {
      preferredOnDemandOption = onDemandContent[0];
      if (preferredOnDemandOption) {
        progressTime = setBookmark(preferredOnDemandOption, apiResponses);
        const preferredWatchOption = watchOptions.find(
          (watchOption) => watchOption?.contentId === preferredOnDemandOption.id
        );
        if (
          preferredWatchOption?.assetType?.toLowerCase() === ASSET_TYPES.MASTER &&
          preferredWatchOption?.rights?.toLowerCase() === WATCH_OPTIONS.watch
        ) {
          playbackId = preferredOnDemandOption?.metadata?.contentId;
        }
      }
    }
  }

  return { progressTime, playbackId };
};

/**
 * Function to return playback info for the given vod option
 * @param {Object} contentUserData content user data of the given program
 * @param {Array} onDemandContent an array of vod content options
 * @param {Array} watchOptions an array of watch options
 * @param {Object} apiResponses an object with entitlements of all vod options
 * @returns playback info for the given vod option
 */
const getPlaybackInfo = (contentUserData, onDemandContent, watchOptions, apiResponses) => {
  let preferredOnDemandOption;
  let playbackInfo = {
    progressTime: null,
    playbackId: 0,
  };

  if (contentUserData) {
    const latestBookmark = contentUserData?.metadata?.bookmarks?.[0];
    if (latestBookmark) {
      preferredOnDemandOption = onDemandContent.find(
        (onDemandOption) => onDemandOption?.metadata?.contentId === latestBookmark.bookmarkSet?.contentId
      );
      if (preferredOnDemandOption) {
        if (!latestBookmark.isComplete) {
          playbackInfo.progressTime = latestBookmark.startDeltaTime;
        }
        if (watchOptions) {
          const preferredWatchOption = watchOptions.find(
            (watchOption) => watchOption?.contentId === preferredOnDemandOption.id
          );
          if (
            preferredWatchOption?.assetType?.toLowerCase() === ASSET_TYPES.MASTER &&
            preferredWatchOption?.rights?.toLowerCase() === WATCH_OPTIONS.watch
          ) {
            playbackInfo.playbackId = preferredOnDemandOption?.metadata?.contentId;
          }
        }
      } else {
        playbackInfo = setFirstOnDemandOptionMetadata(watchOptions, onDemandContent, apiResponses);
      }
    } else {
      playbackInfo = setFirstOnDemandOptionMetadata(watchOptions, onDemandContent, apiResponses);
    }
  }

  return playbackInfo;
};

/**
 * Function to return extended metadata for the vod content
 * @param {Array} onDemandContent an array of vod content options
 * @returns extended metadata for the vod content
 */
const getUnifiedAssetExtendedMetadata = (onDemandContent) => {
  const unifiedAssetExtendedMetadata = onDemandContent.reduce(
    (acc, onDemandOption) => {
      const onDemandOptionExtendedMetadata = onDemandOption?.metadata?.extendedMetadata;

      if (onDemandOptionExtendedMetadata) {
        acc.hasCC ||= onDemandOptionExtendedMetadata.hasCC;
        acc.hasDV ||= onDemandOptionExtendedMetadata.hasDV;
        acc.hasHD = onDemandOptionExtendedMetadata.isHD;
        acc.hasHDR = onDemandOptionExtendedMetadata.isHDR;
        acc.hasUHD = onDemandOptionExtendedMetadata.isUHD;
        acc.has4K = get4KAsset(onDemandOption.assets).length > 0 ? true : false;

        const onDemandOptionExtendedMetadataRatings = onDemandOptionExtendedMetadata.dlum?.rt;

        if (onDemandOptionExtendedMetadataRatings) {
          if (!acc.rt.criticScore && parseInt(onDemandOptionExtendedMetadataRatings.criticScore) > 0) {
            acc.rt.criticScore = onDemandOptionExtendedMetadataRatings.criticScore;
            acc.rt.criticImage = onDemandOptionExtendedMetadataRatings.criticImage;
          }
          if (!acc.rt.fanScore && parseInt(onDemandOptionExtendedMetadataRatings.fanScore) > 0) {
            acc.rt.fanScore = onDemandOptionExtendedMetadataRatings.fanScore;
            acc.rt.fanImage = onDemandOptionExtendedMetadataRatings.fanImage;
          }
        }
      }

      return acc;
    },
    {
      hasCC: false,
      hasDV: false,
      has4K: false,
      hasHD: false,
      hasHDR: false,
      hasUHD: false,
      rt: {},
    }
  );

  return unifiedAssetExtendedMetadata;
};

/**
 * Function to return aster assets for the given vod option
 * @param {Array} assets an array of all assets available for given vod option
 * @param {Object} entitlement entitlements for given vod option
 * @param {Object} onDemandOption vod option for which master assets are needed
 * @returns master assets for the given vod option
 */
const getMasterAssets = (assets, entitlement, onDemandOption) => {
  const masterAssets =
    assets?.length > 0 &&
    assets
      .filter(
        (asset) =>
          asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER && asset?.rights?.toLowerCase() !== WATCH_OPTIONS.none
      )
      .map((asset) =>
        Object.assign(asset, {
          contentId: onDemandOption?.id,
          bookmarks: onDemandOption?.metadata?.bookmarks,
          entitlement,
        })
      );

  return masterAssets;
};

/**
 * Function to return all vod options for the given program
 * @param {Array} onDemandContent an array of vod content options
 * @param {Array} onDemandOptions an array of vod content options content user data
 * @param {Object} contentUserData content user data of the program
 * @returns all vod options for the given program
 */
export const processDemandOptions = (onDemandContent, onDemandOptions, contentUserData) => {
  if (!onDemandContent || onDemandContent?.length === 0) {
    return {
      watchOptions: null,
      trailer: null,
      progressTime: null,
      unifiedAssetExtendedMetadata: null,
      tamsId: null,
      playbackId: 0,
      purchasePackages: [],
      allOnDemandItems: null,
      subscribedWatchOptions: null,
    };
  }

  const trailer = {};
  const apiResponses = {};
  const onDemandChannelAssets = [];

  onDemandContent.forEach((onDemandOption) => {
    const { metadata: { extendedMetadata } = {} } = onDemandOption || {};
    if (extendedMetadata?.source) {
      onDemandChannelAssets.push({ channel: extendedMetadata.source, id: onDemandOption.id });
    }
  });

  let trailerAsset;
  let trailerId;

  const onDemandData = onDemandOptions?.reduce((onDemandAssets, currentAsset) => {
    if (currentAsset?.status === "fulfilled" && currentAsset?.value) {
      const data = findContainerByLayout(currentAsset.value.containers, CONTAINER_LAYOUTS.CONTENT_ITEM);
      apiResponses[data?.id] = data;
      if (!trailerAsset) {
        trailerAsset = getTrailerAsset(data?.entitlement?.assets);
        if (trailerAsset) trailerId = data?.id;
      }
      const { assets, ...entitlement } = data?.entitlement ?? {};
      const masterAssets = getMasterAssets(assets, entitlement, data);

      const assetsChannelData =
        onDemandChannelAssets?.length > 0
          ? onDemandChannelAssets.find((channelAsset) => parseInt(channelAsset?.id) === parseInt(data?.id))
          : null;

      const assetsWithChannelData =
        masterAssets?.length > 0
          ? masterAssets?.map((asset) => ({ ...asset, channel: assetsChannelData?.channel }))
          : null;

      onDemandAssets = onDemandAssets.concat(assetsWithChannelData);
    }
    return onDemandAssets;
  }, []);

  onDemandData.sort(prioritizeTvodCompareFunction);

  if (trailerAsset) {
    trailer.id = trailerId;
    trailer.asset = trailerAsset;
  }

  const { progressTime, playbackId } = getPlaybackInfo(contentUserData, onDemandContent, onDemandData, apiResponses);

  const unifiedAssetExtendedMetadata = getUnifiedAssetExtendedMetadata(onDemandContent);

  const tamsId = onDemandContent[0]?.metadata?.externalId;
  const allOnDemandItems = getWatchOptions(onDemandData);
  const subscribedWatchOptions = allOnDemandItems?.filter((onDemandItem) => {
    return (
      onDemandItem?.channel &&
      Object.keys(onDemandItem.channel).length > 0 &&
      onDemandItem.rights.toLowerCase() === WATCH_OPTIONS.watch
    );
  });
  const purchasePackages = getPurchaseOptions(onDemandData) || [];

  return {
    watchOptions: onDemandData,
    trailer,
    progressTime,
    unifiedAssetExtendedMetadata,
    tamsId,
    playbackId,
    purchasePackages,
    allOnDemandItems,
    subscribedWatchOptions,
  };
};

/**
 * Function to return updated vod options given program
 * @param {Array} onDemandContent an array of vod content options
 * @param {Object} contentUserData content user data of the program
 * @param {Object} purchasedWatchOption purchased vod option
 * @returns updated vod options for given program
 */
export const getUpdatedOnDemandOptions = (onDemandContent, contentUserData, purchasedWatchOption) => {
  if (purchasedWatchOption) {
    const trailer = {};
    const trailerAsset = getTrailerAsset(purchasedWatchOption?.entitlement?.assets);
    if (trailerAsset) {
      trailer.id = purchasedWatchOption?.id;
      trailer.asset = trailerAsset;
    }
    const { assets, ...entitlement } = purchasedWatchOption?.entitlement ?? {};
    const masterAssets = getMasterAssets(assets, entitlement, purchasedWatchOption);
    const tamsId = onDemandContent[0]?.metadata?.externalId;
    const allOnDemandItems = getWatchOptions(masterAssets);
    const subscribedWatchOptions = allOnDemandItems?.filter((onDemandItem) => {
      return (
        onDemandItem?.channel &&
        Object.keys(onDemandItem.channel).length > 0 &&
        onDemandItem.rights.toLowerCase() === WATCH_OPTIONS.watch
      );
    });

    const unifiedAssetExtendedMetadata = getUnifiedAssetExtendedMetadata(onDemandContent);

    const { playbackId } = getPlaybackInfo(contentUserData, onDemandContent, masterAssets);
    const purchasePackages = getPurchaseOptions(masterAssets) || [];

    return {
      watchOptions: masterAssets,
      trailer,
      playbackId,
      unifiedAssetExtendedMetadata,
      subscribedWatchOptions,
      tamsId,
      allOnDemandItems,
      purchasePackages,
    };
  }
};

/**
 * Function to return all live options for the given program
 * @param {Array} schedules an array of all the live schedules of the program
 * @param {Object} subscribedChannels an object containing list of channels user is subscribed to
 * @param {Boolean} isInHome boolean indicating if user is in home network or not
 * @param {Object} appProvider an object containing app data
 * @param {Boolean} isLookbackEnabled boolean indicating if catch up is enabled for the app
 * @param {Object} channelMapInfo an object containing all channels containers
 * @returns all live options for the given program
 */
export const processLiveOptions = (
  schedules,
  subscribedChannels,
  isInHome,
  appProvider,
  isLookbackEnabled,
  channelMapInfo
) => {
  let isPlayableLive = null;

  if (!schedules || schedules?.length === 0) {
    return {
      lookbackProgram: null,
      lookbackHoursLeft: 0,
      currentAndUpcomingSchedules: null,
      firstLiveProgram: null,
      stationId: 0,
      isLiveProgramOOH: null,
      isPlayableLive: false,
    };
  }
  const lookbackProgram = isLookbackEnabled
    ? getNewestLookbackProgram(schedules, subscribedChannels, isInHome, appProvider.channelMapID)
    : null;

  const lookbackHoursLeft = lookbackProgram ? calculateLookbackHoursLeft(lookbackProgram) : 0;

  const currentTime = moment().valueOf();

  const currentAndUpcomingSchedules = schedules.filter((schedule) => {
    return schedule.metadata.airingEndTime >= currentTime;
  });

  const firstLiveSchedule =
    getFirstInHomeLiveSchedule(schedules, currentTime, channelMapInfo, subscribedChannels) ||
    getFirstOutOfHomeLiveSchedule(schedules, currentTime, channelMapInfo, subscribedChannels);

  const firstLiveProgram = lookbackProgram || firstLiveSchedule;

  const stationId = firstLiveProgram?.channel?.metadata?.channelId || firstLiveProgram?.channel?.channelId || 0;

  const isLiveProgramOOH = firstLiveProgram?.isNotAvailableOutOfHome && true;

  if (!firstLiveProgram && !checkIfAnyScheduleIsSubscribed(schedules, subscribedChannels) && isPlayableLive === null) {
    isPlayableLive = false;
  }

  return {
    lookbackProgram,
    lookbackHoursLeft,
    currentAndUpcomingSchedules,
    firstLiveProgram,
    stationId,
    isLiveProgramOOH,
    isPlayableLive,
  };
};
