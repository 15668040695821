import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getRecordingAction,
  SET_RECORDINGS,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
  toggleSettingsPanelAction,
  setRecordingAction,
} from "../../pages/RecordingsPage/state/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import "./style.scss";
import SeoPageTags from "../../components/SeoPageTags";
import CategoryDropdown from "../../components/CategoryDropdown";
import SearchBox from "../../components/SearchBox";
import { mapRecordingSeriesMediaContent } from "../../shared/analytics/helpers";
import {
  getRecordingSystemType,
  isRecordingPendingStateCheck,
  checkCPVRConflicts,
  showCPVRConflictModal,
  navigateToPVRManager,
  getRecordingInfo,
  getCPVRRecordingStatus,
  isCPVRRecordingInProgress,
  setRecording,
  doesCPVREventRecordingExist,
} from "../../shared/utils/recordingHelper";
import constants from "../../shared/constants/index";
import { getSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import { getTimeslotTimestamp, getCurrentDate, getDate, getHour } from "../../shared/utils/epg";
import Epg from "../../components/Epg";
import RecordingModal from "../../components/RecordingModal";
// import GuideTutorialModal from "../../components/GuideTutorial";
import {
  loadChannels,
  loadUserSubscribedChannels,
  showToastNotification,
  toggleSpinningLoaderAction,
  resetAction,
  showModalPopup,
  showRecordingSettingsPanel,
} from "../../App/state/actions";

import epgConstants from "../../shared/constants/epg";
import recordingConstants from "../../shared/constants/recordingConstants";
import { LINK_INFO, ANALYTICS_STORAGE_KEYS, MAPPED_CONTENT_TYPES } from "../../shared/constants/analytics";
import { setLocalStorage, getLocalStorage } from "../../shared/utils/localStorage";
import { getRegionalChannelNumber, isTimeNow } from "../../shared/utils/epg";
import { trackWebAction, trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { ANALYTICS_EVENT_TYPES } from "../../shared/constants/analytics";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useGuidePrograms, {
  getSegmentTimestamp,
  getNextSegmentTimestamp,
  getLookbackSegmentTimestamp,
} from "../../shared/hooks/useGuidePrograms";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import storageConstants from "../../shared/constants/storage";
import i18n from "../../i18n.js";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import GuideToolTip from "../../components/GuideToolTip/index.js";
import useCurrentTime from "../../shared/hooks/useCurrentTime.js";

const {
  EPG_SELECTED_CHANNEL_NUMBER,
  SELECTED_CHANNEL,
  SELECTED_TIME,
  HAS_SEEN_GUIDE_TOOLTIP,
  GUIDE_RESTART,
  HAS_SEEN_FAV_GUIDE_TOOL_TIP,
  TOOL_TIP_SESSION_START,
} = storageConstants;
const { MODAL_TYPES, LOOKBACK_AVAILABILITY_HOURS } = constants;
const { MY_SUBSCRIBED_CHANNELS, ALL_CHANNELS, DEFAULT_RANGE_DAYS, MY_FAVOURITE_CHANNELS } = epgConstants;

const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;
/**
 * Guide page component
 *
 * @component
 * @param {Object} props
 */
const GuidePage = (props) => {
  const {
    appProvider,
    userProfile,
    channelMapInfo,
    currentPrograms,
    isEpgFetched,
    subscribedChannels,
    loadChannels,
    loadUserSubscribedChannels,
    toggleSpinningLoaderAction,
    favouriteChannels,
    setRecordingResponse,
    editRecordingResponse,
    deleteRecordingResponse,
    getRecordingAction,
    resetAction,
    showModalPopup,
    getRecordingError,
    recordingsList,
    showToastNotification,
    manipulatedRecordingParams,
    showRecordingSettingsPanel,
    toggleSettingsPanelAction,
    setRecordingAction,
    featureToggles,
  } = props;
  const history = useHistory();
  const { isAppLanguageFrench } = useAppLanguage();
  const { isFavouritesEnabled, isLookbackEnabled, isUserProfilesEnabled } = featureToggles;
  const hasSeenGuideToolTip = getLocalStorage(HAS_SEEN_FAV_GUIDE_TOOL_TIP);
  const [dateFilterItems, setDateFilterItems] = useState([]);
  const [timeFilterItems, setTimeFilterItems] = useState([]);
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const [isLookbackSegmentFetched, setIsLookbackSegmentFetched] = useState(false);
  const recordingEventTypeRef = useRef(null);
  const recordingEventCallbackFuncRef = useRef(null);
  const epgRef = useRef(null);
  const { t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [showFavChannelToolTip, setShowFavChannelToolTip] = useState(!hasSeenGuideToolTip);
  // As part of TCDWC-3914 we need to stop display guide onboarding tutorial so commented out the code for now
  // const [showGuideOnboardingTutorial, setShowGuideOnboardingTutorial] = useState(
  //   isGuideTutorialEnabled && userProfile?.isLoggedIn && !getLocalStorage(HAS_SEEN_GUIDE_ONBOARDING_TUTORIAL)
  // );
  const [noResultFound, setNoResultFound] = useState(null);
  const [noChannelsFound, setNoChannelsFound] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const selectedRegionalChannelNumber = query.get("channelNumber");
  const rangeDays = appProvider?.config?.web?.program_load_control?.limitOfDays ?? DEFAULT_RANGE_DAYS;
  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { loadGuidePrograms } = useGuidePrograms();
  const isFiltersUpdated = useRef(false);
  const lookbackOffsetHours = useMemo(() => (isLookbackEnabled ? LOOKBACK_AVAILABILITY_HOURS : 0), [isLookbackEnabled]);
  const [epgFilters, setEpgFilters] = useState({
    channels: getLocalStorage(SELECTED_CHANNEL) ?? ALL_CHANNELS,
    restart: getLocalStorage(GUIDE_RESTART),
    customFilter: "", // custom filter for search input
  });
  // Keep a ref to track filter changes
  const epgFiltersRef = useRef(epgFilters);
  const isRecordingActionInProgress = useRef(false);
  const recordingsListRefreshed = useRef(false);
  const currentLookbackEdgeTimestampRef = useRef(null);
  const isNavigatingFromFilter = useRef(false);

  const tooltipMessages = [
    {
      tooltipHeader: translate("filter_channels"),
      tooltipText: translate("filter_channels_des"),
      position: "filter",
      showButton: true,
    },
    {
      tooltipHeader: translate("find_past_future_show"),
      tooltipText: translate("find_past_future_show_des"),
      position: "calendar",
      showButton: true,
    },
    {
      tooltipHeader: translate("favourite_channel"),
      tooltipText: translate("favourite_channel_des"),
      position: "favourite",
      showButton: true,
    },
    {
      tooltipHeader: translate("Feature_Onboarding_Contextual_Title"),
      tooltipText: translate("Feature_Onboarding_Contextual_Body"),
      position: "program",
      showButton: true,
    },
  ];

  const favChannelToolTipMessage = [
    {
      tooltipHeader: translate("favourite_channel_filter"),
      tooltipText: translate("favourite_channel_filter_des"),
      position: "filter",
      showButton: false,
    },
  ];

  const selectedChannelNumber = useMemo(() => {
    if (selectedRegionalChannelNumber) {
      return parseInt(selectedRegionalChannelNumber, 10);
    } else if (
      history?.action === "POP" &&
      ((performance.getEntriesByType("navigation").length &&
        performance.getEntriesByType?.("navigation")[0].type !== "reload") ||
        performance.navigation?.type !== performance.navigation.TYPE_RELOAD)
    ) {
      const storedChannelNumber = getSessionStorage(EPG_SELECTED_CHANNEL_NUMBER);
      if (storedChannelNumber) {
        return storedChannelNumber;
      }
    }
    return null;
  }, [selectedRegionalChannelNumber, history]);

  const initialSelectedTime = useMemo(() => {
    if (
      history?.action === "POP" &&
      ((performance.getEntriesByType("navigation").length &&
        performance.getEntriesByType?.("navigation")[0].type !== "reload") ||
        performance.navigation?.type !== performance.navigation.TYPE_RELOAD)
    ) {
      return getLocalStorage(SELECTED_TIME) ?? null;
    }
    return null;
  }, [history]);

  const [leftEdgeTime, setLeftEdgeTime] = useState(initialSelectedTime ?? getTimeslotTimestamp());
  const leftEdgeTimeRef = useRef(leftEdgeTime);
  const [isLeftEdgeOnNow, setIsLeftEdgeOnNow] = useState(getTimeslotTimestamp(leftEdgeTime) === getTimeslotTimestamp());

  /**
   * Returns the time filter values according to given timestamp and EPG range
   *
   * @param {Number} timestamp
   * @param {Number} rangeDays
   * @param {Number} lookbackOffsetHours
   * @returns {Array}
   */
  const getTimeFilterValues = useCallback(
    (timestamp, rangeDays, lookbackOffsetHours) => {
      // TODO: Refactor dropdowns to use numbers as values
      const timeFilters = [];
      for (let hour = 0; hour <= 23; hour++) {
        const time = moment(timestamp).startOf("day").add(hour, "hours");
        const nextTime = moment(timestamp)
          .startOf("day")
          .add(hour + 1, "hours");
        timeFilters.push({
          value: time.format("[hour_format]"),
          label: isTimeNow(time, nextTime, translate("epg_time_now")),
          isDisabled: !isTimeInEPGRange(time.valueOf(), rangeDays, lookbackOffsetHours),
        });
      }
      return timeFilters;
    },
    [translate]
  );

  /**
   * Returns the date filter values according to given timestamp and EPG range
   *
   * @param {Number} timestamp
   * @param {Number} rangeDays
   * @param {Number} lookbackOffsetHours
   * @returns {Array}
   */
  const getDateFilterValues = useCallback(
    (timestamp, rangeDays, lookbackOffsetHours) => {
      // TODO: Refactor dropdowns to use numbers as values
      const currentDate = getCurrentDate();
      const hour = getHour(translate("epg_time_now"), timestamp, true);
      const newDate = moment().subtract(lookbackOffsetHours, "h");
      const endDate = moment().add(rangeDays, "d");
      const dateFilters = [];
      while (newDate <= endDate) {
        let value;
        let label;
        const day = newDate.format("[date_format]");
        if (day === currentDate) {
          value = i18n.t("today");
        } else if (day === moment().add(1, "d").format("[date_format]")) {
          value = i18n.t("tomorrow");
        } else if (day === moment().subtract(1, "d").format("[date_format]")) {
          value = i18n.t("yesterday");
        } else {
          value = day;
          label = newDate.format("[date_dropdown_format]");
        }
        dateFilters.push({
          value: value,
          label: label ?? value,
          isDisabled: !isTimeInEPGRange(
            moment(`${day} ${hour}`, "[date_format] [hour_format]").valueOf(),
            rangeDays,
            lookbackOffsetHours
          ),
        });
        newDate.add(1, "d");
      }
      return dateFilters;
    },
    [translate]
  );

  /**
   * Update both date and time dropdown with correct disabled state
   * according to the newly selected time
   *
   * @param {Number} timestamp - New selected timestamp
   */
  const updateTimeDropdowns = useCallback(
    (timestamp) => {
      setIsLeftEdgeOnNow(getTimeslotTimestamp(timestamp) === getTimeslotTimestamp());
      setTimeFilterItems(getTimeFilterValues(timestamp, rangeDays, lookbackOffsetHours));
      setDateFilterItems(getDateFilterValues(timestamp, rangeDays, lookbackOffsetHours));
    },
    [rangeDays, lookbackOffsetHours, getTimeFilterValues, getDateFilterValues]
  );

  useEffect(() => {
    const currentUserId = userProfile?.user?.profile?.profileData?.userId;
    let hasSeenTooltip = getLocalStorage(HAS_SEEN_GUIDE_TOOLTIP);
    if (!hasSeenTooltip) {
      hasSeenTooltip = { seen: false, userIds: [] };
      setLocalStorage(HAS_SEEN_GUIDE_TOOLTIP, hasSeenTooltip);
    }
    if (currentUserId && !hasSeenTooltip?.userIds?.includes(currentUserId)) {
      setIsTooltipVisible(true);
      hasSeenTooltip.seen = true;
      hasSeenTooltip?.userIds?.push(currentUserId);
      setLocalStorage(HAS_SEEN_GUIDE_TOOLTIP, hasSeenTooltip);
    } else {
      setIsTooltipVisible(false);
    }
    return () => {
      setLocalStorage(SELECTED_TIME, leftEdgeTimeRef.current);
    };
  }, [userProfile]);

  const currentTime = useCurrentTime(3600000, true);
  useEffect(() => {
    // We need to show the fav channel tool tip after every 72 hours
    const thresholdTime = getLocalStorage(TOOL_TIP_SESSION_START) + 259200000;
    if (thresholdTime <= currentTime) {
      setShowFavChannelToolTip(true);
    }
  }, [currentTime]);

  useEffect(() => {
    return () => {
      toggleSpinningLoaderAction(false);
    };
  }, [toggleSpinningLoaderAction]);

  useEffect(() => {
    if (manipulatedRecordingParams) {
      toggleSpinningLoaderAction(true, "contextual-loader-wrapper");
    } else {
      toggleSpinningLoaderAction(false);
    }
  }, [manipulatedRecordingParams, toggleSpinningLoaderAction]);

  useEffect(() => {
    toggleSpinningLoaderAction(true);
    loadGuidePrograms(getSegmentTimestamp());
    loadGuidePrograms(getNextSegmentTimestamp());
    if (isLookbackEnabled) {
      // load buffered lookback segment
      const lookBackEdgeTimestamp = getLookbackSegmentTimestamp();
      loadGuidePrograms(lookBackEdgeTimestamp);
      currentLookbackEdgeTimestampRef.current = lookBackEdgeTimestamp;
    }
  }, [loadGuidePrograms, toggleSpinningLoaderAction, isLookbackEnabled]);

  useEffect(() => {
    if (isEpgFetched) {
      toggleSpinningLoaderAction(false);
    }
  }, [isEpgFetched, toggleSpinningLoaderAction]);

  useEffect(() => {
    // Show no results found if none of the program segments contain data
    setNoResultFound(
      isEpgFetched &&
        Object.keys(currentPrograms)?.every((timestamp) => {
          return !currentPrograms[timestamp] || Object.keys(currentPrograms[timestamp]).length === 0;
        })
    );
  }, [currentPrograms, isEpgFetched]);

  useEffect(() => {
    let defaultChannelsFilter;
    if (appProvider) {
      if (appProvider.panicMode) {
        defaultChannelsFilter = ALL_CHANNELS;
      } else {
        const selectedChannel = getLocalStorage(SELECTED_CHANNEL);
        if (!selectedChannel) {
          if (userProfile?.isLoggedIn) {
            defaultChannelsFilter = MY_SUBSCRIBED_CHANNELS;
          } else {
            defaultChannelsFilter = ALL_CHANNELS;
          }
        } else {
          /**
           * If favourites is disabled then we do not call the favourites call, in tha case favouriteChannels = null
           * But if favourites is enabled then we make the call we get an empty favourites array
           * (on success when no channels are favourited or failure). Therefore this code block will run regardless.
           */
          if (favouriteChannels || !isFavouritesEnabled) {
            if (selectedChannel === MY_FAVOURITE_CHANNELS) {
              if (favouriteChannels?.length > 0) {
                defaultChannelsFilter = MY_FAVOURITE_CHANNELS;
              } else {
                defaultChannelsFilter = MY_SUBSCRIBED_CHANNELS;
              }
            } else {
              defaultChannelsFilter = selectedChannel;
            }
          }
        }
      }
      setEpgFilters((filters) => ({
        ...filters,
        channels: defaultChannelsFilter ?? filters.channels,
      }));
    }
  }, [appProvider, userProfile, favouriteChannels, isFavouritesEnabled]);

  useEffect(() => {
    epgFiltersRef.current = epgFilters;
    if (!appProvider.panicMode) {
      setLocalStorage(SELECTED_CHANNEL, epgFilters.channels);
    }
  }, [epgFilters, appProvider]);

  useEffect(() => {
    if (appProvider && userProfile?.isLoggedIn && !subscribedChannels) {
      loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled);
    }
  }, [
    appProvider,
    userProfile,
    subscribedChannels,
    loadUserSubscribedChannels,
    cancelTokenSource,
    isUserProfilesEnabled,
  ]);

  useEffect(() => {
    if (appProvider && !channelMapInfo) {
      loadChannels(appProvider, cancelTokenSource);
    }
  }, [channelMapInfo, appProvider, loadChannels, cancelTokenSource]);

  useEffect(() => {
    if (leftEdgeTime) {
      const currentSegmentTimestamp = getSegmentTimestamp(moment().valueOf());
      const leftEdgeSegmentTimestamp = getSegmentTimestamp(leftEdgeTime);
      const nextSegmentTimestamp = getNextSegmentTimestamp(leftEdgeTime);
      const existingSegments = Object.keys(currentPrograms).map((timestamp) => parseInt(timestamp, 10));

      if (leftEdgeSegmentTimestamp !== currentSegmentTimestamp) {
        if (!existingSegments.includes(leftEdgeSegmentTimestamp)) {
          toggleSpinningLoaderAction(true);
          loadGuidePrograms(leftEdgeSegmentTimestamp);
        }
        // Pre-load next segment to fill guide on bigger screens
        if (!existingSegments.includes(nextSegmentTimestamp)) {
          toggleSpinningLoaderAction(true);
          loadGuidePrograms(nextSegmentTimestamp);
        }
      }
      leftEdgeTimeRef.current = leftEdgeTime;
      updateTimeDropdowns(leftEdgeTime);
    }
  }, [currentPrograms, leftEdgeTime, loadGuidePrograms, toggleSpinningLoaderAction, updateTimeDropdowns]);

  useEffect(() => {
    //look back lazy loading process, only happens when lookback feature is enabled
    if (leftEdgeTime && currentLookbackEdgeTimestampRef.current && !isNavigatingFromFilter.current) {
      const lookbackSegmentTimestamp = getLookbackSegmentTimestamp(leftEdgeTime);
      const existingSegments = Object.keys(currentPrograms).map((timestamp) => parseInt(timestamp, 10));
      const lookbackEdgeTimeStamp = getSegmentTimestamp(moment().subtract(lookbackOffsetHours, "hours"));
      if (leftEdgeTime <= currentLookbackEdgeTimestampRef.current && leftEdgeTime > lookbackEdgeTimeStamp) {
        // Load one more lookback segment
        if (
          lookbackSegmentTimestamp &&
          !existingSegments.includes(lookbackSegmentTimestamp) &&
          lookbackSegmentTimestamp < currentLookbackEdgeTimestampRef.current
        ) {
          toggleSpinningLoaderAction(true);
          loadGuidePrograms(lookbackSegmentTimestamp);
          currentLookbackEdgeTimestampRef.current = lookbackSegmentTimestamp;
          setIsLookbackSegmentFetched(true);
        }
      }
    }
  }, [
    currentPrograms,
    leftEdgeTime,
    loadGuidePrograms,
    toggleSpinningLoaderAction,
    setIsLookbackSegmentFetched,
    lookbackOffsetHours,
  ]);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const trackFilterChange = useCallback(
    (updatedFilters) => {
      const previousFilters = {
        date: getDate(leftEdgeTimeRef.current),
        time: getHour(translate("epg_time_now"), leftEdgeTimeRef.current, true),
        channels: epgFiltersRef.current.channels,
        filterRestartableChannel: epgFiltersRef.current.restart,
        language: appProvider.lang,
      };

      const currentFilters = {
        ...previousFilters,
        ...updatedFilters,
      };

      isFiltersUpdated.current = Object.keys(currentFilters).some((key) => {
        return currentFilters[key] !== previousFilters[key];
      });
    },
    [appProvider, translate]
  );

  /**
   * Epg left edge time change handler loads data for corresponding timestamp
   * @param {Number} timestamp
   */
  const timeChangeHandler = useCallback(
    (timestamp) => {
      if (timestamp) {
        trackFilterChange({
          date: getDate(timestamp),
          time: getHour(translate("epg_time_now"), timestamp),
        });
        setLeftEdgeTime(timestamp);
      }
    },
    [trackFilterChange, translate]
  );

  /**
   * Calculates the combination of date and time
   *
   * @param {String} dateTime - date time string
   * @returns {Number} Updated timestamp
   */
  const getUpdatedTimeStampNew = (dateTime) => {
    const formatStr = isAppLanguageFrench ? "ddd D MMM YYYY HH:mm" : "ddd D MMM YYYY h:mm A";
    return moment(dateTime, formatStr);
  };

  /**
   * Date and time selection handler
   *
   * @param {Object} date - date time string
   */
  const dateTimeSelectionHandler = (dateTime) => {
    const newTime = getUpdatedTimeStampNew(dateTime.value);
    trackFilterChange({ dateTime: getDate(newTime) });
    isNavigatingFromFilter.current = true;
    setLeftEdgeTime(newTime);
    if (epgRef.current) {
      epgRef.current.scrollToTime(newTime);
    }
  };

  const filterClickHandler = (filterObj) => {
    const { channels, restart } = filterObj;
    if (!!channels) {
      trackFilterChange({ channels });
    }
    if (typeof restart === "boolean") {
      trackFilterChange({ restart });
    }
    setEpgFilters((filters) => ({
      channels: channels ?? filters.channels,
      restart: typeof restart === "boolean" ? restart : filters.restart,
    }));
  };

  const recordingSystemType = useMemo(
    () => (userProfile?.isLoggedIn ? getRecordingSystemType(userProfile) : null),
    [userProfile]
  );
  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  /**
   * This useEffect is used to keep track of recordings list refreshes
   */
  useEffect(() => {
    recordingsListRefreshed.current = recordingsList && isRecordingActionInProgress.current;
  }, [recordingsList]);

  /**
   * Fetch the user's recordings and save the response in redux
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(
          appProvider,
          true,
          null,
          null,
          null,
          MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
          recordingParamsMR
        );
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR]
  );

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    resetAction(SET_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetEditRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
  ]);

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType, recordingType) => {
      let toastMsg;
      if (recordingInfo.code) {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_NOT_UPDATED" : "RECORDING_NOT_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(
            RECORDING_PARAMS[recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_SET" : "RECORDING_SET"]
          );
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_UPDATED" : "RECORDING_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_CANCELLED" : "RECORDING_CANCELLED"
            ]
          );
        }
      }

      return toastMsg;
    },
    [translate]
  );

  /**
   * Triggers a recording analytics event based on a toast message passed
   * @param {String} toastMsg
   */
  const triggerRecordingAnalyticsEvent = useCallback(
    (toastMsg) => {
      if (recordingEventTypeRef.current) {
        const isSeriesEvent = toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_SET);
        if (isSeriesEvent || toastMsg === translate(RECORDING_PARAMS.RECORDING_SET)) {
          const selectedAssetSchedule = {
            ...selectedAssetRecordingInfo?.assetToRecord,
            channelNumber: getRegionalChannelNumber(
              selectedAssetRecordingInfo?.assetToRecord.channel,
              appProvider?.channelMapID
            ),
          };
          trackGenericAction(recordingEventTypeRef.current, {
            isSeriesEvent,
            isItemLive: true,
            ...(isSeriesEvent ? mapRecordingSeriesMediaContent(selectedAssetSchedule) : selectedAssetSchedule),
          });
          logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
          logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_START);
        } else if (
          toastMsg === translate(RECORDING_PARAMS.RECORDING_UPDATED) ||
          toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_UPDATED)
        ) {
          logNREvent(NR_PAGE_ACTIONS.RECORDING_EDIT);
          logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_EDIT);
          recordingEventCallbackFuncRef.current();
        } else {
          trackRecordingError(recordingEventTypeRef.current, toastMsg, {
            errorCode: setRecordingResponse?.code,
            errorMessage: setRecordingResponse?.message,
          });
        }
      }
    },
    [setRecordingResponse, selectedAssetRecordingInfo, translate, appProvider]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(
          recordingInfo,
          manipulatedRecordingParams.actionType,
          manipulatedRecordingParams.recordingType
        );
        if (toastMsg) {
          showToastNotification(toastMsg);
          triggerRecordingAnalyticsEvent(toastMsg);
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, showToastNotification, triggerRecordingAnalyticsEvent]
  );

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  const trackSetRecording = (setRecordingResponse, isMR = false) => {
    const reccordingPayload = isMR
      ? setRecordingResponse?.recordingParams
      : setRecordingResponse?.content?.containers[0];
    reccordingPayload.isItemLive = true;
    reccordingPayload.mappedContentType = isMR ? MAPPED_CONTENT_TYPES.LPVR : MAPPED_CONTENT_TYPES.CPVR;
    trackGenericAction(ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START, reccordingPayload);
  };

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          trackSetRecording(setRecordingResponse, isMR);
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              () => {
                showToastNotification(translate("recording_not_set"));
              },
              MODAL_TYPES.ERROR,
              showModalPopup
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          trackSetRecording(setRecordingResponse, isMR);
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [
    setRecordingResponse,
    isMR,
    fetchRecordings,
    showModalPopup,
    resetRecordingActionValues,
    showToastNotification,
    translate,
  ]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/edit/cancel recording action.
   */
  useEffect(() => {
    if (
      selectedAssetRecordingInfo &&
      manipulatedRecordingParams &&
      isRecordingActionInProgress.current &&
      recordingsListRefreshed.current
    ) {
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      const selectedRecordingInfo = getRecordingInfo(
        isMR,
        selectedAssetRecordingInfo.assetToRecord,
        recordingsList,
        appProvider?.channelMapID
      );
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (
        isMR &&
        (selectedRecordingInfo.recordingSeriesConflictCheck || selectedRecordingInfo.recordingEventConflictCheck)
      ) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }

      recordingsListRefreshed.current = false;
      resetRecordingActionValues();
    }
  }, [
    selectedAssetRecordingInfo,
    isMR,
    displayRecordingToast,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    recordingsList,
    appProvider,
  ]);

  /**
   * A callback function which will be triggered after a recording has been updated
   * @param {Object} recordingItem
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} mappedContentType live|svod|tvod|cpvr|lpvr
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   * @param {Object} recordingInfo
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({
      recordingItem,
      isItemLive,
      isSeriesEvent,
      mappedContentType,
      analyticsEventType,
      toolSelections,
      error,
      recordingInfo,
    }) => {
      recordingEventCallbackFuncRef.current = () => {
        trackGenericAction(analyticsEventType, {
          isItemLive,
          isSeriesEvent,
          toolSelections,
          mappedContentType,
          ...(isSeriesEvent
            ? mapRecordingSeriesMediaContent(recordingItem || selectedAssetRecordingInfo.assetToRecord)
            : recordingInfo?.assetToRecord || recordingItem),
        });
      };
      if (error) {
        // Triggers a stop|delete recording error event.
        // The error of start recording event comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        if (
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE ||
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE
        ) {
          // At this moment, the result of editing a recording is unknown, but a toastMessage will show up on the screen according to the result of editing a recording.
          // Thus set the recordingEventType here, and then trigger the event based on the toastMessage later.
          recordingEventTypeRef.current = analyticsEventType;
        } else {
          // The result of stop|delete a recording is ready at this moment
          recordingEventCallbackFuncRef.current();
        }
      }
    },
    [selectedAssetRecordingInfo]
  );

  /** Opens the modal to cancel in progress episode recordings. Currently used for CPVR only.
   *
   * @param {Object} recordingInfo Recording information
   * @param {Object} programDetails Program information
   */
  const openCancelRecordingModal = useCallback(
    (recordingInfo, programDetails) => {
      const modalContent = {
        recordingInfo,
        programDetails,
        isRecordingRecorded: false,
        recordingType: RECORDING_PARAMS.EVENT,
        isMR: false,
      };
      showModalPopup(MODAL_TYPES.RECORDING, modalContent);
    },
    [showModalPopup]
  );

  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      const isSeriesEvent = typeOfRecording === RECORDING_PARAMS.SERIES;
      trackRecordingUpdateEventCallback({
        isSeriesEvent,
        isItemLive: true,
        recordingItem: recordingInfo?.assetToRecord,
        mappedContentType: MAPPED_CONTENT_TYPES.LIVE,
        analyticsEventType: isSeriesEvent
          ? ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START
          : ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
      });

      !isMR && toggleSpinningLoaderAction(true, "loader-wrapper setting-spinner-loader");

      showRecordingSettingsPanel(
        typeOfRecording,
        recordingInfo,
        null,
        false,
        (recordingData) => trackRecordingUpdateEventCallback({ recordingInfo, ...recordingData }),
        !isMR
      );
      toggleSettingsPanelAction(true);
    },
    [
      trackRecordingUpdateEventCallback,
      isMR,
      toggleSpinningLoaderAction,
      showRecordingSettingsPanel,
      toggleSettingsPanelAction,
    ]
  );
  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event vs series)
   */
  const scheduleRecording = useCallback(
    (typeOfRecording) => {
      if (selectedAssetRecordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, selectedAssetRecordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, selectedAssetRecordingInfo, setRecordingAction]
  );
  // const updatePopUp = () => {
  //   setShowGuideOnboardingTutorial(false);
  // };
  const closeRecordingModal = () => {
    setShowRecordingModal(false);
  };
  /**
   * Displays the appropriate overlaying component when selecting edit/cancel from the right click menu
   *
   * @param {Object} recordingInfo - Recording info object related to the selected program
   */
  const updateRecordingHandler = useCallback(
    (recordingInfo) => {
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        if (isMR) {
          const hasEventRecording =
            recordingInfo.recordingEventScheduledCheck && !recordingInfo.recordingSeriesScheduledCheck;
          if (hasEventRecording) {
            // If the recording has been set as a single EVENT recording, don't need to show the recording modal.
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
          } else {
            setShowRecordingModal(true);
          }
        } else {
          const cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          if (doesCPVREventRecordingExist(cpvrRecordingStatus)) {
            if (isCPVRRecordingInProgress(recordingInfo.eventRecordingItem)) {
              // event recording is in progress, show the event cancel modal
              openCancelRecordingModal(recordingInfo, recordingInfo.assetToRecord, false);
            } else {
              // event recording is scheduled but not in progress, show the event settings panel
              showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
              toggleSettingsPanelAction(true);
            }
          } else {
            /*
              If a series recording exists for the show that this episode belongs to but there is no episode recording
              for this specific episode, we do not allow the user to edit the series recording.
            */
            setShowRecordingModal(true);
          }
        }
      }
    },
    [isMR, openCancelRecordingModal, showRecordingSettingHandler, toggleSettingsPanelAction]
  );

  const crossClickHandler = () => {
    setEpgFilters((filters) => ({
      ...filters,
      customFilter: "",
    }));
    setNoChannelsFound(false);
  };

  const handleNoSearchResults = useCallback(() => {
    setNoChannelsFound(true);
  }, []);

  const handleSearchChange = (e) => {
    setEpgFilters((filters) => ({
      ...filters,
      customFilter: e.target.value,
    }));
    setNoChannelsFound(false);
  };

  const handleCloseTooltip = () => {
    setIsTooltipVisible(false);
  };

  return (
    <>
      <SeoPageTags title={translate("guide")} keywords={["optik", "telus"]} />
      <div className="epg-wrapper">
        <div className="epg-filters">
          <div className="category-dropdown">
            <CategoryDropdown filters={epgFilters} filterClickHandler={filterClickHandler} isGuideFilter="true" />
          </div>
          <SearchBox
            epgFilters={epgFilters}
            handleSearchChange={handleSearchChange}
            crossClickHandler={crossClickHandler}
          />
        </div>
        {userProfile?.isLoggedIn &&
          (isTooltipVisible ||
            (favouriteChannels?.length > 0 &&
              epgFilters.channels !== MY_FAVOURITE_CHANNELS &&
              showFavChannelToolTip)) && (
            <GuideToolTip
              tooltipMessages={tooltipMessages}
              handleClose={
                isTooltipVisible
                  ? handleCloseTooltip
                  : () => {
                      setShowFavChannelToolTip(false);
                    }
              }
              favChannelToolTipMessage={favChannelToolTipMessage}
              isFavChannelToolTipActive={!isTooltipVisible && showFavChannelToolTip}
              gridHeight={epgRef.current?.gridHeight}
            />
          )}
        <div className="epg-container">
          {!noResultFound && (
            <Epg
              ref={epgRef}
              timeChangeHandler={timeChangeHandler}
              selectedChannelNumber={selectedChannelNumber}
              selectedTime={initialSelectedTime}
              filters={epgFilters}
              onApplyFilters={(isSearchSuccessful) => {
                if (isFiltersUpdated.current) {
                  trackWebAction(ANALYTICS_EVENT_TYPES.GUIDE_SEARCH, {
                    date: getDate(leftEdgeTimeRef.current),
                    time: getHour(translate("epg_time_now"), leftEdgeTimeRef.current, true),
                    channels: epgFilters.channels,
                    language: appProvider.lang,
                    isSearchSuccessful,
                  });
                  isFiltersUpdated.current = false;
                }
              }}
              setSelectedAssetRecordingInfo={setSelectedAssetRecordingInfo}
              updateRecordingHandler={updateRecordingHandler}
              lookbackOffsetHours={lookbackOffsetHours}
              isLookbackSegmentFetched={isLookbackSegmentFetched}
              setIsLookbackSegmentFetched={setIsLookbackSegmentFetched}
              selectedDateAndTimeHandler={dateTimeSelectionHandler}
              showToastNotification={showToastNotification}
              onNoSearchResults={handleNoSearchResults}
              noChannelsFound={noChannelsFound}
            />
          )}
          {/* {showGuideOnboardingTutorial && <GuideTutorialModal updatePopUp={updatePopUp} />}  */}
          {noResultFound && <div className="no-result-found">{translate("no_results_found")}</div>}
          {noChannelsFound && <div className="no-channels-found">{translate("no_channels_found")}</div>}
        </div>
        {showRecordingModal && selectedAssetRecordingInfo && (
          <RecordingModal
            closeModal={closeRecordingModal}
            scheduleRecordingHandler={scheduleRecording}
            recordingInfo={selectedAssetRecordingInfo}
            editRecordingHandler={(recordingInfo, typeOfRecording) => {
              showRecordingSettingHandler(recordingInfo, typeOfRecording);
            }}
            openCancelRecordingModal={() => {
              if (!isMR) openCancelRecordingModal(selectedAssetRecordingInfo, selectedAssetRecordingInfo.assetToRecord);
            }}
          />
        )}
      </div>
    </>
  );
};

GuidePage.propTypes = {
  currentPrograms: PropTypes.object,
  appProvider: PropTypes.object,
  userProfile: PropTypes.object,
  channelMapInfo: PropTypes.object,
  subscribedChannels: PropTypes.object,
  favouriteChannels: PropTypes.array,
  loadChannels: PropTypes.func,
  loadUserSubscribedChannels: PropTypes.func,
  showToastNotification: PropTypes.func,
  toggleSpinningLoaderAction: PropTypes.func,
  featureToggles: PropTypes.object,
};

function mapStateToProps({ epg, app, recording }) {
  return {
    currentPrograms: epg.currentPrograms,
    isEpgFetched: epg.isEpgFetched,
    appProvider: app.provider,
    userProfile: app.userProfile,
    channelMapInfo: app.channelMapInfo,
    subscribedChannels: app.subscribedChannels,
    favouriteChannels: app.favouriteChannels,
    setRecordingResponse: recording.setRecordingResponse,
    editRecordingResponse: recording.editRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    getRecordingError: recording.getRecordingError,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    recordingsList: recording.recordingsList,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  loadChannels,
  loadUserSubscribedChannels,
  showToastNotification,
  toggleSpinningLoaderAction,
  getRecordingAction,
  resetAction,
  showModalPopup,
  showRecordingSettingsPanel,
  toggleSettingsPanelAction,
  setRecordingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidePage);

/*** Helpers ***/

/**
 * Check if time is in scope of EPG grid
 *
 * @param {Number} timestamp
 * @param {Number} rangeDays
 * @returns {Boolean}
 */
const isTimeInEPGRange = (timestamp, rangeDays, lookbackOffsetHours) => {
  if (
    timestamp >= moment().subtract(lookbackOffsetHours, "h").startOf("hour") &&
    timestamp <= moment().add(rangeDays, "d")
  ) {
    return true;
  }
  return false;
};
