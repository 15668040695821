import middleware from "../../shared/middleware";
import constants from "../../shared/constants";
import error from "../../shared/constants/error";
import i18n from "../../i18n";

import { getFavouriteItemId } from "../../shared/utils/favourites";

import telusConvivaAnalytics from "../../shared/utils/convivaAnalytics";

const {
  getUserProfile,
  getUserProfileList,
  getChannels,
  getSubscribedChannels,
  getFavourites,
  addToFavourites,
  removeFromFavourites,
  getUserProfileRecording,
  retrieveAvsItems,
  updateBookmark,
} = middleware;
const { PAGE_CONTENT_ITEM_TYPES, CONTAINER_LAYOUTS, ACTION_KEYS, TARGET_TYPE } = constants;
const { API_STATUS_CODES, AVS_ERROR_CODES } = error;

// Action types
const API_FAILURE = "API_FAILURE";
export const LOAD_CHANNELS = "LOAD_CHANNELS";
export const LOAD_SUBSCRIBED_CHANNELS = "LOAD_SUBSCRIBED_CHANNELS";
export const DISPLAY_TOP_NAV = "DISPLAY_TOP_NAV";
export const DISPLAY_MODAL_POPUP = "DISPLAY_MODAL_POPUP";
export const DISPLAY_TOAST_NOTIFICATION = "DISPLAY_TOAST_NOTIFICATION";
export const LOAD_SUBSCRIBED_NETWORK = "LOAD_SUBSCRIBED_NETWORK";
export const DISPLAY_PLAYBACK_ERROR = "DISPLAY_PLAYBACK_ERROR";
export const LOAD_ON_NOW_PROGRAMS = "LOAD_ON_NOW_PROGRAMS";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const LOAD_USER_PROFILES = "LOAD_USER_PROFILES";
export const DISPLAY_RECORDING_SETTING = "DISPLAY_RECORDING_SETTING";
export const SET_CONVIVA_CONTENT_SUBTYPE = "SET_CONVIVA_CONTENT_SUBTYPE";
export const SET_API_REQUEST_LOADING = "SET_API_REQUEST_LOADING";
export const TOGGLE_SPINNING_LOADER = "TOGGLE_SPINNING_LOADER";
export const VIDEO_PLAYING = "VIDEO_PLAYING";
export const RELOAD_APP = "RELOAD_APP";
export const TOGGLE_CHANNEL_SIDE_PANEL = "TOGGLE_CHANNEL_SIDE_PANEL";
export const FAVOURITE_CHANNELS = "FAVOURITE_CHANNELS";
export const FAVOURITE_ASSETS = "FAVOURITE_ASSETS";
export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const DELETE_FAVOURITE = "DELETE_FAVOURITE";
export const RECORDING_USER_PROFILE = "RECORDING_USER_PROFILE";
export const SEARCH_COLLECTION = "SEARCH_COLLECTION";
// Action methods
function apiFailure() {
  // TODO: Handle api failure inside reducer by passing appropriate data
  return { type: API_FAILURE };
}

function loadChannelSuccess(channelMapInfo) {
  return { type: LOAD_CHANNELS, channelMapInfo };
}

export const loadChannels = (appProvider, cancelTokenSource = null) => {
  return async (dispatch) => {
    try {
      const channelMapInfo = await getChannels(appProvider, cancelTokenSource);
      if (channelMapInfo) {
        dispatch(loadChannelSuccess(channelMapInfo));
      }
    } catch (error) {
      dispatch(apiFailure());
    }
  };
};

function loadSubscribedChannelSuccess(subscribedChannelInfo) {
  return { type: LOAD_SUBSCRIBED_CHANNELS, subscribedChannelInfo };
}

function loadSubscribedChannelFailure() {
  return { type: LOAD_SUBSCRIBED_CHANNELS, subscribedChannelInfo: null };
}

export const loadUserSubscribedChannels = (
  appProvider,
  userProfile,
  cancelTokenSource = null,
  isUserProfilesEnabled
) => {
  return async (dispatch) => {
    try {
      if (appProvider?.packageIds) {
        // making data call for subscribed channels only if LIVE_SUB and VOD_LIVE_SUB technical packages exist
        const subscribedChannelInfo = await getSubscribedChannels(
          appProvider,
          userProfile,
          cancelTokenSource,
          isUserProfilesEnabled
        );
        if (subscribedChannelInfo) {
          dispatch(loadSubscribedChannelSuccess(subscribedChannelInfo));
        }
      } else {
        dispatch(loadSubscribedChannelFailure());
      }
    } catch (error) {
      dispatch(loadSubscribedChannelFailure());
    }
  };
};

export function hideTopNav(status) {
  return { type: DISPLAY_TOP_NAV, status };
}

function retrieveFavouriteChannelSuccess(favouriteChannels) {
  return { type: FAVOURITE_CHANNELS, favouriteChannels };
}
function retrieveFavouriteChannelFailure() {
  return { type: FAVOURITE_CHANNELS, favouriteChannels: [] };
}

function retrieveFavouriteAssetsSuccess(favouriteAssets) {
  return { type: FAVOURITE_ASSETS, favouriteAssets };
}
function retrieveFavouriteAssetsFailure() {
  return { type: FAVOURITE_ASSETS, favouriteAssets: [] };
}
function updateFavouritesSuccess(newFavouriteItem) {
  return { type: ADD_FAVOURITE, content: true, newFavouriteItem };
}
function updateFavouritesFailure() {
  return { type: ADD_FAVOURITE, content: false };
}
function deleteFavouritesSuccess(itemId, itemLayout) {
  return { type: DELETE_FAVOURITE, content: false, itemId, itemLayout };
}
function deleteFavouritesFailure() {
  return { type: DELETE_FAVOURITE, content: true };
}

export const resetAction = (actionType, reducerKey) => {
  return { type: actionType, [reducerKey]: null };
};

const recordingUserProfileSuccess = (recordingProfile) => {
  return { type: RECORDING_USER_PROFILE, recordingProfile };
};

export const showModalPopup = (modalType, modalContent) => {
  return {
    type: DISPLAY_MODAL_POPUP,
    modalType,
    modalContent,
  };
};

// This redux value is used for ECE and keepalive heartbeat.
export const setVideoPlaying = (status) => {
  return {
    type: VIDEO_PLAYING,
    status: status,
  };
};

// This is specific to ECE to determine if we should reload the app
export const setReloadApp = (status) => {
  return {
    type: RELOAD_APP,
    status: status,
  };
};

export const showToastNotification = (message, icon, analyticsInfo) => {
  return { type: DISPLAY_TOAST_NOTIFICATION, message, icon, analyticsInfo };
};

export const showPlaybackError = (code, message) => {
  return {
    type: DISPLAY_PLAYBACK_ERROR,
    code,
    message,
  };
};

function loadUserProfileSuccess(userProfile) {
  return { type: LOAD_USER_PROFILE, userProfile };
}

function updateConvivaUserProfile(appProvider, userProfile) {
  telusConvivaAnalytics.initConvivaAnalytics(appProvider, {
    ...userProfile,
    masterAccountHouseholdID: userProfile?.masterAccountHouseholdID,
  });
}

export const loadUserProfile = (appProvider) => {
  return async (dispatch) => {
    try {
      const newUserProfile = await getUserProfile(appProvider);
      if (newUserProfile?.profile) {
        dispatch(loadUserProfileSuccess(newUserProfile.profile));
        updateConvivaUserProfile(appProvider, newUserProfile);
      }
    } catch (error) {
      dispatch(apiFailure());
    }
  };
};

function loadUserProfilesSuccess(profiles) {
  return { type: LOAD_USER_PROFILES, profiles };
}

function loadUserProfilesFailure() {
  return { type: LOAD_USER_PROFILES, profiles: [] };
}

export const loadUserProfiles = (appProvider, cancelTokenSource = null) => {
  return async (dispatch) => {
    try {
      const profiles = await getUserProfileList(appProvider, cancelTokenSource);
      if (profiles) {
        dispatch(loadUserProfilesSuccess(profiles));
        updateConvivaUserProfile(
          appProvider,
          profiles.find((profile) => profile?.isMaster)
        );
      }
    } catch (error) {
      dispatch(loadUserProfilesFailure());
    }
  };
};

export const showRecordingSettingsPanel = (
  typeOfRecording,
  recordingInfo,
  allSchedules,
  isPVRManager = false,
  trackRecordingUpdateEvent = () => {},
  isScheduleCallNeeded = false
) => {
  let recordingSettingsPanelData = {
    allSchedules: allSchedules,
    recordingInfo: recordingInfo,
    recordingType: typeOfRecording,
    isPVRManager,
    trackRecordingUpdateEvent,
    isScheduleCallNeeded,
  };
  return {
    type: DISPLAY_RECORDING_SETTING,
    recordingSettingsPanelData,
  };
};

export const setConvivaContentSubType = (convivaContentSubType) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONVIVA_CONTENT_SUBTYPE, convivaContentSubType });
  };
};

/**
 * @param {boolean} state
 * @returns true if request is loading
 */
export function setRequestLoading(state) {
  return { type: SET_API_REQUEST_LOADING, state };
}

/**
 * @param {boolean} toggle
 * @param {string} className
 * @returns spinning loader action
 */
export function toggleSpinningLoaderAction(toggle, className = null) {
  const spinningLoaderParams = { toggle: toggle, className: className };
  return { type: TOGGLE_SPINNING_LOADER, spinningLoaderParams };
}

/**
 * @param {boolean} displayChannelSidePanel
 * @returns {object} channel side panel action
 */
export function toggleChannelSidePanelAction(displayChannelSidePanel) {
  return { type: TOGGLE_CHANNEL_SIDE_PANEL, displayChannelSidePanel };
}

/**
 * Returns favourite channels and assets object from the call we make from the client side.
 * @param {Object} appProvider
 * @param {String} contentType - LIVE/MOVIE,TVSHOW
 * @param {Object} subscribedChannels
 */
export const retrieveFavourite = (appProvider, contentType, subscribedChannels) => {
  let successAction, failureAction;
  if (contentType === PAGE_CONTENT_ITEM_TYPES.live) {
    successAction = retrieveFavouriteChannelSuccess;
    failureAction = retrieveFavouriteChannelFailure;
  } else if (contentType.includes(PAGE_CONTENT_ITEM_TYPES.movie)) {
    successAction = retrieveFavouriteAssetsSuccess;
    failureAction = retrieveFavouriteAssetsFailure;
  }
  return async (dispatch) => {
    try {
      const favourites = await getFavourites(appProvider, contentType);
      const subscribedChannelContainers = new Set(
        subscribedChannels?.containers?.map((channel) => parseInt(channel.id)) ?? []
      );
      const favouriteContainers =
        subscribedChannelContainers.size === 0
          ? favourites?.containers
          : favourites?.containers?.filter((container) => subscribedChannelContainers.has(parseInt(container.id))) ||
            [];
      dispatch(successAction(favouriteContainers));
    } catch (error) {
      dispatch(failureAction());
    }
  };
};

export const addFavourites = (appProvider, item, itemType, isSportSeries = false) => {
  return async (dispatch) => {
    try {
      const itemId = getFavouriteItemId(item, isSportSeries);
      const title =
        item?.metadata?.contentType === PAGE_CONTENT_ITEM_TYPES.live
          ? item.metadata?.channelName
          : isSportSeries
          ? item.metadata?.seriesTitle
          : item.metadata?.title;
      const feedContent = await addToFavourites(appProvider, itemId, itemType, title);
      if (feedContent && feedContent.status === API_STATUS_CODES.OK) {
        let modifiedItem = null;
        if (item?.actions?.[0]?.uri) {
          // From guide page
          if (item.metadata?.extendedMetadata?.dlum?.uaGroupType === "TVSHOW") {
            item.actions[0].uri = `/PAGE/DETAILS/TVSHOW/${item.metadata.extendedMetadata.dlum.uaGroupId}`;
          }
        } else {
          // From details page
          modifiedItem = {
            ...item,
            actions: [
              {
                key: ACTION_KEYS.ON_CLICK,
                uri: `/PAGE/DETAILS/${item?.metadata?.uaType === "MOVIE" ? "MOVIE" : "TVSHOW"}/${item.metadata.uaId}`,
              },
            ],
          };
        }
        dispatch(updateFavouritesSuccess(modifiedItem ?? item));
        return feedContent.status;
      }
    } catch (error) {
      if (error?.data?.resultCode === "KO") {
        if (error.data.errorDescription === AVS_ERROR_CODES.FAVOURITE_LIMIT_REACHED) {
          dispatch(showToastNotification(i18n.t("favourite_limit_msg")));
        }
      }
      dispatch(updateFavouritesFailure());
    }
  };
};

export const deleteFavourites = (appProvider, item, itemType, isSportSeries = false) => {
  return async (dispatch) => {
    try {
      const itemId = getFavouriteItemId(item, isSportSeries);
      const itemLayout =
        item?.metadata?.contentType === PAGE_CONTENT_ITEM_TYPES.live
          ? CONTAINER_LAYOUTS.CHANNEL_ITEM
          : item.layout?.toLowerCase();
      const feedContent = await removeFromFavourites(appProvider, itemId, itemType);
      if (feedContent && feedContent.status === API_STATUS_CODES.OK) {
        dispatch(deleteFavouritesSuccess(itemId, itemLayout));
      }
    } catch (error) {
      dispatch(deleteFavouritesFailure());
    }
  };
};

/**
 * To fetch the recording profile object.
 * @param {Object} appProvider App provider information
 * @returns Object
 */
export const getRecordingProfile = (appProvider) => {
  return async (dispatch) => {
    try {
      const recordingProfile = await getUserProfileRecording(appProvider);
      if (recordingProfile?.profile) {
        dispatch(recordingUserProfileSuccess(recordingProfile.profile));
      }
    } catch (error) {
      dispatch(apiFailure());
    }
  };
};

function retrieveSearchCollectionlSuccess(searchCollection) {
  return { type: SEARCH_COLLECTION, searchCollection };
}
function retrieveSearchCollectionFailure() {
  return { type: SEARCH_COLLECTION, searchCollection: [] };
}

/**
 * Returns search collection for search page collection cards.
 * @param {Object} appProvider
 */
export const retrieveSearchCollection = (appProvider) => {
  return async (dispatch) => {
    try {
      const searchCollection = {};
      const layouts = await retrieveAvsItems(appProvider, appProvider.config.web.search_collection_uri);
      const searchCollectionData = layouts?.containers?.filter((container) => container.layout === "searchCollection");
      searchCollection.label = searchCollectionData?.[0]?.metadata?.label;
      const collectionItemsUri = searchCollectionData?.[0]?.retrieveItems?.uri;
      const collectionItems = collectionItemsUri && (await retrieveAvsItems(appProvider, collectionItemsUri));
      searchCollection.items = collectionItems?.containers;
      dispatch(retrieveSearchCollectionlSuccess(searchCollection));
    } catch (error) {
      dispatch(retrieveSearchCollectionFailure());
    }
  };
};

/**
 * Hides the bookmark from resume tray on feed page
 * @param {object} appProvider
 * @param {string} contentType
 * @param {number} contentId
 * @param {string} bookmarkSetId
 * @param {object} requestBody
 */
export const hideBookmark = (appProvider, contentType, contentId, bookmarkSetId, requestBody) => {
  return async (dispatch) => {
    try {
      await updateBookmark(appProvider, contentType, contentId, bookmarkSetId, requestBody);
      dispatch(showToastNotification(i18n.t("removed_from_resume_notification")));
    } catch (err) {
      dispatch(showToastNotification(i18n.t("removed_from_resume_error")));
      throw err;
    }
  };
};
